import axios from 'axios';
import router from '../router';
import store from '@/store'
import { Message } from 'element-ui'
import crypto from '@/utils/cryptoJS.js'
import md5 from 'js-md5'
import utils from '../utils/utils';
import { getEncryCode, sendEncryCode } from '@/utils/encryptoKey.js'

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
    // if (router.currentRoute.path != '/') {
    //     router.replace({
    //         path: '/'
    //     });
    // }
    location.reload()
    // window.onload()
}
const toinfo = () => {
    router.replace({
        path: '/info',
        query: {
            redirect: router.currentRoute.fullPath
        }
    });
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
    // 状态码判断
    switch (status) {
        // 401: 未登录状态，跳转登录页
        case 401:
            Message.error('登录状态已失效')
            store.dispatch('logout')
            toLogin();
            break;
        // 403 token过期
        // 清除token并跳转登录页
        case -1:
            var exp = new Date();
            exp.setTime(exp.getTime() - 1);
            // document.cookie = "usertoken=null;expires=" + exp.toGMTString() + ";domain=" + process.env.VUE_APP_DOMAIN + ";path=/";
            localStorage.removeItem('token');
            setTimeout(() => {
                toLogin();
            }, 1000);
            break;
        case 405:
            setTimeout(() => {
                toinfo();
            }, 1000);
            break;
        // 404请求不存在
        case 404:
            break;
        default:
            console.log(other);
    }
}

// 创建axios实例
var instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    },
    timeout: 1000 * 12
});
// 设置post请求头
//instance.defaults.headers.post['Content-Type'] = 'application/json';
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
    async config => {
        // 生成随机key
        const randomStr = utils.randomString()
        await store.dispatch('addKey', { url: config.url, key: randomStr })
        // 加密key
        const jiamiStr = await getEncryCode(randomStr)
        // 加密参数
        config.data = await crypto.encrypt(JSON.stringify(config.data), randomStr)
        const token = localStorage.getItem('token');
        token && (config.headers['X-Access-Token'] = token);
        config.headers['st'] = jiamiStr
        return config;
    },
    error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    async res => {
        // console.log(res)
        const { data } = res
        // 解密返回值
        const key = store.state.randomKeyArr.find(item => {
            return item.url == res.config.url
        }).key
        await store.dispatch('delKey', key)
        if (res.status === 200) {
            if (data.code == 500) {
                Message.error(data.message)
                return Promise.reject(data);
            }
			if (data.code == 40001) {
			    Message.error(data.message)
			    return Promise.reject(data);
			}
            if (data.code == 0 && !data.success) {
			    Message.error(data.message);
                return Promise.reject(data);
            }
            if (res.data.code == 403 || res.data.code == 401) {
                errorHandle(res.data.status, "");
            } else if (res.data.code == -1) {
                errorHandle(res.data.code, "");
            } else {
                // 如果result 为 null
                if (!res.data.result) {
					if(res.data.result === undefined){
						return Promise.resolve(res.data);
					}else{
						return Promise.resolve(res.data.result);
					}
                    
                }
               
                const str = crypto.decrypt(res.data.result, key)
                // 判断返回值 是json字符串还是普通字符串
                try {
                    var obj=JSON.parse(str);
                    if(typeof obj == 'object' && obj ){
                        return Promise.resolve(JSON.parse(str));
                    }else{
                        return Promise.resolve(str);
                    }
        
                } catch(e) {
                    return Promise.resolve(str);
                }
            }
        } else {
            Promise.reject(res.data)
        }
    },
    // 请求失败
    async error => {
        const { response } = error;
        const key = store.state.randomKeyArr.find(item => {
            return item.url == error.config.url
        }).key
        await store.dispatch('delKey', key)
        if (response) {
            // 请求已发出，但是不在2xx的范围
            if (response.data.code == 403 || response.data.code == 401) {
                errorHandle(response.data.code, "");
            } else if (response.data.code == 405) {
                errorHandle(response.data.code, "");
            } else {
                return Promise.resolve(response.data);
            }
        }
    });

export default instance;
