<template>
    <div class="partner">
        <Header />
        <div class="ban">
            <img :src="info.banner" alt />
			<div>{{info.title}}</div>
        </div>
        <div class="planBg">
            <div class="wp w1200">
                <ul class="plan">
                    <li v-for="(item,index) in list" :key="index" @click="showinfo(item)">
                        <div class="top">
                            <img v-if="item.fileType == 'pdf'" class="icon" src="../assets/images/file_pdf.png" alt="">
                            <img v-else-if="item.fileType == 'apk'" class="icon" src="../assets/images/file_apk.png" alt="">
                            <img v-else-if="item.fileType == 'exe'" class="icon" src="../assets/images/file_exe.png" alt="">
                            <img v-else-if="item.fileType == 'png' || item.fileType == 'jpg'" class="icon" src="../assets/images/file_picture.png" alt="">
                            <img v-else-if="item.fileType == 'pptx'" class="icon" src="../assets/images/file_powerpoint.png" alt="">
                            <img v-else-if="item.fileType == 'xls' || item.fileType == 'xlsx'" class="icon" src="../assets/images/file_xls.png" alt="">
                            <img v-else-if="item.fileType == 'doc' || item.fileType == 'docx'" class="icon" src="../assets/images/file_word.png" alt="">
                            <img v-else-if="item.fileType == 'zip' || item.fileType == 'rar'" class="icon" src="../assets/images/file_zip.png" alt="">
							<h3>{{item.title}}</h3>
                            <div class="btn">
                                <span v-if="item.fileType == 'pdf' || item.fileType == 'png' || item.fileType == 'jpg'" @click.stop="showinfo(item)"><img src="../assets/images/icon_preview.png">预览</span>
                                <div @click.stop="true">
									<a @click.stop="download(item)"><img src="../assets/images/icon_download.png">下载</a>
								</div>
                            </div>
                        </div>
                        <div class="con">
                            <div class="des">
                                <p>{{item.info}}</p>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="planBtn" @click="sign">发起签约</div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Api from '@/request/api.js';
import download from "downloadjs";
export default {
    name: 'PartnerPlan',
    components: { 
        Header,
        Footer 
    },
    data() {
        return {
			list:[],
			id:0,
			info:{}
        }
    },
	created(){
		this.id = this.$route.query.id;
		//获取计划详情
		
		Api.getItemList({planId:this.id}).then(res => {
			console.log(res);
			this.list = res;
		})
		//获取计划详情内容
		Api.getPlanDetail({planId:this.id}).then(res => {
			this.info = res;
		})
		
	},
	methods:{
		sign(){
			//获取个人信息
			Api.getUserInfo().then(res => {
				if(res.userType == 1){
					this.$alert("您需要注册公司账号，并经过签约授权，才可继续签约", "提示").then(({ value }) => {
					  this.$router.push("/companyRegister");
					});
				}else{
					//获取是否已经提交公司授权书
					Api.getUserCertificate().then(res=>{
						if(res == null || res.status == -1){
							this.$alert("您需经过公司签约授权，下载授权书并盖章上传，才可继续签约", "提示").then(({ value }) => {
							  this.$router.push("/PartnerEmpower");
							});
							
						}else if(res.status == 1){
							//进入企业认证页面
							this.$alert("签约授权正在审核中，请耐心等待", "提示");
						}else if(res.status == 0){
							//通过授权认证，判断是否已经签约了
							Api.getDetailByPlanId({planId:this.id}).then(res=>{
								console.log(res);
								if(res == null || res.status <= 1 || (res.authDayNum < 30 && res.authDayNum >= 0)){
									this.$router.push("/partnersign?id="+this.id);
								}else if(res.status >= 3){
									this.$alert("您已完成签约","提示").then(() => {
										this.$router.push("/authIdentity");
									});
									
								}else{
									this.$router.push("/mypost");
								}
							})
						}
					})
				}
			})
		},
		showinfo(item){
			if(item.fileType == 'pdf' || item.fileType == 'png' || item.fileType == 'jpg'){
				window.open(item.file);
			}
			
		},
		download(item){
			let ua = navigator.userAgent.toLowerCase();
			//android终端
			let isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1; //ios终端
			let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			if ((/micromessenger/.test(ua))) {
				return this.$message.info("移动端暂不支持，请登录PC端进行下载");
			} else {
				if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
					return this.$message.info("移动端暂不支持，请登录PC端进行下载");
				}
			}
			let url = item.file;
			window.open(url);
			
			// let name = url.split("?")[0];
			// download(encodeURI(url),name.split("/").pop());
			// let fileName = item.file.split("/").pop();
			// const xhr = new XMLHttpRequest()
			// xhr.open('GET', url, true)
			// xhr.responseType = 'blob'
			// xhr.onload = (e) => {
			//   // 下载之后生成文件url 模拟a标签点击下载
			//   const url = window.URL.createObjectURL(xhr.response)
			//   const a = document.createElement('a')
			//   a.href = url
			//   a.download = fileName
			//   a.click()
			// }
			// xhr.send()
		}
	}
}
</script>
<style scoped lang="scss">
    .partner{
        padding: 80px 0 0;
        overflow: hidden;
    }
	.ban{
		position: relative;
		div{
			position: absolute;
			width: 900px;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			font-size: 40px;
			color: #fff;
			font-weight: bold;
		}
	}
    .planBg{
        padding: 30px 0 54px;
        background-color: #F5F5F7;
        overflow: hidden;
    }
    .plan{
        list-style: none;
        li{
            padding: 10px 20px;
            margin: 0 0 10px;
            background-color: #FFFFFF;
            overflow: hidden;
            .top{
                display: flex;
                margin: 10px 0;
                .icon{
                    flex-shrink: 0;
                    width: 24px;
                    height: 24px;
                    object-fit: contain;
                    margin-right: 8px;
                }
                h3{
                    flex: auto;
                    font-size: 16px;
                    color: #111111;
                    line-height: 24px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    cursor: pointer;
                    transition: all .5s;
                    &:hover{
                        color: #E73522;
                    }
                }
                .btn{
                    flex-shrink: 0;
                    display: flex;
                    color: #999999;
                    line-height: 24px;
                    margin-left: 8px;
                    span{
                        display: block;
                        cursor: pointer;
                        img{
                            width: 16px;
                            height: 16px;
                            margin-right: 9px;
                        }
                        + span{
                            margin-left: 40px;
                        }
                        &:hover{
                            color: #E73522;
                        }
                    }
					a{
						display: block;
						cursor: pointer;
						color: #999999;
						margin-left: 40px;
						img{
						    width: 16px;
						    height: 16px;
                            margin-right: 9px;
						}
						&:hover{
						    color: #E73522;
						}
					}
                }
            }
            .con{
                font-size: 14px;
                color: #666666;
                line-height: 2;
                margin: 10px 0;
                word-break: break-all;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
            }
        }
    }

    .planBtn{
        display: block;
        width: 280px;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 50px;
        border-radius: 3px;
        background: #E73522;
        text-align: center;
        margin: 30px auto;
        cursor: pointer;
    }
</style>