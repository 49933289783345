<template>
	<div class="header">
		<div class="wp1200">
			<div class="hdr-pc clearboth">
				<router-link to="/" class="logo">
					<img src="../assets/images/home/logo.png">
				</router-link>
				<ul class="nav">
					<li>
						<router-link to="/" class="a1">新闻公告</router-link>
					</li>
					<!-- <li>
						<span to class="a1" @click.prevent="toInfor">渠道发展</span>
					</li> -->
					<li v-if="hasLogin"> 
						<span to class="a1" @click.prevent="toProd">产品资料</span>
					</li>
					<li v-else> 
						<router-link to="/login" class="a1">产品资料</router-link>
					</li>
					<li v-if="hasLogin">
						<div class="a1 icon" v-if="user.userType==1" :class="num1==3 ? 'on' : ''" @click.self="dataUpdate3">论坛</div>
						<div class="a1 icon" v-else :class="num1==3 ? 'on' : ''" @click="$message({duration:1500,type:'error',message:'您需登录个人账号，才可继续浏览'});">论坛</div>
					</li>
					<li v-else>
						<router-link class="a1 icon" :class="num1==3 ? 'on' : ''"  to="/login">论坛</router-link>
					</li>
					<li v-if="hasLogin"> 
						<router-link to="/partner" class="a1">成为合作伙伴</router-link>
					</li>
					<li v-else> 
						<router-link to="/login" class="a1">成为合作伙伴</router-link>
					</li>
					<li> 
						<router-link to="/selectpartner" class="a1">查询合作伙伴</router-link>
					</li>
				</ul>
				<div class="m-nav2" :class="nav2Show ? 'active' : ''" @mouseleave="mouseLeave2">
					<div class="item item1">
						<div class="tit" v-if="istit">{{title}}</div>
						<ul class="ul-list-m1 innerbox" v-if="num1==3">
							<li :class="num2==index ? 'on' : ''" v-for="(item, index) in forumTree" :key="index">
								<div class="a1" @mouseover="mouseOver2(index)">{{item.name}}</div>
							</li>
						</ul>
					</div>
					<div class="item item3" :class="isshow ? 'active' : ''">
						<div class="con-m1 innerbox">
							<ul class="ul-list-m2 style3" v-if="num1==3&&forumTree[num2].itemList.length!=0">
								<li v-for="(item, index) in forumTree[num2].itemList">
									<div @click="toList(item, 2)">
										<a class="a1">{{item.name}}</a>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- 只有在添加active是鼠标移动上去才会出现退出下拉框 -->
				
				
				<div class="ban-m1-message" v-if="user.userType==1" @click="liuyan">
					<img src="../assets/images/message.png" style="width: 25px;height: 25px;"/>
					<template v-if="readNum > 0">
						<span v-if="readNum<=99">{{ readNum }}</span>
						<span v-else>...</span>
					</template>
				</div>
				<div class="ban-m1-message" @click="getmessage">
					<img src="../assets/images/myinfo/message.png" />
					<template v-if="messageNum.messageNum+noticenum > 0">
						<span v-if="messageNum.messageNum+noticenum<=99">{{ messageNum.messageNum+noticenum }}</span>
						<span v-else>...</span>
					</template>
				</div>
				<router-link to="/mypost" class="m-logo-m1 active">
					<div class="pic">
						<img src="../assets/images/home/icon-m2.png" v-if="!hasLogin">
						<img :src="user.avatar" v-else>
					</div>
					<div class="txt">
						<div class="txt1" v-if="!hasLogin">未登录</div>
						<div class="txt2" v-else>
							<div class="username">{{user.nickname}}</div>
							<div class="info" v-if="user.userType==2">企业中心</div>
							<div class="info" v-if="user.userType==1">个人中心</div>
							
						</div>
					</div>
					<div v-if="hasLogin" class="slideUp" @click.prevent.stop="logout">
						退出登录
						<div class="slideUp-icon"></div>
					</div>
				</router-link>
				<!--  :class="issearch ? 'active' : ''" -->
				<div class="m-search-mzw1">
					<input :readonly="!hasLogin" type="text" ref="searchInput" :placeholder="searchplaceholder" v-model="search" autocomplete="off" @focus="searchFoucs" @blur="searchBlur" @keyup.enter="searchGo"/>
					<div class="icon" @click="searchGo"></div>
				</div>
			</div>
			<el-dialog :visible="showdialog" width="1100px" @close="showdialog=false" :append-to-body="true" custom-class="messDialog">
			  <div slot="title" class="dialog-title">
				  <div class="title-name">
					  <div v-if="user.userType==1" :class="type==1?'active':''" @click="type=1,pageNo=1,getmessage()">论坛通知<span v-if="messageNum.messageNum > 0">{{messageNum.messageNum}}</span><div></div></div>
					  <div :class="type==2?'active':''" @click="type=2,pageNo=1,getmessage()">系统消息<span v-if="noticenum > 0">{{noticenum}}</span><div></div></div>
				  </div>
				  <div class="title-clear" @click="readstatus"><img src="../assets/images/myinfo/clear.png"/>全部标记为已读</div>
			  </div>
			  <div class="dialog-content" v-if="type==1">
				  <div class="dialog-content-item" v-for="(item,index) in messagelist" :key="index" @click="toinfo(item)">
					  <div class="dialog-content-item-top">
						  <div class="dialog-content-item-top-status" v-if="item.status==1"></div>
						  <div class="dialog-content-item-top-text">
							  <span>{{item.nickname}}</span>回复<span>我</span>
							  
						  </div>
						  <p v-html="item.content"></p>
					  </div>
					  <div class="dialog-content-item-time">
						  {{item.createTime}}
					  </div>
					  <div class="dialog-content-item-p">
						  <span>{{item.pnickname}}：</span>
						  <div v-html="item.pcontent"></div>
					  </div>
				  </div>
				  <div class="pagination-box">
				      <el-pagination v-if="messagelist.length > 0"
				          :total="total"
				          layout="prev, pager, next"
				          :page-size="10"
			              @current-change="handleCurrentChange"
				      ></el-pagination>
				  </div>
			  </div>
			  <div class="dialog-content" v-if="type==2">
				  <div class="dialog-content-item" style="display: flex;justify-content: space-between;" v-for="(item,index) in messagelist" :key="index" @click="toinfo(item)">
					  <div class="dialog-content-item-top">
						  <!-- <div class="dialog-content-item-top-status" v-if="item.status==1"></div> -->
						  <div class="dialog-content-item-top-text">
							  {{item.title}}
						  </div>
					  </div>
					  <div class="dialog-content-item-time" style="flex: 0 0 200px;text-align: right;">
						  <div>{{item.createTime}}</div>
							<div style="color: #333;margin-top: 10px;">查看详情 ></div>
					  </div>
				  </div>
				  <div class="pagination-box" v-if="messagelist.length > 0">
				      <el-pagination
				          :total="total"
				          layout="prev, pager, next"
				          :page-size="10"
			              @current-change="handleCurrentChange"
				      ></el-pagination>
				  </div>
			  </div>
			</el-dialog>

		</div>
		<div class="mHeader">
			<div class="mMenu" @click="menuFn">
				<img src="../assets/images/menu_btn@2x.png" alt="菜单" v-if="!isMenu">
				<img src="../assets/images/myinfo/icon-w1.png" alt="菜单关闭" v-else>
			</div>
			<div class="mLogo">
				<router-link to="/" class="mLogoLink">
					<img src="../assets/images/home/logo.png">
				</router-link>
			</div>
			<div class="mTop">
				<router-link to="/mypost" class="mTopItem mUser">
					<div class="mTopIcon">
						<img src="../assets/images/mob-user.png" v-if="!hasLogin">
						<img :src="user.avatar" v-else>
					</div>
				</router-link>
				<div class="mTopItem mNotice">
					<div class="mTopIcon" @click="getmessage"><img src="../assets/images/mob-notice.png" alt=""></div>					
					<template v-if="messageNum.messageNum+noticenum > 0">
						<span v-if="messageNum.messageNum+noticenum<=99" class="mNoticeNum">{{ messageNum.messageNum+noticenum }}</span>
						<span v-else class="mNoticeNum">...</span>
					</template>
				</div>
				<div class="mTopItem mChat" v-if="user.userType==1" @click="liuyan">
					<div class="mTopIcon"><img src="../assets/images/mob-chat.png" alt=""></div>
					<template v-if="readNum > 0">
						<span v-if="readNum<=99" class="mNoticeNum">{{ readNum }}</span>
						<span v-else class="mNoticeNum">...</span>
					</template>
				</div>
			</div>
			<div :class="['mMenuList',isMenu ? 'open' : '']">
				<el-menu
					class="mMenuLeft"
					text-color="#333333"
					active-text-color="#333333"
					@open="handleOpen"
					@close="handleClose"
					>
					<el-menu-item index="1"><router-link to="/" class="mMenuLink">新闻公告</router-link></el-menu-item>
					<!-- <el-menu-item index="2"><span to class="mMenuLink" @click.prevent="toInfor">渠道发展</span></el-menu-item> -->
					<el-menu-item index="3"><span to class="mMenuLink" @click.prevent="toProd">产品资料</span></el-menu-item>
					<el-submenu index="4" v-if="hasLogin">
						<template v-if="user.userType==1">
							<template slot="title"><span class="mMenuLink" @click="dataUpdate3">论坛</span></template>
							<el-menu-item v-for="(item, index) in forumTree" :key="index" :index="'4-'+index" @click="mouseOver2(index)">{{item.name}}</el-menu-item>
						</template>
						<template v-else>
							<template slot="title"><span class="mMenuLink" @click="$message({duration:1500,type:'error',message:'您需登录个人账号，才可继续浏览'});">论坛</span></template>
						</template>
					</el-submenu>
					<el-menu-item index="4" v-else>
						<router-link class="mMenuLink" :class="num1==3 ? 'on' : ''"  to="/login">论坛</router-link>
					</el-menu-item >
					<el-menu-item index="5">
						<router-link v-if="hasLogin" to="/partner" class="mMenuLink">成为合作伙伴</router-link>
						<router-link v-else to="/login" class="mMenuLink">成为合作伙伴</router-link>
					</el-menu-item>
					<el-menu-item index="6"><router-link to="/selectpartner" class="mMenuLink">查询合作伙伴</router-link></el-menu-item>
				</el-menu>
				<div class="mMentRight" :class="nav2Show ? 'active' : ''">
					<ul class="mMentRightList" v-if="num1==3&&forumTree[num2].itemList.length!=0">
						<li v-for="(item, index) in forumTree[num2].itemList">
							<div @click="toList(item, 2)">
								<router-link to>{{item.name}}</router-link>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<Liuyan ref="liuyan"></Liuyan>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Api from "@/request/api";
import Liuyan from '@/components/liuyan.vue';

	export default {
		name: 'Header',
		components: {
			Liuyan
		},
		props: {
			msg: String
		},
		data() {
			return {
				user: this.$store.state.user,
				userpic: "/src/assets/images/home/icon-m3.png",
				title: '查看全部渠道发展',
				titleArr: ['查看全部渠道发展', '查看全部产品资料'],
				istit: true,
				isshow: false,
				nav2Show: false,
				num1:0,
				num2:0,
				num3:0,
				num4:0,
				list2show:1,
				searchplaceholder:'快速搜索',
				issearch:false,
				search: '',
				showdialog:false,
				messagelist:[],
				total:0,
				pageNo:1,
				messageNum:{likeNum:0,messageNum:0,postsNum:0,subscribeNum:0},
				readNum:0,
				noticenum:0,
				type:1,
				isMenu: false //移动端菜单按钮
			};
		},
		created() {
			if (this.$store.getters.hasLogin) {
				Api.getUserNum().then(res => {
					this.messageNum = res;
					
				})
				Api.getnoticeNotReadNum().then(value=>{
					this.noticenum = Number(value);
				})
				
				//获取私信未度数量
				Api.getNotReadNum().then(res => {
					
					this.readNum = res;
				})
			}
			
		},
		watch:{
			$route(){
				console.log(124);
				this.num1 = 0;
			}
		},
		computed: {
			...mapState([
        		'inforTree',
				'forumTree'
			]),
			...mapGetters([
				'hasLogin'
			])
		},
		methods: {
			getmessage:function(){
				//获取消息列表
				if(this.showdialog == false){
					this.pageNo = 1;
					this.type = this.user.userType;
				}
				if(this.type == 1){
					Api.getMessageList(this.pageNo).then(res => {
						this.messagelist = res.records;
						this.total = res.total;
						this.showdialog = true;
					}).catch(err => {
						this.loading = false
					})
				}else{
					Api.getnoticeList(this.pageNo).then(res => {
						this.messagelist = res.records;
						this.total = res.total;
						this.showdialog = true;
					}).catch(err => {
						this.loading = false
					})
					
				}
				
			},
			// 分页查询
			handleCurrentChange(v) {
				this.pageNo = v
				this.getmessage()
			},
			readstatus:function(){
				if(this.type == 1){
					Api.updateMessageStatus({id:''}).then(res => {
						this.getmessage();
					})
				}else{
					Api.updatenoticeStatus({id:''}).then(res => {
						this.getmessage();
					})
				}
				Api.getUserNum().then(res => {
					this.messageNum = res;
					
				})
				Api.getnoticeNotReadNum().then(value=>{
					this.noticenum = Number(value);
				})
				
			},
            // 退出
            logout() {
                this.$store.dispatch('logout')
				this.$router.replace({ path: '/' })
				this.$router.go(0)
            },
            // 点击板块跳转
            async toList(item, level) {
                // 判断论坛是否关闭
                if (this.$store.state.forumStatus != 1) {
                    return this.$message.error('论坛已关闭')
                }
                // 判断该板块是否有权限
                let flag = false
                await Api.forumInfo(item.id).then(res => {
					if(res != null){
						flag = true
					}
                })
                if (!flag) { return }
				this.nav2Show = false
				let arr = []
				if (level == 3) {
					arr.push(this.forumTree[this.num2].name)
					arr.push(this.forumTree[this.num2].itemList[this.num3].name)
					arr.push(item.name)
				} else if (level == 4) {
					arr.push(this.forumTree[this.num2].name)
					arr.push(this.forumTree[this.num2].itemList[this.num3].name)
					arr.push(this.forumTree[this.num2].itemList[this.num3].itemList[this.num4].name)
					arr.push(item.name)
				} else if (level == 2) {
					arr.push(this.forumTree[this.num2].name)
					arr.push(item.name)
				}
				this.$store.dispatch('setForumCates', arr)
                this.$router.push({ path: '/forumlist', query: { pid: item.id } })
            },
			// 搜索
			searchGo() {
				if (!this.$store.getters.hasLogin) {
					return this.$message.info('登陆后可以搜索')
				}
				if (!this.search) {
					return
				}
				let search = this.search
				this.search = ''
				this.$refs.searchInput.blur()
				if (this.$route.path != '/searchResults') {
					this.$router.push({ name: 'SearchResults', params: { search } })
				} else {
					this.$store.dispatch('setGlobalSearch', search)
					// this.$router.replace({ name: 'SearchResults', params: { search } })
				}
				
			},
            toInfor() {
                if (this.$route.path == '/ChannelInformation') {
                    this.$router.go(0)
                    // this.$router.replace({ path: '/ChannelInformation' })
                } else {
                    this.$router.push({ path: '/ChannelInformation'})
                }
            },
            toProd() {
				if(this.user.userType==1){
					if (this.$route.path == '/GeneralProduct') {
						this.$router.go(0)
						// this.$router.replace({ path: '/GeneralProduct' })
					} else {
						this.$router.push({ path: '/GeneralProduct'})
					}
				}else{
					this.$message({duration:1500,type:'error',message:'您需登录个人账号，才可继续浏览'});
				}
                
            },
            toplan() {
                if (this.$route.path == '/Partner') {
                    this.$router.go(0)
                    // this.$router.replace({ path: '/' })
                } else {
                    this.$router.push({ path: '/Partner'})
                }
            },
			toinfo:function(item){
				if(this.type == 1){
					Api.updateMessageStatus({id:item.id}).then(res => {
						this.$router.push('/forumdetail?id=' + item.postsId + '&pid=' + item.classId+"#"+item.id);
						this.showdialog = false;
					})
				}else{
					this.$router.push("/messageinfo?id="+item.noticeUserId);
						this.showdialog = false;
				}
				
			},
			dataUpdate1() {
				let _this = this;
				_this.num1 = 1;
				_this.nav2Show = true;
				_this.istit = true;
				_this.title = _this.titleArr[0];
				_this.isshow = false;
			},
			dataUpdate2() {
				let _this = this;
				_this.num1 = 2;
				_this.nav2Show = true;
				_this.istit = true;
				_this.title = _this.titleArr[1];
				_this.isshow = false;
			},
			dataUpdate3() {
				let _this = this;
				_this.num1 = 3;
				_this.nav2Show = !_this.nav2Show;
				if(!_this.nav2Show){
					_this.num1 = 0;
				}
				_this.istit = false;
				_this.isshow = true;
			},
			mouseOver2(indexNum){
				let _this = this;
				_this.num2 = indexNum;
                _this.num3 = 0
                _this.num4 = 0
			},
			mouseOver3(indexNum){
				let _this = this;
				_this.num3 = indexNum;
                _this.num4 = 0
			},
			mouseOver4(indexNum){
				let _this = this;
				_this.num4 = indexNum;
			},
			mouseLeave2() {
				let _this = this;
				_this.nav2Show = false;
				_this.num1 = 0;
			},
			searchFoucs(){
				let _this = this;
				_this.searchplaceholder="请输入搜索关键词";
				_this.issearch = true;
			},
			searchBlur(){
				let _this = this;
				_this.searchplaceholder="快速搜索";
				_this.issearch = false;
			},
			liuyan(){
				this.$refs.liuyan.show();
			},
			menuFn() {
				let _this = this;
				_this.isMenu = !_this.isMenu;
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			}
		}
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.m-search-mzw1{
		width: 200px;
		height: 80px;
		border-left: 1px solid #e4e4e4;
		border-bottom: 1px solid #e4e4e4;
		box-sizing: border-box;
		float: right;
		position: relative;
		transition: width .5s;
		padding-right: 40px;
	}
	.m-search-mzw1.active{
		width: 340px;
	}
	.m-search-mzw1 input{
		width: 100%;
		height: 100%;
		border: none;
		outline: none;
		padding: 0 20px;
		box-sizing: border-box;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	.m-search-mzw1 input::-webkit-input-placeholder {color: #999;}
	.m-search-mzw1 input:-moz-placeholder {color: #999;}
	.m-search-mzw1 input::-moz-placeholder {color: #999;}
	.m-search-mzw1 input::-ms-input-placeholder {color: #999;}
	
	.m-search-mzw1 input:-internal-autofill-previewed,
	.m-search-mzw1 input:-internal-autofill-selected {
		-webkit-text-fill-color: #999 !important;
		transition: background-color 5000s ease-in-out 0s !important;
	}
	
	.m-search-mzw1 .icon{
		width: 32px;
		height: 32px;
		cursor: pointer;
		background-image: url(../assets/images/home/search2.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
	}
	.wp1200 {
		max-width: 1200px;
		margin: 0 auto;
	}

	.clearboth::after {
		content: "";
		display: block;
		clear: both;
	}

	.innerbox::-webkit-scrollbar {
		width: 4px;
	}

	.innerbox::-webkit-scrollbar-thumb {
		border-radius: 5px;
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background: rgba(0, 0, 0, 0.2);
	}

	.innerbox::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 0;
		background: rgba(0, 0, 0, 0.1);
	}

	.header {
		width: 100%;
		// height: 80px;
		position: fixed;
		top: 0;
		left: 0;
		background-color: #fff;
		z-index: 999;
		box-shadow: 0px 1px 0 0px rgba(0, 0, 0, 0.1);
		// border-bottom: 1px solid #e4e4e4;
		box-sizing: border-box;
	}

	.header .hdr-pc {
		position: relative;
		height: 80px;
	}

	.hdr-pc .logo {
		width: 134px;
		height: 38px;
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	.hdr-pc .logo img {
		width: 100%;
		height: 100%;
		display: block;
	}

	.hdr-pc .nav {
		float: left;
		margin-left: 160px;
	}

	.nav::after {
		content: "";
		display: block;
		clear: both;
	}

	.nav li {
		float: left;
		position: relative;
	}

	.nav .a1 {
		display: block;
		transition: .5s;
		padding: 0 20px;
		line-height: 80px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #2E2E2E;
		position: relative;
		cursor: pointer;
	}

	.nav .a1.icon::after {
		content: "";
		display: block;
		width: 12px;
		height: 12px;
		background-image: url(../assets/images/home/icon2-m1.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: absolute;
		top: 50%;
		right: 5px;
		transform: translateY(-50%);
	}

	.nav .a1.on {
		font-weight: 500;
		color: #E83421;
	}

	.nav .a1.on.icon::after {
		background-image: url(../assets/images/home/icon2-m1-2.png);
		animation: navrotate .5s linear;
	}

	@keyframes navrotate{
		0% {
			transform: translateY(-50%) rotateZ(180deg);
		}
		
		100% {
			transform: translateY(-50%) rotateZ(0deg);
		}
	}
	
	.m-logo-m1 {
		display: block;
		width: 160px;
		height: 80px;
		background: #E73522;
		float: right;
		padding: 0 20px;
		box-sizing: border-box;
		position: relative;
		// overflow: hidden;
	}
	.m-logo-m1::after{
		content: "";
		display: block;
		clear: both;
	}
	.m-logo-m1 .pic {
		width: 28px;
		height: 28px;
		border-radius: 50%;
		overflow: hidden;
		position: absolute;
		top: 50%;
		left: 20px;
		transform: translateY(-50%);
	}

	.m-logo-m1 .pic img {
		width: 100%;
		height: 100%;
		display: block;
	}

	.m-logo-m1 .txt {
		width: 75px;
		height: 100%;
		float: right;
		display: flex;
		justify-content: left;
		align-items: center;
		text-align: left;
	}

	.m-logo-m1 .txt1 {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		width: 100%;
	}

	.m-logo-m1 .txt2 {
		width: 100%;
	}

	.m-logo-m1 .username {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
	}

	.m-logo-m1 .info {
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		opacity: 0.7;
	}
	.m-logo-m1 .slideUp{
		width: 100%;
		height: 44px;
		padding: 0 20px;
		background-color: #E73522;
		box-sizing: border-box;
		font-size: 16px;
		line-height: 44px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		text-align: left;
		position: absolute;
		left: 0;
		top: 0;
		transition: .5s;
		z-index: -1;
		display: none;
	}
	.m-logo-m1 .slideUp-icon{
		width: 16px;
		height: 16px;
		background-image: url(../assets/images/home/exit2.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
	}
	.m-logo-m1.active .slideUp{
		display: block;
	}
	.m-logo-m1.active:hover .slideUp{
		top: 80px;
	}
	.m-nav2 {
		width: 100%;
		/* height: 550px; */
		height: 0;
		transition: height .2s;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		background-color: #fff;
		z-index: 10;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		overflow: hidden;
		z-index: 110;
	}
	.m-nav2.active{
		height: 60vh;
	}
	.m-nav2 .item {
		height: 100%;
		padding: 0 40px 40px;
		box-sizing: border-box;
		overflow: hidden;
	}

	.m-nav2 .item .tit {
		font-size: 16px;
		line-height: 30px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #111111;
		position: relative;
		margin-bottom: 15px;
	}

	.m-nav2 .item .tit::after {
		content: "";
		display: block;
		width: 14px;
		height: 14px;
		background-image: url(../assets/images/home/icon-m4.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		right: 25px;
		transform: translateY(-50%);
	}

	.m-nav2 .item1 {
		width: 260px;
		background-color: #F5F5F7;
		padding-top: 35px;
	}

	.m-nav2 .item2 {
		width: 0;
		padding: 0;
		background-color: #fafafb;
		transition: width .5s;
	}
	.m-nav2 .item2 .item-con{
		padding: 40px;
		height: 100%;
		box-sizing: border-box;
	}
	.m-nav2 .item2 .ul-list-m1{
		opacity: 0;
	}
	.m-nav2 .item3 {
		padding-top: 45px;
		// padding-bottom: 0;
		padding-bottom: 20px;
		flex: 1;
	}

	.m-nav2 .item .con-m1 {
		overflow-x: hidden;
		overflow-y: auto;
		height: 100%;
	}

	.m-nav2 .item2.hide {
		width: 260px;
	}
	
	
	.ul-list-m1 {
		overflow-x: hidden;
		overflow-y: auto;
		height: 100%;
	}
	.m-nav2 .item1 .ul-list-m1{
		height: calc(100% - 45px);
	}
	.m-nav2 .item2.hide .ul-list-m1{
		opacity: 1;
	}
	// .m-nav2 .item3 .ul-list-m1{
	// 	height: 100%;
	// }
	.ul-list-m1 li {}

	.ul-list-m1 .a1 {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #2e2e2e;
		line-height: 40px;
		position: relative;
		transition: .5s;
		display: inline-block;
		cursor: pointer;
	}

	.ul-list-m1 li.on .a1,
	.ul-list-m1 li .a1:hover {
		color: #E83421;
	}

	.ul-list-m1 li .a1::after {
		content: "";
		display: none;
		height: 2px;
		width: 100%;
		border-radius: 1px;
		background-color: #E83421;
		position: absolute;
		left: 0;
		bottom: -5px;
		transition: .5s;
	}

	.ul-list-m1 li.on .a1::after,
	.ul-list-m1 li .a1:hover::after {
		display: block;
	}

	.ul-list-m2.style1 {
		column-count: 4;
		padding-bottom: 20px;
	}

	.ul-list-m2.style2 {
		column-count: 3;
		padding-bottom: 5px;
		height: 480px;
	}

	.ul-list-m2.style3 {
		column-count: 3;
		// padding-bottom: 20px;
		padding-bottom: 0;
		height: auto;
	}

	.ul-list-m2 {
		height: 470px;
		box-sizing: border-box;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.ul-list-m2 li {
		margin-bottom: 30px;
	}
	.ul-list-m2 li:hover a{
		color: #E83421;
	}
	.g-tit-m1 {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #E83421;
		line-height: 20px;
		position: relative;
		margin-bottom: 15px;
	}

	.g-tit-m1::after {
		content: "";
		display: block;
		width: 14px;
		height: 14px;
		position: absolute;
		background-image: url(../assets/images/home/icon-m4-2.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.g-tit-m1.style-m1::after {
		left: 75px;
		bottom: 3px;
	}

	.g-tit-m1.style-m2::after {
		left: 160px;
		bottom: 3px;
	}

	.m-list-m1 {}

	.m-list-m1 .it1 {}

	.m-list-m1 .a1 {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #2E2E2E;
		line-height: 35px;
		position: relative;
	}
	.m-list-m1 .it1.on .a1,
	.m-list-m1 .it1 .a1:hover {
		color: #E83421;
	}
	
	// .m-list-m1 .it1 .a1::after {
	// 	content: "";
	// 	display: none;
	// 	height: 2px;
	// 	width: 100%;
	// 	border-radius: 1px;
	// 	background-color: #E83421;
	// 	position: absolute;
	// 	left: 0;
	// 	bottom: -5px;
	// 	transition: .5s;
	// }
	
	// .m-list-m1 .it1.on .a1::after,
	// .m-list-m1 .it1 .a1:hover::after {
	// 	display: block;
	// }
	.ban-m1-message{
		width: 50px;
		height: 50px;
		margin-left: 0px;
		line-height: 30px;
		position: relative;
		cursor: pointer;
		margin-top: 15px;
		margin-right: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.ban-m1-message img{
		width: 50px;
		height: 50px;
	}
	.ban-m1-message span{
		width: 18px;
		height: 18px;
		background: #E73522;
		border-radius: 50%;
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 18px;
		text-align: center;
		position: absolute;
		display: block;
		top: 4px;
		right: 6px;
	}
	.dialog-title{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-right: 40px;
	}
	.title-name{
		font-size: 20px;
		font-weight: 500;
		color: #333333;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		cursor: pointer;
		&>div{
			margin-right: 80px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			position: relative;
			span{
				width: 24px;
				height: 20px;
				background: #E73522;
				border: 2px solid #FFFFFF;
				border-radius: 100%;
				font-size:12px;
				display: block;
				text-align: center;
				line-height: 17px;
				color: #fff;
				margin-left: 5px;
			}
			
		}
		.active{
			div{
				position: absolute;
				bottom: -12px;
				left: 0px;
				height: 2px;
				width:80px;
				background-color: #111111;
			}
			
		}
	}
	.title-clear img{
		width: 18px;
		height: 18px;
		margin-right: 5px;
	}
	.title-clear{
		font-size: 14px;
		font-weight: 400;
		color: #666666;
		margin-left: 55px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		cursor: pointer;
	}
	.dialog-content-item{
		margin: 0px 30px;
		padding: 20px 0px;
		border-bottom: 1px solid #DDDDDD;
		cursor: pointer;
	}
	.dialog-content-item-top{
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}
	.dialog-content-item-top-status{
		width: 12px;
		height: 12px;
		background: #E73522;
		border-radius: 50%;
		margin-top: 6px;
		flex: 0 0 12px;
	}
	.dialog-content-item-top-text{
		font-size: 14px;
		font-weight: 500;
		color: #2E2E2E;
		line-height: 24px;
	}
	.dialog-content-item-top p{
		font-size: 14px;
		font-weight: 500;
		color: #2E2E2E;
		line-height: 24px;
	}
	.dialog-content-item-top-text span{
		font-size: 14px;
		font-weight: 500;
		color: #E73522;
		line-height: 24px;
		margin: 0px 5px;
	}
	.dialog-content-item-time{
		font-size: 14px;
		font-weight: 400;
		color: #33ADA6;
		text-align: right;
	}
	.dialog-content-item-p{
		width: 100%;
		background: #F5F5F7;
		padding: 13px 20px;
		box-sizing: border-box;
		margin-top: 10px;
		font-size: 14px;
		font-weight: 500;
		color: #666666;
		line-height: 18px;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}
	.dialog-content-item-p span{
		color: #E73522;
	}
	.dialog-content{
		height: 500px;
		overflow: auto;
	}
	.pagination-box{
		text-align: center;
		margin-top: 20px;
	}
</style>
<style lang="scss" scoped>
    .mHeader{
        height: 1.4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 .3rem;
        background-color: #FFFFFF;
        z-index: 10;
    }
    .mMenu{
        width: .48rem;
        height: .48rem;
        cursor: pointer;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .mLogo{
        position: absolute;
        left: 50%;
        top: 50%;
        width: 1.7rem;
		height: auto;
        transform: translate(-50%,-50%);
        a{
            display: block;
			width: 100%;
            img{
                display: block;
				width: 100%;
            }
        }
    }
    .mTop{
        display: flex;
        align-items: center;
        .mTopItem{
            position: relative;
            margin-left: .2rem;
            &:first-child{
                margin-left: 0;
				border-radius: 50%;
				overflow: hidden;;
            }
            .mTopIcon{
                display: block;
                width: .4rem;
                height: .4rem;
                cursor: pointer;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .mNoticeNum{
                position: absolute;
                right: 0;
                top: 0;
                display: block;
                width: .2rem;
                height: .2rem;
                font-size: .2rem;
                color: #FFFFFF;
                line-height: .2rem;
                border-radius: 50%;
                background-color: #e73522;
                text-align: center;
                overflow: hidden;
            }
        }
    }
    .mMenuList{
        position: fixed;
        top: 1.4rem;
        left: -3.2rem;
        bottom: 0;
        width: 3.2rem;
        background-color: #F4F5F7;
		opacity: 0;
		visibility: hidden;
		transition: all .5s;
		&.open{
			left: 0;
			opacity: 1;
			visibility: visible;
		}
        .mMenuLeft{
            background-color: #F4F5F7;
            .el-menu-item{
                padding: 0 !important;
				height: 1rem;
				line-height: 1rem;
                .mMenuLink{
                    display: block;
					font-size: .28rem;
					padding: 0 .3rem;
                    &:hover{
                        color: #333333;
                    }
                }
                &:focus,
                &:hover{
                    background-color: transparent;
                }
            }
            .el-submenu{
                ::v-deep .el-submenu__title{
					height: 1rem;
					line-height: 1rem;
					padding-left: .3rem !important;
                    &:focus,
                    &:hover{
                        background-color: transparent;
                    }
					
					.el-submenu__icon-arrow{
						font-size: .3rem;
						margin-top: -.15rem;
					}
                }
                .mMenuLink{
                    display: block;
					font-size: .28rem;
                }
				.el-menu-item{
					height: auto;
					line-height: .4rem;
					padding: .2rem 0 !important;
					padding-right: .3rem !important;
					padding-left: .7rem !important;
					font-size: .24rem;
					white-space: normal;
					min-width: 0;
				}
                &.is-active{
                    ::v-deep .el-submenu__title{
                        background-color: #FFFFFF;
                    }
                }
                &.is-opened{
                    background-color: #FFFFFF;
                    ::v-deep .el-submenu__title{
                        position: relative;
                        background-color: #FFFFFF;
                        &::before{
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            display: block;
                            content: "";
                            width: .04rem;
                            height: .28rem;
                            background-color: #e73522;
                        }
						
                    }
                }
            }
        }
		.mMentRight{
			position: absolute;
			left: 100%;
			top: 0;
			width: calc(100vw - 3.2rem);
			height: 100%;
			background-color: #FFFFFF;
			box-shadow: -.04rem 0px .1rem 0px rgba(0,0,0,0.1);
			display: none;
			&.active{
				display: block;
			}
			.mMentRightList{
				list-style: none;
				li{
					line-height: .4rem;
					a{
						display: block;
						font-size: .24rem;
						color: #333333;
						padding: .2rem .3rem;
					}
				}
			}
		}
    }
@media only screen and (max-width: 750px) {
	::v-deep .messDialog{
		width: 96% !important;
		.el-dialog__header{
			padding: .3rem;
		}
		.el-dialog__headerbtn{
			font-size: .38rem;
			right: .3rem;
			top: .3rem;
		}
		.dialog-title{
			margin-right: .5rem;
			.title-name{
				font-size: .32rem;
				> div{
					margin-right: .3rem;
				}
				.active{
					div{
						width: 1.28rem;
						height: .02rem;
						bottom: -.2rem;
					}
				}
			}
			.title-clear{
				font-size: .24rem;
				margin-left: .3rem;
				img{
					width: .32rem;
					height: .32rem;
					margin-right: .06rem;
				}
			}
		}
		.el-dialog__body{
			padding: .3rem 0;
		}
		.dialog-content{
			height: 6rem;
			padding: 0 .3rem;
			.dialog-content-item{
				margin: 0;
				padding: .2rem 0 .3rem;
				.dialog-content-item-top{
					.dialog-content-item-top-text{
						font-size: .28rem;
						line-height: .5rem;
						span{
							font-size: .28rem;
						}
					}
					p{
						font-size: .28rem;
						line-height: .5rem;
					}
				}
				.dialog-content-item-time{
					font-size: .24rem;
				}
				.dialog-content-item-p{
					font-size: .24rem;
					padding: .2rem .3rem;
					margin-top: .1rem;
					line-height: 1.8;
				}
			}
		}
	}
	.pagination-box{
		margin-top: .3rem;
	}

}
</style>

