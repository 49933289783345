<template>
	<div class="footer">
		<div class="wp1200">
			<div class="ftr">
				<div class="ftr-m1">
					<div class="ftr-l">
						<div class="m-list-m2">
							<!-- <div class="item">
								<div class="tit">渠道发展</div>
								<ul>
									<li v-for="(item,index) in inforTree" @click="toInfor(item.id)" :key="index">
										<router-link to class="a1">{{item.title}}</router-link>
									</li>
								</ul>
							</div> -->
							<div class="item">
								<div class="tit">产品资料</div>
								<ul>
									<li v-for="(item,index) in productTree" :key="index">
										<a @click.prevent="toProduct(item)" class="a1">{{item.title}}</a>
									</li>
									<!-- <li>
										<router-link to="" class="a1">通行产品</router-link>
									</li> -->
								</ul>
							</div>
							<div class="item">
								<div class="tit">论坛</div>
								<ul>
									<li>
										<router-link to class="a1">商汤中小企业产品和方案</router-link>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="ftr-r">
						<div class="m-info-m1">
							<router-link to="/" class="logo">
								<img src="../assets/images/home/logo.png">
							</router-link>
							<div class="txt">
								<div class="tel">商业合作：400 900 5986</div>
								<div class="date">( 周一至周五 9:00-12:00, 13:00-18:00 )</div>
								<div class="email">违法和不良信息举报：business@sensetime.com</div>
							</div>
							<ul>
								<li>
									<div class="con-a1">
										<router-link to="" class="a1">
											<img src="../assets/images/home/icon-z1.png" class="img1">
											<img src="../assets/images/home/icon-z1-2.png" class="img2">
										</router-link>
										<div class="hd-share-qrcode">
											<img src="../assets/images/home/hd-share-img01.jpg" >
										</div>
									</div>
									
								</li>
								<li>
									<div class="con-a1">
										<router-link to="" class="a1">
											<img src="../assets/images/home/icon-z2.png" class="img1">
											<img src="../assets/images/home/icon-z2-2.png" class="img2">
										</router-link>
										<div class="hd-share-qrcode">
											<img src="../assets/images/home/hd-share-img01.jpg" >
										</div>
									</div>
								</li>
								<li>
									<div class="con-a1">
										<router-link to="" class="a1">
											<img src="../assets/images/home/icon-z3.png" class="img1">
											<img src="../assets/images/home/icon-z3-2.png" class="img2">
										</router-link>
										<div class="hd-share-qrcode">
											<img src="../assets/images/home/hd-share-img01.jpg" >
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="ftr-m2">
					<div class="g-copyright-m1">
						<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">京 ICP 备 15000892 号 - 8</a> | <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802039141">京公网安备 11010802039141号</a> | <a target="_blank" href="https://sensetime-partner.com">© 2014-2023 SenseTime. All Rights Reserved. 北京市商汤科技开发有限公司</a>	<a target="_blank" href="https://www.etycx.com/">技术支持：北京时代创信</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
	import { mapState } from 'vuex'
	import Api from '@/request/api';
	export default {
		name: 'Footer',
		props: {
			
		},
		data() {
			return {
				user: this.$store.state.user,
			};
		},
		computed: {
			...mapState([
				'productTree',
				'inforTree'
			])
		},
		methods: {
			async toProduct(e) {
				if(this.user.userType==1){
					// if(e.applyType == 0 && e.applyStatus == 1){
					// 		this.$confirm("您尚未开通该目录权限，是否申请开通？","提示").then(()=>{
					// 			Api.doApply({inforClassId:e.id}).then(res=>{
					// 				this.$message.success("申请已提交，请耐心等待");
					// 			})
					// 		})
					// }else{
						if (this.$route.path == '/GeneralProduct') {
							this.$router.go(0)
							// this.$router.replace({ path: '/GeneralProduct' })
						} else {
							await this.$store.dispatch('setTempProductId', e.id)
							this.$router.push({ path: '/GeneralProduct' })
						}
					// }
				}else{
					this.$message({duration:1500,type:'error',message:'您需登录个人账号，才可继续浏览'});
				}
				
			},
			async toInfor(id) {
				await this.$store.dispatch('setTempProductId', id)
				this.$router.push({ path: '/ChannelInformation' })
			}
		},
	}
</script>

<style scoped  lang="scss">
	.wp1200{
		max-width: 1200px;
		margin: 0 auto;
	}
	.footer{
		background-color: #fff;
	}
	.footer .ftr{}
	.footer .ftr-m1{
		overflow: hidden;
		padding-top: 90px;
		padding-bottom: 50px;
		border-bottom: 1px solid #e4e4e4;
	}
	.footer .ftr-l{
		float: left;
	}
	.footer .ftr-r{
		float: right;
	}
	.m-list-m2{
		overflow: hidden;
		width: 460px;
		display: flex;
		justify-content: space-between;
	}
	.m-list-m2 .item{
		
	}
	.m-list-m2 .tit{
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #333333;
		margin-bottom: 40px;
	}
	.m-list-m2 ul{}
	
	.m-list-m2 .a1{
		display: block;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 36px;
		transition: .5s;
	}
	.m-list-m2 li:hover .a1{
		color: #E83421;
	}
	.m-info-m1{}
	.m-info-m1 .logo{
		width: 134px;
		height: 38px;
		overflow: hidden;
		display: block;
		margin-bottom: 60px;
	}
	.m-info-m1 .logo img{
		width: 100%;
		height: 100%;
		display: block;
	}
	.m-info-m1 .txt{
		margin-bottom: 55px;
	}
	.m-info-m1 .tel{
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #333333;
	}
	.m-info-m1 .date{
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 30px;
	}
	.m-info-m1 .email{
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #333;
		line-height: 30px;
	}
	.m-info-m1 ul{}
	.m-info-m1 li{
		float: left;
		margin-right: 23px;
	}
	.m-info-m1 .con-a1{
		width: 30px;
		height: 30px;
		position: relative;
	}
	.m-info-m1 .a1{
		display: block;
		width: 30px;
		height: 30px;
		overflow: hidden;
	}
	.m-info-m1 .a1 img{
		width: 100%;
		height: 100%;
		display: block;
		transition: .5s;
	}
	.m-info-m1 .con-a1 .hd-share-qrcode{
		overflow: hidden;
		width: 106px;
		height: 106px;
		position: absolute;
		top: -125px;
		left: 50%;
		transform: translateX(-50%);
		display: none;
		border: 1px solid #e4e4e4;
		padding: 5px;
		background-color: #fff;
	}
	.m-info-m1 .con-a1 .hd-share-qrcode img{
		width: 100%;
		height: 100%;
		display: block;
	}
	.m-info-m1 li:hover .img1,
	.m-info-m1 li:hover .img2{
		transform: translateY(-30px);
	}
	.m-info-m1 li:hover .hd-share-qrcode{
		display: block;
	}
/* 	.m-info-m1 .img2{
		display: none;
	}
	.m-info-m1 li:hover .img1{
		display: none;
	}
	.m-info-m1 li:hover .img2{
		display: block;
	} */
	.footer .ftr-m2{}
	.g-copyright-m1{
		font-size: 14px;
		line-height: 75px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #999999;
		display: flex;
		justify-content: space-between;
		a{
			color: #999;
			&:hover{
				color: #999;
			}
		}
	}
	.g-copyright-m1 .a1{
		color: #999;
		transition: .5s;
	}
	.g-copyright-m1 .a1:hover{
		color: #E83421;
	}
	@media only screen and (max-width: 750px) {
		.wp1200{
			margin: 0 .3rem;
		}
		.footer{
			.ftr-m1{
				padding: .6rem 0;
				.ftr-l,
				.ftr-r{
					float: none;
				}
				.m-list-m2{
					display: block;
					width: 100%;
					.item{
						padding: .1rem 0;
						border-bottom: 1px dashed rgba($color: #000000, $alpha: 0.1);
						&:last-child{
							border-bottom: none;
						}
					}
					.tit{
						font-size: .32rem;
						line-height: 1.4;
						margin: .1rem 0;
					}
					ul{
						display: flex;
						flex-wrap: wrap;
						margin: 0 -.15rem .2em;
						li{
							padding: 0 .15rem;
							.a1{
								font-size: .28rem;
								line-height: 2;
							}
						}
					}
				}
				.m-info-m1{
					.logo{
						width: 40%;
						max-width: 268px;
						height: auto;
						margin: .5rem 0;
					}
					.txt{
						margin-bottom: .5rem;
						.tel{
							font-size: .36rem;
						}
						.date{
							font-size: .28rem;
						}
						.email{
							font-size: .28rem;
							line-height: 1.8;
						}
					}
					ul{
						display: flex;
						justify-content: center;
						li{
							float: none;
							margin: 0 .15rem;
							.con-a1{
								width: .5rem;
								height: .5rem;
								.a1{
									width: 100%;
									height: 100%;
								}
								.hd-share-qrcode{
									width: 1.8rem;
									height: 1.8rem;
									top: -2rem;
									padding: .1rem;
								}
							}
							&:hover{
								.img1,
								.img2{
									transform: translateY(-.5rem);
								}
							}
						}
					}
				}
			}
			.g-copyright-m1{
				display: block;
				font-size: .24rem;
				line-height: 1.8;
				padding: .3rem 0;
				text-align: center;
			}
		}
	}
</style>
