<template>
    <div class="partner">
        <Header />
        <div class="ban">
            <img :src="info.banner" alt />
			<div>{{info.title}}-签约</div>
        </div>
        <div class="parSortBg">
            <div class="wp w1200">
                <ul class="parSort">
                    <li :class="step==1?'active':''"><span>填写认证信息</span></li>
                    <li :class="step==2?'active':''"><span>信息核对</span></li>
                    <li :class="step==3?'active':''"><span>签署合作协议</span></li>
                </ul>
            </div>
        </div>
        <div class="parSignBg">
            <div class="wp w1200">
                <div class="parSign" v-if="step==1">
                    <el-form ref="parSignForm" :model="parSignForm" :rules="rules" class="parSignForm">
                        <div class="parSignItem">
                            <div class="parSignCon">
                                <label class="parSignLabel">代理主要品牌I<span>*</span></label>
                                <el-form-item prop="firstBrandId">
                                    <el-select v-model="parSignForm.firstBrandId" placeholder="选择">
                                        <el-option v-for="item in brandList" :key="item.id+'one'" :label="item.info" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="parSignCon">
                                <label class="parSignLabel">大类<span>*</span></label>
                                <el-form-item prop="firstClassId">
                                    <el-select v-model="parSignForm.firstClassId" placeholder="选择">
                                        <el-option v-for="item in daleiList" :key="item.id+'one'" :label="item.info" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="parSignCon">
                                <label class="parSignLabel">年销售额<span>*</span></label>
                                <el-form-item prop="firstClassId">
                                    <el-select v-model="parSignForm.firstSalesVolumeId" placeholder="选择">
                                        <el-option v-for="item in xiaoshoueList" :key="item.id+'one'" :label="item.info" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="parSignItem">
                            <div class="parSignCon">
                                <label class="parSignLabel">代理主要品牌II</label>
                                <el-form-item prop="secondBrandId">
                                    <el-select v-model="parSignForm.secondBrandId" placeholder="选择">
										<el-option label="无" :value="0"></el-option>
                                        <el-option v-for="item in brandList" :key="item.id+'one'" :label="item.info" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="parSignCon" v-if="parSignForm.secondBrandId">
                                <label class="parSignLabel">大类<span>*</span></label>
                                <el-form-item prop="secondClassId">
                                    <el-select v-model="parSignForm.secondClassId" placeholder="选择">
                                        <el-option v-for="item in daleiList" :key="item.id+'one'" :label="item.info" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="parSignCon" v-if="parSignForm.secondBrandId">
                                <label class="parSignLabel">年销售额<span>*</span></label>
                                <el-form-item prop="secondSalesVolumeId">
                                    <el-select v-model="parSignForm.secondSalesVolumeId" placeholder="选择">
                                        <el-option v-for="item in xiaoshoueList" :key="item.id+'one'" :label="item.info" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>
						<div class="parSignItem">
                            <div class="parSignCon">
                                <label class="parSignLabel">代理主要品牌III</label>
                                <el-form-item prop="thirdBrandId">
                                    <el-select v-model="parSignForm.thirdBrandId" placeholder="选择">
										<el-option label="无" :value="0"></el-option>
                                        <el-option v-for="item in brandList" :key="item.id+'one'" :label="item.info" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="parSignCon" v-if="parSignForm.thirdBrandId">
                                <label class="parSignLabel">大类<span>*</span></label>
                                <el-form-item prop="thirdClassId">
                                    <el-select v-model="parSignForm.thirdClassId" placeholder="选择">
                                        <el-option v-for="item in daleiList" :key="item.id+'one'" :label="item.info" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="parSignCon" v-if="parSignForm.thirdBrandId">
                                <label class="parSignLabel">年销售额<span>*</span></label>
                                <el-form-item prop="thirdSalesVolumeId">
                                    <el-select v-model="parSignForm.thirdSalesVolumeId" placeholder="选择">
                                        <el-option v-for="item in xiaoshoueList" :key="item.id+'one'" :label="item.info" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="parSignItem">
                            <div class="parSignCon">
                                <label class="parSignLabel">推荐人</label>
                                <el-form-item prop="referrer">
                                    <el-input placeholder="请输入推荐人" v-model="parSignForm.referrer"></el-input>
                                </el-form-item>
                            </div>
                            <div class="parSignCon">
                                <label class="parSignLabel">公司</label>
                                <el-form-item prop="companyName">
                                    <el-input placeholder="请输入公司名称" v-model="parSignForm.companyName"></el-input>
                                </el-form-item>
                            </div>
                            <div class="parSignCon">
                                <label class="parSignLabel">手机号</label>
                                <el-form-item prop="phone">
                                    <el-input placeholder="请输入手机号" v-model="parSignForm.phone"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="parSignItem">
                            <div class="parSignCon">
                                <label class="parSignLabel">授权区域<span>*</span></label>
                                <el-form-item prop="province">
                                    <el-select v-model="parSignForm.province" multiple :filterable="true" placeholder="选择省份，多选">
                                        <el-option v-for="item in city" :key="item" :label="item" :value="item"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="parSignCon">
                                <label class="parSignLabel">对接商汤渠道经理<span>*</span><i>若无对接人，可选“无”</i></label>
                                <el-form-item prop="appointUserId">
                                    <el-select remote reserve-keyword :remote-method="remoteMethod" v-model="parSignForm.appointUserId" :filterable="true" placeholder="输入对接渠道经理">
										<!-- <el-option label="无" value="0"></el-option> -->
                                        <el-option v-for="item in userList" :key="item.id+'user'" :label="item.realname" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="parSignItem">
                            <div class="parSignfujian">
                                <div class="empowerImg">
                                    <i class="empowerIcon empowerUpload"></i>
                                    <el-upload
                                        class="upload"
                                        action=""
                                        list-type="picture"
                                        :show-file-list="false"
                                        :on-success="handleSuccess"
                                		:http-request="uploadPic"
                                        :before-upload="beforeUpload">
                                		<template v-if="parSignForm.attachment">
                                			<img v-if="parSignForm.attachment.indexOf('.pdf') > -1" src="@/assets/images/file_pdf.png" class="book">
                                			<img v-else :src="parSignForm.attachment" class="book">
                                		</template>
                                        
                                        <i v-else class="empowerIcon empowerUpload"></i>
                                    </el-upload>
                                </div>
                                <div class="empowerTips">
                                    <p>上传附件</p>
                                    <p>支持jpg png pdf格式</p>
									<p>若贵公司成立不满2年，或注册资本未达到50万元人民币，请联系商汤渠道经理协助拉通总代，并上传总代同意开户建档的证明</p>
                                </div>
                            </div>
                        </div>
                    </el-form>
                    <div v-if="result == 1" class="parSignTips">
                        <p>请联系商汤渠道经理以取得总代分配，即可建档确认</p>
                    </div>
                    <div class="parSignBtn" @click="next">下一步</div>
                </div>
				
				<div class="parSigntwo" v-if="step == 2">
				   <!-- <div class="parSignTop">
				        <label class="parSignLabel">授权区域</label>
				        <div class="parSignTopCon">{{ parSignForm.province.join(",") }}</div>
				    </div> -->
				    <div class="parSignItem">
				        <div class="parSignCon">
				            <label class="parSignLabel">代理主要品牌I</label>
				            <div class="parSignText">{{ parSignForm.firstBrandName }}</div>
				        </div>
				        <div class="parSignCon">
				            <label class="parSignLabel">大类</label>
				            <div class="parSignText">{{ parSignForm.firstClassName }}</div>
				        </div>
				        <div class="parSignCon">
				            <label class="parSignLabel">年销售额</label>
				            <div class="parSignText">{{ parSignForm.firstSalesVolumeName }}</div>
				        </div>
				    </div>
				    <div class="parSignItem">
				        <div class="parSignCon">
				            <label class="parSignLabel">代理主要品牌II</label>
				            <div class="parSignText">{{ parSignForm.secondBrandName }}</div>
				        </div>
				        <div class="parSignCon" v-if="parSignForm.secondBrandId > 0">
				            <label class="parSignLabel">大类</label>
				            <div class="parSignText">{{ parSignForm.secondClassName }}</div>
				        </div>
				        <div class="parSignCon" v-if="parSignForm.secondBrandId > 0">
				            <label class="parSignLabel">年销售额</label>
				            <div class="parSignText">{{ parSignForm.secondSalesVolumeName }}</div>
				        </div>
				    </div>
				    <div class="parSignItem">
				        <div class="parSignCon">
				            <label class="parSignLabel">代理主要品牌III</label>
				            <div class="parSignText">{{ parSignForm.thirdBrandName }}</div>
				        </div>
				        <div class="parSignCon" v-if="parSignForm.thirdBrandId > 0">
				            <label class="parSignLabel">大类</label>
				            <div class="parSignText">{{ parSignForm.thirdClassName }}</div>
				        </div>
				        <div class="parSignCon" v-if="parSignForm.thirdBrandId > 0">
				            <label class="parSignLabel">年销售额</label>
				            <div class="parSignText">{{ parSignForm.thirdSalesVolumeName }}</div>
				        </div>
				    </div>
				    <div class="parSignItem noBg">
				        <div class="parSignCon">
				            <label class="parSignLabel">推荐人</label>
				            <div class="parSignText">{{ parSignForm.referrer == ""?"无":parSignForm.referrer }}</div>
				        </div>
				        <div class="parSignCon">
				            <label class="parSignLabel">公司</label>
				            <div class="parSignText">{{ parSignForm.companyName == ""?"无":parSignForm.companyName }}</div>
				        </div>
				        <div class="parSignCon">
				            <label class="parSignLabel">手机号</label>
				            <div class="parSignText">{{ parSignForm.phone == ""?"无":parSignForm.phone }}</div>
				        </div>
				    </div>
				    <div class="parSignItem noBg">
				        <div class="parSignCon">
				            <label class="parSignLabel">授权区域</label>
				            <div class="parSignText">{{ parSignForm.province.join("，") }}</div>
				        </div>
				        <div class="parSignCon">
				            <label class="parSignLabel">对接商汤渠道经理</label>
				            <div class="parSignText">{{ parSignForm.channelManager }}</div>
				        </div>
				    </div>
				    <div class="parSignItem noBg">
				        <div class="parSignCon parSignfujian">
				            <label class="parSignLabel">附件</label>
				            <div class="empowerImg">
				                <template v-if="parSignForm.attachment">
				                	<img v-if="parSignForm.attachment.indexOf('.pdf') > -1" src="@/assets/images/file_pdf.png" class="book">
				                	<img v-else :src="parSignForm.attachment" class="book">
				                </template>
				            </div>
				        </div>
				    </div>
				    <div class="empowerBtn">
				        <span class="wait" @click="step=1">上一步</span>
				        <span class="submit" @click="chanegstep">下一步</span>
				    </div>
				</div>
				
				<div v-if="step == 3" class="parSignthree">
				    <div class="signTit">
				        <h2>《合作协议》</h2>
				        <span @click="download"><img src="../assets/images/partner/icon-down-red@2x.png">下载</span>
				    </div>
				    <div class="signText" v-html="info.agreementContent"></div>
				    <el-checkbox class="isAgree" :false-label="0" :true-label="1" v-model="checked">点击签署后视同合作伙伴公司已签署协议，与加盖公司公章具备同等法律效力</el-checkbox>
				    <div class="signBot">
				        <div class="signTel">公司联系人手机号：{{user.linkPhone | strReplace}}</div>
				        <el-form ref="signTelForm" class="signTelForm">
				            <el-form-item class="signYzm" prop="code">
				                <el-input
				                    v-model="yzm"
				                    type="text"
				                    placeholder="请输入验证码"
				                ></el-input>
				                <div class="yzmBtn" v-show="show" @click.prevent="getCode()">获取验证码</div>
				                <div type="text" class="yzmBtn yzmBtn2" v-show="!show">{{count}}s后重试</div> 
				            </el-form-item>
				        </el-form>
				    </div>
				    <div class="empowerBtn">
				        <span class="wait" @click="step=2">上一步</span>
				        <span class="submit" v-if="second == 0" @click="comfirm">签署</span>
				        <span class="wait" v-else>{{second}}S</span>
				    </div>
				</div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Api from '@/request/api.js';

import download from "downloadjs";
export default {
    name: 'PartnerEmpower',
    components: { 
        Header,
        Footer
    },
    data() {
		
		// 手机号验证
		const validatePhone = (rule, value, callback) => {
			if(value == ""){
				callback();
			}else{
				if (!/^1[3456789]\d{9}$/.test(value)) {
					callback(new Error("请输入正确的手机号"));
				} else {
					callback();
				}
			}
			
		}
        return {
            parSignForm:{
                firstBrandId:'',                
                firstBrandName:'',
                firstClassId:'',
                firstClassName:'',
                firstSalesVolumeId:'',
                firstSalesVolumeName:'',
                secondBrandId:0,
                secondBrandName:'',
                secondClassId:'',
                secondClassName:'',
                secondSalesVolumeId:'',
                secondSalesVolumeName:'',
                thirdBrandId:0,
                thirdBrandName:'',
                thirdClassId:'',
                thirdClassName:'',
                thirdSalesVolumeId:'',
                thirdSalesVolumeName:'',
                referrer:'',
                companyName:'',
                phone:'',
                province:'',
                channelManager:'',
                attachment:'',
                appointUserId:"",
				status:""
            },
			user: this.$store.state.user,
            rules: {
                firstBrandId: [{ required: true, message: '请选择代理主要品牌I', trigger: 'blur' }],
                firstClassId: [{ required: true, message: '请选择大类', trigger: 'blur' }],
                firstSalesVolumeId: [{ required: true, message: '请选择销售额', trigger: 'blur' }],
                secondClassId: [{ required: true, message: '请选择大类', trigger: 'blur' }],
                secondSalesVolumeId: [{ required: true, message: '请选择销售额', trigger: 'blur' }],
                thirdClassId: [{ required: true, message: '请选择大类', trigger: 'blur' }],
                thirdSalesVolumeId: [{ required: true, message: '请选择销售额', trigger: 'blur' }],
                province: [{ required: true, message: '请选择授权区域', trigger: 'blur' }],
                appointUserId: [{ required: true, message: '请选择渠道经理', trigger: 'blur' }],
                phone: [
                    { validator: validatePhone, trigger: 'blur' }
                ],
			},
			city:["黑龙江省","辽宁省","吉林省","河北省","河南省","湖北省","湖南省","山东省","山西省","陕西省","安徽省","浙江省","江苏省",
			"福建省","广东省","海南省","四川省","云南省","贵州省","青海省","甘肃省","江西省","台湾省","内蒙古自治区","宁夏回族自治区","新疆维吾尔自治区","西藏自治区",
			"广西壮族自治区","北京市","上海市","天津市","重庆市","香港特别行政区","澳门特别行政区"],
			info:{},
			brandList:[],
			daleiList:[],
			xiaoshoueList:[],
			userList:[],
			nowuserList:[],
			type:"",
			result:0,
			companyId:0,
			step:1,
			checked:1,
			yzm:"",
			show:true,
			count:0,
			timer:"",
			second:10,
			secondtimer:""
        }
    },
	filters: {
		strReplace(val) {
			if (val) {
				return val.substr(0, 3) + '****' + val.substr(7)
			} else {
				return ''
			}

		}
	},
	created(){
		this.id = this.$route.query.id;
		//获取计划详情内容
		Api.getPlanDetail({planId:this.id}).then(res => {
			this.info = res;
		})
		//获取是否已经提交公司授权书
		Api.getUserCertificate().then(res=>{
			if(res == null || res.status != 0){
				this.$router.push("/");
			}
		})
		//获取品牌
		Api.getOptionList({planId:this.id}).then(res=>{
			this.brandList = res.typ1;
			this.daleiList = res.typ2;
			this.xiaoshoueList = res.typ3;
		})
		//获取渠道经理
		Api.getUserList({planId:this.id}).then(res=>{
			console.log(res);
			this.nowuserList = res;
		})
		//获取是否满足资质
		Api.checkCompany().then(res=>{
			this.result = res;
		})
		this.getinfo();
		
	},
	mounted() {
		this.second = 10;
		
	},
    methods: {
		chanegstep(){
			this.step = 3;
			if(this.secondtimer){
				clearInterval(this.secondtimer)
			}
			this.secondtimer = setInterval(() => {
			    this.second--;
			    if (this.second <= 0) {
					this.second = 0;
			        clearInterval(this.secondtimer)
			    }
			}, 1000)
		},
		getinfo(){
			//通过授权认证，判断是否已经签约了
			Api.getDetailByPlanId({planId:this.id}).then(res=>{
				if(res != null && (res.status >= 1 && res.authDayNum >= 30)){
					this.$router.push("/");
				}else{
					if(res != null){
						this.companyId = res.id;
						Api.companyAuthInfo({companyAuthId:this.companyId}).then(res=>{
							res.province = res.province.split("，");
							this.parSignForm = res;
							this.remoteMethod(res.channelManager);
							console.log(this.parSignForm);
						})
					}
				}
			})
			
			
		},
		remoteMethod(query){
			this.userList = this.nowuserList.filter(item => {
			    return item.realname.indexOf(query.toLowerCase()) > -1;
			});
			this.userList.push({id:"0",realname:"无"});
			
		},
        next(){
            this.$refs['parSignForm'].validate(async (valid) => {
                if (valid) {
                    // if (this.result == 1 && this.parSignForm.attachment == "") {
                    //     return this.$message.error('请上传附件')
                    // }
					let onebrand = this.brandList.find(item=>{return item.id == this.parSignForm.firstBrandId});
					if(onebrand){
						this.parSignForm.firstBrandName = onebrand["info"];
					}
					let onedalei = this.daleiList.find(item=>{return item.id == this.parSignForm.firstClassId});
					if(onedalei){
						this.parSignForm.firstClassName = onedalei["info"];
					}
					let onexiaoshoue = this.xiaoshoueList.find(item=>{return item.id == this.parSignForm.firstSalesVolumeId});
					if(onexiaoshoue){
						this.parSignForm.firstSalesVolumeName = onexiaoshoue["info"];
					}
					if(this.parSignForm.secondBrandId > 0){
						let onebrand = this.brandList.find(item=>{return item.id == this.parSignForm.secondBrandId});
						if(onebrand){
							this.parSignForm.secondBrandName = onebrand["info"];
						}
						let onedalei = this.daleiList.find(item=>{return item.id == this.parSignForm.secondClassId});
						if(onedalei){
							this.parSignForm.secondClassName = onedalei["info"];
						}
						let onexiaoshoue = this.xiaoshoueList.find(item=>{return item.id == this.parSignForm.secondSalesVolumeId});
						if(onexiaoshoue){
							this.parSignForm.secondSalesVolumeName = onexiaoshoue["info"];
						}
						
					}else{
						this.parSignForm.secondBrandName = "无";
					}
					if(this.parSignForm.thirdBrandId > 0){
						
						let onebrand = this.brandList.find(item=>{return item.id == this.parSignForm.thirdBrandId});
						if(onebrand){
							this.parSignForm.thirdBrandName = onebrand["info"];
						}
						let onedalei = this.daleiList.find(item=>{return item.id == this.parSignForm.thirdClassId});
						if(onedalei){
							this.parSignForm.thirdClassName = onedalei["info"];
						}
						let onexiaoshoue = this.xiaoshoueList.find(item=>{return item.id == this.parSignForm.thirdSalesVolumeId});
						if(onexiaoshoue){
							this.parSignForm.thirdSalesVolumeName = onexiaoshoue["info"];
						}
						
					}else{
						this.parSignForm.thirdBrandName = "无";
					}
					if(this.parSignForm.appointUserId == "" || this.parSignForm.appointUserId == "0"){
						this.parSignForm.channelManager = "无";
					}else{
						this.parSignForm.channelManager = this.userList.find(item=>{return item.id == this.parSignForm.appointUserId})["realname"];
					}
					let data = Object.assign({}, this.parSignForm);
					data.province = data.province.join("，");
					data.planId = this.id;
					if(data.status == 6){
						data.id  = null;
					}
                    await Api.saveCompanyAuth(data).then(res=>{
						this.getinfo();
						this.step = 2;
					})
                }
            })
        },
		
		uploadPic({ file }) {
			return Api.upload(file)
		},
		handleSuccess(res) {
			if (res.data.code == 0) {
				this.parSignForm.attachment = res.data.message
			}
		},
		beforeUpload(file) {
			const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf');
			this.type = file.type;
			if (!isJPG) {
				this.$message.error('上传文件只支持jpg png pdf格式!');
			}
			return isJPG;
		},
		download(){
			let ua = navigator.userAgent.toLowerCase();
			//android终端
			let isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1; //ios终端
			let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			if ((/micromessenger/.test(ua))) {
				return this.$message.info("移动端暂不支持，请登录PC端进行下载");
			} else {
				if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
					return this.$message.info("移动端暂不支持，请登录PC端进行下载");
				}
			}
			let url = this.info.agreement;
			
			window.open(url);
			
			// let name = url.split("?")[0];
			// download(encodeURI(url),name.split("/").pop());
		},
		getCode(){
			//发送验证码
			Api.companyAuthsendSms({ mobile: this.user.linkPhone}).then(res => {
			    this.count = 60
				this.show = false
				if(this.timer != null){
					clearInterval(this.timer);
				}
			    this.timer = setInterval(() => {
			        this.count--
			        if (this.count <= 0) {
						this.show = true
						this.count = 0;
			            clearInterval(this.timer)
			        }
			    }, 1000)
			    this.$message.success('短信发送成功');
			})
		},
		comfirm(){
			if(this.checked == 0){
				return this.$message.error("请点击签约协议");
			}
			if(this.yzm == ""){
				return this.$message.error("请输入验证码");
			}
			Api.confirmSign({code:this.yzm,companyAuthId:this.parSignForm.id}).then(res=>{
				this.$message.success("您已提交成功，我们将于3个工作日内完成审核，请您留意邮件通知。");
				this.$router.push("/mypost");
			})
		}
    }
}
</script>
<style scoped lang="scss">
	
.ban{
	position: relative;
	div{
		position: absolute;
		width: 900px;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		font-size: 40px;
		color: #fff;
		font-weight: bold;
	}
}
.partner{
	padding: 80px 0 0;
	overflow: hidden;
}
.parSortBg{
	line-height: 80px;
	background-color: #FFFFFF;
	text-align: center;
	.parSort{
		display: flex;
		li{
			flex: 1;
			font-size: 20px;
			color: #111111;
			opacity: .5;
			&.active{
				color: #FFFFFF;
				background: #E73522;
				opacity: 1;
			}
		}
	}
}

.parSignBg{
	padding: 30px 0 74px;
	background-color: #F5F5F7;
}
.parSign{
	padding: 30px 50px 60px;
	background: #FFFFFF;



	.parSignTips{
		font-size: 16px;
		color: #E73522;
		line-height: 1.6;
		margin: 20px auto;
		text-align: center;
	}
	.parSignBtn{
		width: 280px;
		line-height: 50px;
		font-size: 16px;
		color: #FFFFFF;
		background: #E73522;
		border-radius: 3px;
		margin: 10px auto;
		text-align: center;
		cursor: pointer;
	}
}
.parSignForm{
	margin-bottom: 40px;
}
.parSignItem{
	display: flex;
	flex-wrap: wrap;
	margin: 0 -20px;
	.parSignCon{
		width: 33.33%;
		padding: 5px 20px;
		.parSignLabel{
			display: block;
			font-size: 14px;
			color: #2E2E2E;
			line-height: 2;
			padding: 5px 0;
			overflow: hidden;
			span{
				color: #E83421;
				margin-left: 10px;
			}
			i{
				float: right;
				color: #999999;
				font-style: normal;
			}
		}
		.el-select{
			display: block;
			::v-deep .el-input__inner{
				height: 50px;
				line-height: 50px;
				font-size: 16px;
				color: #2E2E2E;
				border: none;
				border-radius: 3px;
				background: #F5F5F8;
			}
		}
		.el-input{
			display: block;
			::v-deep .el-input__inner{
				height: 50px;
				line-height: 50px;
				font-size: 16px;
				color: #2E2E2E;
				padding: 0;
				border: none;
				border-radius: 0;
				border-bottom: 2px solid #DDDDDD;
			}
		}
	}
	.parSignfujian{
		display: flex;
		align-items: flex-end;
		padding: 5px 20px;
		.empowerImg{
			position: relative;
			flex-shrink: 0;
			width: 84px;
			height: 84px;
			background: #F5F5F8;
			border-radius: 3px;
			margin-right: 16px;
			cursor: pointer;
			img{
				display: block;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
				max-width: 100%;
				max-height: 100%;
				object-fit: cover;
			}
			.empowerIcon{
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
				max-width: 100%;
				max-height: 100%;
			}
			.upload{
				width: 100%;
				height: 100%;
				::v-deep .el-upload{
					display: block;
					height: 100%;
				}
			}
		}
		.empowerTips{
			flex: auto;
			font-size: 14px;
			color: #999999;
			line-height: 1.8;
			overflow: hidden;
		}
	}
}
.el-select-dropdown__item.selected{
	color: #E73522;
}
.empowerUpload{
	display: block;
	width: 20px;
	height: 20px;
	background: url("../assets/images/partner/icon-upload@2x.png") center center no-repeat;
	background-size: cover;
}
.parSigntwo{
	padding: 30px 50px 60px;
	background: #FFFFFF;
	.parSignItem{
		display: flex;
		flex-wrap: wrap;
		margin: 10px 0;
		background: #F5F5F8;
		.parSignCon{
			width: 25%;
			padding: 10px;
			&:first-child{
				width: 50%;
			}
		}
		.parSignfujian{
			width: 100%;
			padding: 5px 10px;
			.empowerImg{
				position: relative;
				flex-shrink: 0;
				width: 120px;
				height: 120px;
				img{
					display: block;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
					max-width: 100%;
					max-height: 100%;
					object-fit: cover;
				}
			}
		}
		&.noBg{
			background: transparent;
			margin: 10px -10px;
			.parSignCon{
				width: 33.33% !important;
			}
		}
	}
	.parSignTop{
		margin-bottom: 30px;
	}
	.parSignTopCon{
		font-size: 24px;
		color: #111111;
		font-weight: bold;
	}
	.parSignLabel{
		display: block;
		font-size: 14px;
		color: #666666;
		line-height: 2;
		padding: 5px 0;
		overflow: hidden;
		span{
			color: #E83421;
			margin-left: 10px;
		}
		i{
			float: right;
			color: #999999;
			font-style: normal;
		}
	}
	.parSignText{
		font-size: 18px;
		color: #111111;
		line-height: 2;
		font-weight: bold;
	}
	.empowerBtn{
		display: flex;
		justify-content: center;
		margin-top: .5rem;
		span{
			display: block;
			width: 280px;
			font-size: 16px;
			line-height: 50px;
			margin: 0 10px;
			border-radius: 3px;
			text-align: center;
			cursor: pointer;
		}
		.wait{
			color: #2E2E2E;
			background: #E3E4E5;;
		}
		.submit{
			color: #FFFFFF;
			background: #E73522;
		}
	}
}
.parSignthree{
	padding: 30px 50px 60px;
		background: #FFFFFF;
	.signTit{
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 16px;
		color: #111111;
		line-height: 32px;
		margin: 20px 0 40px;
		h2{
			font-size: 24px;
		}
		span{
			flex-shrink: 0;
			display: block;
			cursor: pointer;
			transition: all .3s;
			img{
				width: 17px;
				height: auto;
				margin-right: 5px;
			}
			&:hover{
				color: #E73522;
			}
		}
	}
	
	.signText{
		font-size: 18px;
		color: #111111;
		line-height: 2;
		height: 560px;
		padding-right: 30px;
		margin-right: -30px;
		margin: 30px 0 60px;
		overflow-y: auto;
		&::-webkit-scrollbar{
			width: 8px;
			border-radius: 4px;
			background: #EEEEEE;
		}
		&::-webkit-scrollbar-thumb{
			border-radius: 4px;
			background: #666666;
		}
	}
	.isAgree{
		font-size: 16px;
		color: #2E2E2E;
		line-height: 30px;
		margin: 30px 0;
		::v-deep .el-checkbox__inner{
			width: 16px;
			height: 16px;
			border-radius: 50%;
			&::after{
				left: 5px;
				top: 2px;
			}
			&:hover{
				border-color: #E73522;
			}
		}
		::v-deep .el-checkbox__label{
			font-size: 16px;
			color: #2E2E2E;
		}
		::v-deep &.is-checked{
			.el-checkbox__inner{
				border-color: #E73522;
				background-color: #E73522;
			}            
		}
		::v-deep .el-checkbox__input.is-focus{
			.el-checkbox__inner{
				border-color: #E73522;
			}
		}
		::v-deep .is-checked+.el-checkbox__label{
			color: #2E2E2E;
		}
	}
	.signBot{
		display: flex;
		align-items: center;
		.signTel{
			min-width: 300px;
			font-size: 16px;
			color: #2E2E2E;
			line-height: 2;
			margin-right: 15px;
		}
		.signYzm{
			margin-bottom: 0;
			border-radius: 3px;
			overflow: hidden;
			::v-deep  .el-form-item__content{
				display: flex;
				.el-input{
					display: block;
				}
			}
			::v-deep .el-input__inner{
				width: 240px;
				height: 50px;
				font-size: 16px;
				color: #666666;
				line-height: 50px;
				border: none;
				border-radius: 0;
				background: #F5F5F8;
			}
			.yzmBtn{
				flex-shrink: 0;
				width: 120px;
				height: 50px;
				line-height: 50px;
				background: #E3E4E5;
				text-align: center;
				cursor: pointer;
			}
			.yzmBtn2{
				cursor: no-drop;
			}
		}
	}
	.empowerBtn{
		display: flex;
		justify-content: center;
		margin-top: .5rem;
		span{
			display: block;
			width: 280px;
			font-size: 16px;
			line-height: 50px;
			margin: 0 10px;
			border-radius: 3px;
			text-align: center;
			cursor: pointer;
		}
		.wait{
			color: #2E2E2E;
			background: #E3E4E5;;
		}
		.submit{
			color: #FFFFFF;
			background: #E73522;
		}
	}
}
@media only screen and (max-width: 750px) {
    .ban{
        height: 2rem;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .partner{
        padding: 1.4rem 0 0;
    }
    .parSortBg{
        line-height: .8rem;
        .parSort{
            display: flex;
            li{
                font-size: .28rem;
            }
        }
    }

    .parSignBg{
        padding: .3rem 0 .6rem;
    }
	.parSign{
        padding: .3rem .3rem .6rem;
        .parSignTips{
            font-size: .24rem;
            margin: .2rem auto;
        }
        .parSignBtn{
            width: 100%;
            max-width: 280px;
            line-height: .7rem;
            font-size: .28rem;
            margin: .1rem auto;
        }
	}
	.parSignForm{
        margin-bottom: .3rem;
	}
	.parSignItem{
        margin: 0 -.1rem;
		.parSignCon{
            width: 100%;
            padding: 5px .1rem;
			.parSignLabel{
                font-size: .24rem;
                padding: .1rem 0;
                span{
                    margin-left: .1rem;
                }
			}
            .el-select{
                display: block;
                ::v-deep .el-input__inner{
                    height: .7rem;
                    line-height: .7rem;
                    font-size: .24rem;
                }
            }
            .el-input{
                display: block;
                ::v-deep .el-input__inner{
                    height: .7rem;
                    line-height: .7rem;
                    font-size: .24rem;
                }
            }
		}
		.parSignfujian{
            padding: .1rem .2rem;
			.empowerImg{
                width: 2rem;
                height: 2rem;
                margin-right: .2rem;
				.empowerIcon{
                    width: .4rem;
                    height: .4rem;
				}
			}
			.empowerTips{
                font-size: .24rem;
			}
		}
	}
	.el-select-dropdown__item{
        height: auto;
        font-size: .28rem;
        line-height: 2;
    }


	.parSigntwo{
		padding: .3rem .3rem .6rem;
		.parSignItem{
			margin: .1rem 0;
			.parSignCon{
				width: 100%;
				padding: .1rem;
				&:first-child{
					width: 100%;
				}
			}
			.parSignfujian{
				width: 100%;
				padding: .1rem .2rem;
				.empowerImg{
					width: 2rem;
					height: 2rem;
				}
			}
			&.noBg{
				background: transparent;
				margin: .1rem -.1rem;
				.parSignCon{
					width: 100% !important;
				}
			}
		}
		.parSignTop{
			margin-bottom: .3rem;
		}
		.parSignTopCon{
			font-size: .32rem;
		}
		.parSignLabel{
                font-size: .24rem;
                padding: .1rem 0;
			span{
				margin-left: .1rem;
			}
		}
		.parSignText{
			font-size: .28rem;
		}
		.empowerBtn{
			span{
				width: 48%;
				font-size: .28rem;
				line-height: .7rem;
				margin: 0 1%;
			}
		}
	}
	.parSignthree{
		padding: .3rem .3rem .6rem;
		.signTit{
			font-size: .24rem;
			line-height: 2;
			margin: .3rem 0 .6rem;
			h2{
				font-size: .32rem;
			}
			span{
				img{
					width: .3rem;
					margin-right: .1rem;
				}
			}
		}
		
		.signText{
			font-size: .24rem;
			height: 6rem;
			padding-right: .3rem;
			margin-right: -.3rem;
			margin: .3rem 0 .6rem;
			&::-webkit-scrollbar{
				width: .08rem;
			}
		}
		.isAgree{
			display: flex;
			font-size: .24rem;
			line-height: .5rem;
			margin: .3rem 0;
			::v-deep .el-checkbox__input{
				flex-shrink: 0;
				display: block;
				width: .3rem;
			}
			::v-deep .el-checkbox__inner{
				width: .3rem;
				height: .3rem;
				&::after{
					left: .1rem;
					top: .02rem;
					width: .08rem;
					height: .18rem;
					border-width: .02rem;
				}
			}
			::v-deep .el-checkbox__label{
				display: block;
				flex: auto;
				font-size: .24rem;
				line-height: .3rem;
				white-space: normal;
				overflow: hidden;
			}
		}
		.signBot{
			display: block;
			.signTel{
				min-width: 0;
				font-size: .24rem;
				margin-right: 0;
			}
			.signYzm{
				::v-deep .el-input__inner{
					width: 100%;
					height: .7rem;
					font-size: .24rem;
					line-height: .7rem;
				}
				.yzmBtn{
					flex-shrink: 0;
					width: 1.4rem;
					height: .7rem;
					font-size: .24rem;
					line-height: .7rem;
				}
			}
		}
		.empowerBtn{
			margin-top: .5rem;
			span{
				width: 48%;
				font-size: .24rem;
				line-height: .7rem;
				margin: 0 1%;
			}
		}
	}
}  
</style>