<template>
	<div class="m-banner-mzw1">
		<div v-if="user.userType == 1" class="ban-m1">
			<div class="wp1200">
				<div class="ban-m1-1">
					<router-link to="/myinfo" class="username">账号设置</router-link>
					
				</div>
				<div class="ban-m1-2">
					<div class="pic">
						<img :src="user.avatar" />
						<!-- <el-upload action="" :show-file-list="false" :http-request="uploadPic" :on-success="handleSuccess">
							
						</el-upload> -->
						
					</div>
					<div class="txt">
						<div class="email">{{ user.nickname }}</div>
						<!-- <div class="tel">手机号：{{ user.telephone | strReplace }}</div> -->
					</div>
					<ul class="ul-nums-m1">
						<li>
							<div class="num">{{ messageNum.likeNum }}</div>
							<div class="info">点赞</div>
						</li>
						<li>
							<div class="num">{{ messageNum.subscribeNum }}</div>
							<div class="info">订阅</div>
						</li>
						<li>
							<div class="num">{{ messageNum.postsNum }}</div>
							<div class="info">发帖</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div v-if="user.userType == 2" class="ban-m1">
			<div class="wp1200">
				<div class="ban-m1-1">
					
					
				</div>
				<div class="ban-m1-2">
					<div class="pic">
						<img :src="user.avatar" />
						<!-- <el-upload action="" :show-file-list="false" :http-request="uploadPic" :on-success="handleSuccess">
							
						</el-upload> -->
						
					</div>
					<div class="txt">
						<div class="email">{{ user.companyname }}</div>
						<div class="tel">{{ user.username }}</div>
					</div>
					<!-- <ul class="ul-nums-m1">
						<li>
							<router-link to="/companyinfo" class="username" style="font-size: 18px;">公司信息维护</router-link>
						</li>
					</ul> -->
				</div>
			</div>
		</div>
		<div v-if="user.userType == 1" class="ban-m2">
			<div class="wp1200">
				<div class="ban-m2-1">
					<ul class="ul-nav2-m1 style1">
						<li :class="navIndex == 1 ? 'on' : ''" @click="toPage('/mypost')">
							<router-link to class="con">我的帖子</router-link>
						</li>
						<li :class="navIndex == 2 ? 'on' : ''" @click="toPage('/mysubscribe')">
							<router-link to class="con">我的订阅</router-link>
						</li>
						<li :class="navIndex == 3 ? 'on' : ''" @click="toPage('/mycomments')">
							<router-link to class="con">我的评论</router-link>
						</li>
						<li :class="navIndex == 6 ? 'on' : ''" @click="toPage('/mylike')">
							<router-link to class="con">我点赞的</router-link>
						</li>
					</ul>
					<ul class="ul-nav2-m1 style2">
						<li :class="navIndex == 4 ? 'on' : ''" @click="toPage('/mydown')">
							<router-link to class="con">资料下载</router-link>
						</li>
						<li :class="navIndex == 5 ? 'on' : ''" @click="toPage('/mycollect')">
							<router-link to class="con">资料收藏</router-link>
						</li>
						<li :class="navIndex == 7 ? 'on' : ''" @click="toPage('/browserecords')">
							<router-link to class="con">浏览记录</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div v-if="user.userType == 2" class="ban-m2">
			<div class="wp1200">
				<div class="ban-m2-1">
					<ul class="ul-nav2-m1 style1">
						<li :class="navIndex == 3 ? 'on' : ''" @click="toPage('/companyinfo?type=1')">
							<router-link to class="con">企业信息维护</router-link>
						</li>
						<li :class="navIndex == 4 ? 'on' : ''" @click="toPage('/companyinfo?type=2')">
							<router-link to class="con">公司签约授权</router-link>
						</li>
						<li :class="navIndex == 1 ? 'on' : ''" @click="toPage('/mypost')">
							<router-link to class="con">认证中进度查询</router-link>
						</li>
						<li :class="navIndex == 2 ? 'on' : ''" @click="toPage('/authIdentity')">
							<router-link to class="con">已认证身份查询</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import Api from '@/request/api';
import ContentNull from '@/components/ContentNull.vue';

export default {
	name: 'MyBanners',
	props: {
		navIndex: Number,
	},
    components: {
        ContentNull
    },
	data() {
		return {
			showdialog:false,
			messagelist:[],
			total:0,
			pageNo:1,
			messageNum:{likeNum:0,messageNum:0,postsNum:0,subscribeNum:0}
		};
	},
	computed: {
		...mapState([
			'user'
		])
	},
	filters: {
		strReplace(val) {
			if (val) {
				return val.substr(0, 3) + '****' + val.substr(7)
			} else {
				return ''
			}

		}
	},
	mounted() {
	},
	methods: {
		toPage(path) {
			// if (path == this.$route.path) { return }
			this.$router.replace({ path: path })
		},
		uploadPic({ file }) {
			return Api.upload(file)
		},
		handleSuccess(res,file) {
			this.loading = false
			// 如果上传成功
			if (res.data.message) {
				Api.updateAvatar({avatar:res.data.message});
			} else {
				// 提示信息，需引入Message
				this.$message.error('上传失败，请重试')
			}
		},
		getmessage:function(){
			//获取消息列表
			if(this.showdialog == false){
				this.pageNo = 1;
			}
			Api.getMessageList(this.pageNo).then(res => {
                this.messagelist = res.records;
				this.total = res.total;
				this.showdialog = true;
            }).catch(err => {
                this.loading = false
            })
		},
        // 分页查询
        handleCurrentChange(v) {
            this.pageNo = v
            this.getmessage()
        },
		readstatus:function(){
			Api.updateMessageStatus({id:''}).then(res => {
				this.getmessage();
			})
		}
	},
}
</script>

<style lang="scss">
.wp1200 {
	max-width: 1200px;
	margin: 0 auto;
}

.m-banner-mzw1 {
	background-color: #fff;
}
.m-banner-mzw1 .ban-m1 {
	width: 100%;
	height: 170px;
	background-image: url(../assets/images/mycomments/pic-w1.jpg);
	background-repeat: no-repeat;
	background-size: 100% 100%;
}
.m-banner-mzw1 .ban-m1-1 {
	overflow: hidden;
	padding-top: 25px;
	margin-bottom: 27px;
}
.m-banner-mzw1 .username {
	display: block;
	float: right;
	font-size: 16px;
	line-height: 30px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #ffffff;
	padding-left: 35px;
	background-image: url(../assets/images/mycomments/icon-w1.png);
	background-size: 16px 16px;
	background-position: left center;
	background-repeat: no-repeat;
}
.m-banner-mzw1 .ban-m1-2 {
	position: relative;
	padding-left: 180px;
}
.m-banner-mzw1 .ban-m1-2::after {
	content: "";
	display: block;
	clear: both;
}
.m-banner-mzw1 .pic {
	width: 140px;
	height: 140px;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
}
.m-banner-mzw1 .pic img {
	width: 100%;
	height: 100%;
    border-radius: 50%;
	display: block;
}
.m-banner-mzw1 .txt {
	float: left;
}
.m-banner-mzw1 .email {
	font-size: 28px;
	line-height: 35px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #ffffff;
	margin-bottom: 5px;
}
.m-banner-mzw1 .tel {
	font-size: 16px;
	line-height: 20px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #ffffff;
	opacity: 0.5;
}
.m-banner-mzw1 .ban-m2 {
	border-bottom: 1px solid #e4e4e4;
}
.m-banner-mzw1 .ban-m2-1 {
	padding-left: 180px;
	display: flex;
	justify-content: space-between;
}

.ul-nums-m1 {
	width: 240px;
	float: right;
	display: flex;
	justify-content: space-between;
}
.ul-nums-m1 .con {
	display: block;
	text-align: center;
}
.ul-nums-m1 .num {
	font-size: 32px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #ffffff;
}
.ul-nums-m1 .info {
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #ffffff;
	opacity: 0.5;
	text-align: center;
}

.ul-nav2-m1::after {
	content: "";
	display: block;
	clear: both;
}
.ul-nav2-m1 li {
	float: left;
	margin-right: 68px;
	cursor: pointer;
}
.ul-nav2-m1 li:last-child {
	margin-right: 0;
}
.ul-nav2-m1 .con {
	font-size: 18px;
	line-height: 70px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #111111;
	transition: 0.5s;
	position: relative;
}
.ul-nav2-m1 .con::after {
	content: "";
	display: block;
	width: 100%;
	height: 3px;
	background-color: #e73522;
	position: absolute;
	bottom: -23px;
	left: 0;
	opacity: 0;
}
.ul-nav2-m1 li:hover .con,
.ul-nav2-m1 li.on .con {
	color: #e73522;
}
.ul-nav2-m1 li:hover .con::after,
.ul-nav2-m1 li.on .con::after {
	opacity: 1;
}
.ban-m1-message{
	width: 20px;
	height: 30px;
	float: right;
	margin-right: 40px;
	line-height: 30px;
	position: relative;
	cursor: pointer;
}
.ban-m1-message img{
	width: 20px;
	height: 20px;
}
.ban-m1-message span{
	width: 18px;
	height: 18px;
	background: #E73522;
	border-radius: 50%;
	font-size: 12px;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 18px;
	text-align: center;
	position: absolute;
	display: block;
	top: -4px;
	right: -9px;
}
.dialog-title{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.title-name{
	font-size: 20px;
	font-weight: 500;
	color: #333333;
}
.title-clear img{
	width: 18px;
	height: 18px;
	margin-right: 5px;
}
.title-clear{
	font-size: 14px;
	font-weight: 400;
	color: #666666;
	margin-left: 55px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
}
.dialog-content-item{
	margin: 0px 30px;
	padding: 20px 0px;
	border-bottom: 1px solid #DDDDDD;
	cursor: pointer;
}
.dialog-content-item-top{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.dialog-content-item-top-status{
	width: 12px;
	height: 12px;
	background: #E73522;
	border-radius: 50%;
	margin-top: 6px;
	flex: 0 0 12px;
}
.dialog-content-item-top-text{
	font-size: 14px;
	font-weight: 500;
	color: #2E2E2E;
	line-height: 24px;
}
.dialog-content-item-top p{
	font-size: 14px;
	font-weight: 500;
	color: #2E2E2E;
	line-height: 24px;
}
.dialog-content-item-top-text span{
	font-size: 14px;
	font-weight: 500;
	color: #E73522;
	line-height: 24px;
	margin: 0px 5px;
}
.dialog-content-item-time{
	font-size: 14px;
	font-weight: 400;
	color: #33ADA6;
	text-align: right;
}
.dialog-content-item-p{
	width: 100%;
	background: #F5F5F7;
	padding: 13px 20px;
	box-sizing: border-box;
	margin-top: 10px;
	font-size: 14px;
	font-weight: 500;
	color: #666666;
	line-height: 18px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.dialog-content-item-p span{
	color: #E73522;
}
.dialog-content{
	height: 500px;
	overflow: auto;
}
.pagination-box{
	text-align: center;
	margin-top: 20px;
}
@media only screen and (max-width: 750px) {
	.wp1200 {
		max-width: 1200px;
		margin: 0 .3rem;
	}
	.m-banner-mzw1{
		.ban-m1{
			height: auto;
			background-size: cover;
			overflow: hidden;
			.ban-m1-1{
				padding: 0;
				margin: .3rem 0;
				.username{
					font-size: .28rem;
					padding-left: .4rem;
					background-size: .3rem auto;
				}
			}
			.ban-m1-2{
				padding: 0;
				padding-left: 1.6rem;
				margin: .3rem 0;
				.pic{
					width: 1.4rem;
					height: 1.4rem;
				}
				.txt{
					float: none;
					.email{
						font-size: .28rem;
						line-height: 1.8;
					}
					.tel{
						font-size: .24rem;
						line-height: 1.8;
					}
				}
				.ul-nums-m1{
					float: none;
					width: 100%;
					text-align: center;
					margin-top: .1rem;
					li{
						display: flex;
						flex-direction: row-reverse;
						align-items: center;
					}
					.num{
						font-size: .28rem;
					}
					.info{
						font-size: .24rem;
						margin-right: .1rem;
						&::after{
							content: ":";
						}
					}
				}
			}
		}
		.ban-m2{			
			.ban-m2-1{
				display: block;
				padding: 0;
				.ul-nav2-m1{
					margin: 0 -.1rem;
					text-align: center;
					li{
						width: 25%;
						padding: 0 .1rem;
						margin-right: 0;
						&:last-child{
							margin-right: 0;
						}
					}
					.con{
						display: inline-block;
						font-size: .28rem;
						line-height: 2;
						padding: .1rem 0;
						&::after{
							height: .04rem;
							bottom: 0;
						}
					}
				}
			}
		}
	}
}
</style>
