<template>
    <div class="partner">
        <Header />
        <div class="ban">
            <img src="../assets/images/partner/ban-sign.png" alt />
        </div>
        <div class="parSortBg">
            <div class="wp w1200">
                <ul class="parSort">
                    <li><span>填写认证信息</span></li>
                    <li class="active"><span>信息核对</span></li>
                    <li><span>签署合作协议</span></li>
                </ul>
            </div>
        </div>
        <div class="parSignBg">
            <div class="wp w1200">
                <div class="parSign">
                    <div class="parSignTop">
                        <label class="parSignLabel">授权区域</label>
                        <div class="parSignTopCon">{{ parSignForm.shouquanquyu }}</div>
                    </div>
                    <div class="parSignItem">
                        <div class="parSignCon">
                            <label class="parSignLabel">代理主要品牌I</label>
                            <div class="parSignText">{{ parSignForm.pinpai }}</div>
                        </div>
                        <div class="parSignCon">
                            <label class="parSignLabel">大类</label>
                            <div class="parSignText">{{ parSignForm.dalei }}</div>
                        </div>
                        <div class="parSignCon">
                            <label class="parSignLabel">年销售额</label>
                            <div class="parSignText">{{ parSignForm.xiaoshoue }}</div>
                        </div>
                    </div>
                    <div class="parSignItem">
                        <div class="parSignCon">
                            <label class="parSignLabel">代理主要品牌II</label>
                            <div class="parSignText">{{ parSignForm.pinpai2 }}</div>
                        </div>
                    </div>
                    <div class="parSignItem">
                        <div class="parSignCon">
                            <label class="parSignLabel">代理主要品牌III</label>
                            <div class="parSignText">{{ parSignForm.pinpai3 }}</div>
                        </div>
                    </div>
                    <div class="parSignItem noBg">
                        <div class="parSignCon">
                            <label class="parSignLabel">推荐人</label>
                            <div class="parSignText">{{ parSignForm.tuijianren }}</div>
                        </div>
                        <div class="parSignCon">
                            <label class="parSignLabel">公司</label>
                            <div class="parSignText">{{ parSignForm.gongsi }}</div>
                        </div>
                        <div class="parSignCon">
                            <label class="parSignLabel">手机号</label>
                            <div class="parSignText">{{ parSignForm.shoujihao }}</div>
                        </div>
                    </div>
                    <div class="parSignItem noBg">
                        <div class="parSignCon">
                            <label class="parSignLabel">授权区域</label>
                            <div class="parSignText">{{ parSignForm.shouquanquyu }}</div>
                        </div>
                        <div class="parSignCon">
                            <label class="parSignLabel">对接商汤渠道经理</label>
                            <div class="parSignText">{{ parSignForm.qudaojingli }}</div>
                        </div>
                    </div>
                    <div class="parSignItem noBg">
                        <div class="parSignCon parSignfujian">
                            <label class="parSignLabel">附件</label>
                            <div class="empowerImg">
                                <el-upload
                                    class="upload"
                                    action=""
                                    list-type="picture"
                                    :show-file-list="false">
                                    <img v-if="parSignForm.fujian" :src="parSignForm.fujian" class="book">
                                    <i v-else class="empowerIcon empowerUpload"></i>
                                </el-upload>
                            </div>
                        </div>
                    </div>
                    <div class="empowerBtn">
                        <span class="wait" @click="prev">上一步</span>
                        <span class="submit" @click="next">下一步</span>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
    name: 'PartnerEmpower',
    components: { 
        Header,
        Footer
    },
    data() {
        return {
            parSignForm:{
                pinpai:'品牌甲',                
                dalei:'大类一',
                xiaoshoue:'100～500万元',
                pinpai2:'无',
                pinpai3:'无',
                tuijianren:'陈小虎',
                gongsi:'星光科技',
                shoujihao:'186172817465',
                shouquanquyu:'湖北省',
                qudaojingli:'李成林',
                fujian: require('@/assets/images/partner/fujian.png')
            }
        }
    },
    methods: {
        prev(){
            this.$router.push({ path: '/partnersign1'})
        },
        next(){
            this.$router.push({ path: '/partnersign3'})
        }
    }
}
</script>
<style scoped lang="scss">
.partner{
    padding: 80px 0 0;
    overflow: hidden;
}
.parSortBg{
    line-height: 80px;
    background-color: #FFFFFF;
    text-align: center;
    .parSort{
        display: flex;
        li{
            flex: 1;
            font-size: 20px;
            color: #111111;
            opacity: .5;
            &.active{
                color: #FFFFFF;
                background: #E73522;
                opacity: 1;
            }
        }
    }
}

.parSignBg{
    padding: 30px 0 74px;
    background-color: #F5F5F7;
}
.parSign{
    padding: 30px 50px 60px;
    background: #FFFFFF;
}
.parSignItem{
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
    background: #F5F5F8;
    .parSignCon{
        width: 25%;
        padding: 10px;
        &:first-child{
            width: 50%;
        }
    }
    .parSignfujian{
        width: 100%;
        padding: 5px 10px;
        .empowerImg{
            position: relative;
            flex-shrink: 0;
            width: 120px;
            height: 120px;
            img{
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
            }
        }
    }
    &.noBg{
        background: transparent;
        margin: 10px -10px;
        .parSignCon{
            width: 33.33% !important;
        }
    }
}
.parSignTop{
    margin-bottom: 30px;
}
.parSignTopCon{
    font-size: 24px;
    color: #111111;
    font-weight: bold;
}
.parSignLabel{
    display: block;
    font-size: 14px;
    color: #666666;
    line-height: 2;
    padding: 5px 0;
    overflow: hidden;
    span{
        color: #E83421;
        margin-left: 10px;
    }
    i{
        float: right;
        color: #999999;
        font-style: normal;
    }
}
.parSignText{
    font-size: 18px;
    color: #111111;
    line-height: 2;
    font-weight: bold;
}
.empowerBtn{
    display: flex;
    justify-content: center;
    margin-top: .5rem;
    span{
        display: block;
        width: 280px;
        font-size: 16px;
        line-height: 50px;
        margin: 0 10px;
        border-radius: 3px;
        text-align: center;
        cursor: pointer;
    }
    .wait{
        color: #2E2E2E;
        background: #E3E4E5;;
    }
    .submit{
        color: #FFFFFF;
        background: #E73522;
    }
}
@media only screen and (max-width: 750px) {    
    .ban{
        height: 2rem;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .partner{
        padding: 1.4rem 0 0;
    }
    .parSortBg{
        line-height: .8rem;
        .parSort{
            display: flex;
            li{
                font-size: .28rem;
            }
        }
    }
    .parSignBg{
        padding: .3rem 0 .6rem;
    }
    .parSign{
        padding: .3rem .3rem .6rem;
        .parSignTips{
            font-size: .24rem;
            margin: .2rem auto;
        }
        .parSignBtn{
            width: 100%;
            max-width: 280px;
            line-height: .7rem;
            font-size: .28rem;
            margin: .1rem auto;
        }
    }
    .parSignItem{
        margin: .1rem 0;
        .parSignCon{
            width: 50%;
            padding: .1rem;
            &:first-child{
                width: 100%;
            }
        }
        .parSignfujian{
            width: 100%;
            padding: .1rem;
            .empowerImg{
                width: 2rem;
                height: 2rem;
            }
        }
        &.noBg{
            background: transparent;
            margin: .1rem -.1rem;
            .parSignCon{
                width: 50% !important;
            }
        }
    }
    .parSignTop{
        margin-bottom: .3rem;
    }
    .parSignTopCon{
        font-size: .32rem;
    }
    .parSignLabel{
        font-size: .24rem;
        padding: .1rem 0;
        span{
            margin-left: .1rem;
        }
    }
    .parSignText{
        font-size: .32rem;
    }
    .empowerBtn{
        span{
            display: block;
            width: 48%;
            font-size: .28rem;
            line-height: .7rem;
            margin: 0 1%;
        }
    }
}
</style>