<template>
	<div class="mysubscribe">
		<Header />
		<div class="main" id="app" style="background-color: #f5f5f7;">
			<MyBanners :navIndex="2"></MyBanners>
			<div class="wp w1200">
				<div class="m-mysubscribe">
					<ul class="ul-mysubscribe">
						<li v-for="(item, index) in list" :key="index">
							<div class="con">
								<Admindesc :sublist="item"></Admindesc>
							</div>
						</li>
					</ul>
					<!-- 内容为空 -->
					<ContentNull style="display: none;" />
					<div class="pagination-box style2" v-loading.fullscreen.lock="false">
					    <el-pagination
							:total="total"
							layout="prev, pager, next"
							:page-size="params.pageSize"
							@current-change="handleCurrentChange"
						></el-pagination>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Header from '@/components/Header.vue';
import MyBanners from '@/components/MyBanners.vue';
import Admindesc from '@/components/Admindesc.vue';
import Footer from '@/components/Footer.vue';
import ContentNull from '@/components/ContentNull.vue';
import Api from '@/request/api';
export default {
	components: {
		Header,
		MyBanners,
		Admindesc,
		Footer,
		ContentNull
	},
	data() {
		return {
			params: {
				order: '',
				pageNo: 1,
				pageSize: 10,
				sort: 0
			},
			total: 0,
			list: []
		}
	},
	created() {
		this.getList()
	},
	watch: {
        $route(v) {
            if (v.path == '/mysubscribe') {
                this.getList()
            }
        }
    },
	methods: {
		getList() {
			Api.mySubscription(this.params).then(res => {
				console.log(res)
				for (let i = 0; i < res.records.length; i++) {
					res.records[i].subscription = true
				}
				this.list = res.records
				this.total = res.total
			})
		},
		handleCurrentChange(v) {
            this.params.pageNo = v
            this.getList()
        },
	},
	mounted() {
	}

}
</script>

<style lang="scss" scoped>
a:hover {
	color: #e73522;
}
.main {
	padding: 80px 0 20px;
}
.g-ban {
	margin-bottom: 30px;
}

.m-mysubscribe {
	padding-top: 20px;
}

.ul-mysubscribe {
	margin-bottom: 55px;
}
.ul-mysubscribe li {
	margin-bottom: 10px;
}
.ul-mysubscribe li:last-of-type {
	margin-bottom: 0;
}

.pagination-box {
	text-align: center;
	margin-bottom: 37px;
	margin-top: 13px;
}
@media only screen and (max-width: 750px) {
	.main {
		padding: 1.4rem 0 .4rem;
	}
	.m-mysubscribe{
		padding-top: .3rem;
		.ul-mysubscribe{
			margin-bottom: .5rem;
			li{
				margin-bottom: .2rem;
			}
		}
	}
	.pagination-box{
		margin: .2rem 0 .4rem;
	}
}
</style>