<template>
	<div id="app">
		<!-- <div id="nav">
			<router-link to="/">Home</router-link> |
			<router-link to="/about">About</router-link>
		</div> -->
		<!-- <keep-alive :include="['Mycollect', 'Mydown', 'Browserecords', 'GeneralProduct']"> -->
		<keep-alive :exclude="['ForumDetail', 'Detail', 'Mypostpage', 'ForumList']">
			<router-view :key="key" />
		</keep-alive>
	</div>
</template>

<script>
import Api from "@/request/api"
import axios from "axios"

export default {
	data(){
		return{
			isRegister:false,
		}
	},
	watch:{
		'$route':function(){
			if(this.$route.path=='/register'){
				document.body.style='padding-top:80px;'
			}else{
				document.body.style=''
			}
		}
	},
	computed: {
		key() {
			return this.$route.fullPath
		}
	},
	async created() {
		// let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
		// if (flag) {
		// 	return this.$router.replace({ path: '/mobile' })
		// }
        await this.getKey()
		this.getProductTree()
		// 获取资讯分类
		this.getInforTree()
		// 获取论坛分类
		this.getforumTree()
		// 获取文件格式
		this.getFormat()
		// 获取用户信息
		this.getUserInfo()
		setTimeout(() => {
			// 获取论坛是否关闭
			this.getForumConfig()
		}, 500);
	},
	mounted(){
		if(this.$route.path=='/register'){
			document.body.style='padding-top:80px;'
		}else{
			document.body.style=''
		}
	},
	methods: {
        // 获取key
        getKey() {
            return new Promise(resolve => {
                axios.post(process.env.VUE_APP_BASE_URL + '/teamwork/public/getparameter').then(res => {
                    localStorage.setItem('publickey', res.data.result)
					resolve(res.data.result)
                })
            })
        },
		// 获取当前用户信息
		getUserInfo() {
			if (localStorage.getItem('token')) {
			    Api.getUserInfo().then(res => {
					if (res) {
						this.$store.dispatch('setUserInfo', res)
					}
				})
			} else {
				this.$store.dispatch('logout')
			}
		},
		// 获取产品树
		getProductTree() {
			Api.productTree().then(res => {
				// const { result } = res
                // console.log(res)
				this.$store.dispatch('setPorductTree', res)
			})
		},
		// 获取资讯分类
		getInforTree() {
			Api.inforTree().then(res => {
                // console.log(res)
				this.$store.dispatch('setInforTree', res)
			})
		},
		// 获取论坛分类
		getforumTree() {
			if (!this.$store.getters.hasLogin) { return }
			Api.forumTree().then(res => {
                // console.log(res)
				this.$store.dispatch('setForumTree', res)
			})
		},
		// 获取文件格式数组
		getFormat() {
			Api.getConfigKey({ key: 2 }).then(res => {
                // console.log(res)
				this.$store.dispatch('setFormats', res)
			})
		},
        // 获取论坛是否开启
        getForumConfig() {
            Api.getConfigId({ id: 17 }).then(res => {
                // console.log(res)
				this.$store.dispatch('setForumStatus', res.config)
			})
        }
	}
}
</script>

<style lang="scss">
	.el-loading-spinner .path{
		stroke: #E73522 !important;
	}
</style>
