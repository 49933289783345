<template>
    <div class="searchResults">
        <Header />
        <div class="main" style="margin-top:100px;">
            <div class="row-m1">
                <div class="wp1200">
                    <div class="m-search-results">
                        <el-input
                            v-model="params.title"
                            type="text"
                            placeholder="请输入搜索内容"
                            @keyup.enter.native="getList()"
                        />
                        <div class="label" @click="getList"></div>
                    </div>
                    <ul class="ul-nav2-m2">
                       <!-- <li
                            :class="params.belong == 1 ? 'on' : ''"
                            @click="params.belong = 1; getList();"
                        >
                            <div class="con">渠道发展</div>
                        </li> -->
                        <li
                            :class="params.belong == 2 ? 'on' : ''"
                            @click="params.belong = 2; getList();"
                        >
                            <div class="con">产品资料</div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 搜索结果页空状态 -->
            <ContentNull v-if="list.length == 0" />
            <div class="row-m2" v-else v-loading="loading">
                <div class="wp1200">
                    <div class="g-search-content">
                        共检索到关于 “
                        <span class="s1">{{ title }}</span>” 的信息共
                        <span class="s1">{{ total }}</span>
                        条
                    </div>
                    <div class="work-content-box">
                        <div class="bot">
                            <ul class="list-box">
                                <li
                                    :class="getFormatType(item.infoFormat)"
                                    v-for="(item, index) in list"
                                    :key="index"
                                >
                                    <div class="tit">
                                        <div class="val">{{ item.title }}</div>
                                        <div class="op">
                                            <span
                                                class="icon-preview"
                                                v-if="item.infoFormat == 'pdf'"
                                                @click="priview(item)"
                                            >预览</span>
                                            <span
                                                class="icon-preview"
                                                v-if="(item.infoFormat != 'pdf' && item.infoContent != '') || item.infoFormat == '视频'"
                                                @click="toDetail(item)"
                                            >查看</span>
                                            <span
                                                class="icon-download"
                                                @click="showDownload(item)"
                                                v-if="(item.infoFormat != '视频' && item.infoFormat != '链接')"
                                            >下载</span>
                                        </div>
                                    </div>
                                    <div class="tim-size">
                                        <span
                                            class="val"
                                        >{{ item.createTime }} I {{ item.infoSize }}</span>
                                        <div
                                            class="op"
                                            @click="collection(item.id, index, item.collect)"
                                        >
                                            <span
                                                :class="item.collect ? 'icon-faved' : 'icon-fav'"
                                            >收藏</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div
                            class="pagination-box"
                            v-loading.fullscreen.lock="false"
                            element-loading-background="#E73522"
                        >
                            <el-pagination
                                :total="total"
                                :page-size="params.pageSize"
                                layout="prev, pager, next"
                                @current-change="handleCurrentChange"
                            ></el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Download :info="downInfo" ref="downloadRef" @confirm="download" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import ContentNull from '@/components/ContentNull.vue';
import Download from '@/components/Download.vue';
import Api from '@/request/api';
import { mapState } from 'vuex';

export default {
    name: 'SearchResults',
    components: {
        Header,
        Footer,
        ContentNull,
        Download
    },
    data() {
        return {
            loading: false,
            params: {
                belong: 2,
                format: '',
                order: '',
                sort: 0,
                type: '',
                pageNo: 1,
                pageSize: 10,
                pid: 0,
                title: ''
            },
            title: '',
            total: 0,
            list: [],
            downInfo: {}
        }
    },
    created() {
        if (this.$route.params) {
            this.params.title = this.$route.params.search
            this.getList()
        }
    },
    computed: {
        ...mapState([
            'globalSearch'
        ])
    },
    watch: {
        globalSearch() {
            this.params.title = this.globalSearch
            this.getList()
        }
    },
    methods: {
        getList() {
            if (!this.params.title) {
                return this.list = []
            }
            this.loading = true
            this.title = this.params.title
            Api.getList(this.params).then(res => {
                setTimeout(() => {
                    this.list = res.records
                    this.total = res.total
                    this.loading = false
                }, 300)
            })
        },
        // 分页查询
        handleCurrentChange(v) {
            this.params.pageNo = v
            this.getList()
        },
       // 显示下载弹窗
        showDownload(item) {
            if((item.applyType == 0 && item.applyStatus == 1) || (item.applyType == 0 && item.applyStatus==undefined)){
            	this.$confirm("您尚未开通该目录权限，是否申请开通？","提示").then(()=>{
            		
            		Api.doApply({inforClassId:item.infoTypeId,typeIdList:this.params.typeIdList}).then(res=>{
            			this.$message.success("申请已提交，请耐心等待");
            		})
            	})
            }else{
            	this.downInfo = {
            	    id: item.id,
            	    title: item.title,
            	    url: item.document,
            	    icon: item.infoFormat,
            		fileName:item.fileName?item.fileName:''
            	}
            	this.$refs.downloadRef.open()
            }
        },
        // 下载
        download() {
            const data = {
                belong: this.belong,
                id: this.downInfo.id,
                type: 4
            }
            Api.inforPosts(data).then(res => {
                // window.location = this.downInfo.url
                // window.location = res.document
                window.open(res.document.replace('http', 'https'))
            })
            this.$refs.downloadRef.close()
        },
        // 收藏
        collection(id, index, vlaue) {
            const data = {
                belong: 2,
                id: id,
                type: 3
            }
            if (vlaue) {
                Api.cancelInforPosts(data)
            } else {
                Api.inforPosts(data)
            }
            this.list[index].collect = !vlaue
        },
        // 跳转到详情页面
        toDetail(item) {
            if((item.applyType == 0 && item.applyStatus == 1) || (item.applyType == 0 && item.applyStatus==undefined)){
            	this.$confirm("您尚未开通该目录权限，是否申请开通？","提示").then(()=>{
            		
            		Api.doApply({inforClassId:item.infoTypeId,typeIdList:this.params.typeIdList}).then(res=>{
            			this.$message.success("申请已提交，请耐心等待");
            		})
            	})
            }else{
            	if (item.infoFormat == '链接') {
            	    window.location = item.document
            	} else {
            	    this.$router.push({ path: '/detail', query: { id: item.id, type: 2 } })
            	}
            }
        },
        // 根据类型返回类
        getFormatType(type) {
            let str = ''
            this.$store.state.infoFormatType.forEach(item => {
                if (item.type == type) {
                    str = item.class
                    return;
                }
            })
            return str
        },
        // 预览
        priview(e) {
            if((e.applyType == 0 && e.applyStatus == 1) || (e.applyType == 0 && e.applyStatus==undefined)){
            	this.$confirm("您尚未开通该目录权限，是否申请开通？","提示").then(()=>{
            		
            		Api.doApply({inforClassId:e.infoTypeId,typeIdList:this.params.typeIdList}).then(res=>{
            			this.$message.success("申请已提交，请耐心等待");
            		})
            	})
            }else{
            	const data = {
            	    belong: 2,
            	    id: e.id,
            	    type: 1
            	}
            	Api.inforPosts(data).then(res => {
            	    console.log(res)
            	    if (res.document) {
            	        // window.location = res.document
            	        window.open(res.document.replace('http', 'https'))
            	    }
            	})
            }
        },
    }
}
</script>

<style scoped lang="scss">
.wp1200 {
    max-width: 1200px;
    margin: 0 auto;
}

.main {
    padding-top: 0;
}

.row-m1 {
    background-color: #fff;
    padding-top: 40px;
}

.row-m2 {
    background-color: #f5f5f7;
    margin-bottom: 80px;
}

.m-search-results {
    position: relative;
    margin-bottom: 5px;
}

.m-search-results .el-input {
    width: 100%;
    height: 60px;
}

::v-deep .m-search-results input {
    background-color: #fff;
    border: 1px solid #ccc;
    height: 100%;
    box-sizing: border-box;
    line-height: 58px;
    padding: 0 20px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
}

.m-search-results .label {
    width: 32px;
    height: 32px;
    overflow: hidden;
    background-image: url(../assets/images/home/search2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
}

.ul-nav2-m2 {
    overflow: hidden;
    margin: 0 -35px;
}

.ul-nav2-m2 li {
    float: left;
    margin: 0 35px;
}

.ul-nav2-m2 .con {
    font-size: 18px;
    line-height: 65px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    position: relative;
    transition: 0.5s;
    cursor: pointer;
}

.ul-nav2-m2 .con::after {
    display: block;
    content: "";
    width: 100%;
    height: 2px;
    background-color: #e73522;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    transition: 0.5s;
}

.ul-nav2-m2 li.on .con,
.ul-nav2-m2 li:hover .con {
    color: #333;
    font-weight: 500;
}

.ul-nav2-m2 li.on .con::after,
.ul-nav2-m2 li:hover .con::after {
    opacity: 1;
}

.g-search-content {
    font-size: 14px;
    line-height: 46px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #707070;
    padding-top: 4px;
}

.g-search-content .s1 {
    color: #e83421;
}

.work-content-box {
    border-radius: 5px;
    background: #fff;
    width: 100%;
    overflow: hidden;
    .bot {
        padding: 35px 22px 25px 18px;

        .list-box {
            li {
                padding-bottom: 22px;
                border-bottom: 1px solid #dddddd;
                margin-bottom: 24px;
                padding-left: 50px;

                .tit {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .val {
                        font-size: 16px;
                        font-weight: 500;
                    }
                }

                .op {
                    span {
                        padding-left: 20px;
                        cursor: pointer;

                        &:hover {
                            color: #e23523;
                        }
                    }

                    span:last-child {
                        margin-left: 32px;
                    }

                    .icon-preview {
                        background: url("../assets/images/icon_preview.png")
                            no-repeat;
                        background-size: 16px 16px;
                        background-position: left;
                    }

                    .icon-download {
                        background: url("../assets/images/icon_download.png")
                            no-repeat;
                        background-size: 16px 16px;
                        background-position: left;
                    }

                    .icon-fav {
                        background: url("../assets/images/icon_fav.png")
                            no-repeat;
                        background-size: 16px 16px;
                        background-position: left;
                    }

                    .icon-faved {
                        background: url("../assets/images/icon_faved.png")
                            no-repeat;
                        background-size: 16px 16px;
                        background-position: left;
                    }
                }

                .tim-size {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 13px;

                    .val {
                        color: #989898;
                        font-size: 14px;
                    }
                }
            }

            li:last-child {
                margin-bottom: 0px;
            }

            .file-pdf {
                background: url("../assets/images/file_pdf.png") no-repeat;
                background-size: 40px 40px;
                background-position: left 0;
            }

            .file-word {
                background: url("../assets/images/file_word.png") no-repeat;
                background-size: 40px 40px;
                background-position: left 0;
            }

            .file-xls {
                background: url("../assets/images/file_xls.png") no-repeat;
                background-size: 40px 40px;
                background-position: left 0;
            }

            .file-albums {
                background: url("../assets/images/file_albums.png") no-repeat;
                background-size: 40px 40px;
                background-position: left 0;
            }

            .file-apk {
                background: url("../assets/images/file_apk.png") no-repeat;
                background-size: 40px 40px;
                background-position: left 0;
            }

            .file-exe {
                background: url("../assets/images/file_exe.png") no-repeat;
                background-size: 40px 40px;
                background-position: left 0;
            }

            .file-link {
                background: url("../assets/images/file_link.png") no-repeat;
                background-size: 40px 40px;
                background-position: left 0;
            }

            .file-picture {
                background: url("../assets/images/file_picture.png") no-repeat;
                background-size: 40px 40px;
                background-position: left 0;
            }

            .file-powerpoint {
                background: url("../assets/images/file_powerpoint.png")
                    no-repeat;
                background-size: 40px 40px;
                background-position: left 0;
            }

            .file-video {
                background: url("../assets/images/file_video.png") no-repeat;
                background-size: 40px 40px;
                background-position: left 0;
            }
        }
    }

    .pagination-box {
        text-align: center;
        margin-bottom: 37px;
        margin-top: 13px;
    }
}
@media only screen and (max-width: 750px) {
    .main{
        padding: 1.7rem .3rem 0;
    }
    .row-m1{
        padding-top: 0;
        .m-search-results{
            margin-bottom: .1rem;
            .el-input{
                height: .7rem;
            }
            ::v-deep .el-input__inner{
                font-size: .24rem;
                height: .7rem;
                line-height: .7rem;
            }
            .label{
                right: .1rem;
                width: .5rem;
                height: .5rem;
            }
        }
        .ul-nav2-m2{
            margin: 0 -.15rem;
            li{
                margin: 0;
                padding: 0 .15rem;
            }
            .con{
                font-size: .28rem;
                line-height: .8rem;
            }
        }
    }
    .row-m2{
        margin-bottom: .6rem;
        .g-search-content{
            font-size: .24rem;
            line-height: 2;
            padding: .15rem;
        }
        .work-content-box{
            .bot{
                padding: .3rem 0;
                .list-box{
                    li{
                        padding-bottom: .2rem;
                        padding-left: .8rem;
                        background-size: .6rem auto;
                        .tit{
                            display: block;
                            .val{
                                font-size: .28rem;
                            }
                            .op{
                                font-size: .24rem;
                                margin-top: .1rem;
                                span{
                                    background-size: .3rem auto;
                                    padding-left: .4rem;
                                    margin-left: 0;
                                    margin-right: .3rem;
                                }
                            }
                        }
                        .tim-size{
                            display: block;
                            .val{
                                font-size: .24rem;
                            }
                            .op{
                                font-size: .24rem;
                                margin-top: .1rem;
                                span{
                                    background-size: .3rem auto;
                                    padding-left: .4rem;
                                    margin-left: 0;
                                    margin-right: .3rem;
                                }
                            }
                        }
                    }
                }
            }
            .pagination-box{
                margin: .3rem 0;
            }
        }
    }
}
</style>
