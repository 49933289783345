<template>
    <div class="forumdetail">
        <Header />
        <div class="main pb80" id="app" style="background-color: #f5f5f7;">
            <div class="g-ban">
				<el-carousel>
				  <el-carousel-item v-for="item in banner" :key="item.id">
					<img @click="tourl(item)" :src="item.image" />
				  </el-carousel-item>
				</el-carousel>
                
            </div>
            <div class="wp w1200">
                <div class="m-admintiezi">
                    <ul class="ul-alltiezi">
						<router-link :to="'/newsinfo?id='+item.id" v-for="item in list" :key="item.id" class="news-item">
							<div class="news-item-title">
								<div>{{item.title}}</div>
								<div>查看详情 ></div>
							</div>
							<div class="news-item-date">{{item.releaseTime}}</div>
							<div class="news-item-content">{{item.info}}</div>
						</router-link>
                    </ul>
                    <!-- 内容为空 -->
                    <!-- <ContentNull v-if="list.length == 0" /> -->
                    <div class="pagination-box">
                        <el-pagination
                            :total="total"
                            layout="prev, pager, next"
                            :page-size="params.pageSize"
							@current-change="handleCurrentChange"
                        ></el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Api from '@/request/api.js';
import { mapState } from 'vuex';
import ContentNull from '@/components/ContentNull.vue';
export default {
    name: 'ForumList',
    components: {
        Header,
        Footer,
        ContentNull
    },
    data() {
        return {
			list:[],
			total:0,
			params:{
				pageSize:10,
				pageNo:1
			},
			banner:[]
			
        }
    },
    created() {
    	this.getList()
		//获取广告列表
		Api.banner({position:1}).then(res => {
			this.banner = res;
		})
		
    },
    watch: {
        $route(v) {
            if (v.path == '/') {
                this.getList()
            }
        }
    },
    methods: {
    	getList() {
    		Api.newsList(this.params).then(res => {
				console.log(res.records);
    			this.list = res.records
    			this.total = res.total
    		})
    	},
    	handleCurrentChange(v) {
            this.params.pageNo = v
            this.getList()
        },
		tourl(item){
			Api.updateClickNum({id:item.id});
			if(item.link){
				window.open(item.link);
			}
			
		},
    },
    mounted() {
    }

}
</script>

<style scoped lang="scss">
.opts .sort-label {
    text-align: center;
    border: 1px solid #cccccc;
    border-radius: 3px;
    display: inline-block;
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
    margin-right: 34px;
    cursor: pointer;
}

a:hover {
    color: #e73522;
}
.main.pb80 {
    padding-bottom: 80px;
}
.g-ban {
    margin-bottom: 30px;
}



.ul-alltiezi {
    margin-bottom: 34px;
}


.pagination-box {
    text-align: center;
    padding:13px 0px 37px;
}
.news-item{
	width: 100%;
	background: #FFFFFF;
	border-radius: 5px;
	padding: 20px;
	box-sizing: border-box;
	margin-bottom: 10px;
	display: block;
}
.news-item-title{
	display: flex;
	justify-content: space-between;
	font-size: 16px;
	font-weight: 500;
	color: #111111;
	line-height: 16px;
	margin-bottom: 14px;
}
.news-item-title div:last-of-type{
	font-size: 14px;
	font-weight: 400;
	color: #E73522;
	line-height: 16px;
}
.news-item-date{
	font-size: 14px;
	font-weight: 400;
	color: #989898;
	line-height: 16px;
	margin-bottom: 17px;
}
.news-item-content{
	font-size: 14px;
	font-weight: 400;
	color: #666666;
	line-height: 20px;
	word-break: break-all;
}
::v-deep .el-carousel__container{
	height: 350px;
}
::v-deep .el-carousel__container img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media only screen and (max-width: 750px) {
	.opts .sort-label {
		padding: 0 .1rem;
		height: .6rem;
		line-height: .6rem;
		margin-right: .4rem;
	}
	.main.pb80 {
		padding-top: 1.4rem;
		padding-bottom: .8rem;
	}
	.g-ban {
		margin-bottom: .3rem;
	}
	.ul-alltiezi {
		margin-bottom: .4rem;
	}

	.pagination-box {
		text-align: center;
		padding:.2rem 0px 0;
	}
	.news-item{
		padding: .3rem;
		margin-bottom: .1rem;
	}
	.news-item-title{
		font-size: .32rem;
		line-height: .4rem;
		margin-bottom: .2rem;
	}
	.news-item-title div:first-child{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.news-item-title div:last-of-type{
		flex-shrink: 0;
		font-size: .24rem;
		line-height: .4rem;
	}
	.news-item-date{
		font-size: .24rem;
		line-height: 1.6;
		margin-bottom: .2rem;
	}
	.news-item-content{
		font-size: .28rem;
		line-height: 1.6;
		word-break: break-all;
	}
	::v-deep .el-carousel__container{
		height: 2rem;
	}
}

</style>