
function getRem(pwidth,prem){
	var html = document.getElementsByTagName("html")[0];
    // var oWidth = window.innerWidth;
    var oWidth = document.documentElement.clientWidth
    // console.log('oWidthoWidthoWidth',oWidth)
    if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent) || oWidth <= 750) {
        pwidth = 750;
    } else if (oWidth > 750 && oWidth <= 1200){
        oWidth = 1200;
    }
	var size = oWidth/pwidth*prem;
    size = size >= 100 ? 100 : size;
    size = size <= 40 ? 40 : size;
    // alert(`size:${size}, pwidth:${pwidth}, owidth${oWidth}`)
	html.style.fontSize = size + "px";

}

getRem(1920,100);
/*1920代表设计师给的设计稿的宽度，你的设计稿是多少，就写多少;100代表换算比例，这里写100是
为了以后好算,比如，你测量的一个宽度是100px,就可以写为1rem,以及1px=0.01rem等等*/
window.onresize = function(){
    getRem(1920,100);
};

export default{
    getChar(x){
        return x.charAt(0) + x.slice(1,x.length);
    }
}