<template>
    <div class="forumdetail">
        <Header />
        <div class="main pb80" id="app" style="background-color: #f5f5f7;">
            <div class="g-ban">
				<el-carousel>
				  <el-carousel-item v-for="item in banner" :key="item.id">
					<img @click="tourl(item)" :src="item.image" />
				  </el-carousel-item>
				</el-carousel>
            </div>
            <div class="wp w1200">
                <div class="m-admindesc">
                    <div class="pic">
                        <img :src="forumInfo.plateImg" alt />
                    </div>
                    <div class="txts">
                        <div class="info">
                            <div class="info-top">
								<div class="tit">{{ forumInfo.name }}</div>
								<div class="nums">
								    <div class="num">订阅: {{ forumInfo.subscribeNum }}</div>
								    <div class="num">评论: {{ forumInfo.replyNum }}</div>
								    <div class="num">帖子: {{ forumInfo.postsNum }}</div>
								</div>
							</div>
							<div class="info-author" v-if="forumInfo.moderatorList">版主：<span>{{forumInfo.moderatorList.join("、")}}</span></div>
                            <div class="desc">{{ forumInfo.memo }}</div>
                        </div>
                        <div class="btns" :class="forumInfo.subscription ? 'on' : ''">
                            <div
                                @click="subscription"
                                v-if="forumInfo.subscribeStatus==1"
                                class="g-dingyuebtn bt1"
                            >订阅</div>
                            <div @click="subscription" v-else class="g-dingyuebtn bt2">取消订阅</div>
                        </div>
                    </div>
                </div>
                <div class="m-admintiezi">
                    <div class="alltabs">
                        <div class="tit">分类:</div>
                        <div class="tabs">
                            <div
                                :class="['tab', current == 0 ? 'on' : '']"
                                @click="changeCurrent(-1)"
                            >全部</div>
                            <div
                                :class="['tab', current == (index + 1) ? 'on' : '']"
                                v-for="(item, index) in forumCates"
                                :key="index"
                                @click="changeCurrent(index)"
                            >{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="allopts">
                        <div class="opts">
                            <!-- <div class="opt">上线时间</div> -->
                            <el-dropdown placement="bottom-start">
                                <span class="el-dropdown-link sort-label">
                                    {{ sortDate }}
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" class="sort-menu">
                                    <el-dropdown-item
                                        v-for="(item, index) in dates"
                                        :key="index"
                                        @click.native="sortDate = item.day; params.time = index; getList();"
                                    >{{ item.day }}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <el-dropdown placement="bottom-start">
                                <span class="el-dropdown-link sort-label">
                                    {{ sortOrder }}
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" class="sort-menu">
                                    <el-dropdown-item
                                        v-for="(item, index) in orders"
                                        :key="index"
                                        @click.native="sortOrder = item; params.orderType = index+1; getList();"
                                    >{{ item }}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <!-- <div
                                :class="['opt', params.order == '阅读量' ? 'ac' : '']"
                                @click="orderSearch"
                            >阅读量</div> -->
                            <div class="search-box">
                                <input
                                    type="text"
                                    v-model="params.keywords"
                                    placeholder="请输入搜索关键词"
                                    @keyup.enter="getList"
                                />
                                <i class="search-btn" @click.stop="getList"></i>
                            </div>
                        </div>
                        <div class="btn" @click="toAdd">发帖子</div>
                    </div>
                    <ul class="ul-alltiezi" v-loading="loading">
                        <li v-for="(item, index) in list" :key="index">
                            <div class="con">
                                <router-link
                                    :to="'/forumdetail?id=' + item.id + '&pid=' + pid"
                                    class="txts"
                                >
                                    <div class="tit">{{ item.title }}<span v-if="item.topStatus==0" class="tit-icon-one">置顶</span><span v-if="item.replyAdminVisible==0" class="tit-icon-two">回复仅管理员可见</span></div>
                                    <div class="desc" v-html="item.postsContent"></div>
                                </router-link>
                                <div class="info">
                                    <router-link
                                        :to="'/forumdetail?id=' + item.id + '&pid=' + pid"
                                        class="hdinfo"
                                    >
                                        <div class="pic" style="width: 32px;height: 32px;border-radius: 100%;margin-right: 18px;">
                                            <img class="pic" style="width: 32px;height: 32px;border-radius: 100%;margin-right: 18px;" :src="item.createByImg" />
                                        </div>
                                        <div class="txt">
                                            <div class="name">{{ item.createName }}
												<div class="name-liuyan" @click.stop.prevent="liuyan(item)"><img src="@/assets/images/fengxiang.png"/>私信</div>
											</div>
                                            <div class="date">
                                                <span
                                                    class="senddate"
                                                >发帖时间：{{ item.createTime }}</span>
                                                <span
                                                    class="backdate"
                                                    v-if="item.lastRevertTime"
                                                >最后回复：{{ item.lastRevertTime }}</span>
                                            </div>
                                        </div>
                                    </router-link>
                                    <div class="icons">
                                        <div class="icon i1">{{ item.readNum }}</div>
                                        <div v-if="item.likeStatus==0" @click.stop="like(item.id,1,index)" class="icon i2">{{ item.likeNum }}</div>
                                        <div v-else class="icon i2" @click.stop="like(item.id,0,index)">{{ item.likeNum }}</div>
                                        <div class="icon i3">{{ item.revertNum }}</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- 内容为空 -->
                    <ContentNull v-if="list.length == 0" />
                    <div class="pagination-box">
                        <el-pagination
                            :total="total"
                            layout="prev, pager, next"
                            :page-size="params.pageSize"
                            @current-change="handleCurrentChange"
                        ></el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
		<Liuyan ref="liuyan"></Liuyan>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Api from '@/request/api.js';
import { mapState } from 'vuex';
import ContentNull from '@/components/ContentNull.vue';
import Liuyan from '@/components/liuyan.vue';
export default {
    name: 'ForumList',
    components: {
        Header,
        Footer,
        ContentNull,
		Liuyan
    },
    data() {
        return {
            pid: null,
            sortVal: 1,
            date: new Date(),
            dates: [],
            sortDate: '全部时间',
			orders:["最新发帖" ,"最多回帖","最新回帖","热门"],
			sortOrder:"默认排序",
            loading: false,
            params: {
                belong: 3,
                time: '',
                sort: 0,
                keywords: '',
                pageNo: 1,
                pageSize: 10,
                classId: 0,
                type: 0,
				orderType:""
            },
            forumInfo: {},
            forumCates: [],  // 分类数组
            current: 0,  // 当前分类
            list: [],
            total: 0,
            dingyue: false,
			banner:[]

        }
    },
    async created() {
        // 如果论坛关闭 跳转到首页
        if (this.forumStatus == null) {
            await this.getForumConfig()
        }
        if (this.forumStatus != 1) {
            this.$message.error('论坛已关闭')
            return this.$router.replace({ path: '/' })
        }
        if (this.$route.query.pid) {
            this.pid = Number(this.$route.query.pid)
            this.params.classId = Number(this.$route.query.pid)
            // 获取板块信息
            this.getForumInfo()
            // 获取板块分类
            this.getForumCates()
            // 获取列表
            this.getList()
            // 获取日期列表
            this.getDateList()
        } else {
            this.$router.replace({ path: '/' })
        }
		
		//获取广告列表
		Api.banner({position:4}).then(res => {
			console.log(res);
			this.banner = res;
		})
    },
    computed: {
        ...mapState([
            'forumStatus'
        ])
    },
		
		mounted() {
			if(this.$store.state.user.userType != 1){
				this.$router.replace("/");
			}
		},
    methods: {
		
		tourl(item){
			Api.updateClickNum({id:item.id});
			if(item.link){
				window.open(item.link);
			}
		},
        getList() {
            this.loading = true
            Api.forumList(this.params).then(res => {
                const imgReg = new RegExp('<img.*?(?:>|\/>)', 'gi')
                const videoReg = new RegExp('<iframe.*?(?:>|\/>)', 'gi')
                const reg = new RegExp('<p>' + '(.*?)' + '</p>')
                for (let i = 0; i < res.records.length; i++) {
                    const str = res.records[i].postsContent
                    if (str.match(reg)) {
                        res.records[i].postsContent = str.match(reg)[1]
                    }
                    if (imgReg.test(str)) {
                        res.records[i].postsContent = str.replace(imgReg, '')
                    }
                    if (videoReg.test(str)) {
                        res.records[i].postsContent = str.replace(videoReg, '')
                    }
                }
                this.list = res.records
				console.log(res)
                this.total = res.total
                this.loading = false
            })
        },
        // 分页查询
        handleCurrentChange(v) {
            this.params.pageNo = v
            this.getList()
        },
        // 排序查询
        orderSearch() {
            if (this.params.order != '') {
                this.params.order = ''
            } else {
                this.params.order = '阅读量'
            }
            this.getList()
        },
        // 获取板块信息
        getForumInfo() {
            Api.forumInfo(this.params.classId).then(res => {
				if(res == null){
					this.$router.replace({ path: '/' })
				}
                this.forumInfo = res
            }).catch(err => {
                this.$router.replace({ path: '/' })
            })
        },
        // 获取分类数组
        getForumCates() {
            Api.forumCates(this.params.classId).then(res => {
				console.log("数据结构");
				console.log(res);
                this.forumCates = res
            })
        },
        // 切换分类
        changeCurrent(index) {
            this.current = index + 1
            // 如果是全部分类 相当于查询倒数第二级的所有列表
            if (index == -1) {
                this.params.type = 0
            } else {
                this.params.type = this.forumCates[index].id
            }
            this.getList()
        },
        // 分页查询
        handleCurrentChange(v) {
            this.params.pageNo = v
            this.getList()
        },
        // 点赞
        like(id, like, index) {
            if (like) {
				
				Api.forumLike({ postsId: id,type:2 }).then(res => {
					this.list[index].likeStatus = like
					this.list[index].likeNum -= 1
				})
            } else {
				Api.forumLike({ postsId: id,type:2 }).then(res => {
                    this.list[index].likeStatus = like
                    this.list[index].likeNum += 1
				})
            }
        },
		handleImgUpload(blobInfo, success, failure) {
			Api.upload(blobInfo.blob()).then((res)=>{
				if(res.data.code == 0){
					success(res.data.message);
				}else{
					failure('HTTP Error: ' + res.data.message);
				}
			});
		},
        // 订阅
        subscription() {
            if (this.forumInfo.subscription) {
                Api.subscriptionDel({ classId: this.forumInfo.id }).then(res => {
                    this.forumInfo.subscribeStatus = !this.forumInfo.subscribeStatus
                    this.getForumInfo()
                })
            } else {
                Api.subscriptionAdd({ classId: this.forumInfo.id }).then(res => {
                    this.forumInfo.subscribeStatus = !this.forumInfo.subscribeStatus
                    this.getForumInfo()
                })
            }
        },
        // 跳转到发帖页面
        toAdd() {
			if(this.current == 0){
				this.$router.push({ path: '/mypostpage', query: { pid: this.params.classId} })
			}else{
				this.$router.push({ path: '/mypostpage', query: { pid: this.params.classId,cateId:this.forumCates[this.current-1].id } })
			}
            
        },
        // 获取日期列表
        getDateList() {
            let arr = []
            arr.push({ day: '全部时间'})
            arr.push({ day: '一周'})
            arr.push({ day: '一月'})
            arr.push({ day: '半年'})
            arr.push({ day: '一年'})
            arr.push({ day: '三年'})
            this.dates = arr
        },
        // 获取论坛是否开启
        async getForumConfig() {
            await Api.getConfigId({ id: 17 }).then(async res => {
                console.log(res)
				await this.$store.dispatch('setForumStatus', res.config)
			})
        },
		liuyan(row){
			this.$refs.liuyan.init(row.userId);
		}
    },
    mounted() {
    }

}
</script>

<style scoped lang="scss">
.opts .sort-label {
    text-align: center;
    border: 1px solid #cccccc;
    border-radius: 3px;
    display: inline-block;
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
    margin-right: 34px;
    cursor: pointer;
}
.search-box {
    position: relative;
}
.search-box input {
    border: 1px solid #ccc;
    line-height: 32px;
    outline: none;
    padding: 0px 10px;
    height: 32px;
    min-width: 260px;
}
.search-box .search-btn {
    width: 32px;
    height: 32px;
    background: url("../assets/images/search.png") no-repeat;
    display: inline-block;
    background-size: 16px 16px;
    background-position: center;
    cursor: pointer;
    position: absolute;
    right: 0px;
    border: 0px;
    top: 0px;
    transition: 0.2s all linear;
}
.search-box .search-btn.active {
    opacity: 0.8;
    background-color: #eee;
}

a:hover {
    color: #e73522;
}
.main.pb80 {
    padding-bottom: 80px;
}
.g-ban {
    margin-bottom: 30px;
}
.m-admindesc {
    border-top: 2px solid #e83421;
    padding: 28px 60px 30px 17px;
    background-color: #fff;
    overflow: hidden;
    color: #333;
    margin-bottom: 20px;
}
.m-admindesc .pic {
    float: left;
    width: 90px;
    height: 90px;
    overflow: hidden;
    margin-right: 43px;
}
.m-admindesc .txts {
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
}
.m-admindesc .txts .info {
    width: 752px;
    position: relative;
}
.info-top{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.m-admindesc .txts .info::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -58px;
    border-right: 1px solid #e4e4e4;
}
.m-admindesc .txts .tit {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}
.m-admindesc .txts .nums {
    font-size: 14px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #333;
}
.m-admindesc .txts .num {
    float: left;
    margin-right: 50px;
}
.m-admindesc .txts .num:last-of-type {
    margin-right: 0;
}
.m-admindesc .txts .desc {
    font-size: 14px;
    color: #999;
}
.m-admindesc .txts .g-dingyuebtn,
.m-admindesc .txts .bt2{
    width: 120px;
    height: 36px;
    font-size: 14px;
    line-height: 36px;
    border-color: #ddd;
    display: block;
}
.m-admindesc .txts .g-dingyuebtn:hover {
    color: #fff;
}
.m-admindesc .txts .on .bt2 {
    opacity: 1;
    height: 36px;
	border: 1px solid #ddd;
}
.m-admindesc .txts .on .bt2:hover{
	background-color: #E73522;
	color: #fff;
}
.m-admindesc .txts .on .bt1::before {
    display: none;
}
.m-admindesc .txts .bt2 {
    text-align: center;
    line-height: 36px;
    transition: 0.5s;
    color: #666;
    cursor: pointer;
}

.m-admintiezi {
    font-size: 14px;
    border-radius: 5px;
    overflow: hidden;
    background-color: #fff;
}

.m-admintiezi .alltabs {
    padding: 23px 20px 20px;
    border-bottom: 1px solid #ddd;
}
.m-admintiezi .alltabs .tit {
    float: left;
}
.m-admintiezi .tabs {
    overflow: hidden;
}

.m-admintiezi .tab {
    height: 22px;
    line-height: 22px;
    margin: 0 10px;
    color: #707070;
    text-align: center;
    border-radius: 11px;
    float: left;
    cursor: pointer;
    padding: 0 12px;
    transition: 0.5s;
}
.m-admintiezi .tab.on,
.m-admintiezi .tab:hover {
    background-color: #e73522;
    color: #fff;
}

.m-admintiezi .allopts {
    padding: 10px 20px;
    font-size: 14px;
    color: #707070;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.m-admintiezi .allopts .opts {
    overflow: hidden;
    display: flex;
    align-items: center;
}
.m-admintiezi .allopts .opt {
    float: left;
    margin-right: 34px;
    cursor: pointer;
}
.m-admintiezi .allopts .opt:hover {
    color: #e73522;
}
.m-admintiezi .allopts .opt.ac {
    color: #e73522;
}
.m-admintiezi .allopts .btn {
    width: 150px;
    height: 40px;
    background: linear-gradient(-70deg, #d62016, #e66059);
    border-radius: 5px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    transition: 0.5s;
}
.m-admintiezi .allopts .btn::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(../assets/images/detail/icon7.png) no-repeat;
    background-size: 100%;
    line-height: 40px;
    margin-right: 10px;
    transform: translateY(4px);
}
.m-admintiezi .allopts .btn:hover {
    box-shadow: 0 0 10px #e66059;
    cursor: pointer;
}

.ul-alltiezi {
    padding: 0 20px;
    margin-bottom: 34px;
}
.ul-alltiezi .con {
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
}
.ul-alltiezi .txts {
    margin-bottom: 20px;
    display: block;
}
.ul-alltiezi .txts .tit {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
}
.ul-alltiezi .txts .desc {
    font-size: 14px;
    color: #707070;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.ul-alltiezi .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ul-alltiezi .hdinfo {
    overflow: hidden;
    color: #333;
}
.ul-alltiezi .hdinfo .pic {
    width: 90px;
    height: 90px;
    float: left;
    /* overflow: hidden; */
    margin-right: 43px;
}
.ul-alltiezi .hdinfo .txt {
    overflow: hidden;
}
.ul-alltiezi .hdinfo .name {
    font-size: 14px;
	display: flex;
	justify-content: flex-start;
}
.name-liuyan{
	width: 80px;
	height: 22px;
	background: #E8E8EB;
	border-radius: 11px;
	font-size: 14px;
	font-weight: 400;
	color: #E73522;
	line-height: 22px;
	text-align: center;
	margin-left: 17px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.name-liuyan img{
	width: 14px;
	height: 14px;
	margin-right: 6px;
}
.ul-alltiezi .hdinfo .date {
    font-size: 14px;
    color: #999;
}
.ul-alltiezi .hdinfo .date span {
    display: inline-block;
    margin-right: 30px;
}

.ul-alltiezi .icons {
}
.ul-alltiezi .icons .icon {
    margin-right: 40px;
    float: left;
    padding-left: 20px;
    background: url(../assets/images/detail/icon8.png) no-repeat left center;
    background-size: 16px;
    cursor: pointer;
}
.ul-alltiezi .icons .icon:hover {
    color: #e73522;
}
.ul-alltiezi .icons .icon.i2 {
    background-image: url(../assets/images/detail/icon9.png);
}
.ul-alltiezi .icons .icon.i3 {
    background-image: url(../assets/images/detail/icon10.png);
    background-position: left 3px;
}
.ul-alltiezi .icons .icon:last-of-type {
    margin-right: 0;
}

.pagination-box {
    text-align: center;
    margin-bottom: 37px;
    margin-top: 13px;
}
.info-author{
	height: 14px;
	font-size: 14px;
	font-weight: 400;
	color: #333333;
	margin: 0px 0px 16px;
}
.info-author span{
	color:#666666;
}
.tit-icon-one{
	width: 40px;
	height: 22px;
	background: #E73522;
	border-radius: 3px;
	text-align: center;
	line-height: 22px;
	font-size: 14px;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 22px;
	display: inline-block;
	margin-left: 10px;
}
.tit-icon-two{
	width: 133px;
	height: 23px;
	border: 1px solid #E73522;
	border-radius: 11px;
	font-size: 14px;
	font-weight: 400;
	color: #E73522;
	line-height: 23px;
	text-align: center;
	display: inline-block;
	margin-left: 10px;
}
::v-deep .el-carousel__container{
	height: 350px;
}
::v-deep .el-carousel__container img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
@media only screen and (max-width: 750px) {
    .main.pb80{
        margin-top: 1.4rem;
        padding-bottom: .6rem;
    }
    .g-ban{
        margin-bottom: .3rem;
    }
    .m-admindesc{
        padding: .3rem;
        .pic{
            width: 1.2rem;
            height: 1.2rem;
            margin-right: .2rem;
        }
        .txts{
            display: block;
            overflow: hidden;
            .info{
                width: 100%;
                .info-top{
                    display: block;
                    .tit{
                        font-size: .32rem;
                        margin-bottom: .1rem;
                    }
                    .nums{
                        font-size: .24rem;
                        .num{
                            margin-right: .3rem;
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
                .info-author{
                    font-size: .2rem;
                    height: auto;
                    margin: .1rem 0;
                }
                .desc{
                    font-size: .24rem;
                    line-height: 1.8;
                }                
            }
            .g-dingyuebtn{
                width: 2rem;
                height: .6rem;
                font-size: .24rem;
                line-height: .6rem;
                margin-top: .1rem;
            }
        }
    }
    .m-admintiezi{
        font-size: .24rem;
        .alltabs{
            padding: .3rem;
            .tit{
                line-height: .4rem;
            }
            .tab{
                line-height: .4rem;
                height: .4rem;
                border-radius: .2rem;
                padding: 0 .2rem;
                margin: 0 .1rem;
            }
        }
        .allopts{
            display: block;
            font-size: .24rem;
            padding: .3rem;
            margin-bottom: .1rem;
            .opts{
                display: block;
                .el-dropdown{
                    display: block;
                    width: 100%;
                    margin-top: .2rem;
                }
                .sort-label{
                    display: block;
                    width: 100%;
                    height: .6rem;
                    font-size: .24rem;
                    line-height: .6rem;
                }
                .search-box {
                    position: relative;
                    margin-top: .2rem;
                    input {
                        line-height: .6rem;
                        padding: 0px .2rem;
                        height: .6rem;
                        min-width: 260px;
                        width: calc(100% - .7rem);
                        padding-right: .3rem;
                    }
                    .search-btn {
                        width: .6rem;
                        height: .6rem;
                        background-position: center center;
                        background-size: .3rem auto;
                    }
                }
            }
            .btn{
                width: 100%;
                height: .6rem;
                line-height: .6rem;
                margin-top: .2rem;
                &::before{
                    width: .3rem;
                    height: .3rem;
                }
            }
        }
        .ul-alltiezi{
            padding: 0 .3rem;
            margin-bottom: .6rem;
            .con{
                padding: .2rem 0;
                .txts{
                    margin-bottom: .2rem;
                    .tit{
                        font-size: .28rem;
                        line-height: .4rem;
                        .tit-icon-one{
                            width: auto;
                            height: .4rem;
                            font-size: .24rem;
                            padding: 0 .1rem;
                            line-height: .4rem;
                        }
                    }
                    .desc{
                        font-size: .24rem;
                    }
                }
                .info{
                    display: block;
                    overflow: hidden;
                    .hdinfo{
                        display: block;
                        .pic{
                            flex-shrink: 0;
                            width: 0.8rem !important;
                            height: 0.8rem !important;
                            margin-right: .1rem !important;
                        }
                        .txt{
                            overflow: hidden;
                            .name{
                                font-size: .24rem;
                                line-height: .8rem;
                                .name-liuyan{
                                    width: 1.2rem;
                                    height: .5rem;
                                    border-radius: .3rem;
                                    font-size: .24rem;
                                    margin-left: .1rem;
                                    line-height: .5rem;
                                    margin-top: .15rem;
                                    img{
                                        width: .3rem;
                                        height: .3rem;
                                        margin-right: .1rem;
                                    }
                                }
                            }
                            .date{
                                font-size: .24rem;
                                line-height: 1.6;
                                margin-top: .1rem;
                            }
                        }
                    }
                    .icons{
                        margin-top: .2rem;
                        overflow: hidden;
                        margin-left: 0.9rem;
                        .icon{
                            padding-left: .4rem;
                            background-size: .3rem auto;
                        }
                    }
                }
            }
        }
        .pagination-box{
            margin: .6rem 0;
        }
    }

    ::v-deep .el-carousel__container{
		height: 2rem;
	}
    ::v-deep .el-dropdown-menu__item{
        font-size: .28rem;
        line-height: 2;
    }
}
</style>