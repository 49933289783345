<template>
    <div class="partner">
        <Header />
        <div class="ban">
            <img src="../assets/images/partner/ban-sign.png" alt />
        </div>
        <div class="parSortBg">
            <div class="wp w1200">
                <ul class="parSort">
                    <li><span>填写认证信息</span></li>
                    <li><span>信息核对</span></li>
                    <li class="active"><span>签署合作协议</span></li>
                </ul>
            </div>
        </div>
        <div class="parSignBg">
            <div class="wp w1200">
                <div class="parSign">
                    <div class="signTit">
                        <h2>《合作协议》</h2>
                        <span><img src="../assets/images/partner/icon-down-red@2x.png">下载</span>
                    </div>
                    <div class="signText">
                        <p>商汤论坛用户协议</p>
                        <p>1. 总则</p>
                        <p>1.1  商汤科技合作伙伴论坛（以下简称“论坛”）为商汤与商汤合作伙伴进行商汤产品销售推广相关的技术和商务交流平台。</p>
                        <p>1.2 商汤合作伙伴（以下简称“合作伙伴”）是指与销售或推广商汤产品相关第三方公司及其公司员工。合伙伙伴包括商汤产品的经销商、分销商、ISV（独立软件提供商）、售后服务商等。</p>
                        <p>1.3 本论坛的所有权归北京市商汤科技开发有限公司所有（以下简称“商汤公司”）</p>
                        <p>1.4 合作伙伴在使用本论坛的服务之前，应当仔细阅读本协议，特别是与合作伙伴的权益有重大关系、涉及免除或限制商汤公司责任的条款。</p>
                        <p>1.5 合作伙伴同意遵守本协议后方可成为本论坛用户，一旦合作伙伴注册成功，则合作伙伴与本论坛之间自动形成协议关系。合作伙伴应当受本协议的约束，包括接受本论坛本协议所做的修订和补充。</p>
                        <p>1.6 合作伙伴理解本协议可由本论坛单方进行更新，合作伙伴在使用本论坛时应当及时关注并仔细阅读，合作伙伴理解并同意本论坛不承担针对每个合作伙伴的单独通知义务。本论坛其后针对合作伙伴注册而更新的通知、公告、声明或其它类似内容是本协议的一部分，具有同等的效力。</p>
                        <p>2. 服务内容</p>
                        <p>2.1 论坛为商汤与商汤合作伙伴进行商汤产品销售推广相关的技术和商务交流平台，论坛讨论内容可包括商汤产品的产品技术讨论、销售方法讨论、售后问题反馈、产品改进建议、渠道问题反馈、销售经验分享等，非商汤产品销售推广相关的内容不得在论坛内发布和讨论。</p>
                        <p>2.2 论坛仅面向商汤的合作伙伴（与商汤签约，有正式合作伙伴关系）开放和使用，通过网站邀请码定向邀请的合作伙伴可免费注册和使用，未受到邀请的用户不得注册和使用本论坛。</p>
                        <p>2.3 本论坛由商汤公司负责运营，商汤公司拥有对本论坛进行临时或永久关停的权利，并不承担由此造成的责任。</p>
                        <p>3. 合作伙伴账号和密码</p>
                        <p>3.1  经本论坛注册系统完成注册程序的合作伙伴即成为本论坛的正式用户，并获得本论坛规定合作伙伴所应享受的部分权限。本论坛有权对合作伙伴的权限设计进行单方变更。
                        <p>3.2 本论坛为实名制，详见《隐私条款》。同时，合作伙伴除个人信息外，需用真实的工作信息进行注册。如发现虚假信息，本论坛保留对账号进行删除账号、删除发帖的权利，本论坛不承担由此造成的责任。</p>
                        <p>3.3 本论坛不向未成年人开放注册。</p>
                        <p>3.4  如发现合作伙伴账号中的昵称含有不恰当的名称，或非受邀请的合作伙伴注册，本论坛保留取消其资格的权利。</p>
                        <p>3.5  合作伙伴有义务保证其密码和账号的安全。如因合作伙伴未保管好自己的账号和密码而给本论坛、第三方造成损失，合作伙伴应当赔偿本论坛或第三方因此而受到的损失，并消除影响。</p>
                        <p>3.6 如合作伙伴发现账号遭到未授权的使用或者发生其他任何异常情况，应立即修改账号密码并妥善保管，如有必要，请通知本论坛问题反馈邮箱groupchannel@sensetime.com。因黑客行为或者合作伙伴的保管疏忽乎导致账号被非法使用，本论坛不承担由此造成的责任。</p>
                        <p>3.7 本论坛在收到合作伙伴因异常行为要求论坛采取措施暂停其账户登录和使用的通知后，有权要求合作伙伴提供并核实与其注册身份信息相一致的个人有效身份信息。本论坛经核实与所注册的身份信息相一致的，应当及时采取措施暂停合作伙伴账号的登陆和使用。合作伙伴没有提供其个人有效身份信息或者合作伙伴提供的个人有效身份信息与所注册的身份信息不一致的，本论坛有权拒绝合作伙伴的前述要求。</p>
                        <p> 4. 使用规则</p>
                        <p>4.1 合作伙伴在使用本论坛时，应当遵守所有现行适用的相关法律法规及政策，包括但不限于《中华人民共和国宪法》、《中华人民共和国刑法》、《中华人民共和国民法通则》、《中华人民共和国民法总则》、《中华人民共和国合同法》、《中华人民共和国著作权法》、《中华人民共和国个人信息保护法》、《中华人民共和国数据安全法》、《中华人民共和国网络安全法》、《信息网络传播权保护条例》、《中华人民共和国电信条例》、《互联网信息服务管理办法》、《互联网电子公告服务管理规定》、《中华人民共和国保守国家秘密法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机信息网络国际联网安全保护管理办法》、《全国人民代表大会常务委员会关于维护互联网安全的决定》及其相关的司法解释。</p>
                        <p>4.2  合作伙伴在使用本论坛的过程中，必须遵循以下原则：</p>
                        <p>1) 遵守所有与网络服务有关的网络协议、规定和程序；</p>
                        <p>2) 不得利用本论坛进行任何可能对互联网的正常运转造成不利影响的行为；</p>
                        <p>3) 不得利用本论坛传输任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的，侵犯他人知识产权的，或其他任何非法的信息资料。</p>
                        <p>4) 不得以任何方式干扰或企图干扰本论坛任何部分或功能正常运行；</p>
                        <p>5) 不得避开、尝试避开或声称能够避开任何内容保护机制或者本论坛数据度量工具； </p>
                        <p>6) 不得以任何形式利用本论坛侵犯商汤的商业利益，包括但不限于发布非经本论坛许可的商业广告；</p>
                        <p>4.3  合作伙伴可根据本论坛的使用规则、原则自由发布相关信息，但所发布之信息不得发布违反国家相关法律法规，主要表现为：</p>
                        <p>1) 反对宪法所确定的基本原则</p>
                        <p>2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一</p>
                        <p>3) 损害国家荣誉和利益</p>
                        <p>4) 煽动民族仇恨、民族歧视，破坏民族团结</p>
                        <p>5) 侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的</p>
                        <p>6) 破坏国家宗教政策，宣扬邪教和封建迷信</p>
                        <p>7) 散布谣言，扰乱社会秩序，破坏社会稳定</p>
                        <p>8) 宣扬淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪</p>
                        <p>9) 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序</p>
                        <p>10) 诽谤他人，泄露他人隐私，侵害他人合法权益</p>
                        <p>11) 含有法律、行政法规禁止的其他内容的信息</p>
                        <p>4.4 合作伙伴不得利用本论坛的在线服务以任何方式从事以下活动：</p>
                        <p>1) 未经允许，进入计算机信息网络或者使用计算机信息网络资源；</p>
                        <p>2) 未经允许，对计算机信息网络功能进行删除、修改或者增加；</p>
                        <p>3) 未经允许，对计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增</p>
                    </div>
                    <el-checkbox class="isAgree" v-model="checked">点击签署后视同合作伙伴公司已签署协议，与加盖公司公章具备同等法律效力</el-checkbox>
                    <div class="signBot">
                        <div class="signTel">公司联系人手机号：183***6666</div>
                        <el-form ref="signTelForm" :model="form" :rules="rules" class="signTelForm">
                            <el-form-item class="signYzm" prop="code">
                                <el-input
                                    v-model="yzm"
                                    type="text"
                                    placeholder="请输入验证码"
                                ></el-input>
                                <div class="yzmBtn" v-show="show" @click.prevent="getCode()">获取验证码</div>
                                <div type="text" class="yzmBtn yzmBtn2" v-show="!show">{{count}}s后重试</div> 
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="empowerBtn">
                        <span class="wait" @click="prev">上一步</span>
                        <span class="submit" @click="comfirm">确认</span>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
    name: 'PartnerEmpower',
    components: { 
        Header,
        Footer
    },
    data() {
        return {
            checked: true,
            show: true,
            count: '',
            form:{
                yzm:''
            },
            rules:{

            }
        }
    },
    methods: {
        prev(){
            this.$router.push({ path: '/partnersign2'})
        },
        comfirm(){

        },
        // 获取短信验证码
        getCode() {
            // ……
            this.vaTime()
        },
        // 验证码倒计时
        vaTime(){
            this.count = 120;
            this.show = false;
            let timer=0
            timer = setInterval(() => {
                if (this.count > 0 && this.count <= 120) {
                    this.count--;
                }else {
                    this.show = true;
                    clearInterval(timer);
                    timer = 0;
                }
            }, 1000);
        },
    }
}
</script>
<style scoped lang="scss">
.partner{
    padding: 80px 0 0;
    overflow: hidden;
}
.parSortBg{
    line-height: 80px;
    background-color: #FFFFFF;
    text-align: center;
    .parSort{
        display: flex;
        li{
            flex: 1;
            font-size: 20px;
            color: #111111;
            opacity: .5;
            &.active{
                color: #FFFFFF;
                background: #E73522;
                opacity: 1;
            }
        }
    }
}

.parSignBg{
    padding: 30px 0 74px;
    background-color: #F5F5F7;
}
.parSign{
    padding: 30px 50px 60px;
    background: #FFFFFF;
}
.signTit{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #111111;
    line-height: 32px;
    margin: 20px 0 40px;
    h2{
        font-size: 24px;
    }
    span{
        flex-shrink: 0;
        display: block;
        cursor: pointer;
        transition: all .3s;
        img{
            width: 17px;
            height: auto;
            margin-right: 5px;
        }
        &:hover{
            color: #E73522;
        }
    }
}

.signText{
    font-size: 18px;
    color: #111111;
    line-height: 2;
    height: 560px;
    padding-right: 30px;
    margin-right: -30px;
    margin: 30px 0 60px;
    overflow-y: auto;
    &::-webkit-scrollbar{
        width: 8px;
        border-radius: 4px;
        background: #EEEEEE;
    }
    &::-webkit-scrollbar-thumb{
        border-radius: 4px;
        background: #666666;
    }
}
.isAgree{
    font-size: 16px;
    color: #2E2E2E;
    line-height: 30px;
    margin: 30px 0;
    ::v-deep .el-checkbox__inner{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        &::after{
            left: 5px;
            top: 2px;
        }
        &:hover{
            border-color: #E73522;
        }
    }
    ::v-deep .el-checkbox__label{
        font-size: 16px;
        color: #2E2E2E;
    }
    ::v-deep &.is-checked{
        .el-checkbox__inner{
            border-color: #E73522;
            background-color: #E73522;
        }            
    }
    ::v-deep .el-checkbox__input.is-focus{
        .el-checkbox__inner{
            border-color: #E73522;
        }
    }
    ::v-deep .is-checked+.el-checkbox__label{
        color: #2E2E2E;
    }
}
.signBot{
    display: flex;
    align-items: center;
    .signTel{
        min-width: 300px;
        font-size: 16px;
        color: #2E2E2E;
        line-height: 2;
        margin-right: 15px;
    }
    .signYzm{
        margin-bottom: 0;
        border-radius: 3px;
        overflow: hidden;
        ::v-deep  .el-form-item__content{
            display: flex;
            .el-input{
                display: block;
            }
        }
        ::v-deep .el-input__inner{
            width: 240px;
            height: 50px;
            font-size: 16px;
            color: #666666;
            line-height: 50px;
            border: none;
            border-radius: 0;
            background: #F5F5F8;
        }
        .yzmBtn{
            flex-shrink: 0;
            width: 120px;
            height: 50px;
            line-height: 50px;
            background: #E3E4E5;
            text-align: center;
            cursor: pointer;
        }
        .yzmBtn2{
            cursor: no-drop;
        }
    }
}
.empowerBtn{
    display: flex;
    justify-content: center;
    margin-top: .5rem;
    span{
        display: block;
        width: 280px;
        font-size: 16px;
        line-height: 50px;
        margin: 0 10px;
        border-radius: 3px;
        text-align: center;
        cursor: pointer;
    }
    .wait{
        color: #2E2E2E;
        background: #E3E4E5;;
    }
    .submit{
        color: #FFFFFF;
        background: #E73522;
    }
}
@media only screen and (max-width: 750px) {
    .ban{
        height: 2rem;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .partner{
        padding: 1.4rem 0 0;
    }
    .parSortBg{
        line-height: .8rem;
        .parSort{
            display: flex;
            li{
                font-size: .28rem;
            }
        }
    }

    .parSignBg{
        padding: .3rem 0 .6rem;
    }
    .parSign{
        padding: .3rem .3rem .6rem;
        .parSignTips{
            font-size: .24rem;
            margin: .2rem auto;
        }
        .parSignBtn{
            width: 100%;
            max-width: 280px;
            line-height: .7rem;
            font-size: .28rem;
            margin: .1rem auto;
        }
    }
    .signTit{
        font-size: .3rem;
        line-height: 2;
        margin: .2rem 0 .4rem;
        h2{
            font-size: .3rem;
        }
        span{
            img{
                width: .3rem;
                margin-right: .1rem;
            }
        }
    }

    .signText{
        font-size: .24rem;
        height: 6rem;
        padding-right: .3rem;
        margin-right: -.3rem;
        margin: .3rem 0 .6rem;
        &::-webkit-scrollbar{
            width: .08rem;
        }
    }
    .isAgree{
        display: flex;
        font-size: .24rem;
        line-height: .5rem;
        margin: .3rem 0;
        ::v-deep .el-checkbox__input{
            flex-shrink: 0;
            display: block;
            width: .3rem;
        }
        ::v-deep .el-checkbox__inner{
            width: .3rem;
            height: .3rem;
            &::after{
                left: .1rem;
                top: .02rem;
                width: .08rem;
                height: .18rem;
                border-width: .02rem;
            }
        }
        ::v-deep .el-checkbox__label{
            display: block;
            flex: auto;
            font-size: .24rem;
            line-height: .3rem;
            white-space: normal;
            overflow: hidden;
        }
    }
    .signBot{
        display: block;
        .signTel{
            min-width: 0;
            font-size: .24rem;
            margin-right: 0;
        }
        .signYzm{
            ::v-deep .el-input__inner{
                width: 100%;
                height: .7rem;
                font-size: .24rem;
                line-height: .7rem;
            }
            .yzmBtn{
                flex-shrink: 0;
                width: 1.4rem;
                height: .7rem;
                font-size: .24rem;
                line-height: .7rem;
            }
        }
    }
    .empowerBtn{
        margin-top: .5rem;
        span{
            width: 48%;
            font-size: .24rem;
            line-height: .7rem;
            margin: 0 1%;
        }
    }
}
</style>