import JSEncrypt from "jsencrypt";
import axios from "axios";

export async function getEncryCode(str){
    //实例化JSEncrypt
    let jse = new JSEncrypt();
    //加密公钥（由服务端生成）
    let pubKey = localStorage.getItem('publickey')
    if (!pubKey) {
        const res = await axios.post(process.env.VUE_APP_BASE_URL + '/teamwork/public/getparameter')
        localStorage.setItem('publickey', res.data.result)
        pubKey = res.data.result
    }
    jse.setPublicKey(pubKey);
    let  data = jse.encrypt(str.toString());  // 进行加密
    return data;
}

export function sendEncryCode(params, key){
    //实例化JSEncrypt
    let jse = new JSEncrypt();
    //使用前端私钥解密（私钥由服务端生成）
    let privKey = localStorage.getItem('randomStr')
 
    jse.setPrivateKey(privKey);

    let data = jse.decrypt(params); //解密

    return data;
}