<template>
    <div class="register">
        <Header />
        <div class="m-regform">
            <div class="wp w1200">
                <div class="regformbox">
                    <div class="regform" ref="compnayinfo">
                        <div class="tit">公司账号注册</div>
                        <el-form ref="companyForm" :model="formone" :rules="rules" class="boxform">
                            <div class="box-form-title">公司名称</div>
							<el-form-item prop="companyName">
								<el-input class="form-input left10" v-model="formone.companyName" placeholder="请输入公司名称 （注册全称），每日可校验3次"></el-input>
							</el-form-item>	
							<div class="box-form-title">验证码</div>
							<el-form-item prop="captcha">
								<div class="box-form-cat">
									
									<el-input @change="checkcaptcha" v-model="formone.captcha" class="form-input left10" placeholder="请输入验证码"></el-input>
									
									<img @click="getCaptcha" :src="captchaImg" />
								</div>
							</el-form-item>	
							<template v-if="secondcheck == 0">
								<div class="box-form-apply" @click="checkcompany" v-if="formone.companyName!='' && formone.captcha_result">校验</div>
								<div class="box-form-check" v-else>校验</div>
							</template>
							<template v-else>
								<div class="box-form-check">{{secondcheck}}S</div>
							</template>
							
							<div v-if="msgshow" style="text-align: center;color:red;margin-top: -80px;">校验失败，未找到匹配的公司，请重新输入并校验</div>
						</el-form>
						<el-form ref="registerForm" :model="form" :rules="rules" class="boxform" style="margin-top: -80px;">	
							
							
							<div class="company_info" v-show="step == 3">
								<div class="item" style="width: 100%;">
								    <div class="con">
								        <div class="label">公司名称</div>
								        <el-form-item class="libipt noborder" prop="companyName">
								            <!-- <input type="text" v-model="form.companyname" name="" placeholder="请输入公司名称" /> -->
								            <el-input
								                v-model="form.companyName"
								                type="text"
								                autocomplete="off"
								                placeholder="请输入公司名称"
								            ></el-input>
								        </el-form-item>
								    </div>
								</div>
								<div class="item">
								    <div class="con right">
								        <div class="label">法定代表人</div>
								        <el-form-item class="libipt black" prop="legalRepresentative">
											<el-input placeholder="请输入法定代表人" v-model="form.legalRepresentative"></el-input>
								        </el-form-item>
								    </div>
								</div>
								<div class="item">
								    <div class="con left">
								        <div class="label">注册资本</div>
								        <el-form-item class="libipt black" prop="registeredCapital">
											<el-input placeholder="请输入注册资本" v-model="form.registeredCapital"></el-input>
								        </el-form-item>
								    </div>
								</div>
								<div class="item">
								    <div class="con right">
								        <div class="label">成立日期</div>
										
								        <el-form-item class="libipt black between">
											<el-date-picker
											      type="date" v-model="form.establishmentDate"
												  value-format="yyyy-MM-dd"
											      placeholder="选择成立日期" prefix-icon="">
											    </el-date-picker>
											<i class="el-icon-arrow-down"/>
								        </el-form-item>
								    </div>
								</div>
								<div class="item">
								    <div class="con left">
								        <div class="label">营业期限</div>
										
								        <el-form-item class="libipt black morediv">
											<div class="between">
												<!-- <el-date-picker style="width: 100%;"
												      type="date" v-model="form.businessStartDate"
													  value-format="yyyy-MM-dd"
												      placeholder="选择开始日期">
												    </el-date-picker>
												<i class="el-icon-arrow-down"/> -->
												<el-input placeholder="请输入开始日期" v-model="form.businessStartDate"></el-input>
											</div>
											<div>至</div>
											<div class="between">
												<!-- <el-date-picker
														style="width: 100%;"
												      type="date" v-model="form.businessEndDate"
													  value-format="yyyy-MM-dd"
												      placeholder="选择结束日期">
												    </el-date-picker>
												<i class="el-icon-arrow-down"/> -->
												<el-input placeholder="请输入结束日期" v-model="form.businessEndDate"></el-input>
											</div>
								        </el-form-item>
								    </div>
								</div>
								<div class="item" style="width: 100%;">
								    <div class="con">
								        <div class="label">注册地址</div>
										
								        <el-form-item class="libipt black" prop="registeredAddress">
											<el-input placeholder="请输入注册地址" v-model="form.registeredAddress"></el-input>
								        </el-form-item>
								    </div>
								</div>
								
								<div class="item" style="width: 100%;">
								    <div class="con">
								        <div class="label">经营范围</div>
										
								        <el-form-item class="libipt noborder" prop="businessScope">
											<el-input type="textarea" resize="none" :maxlength="800" :show-word-limit="true" :rows="6" placeholder="请输入经营范围" v-model="form.businessScope"></el-input>
								        </el-form-item>
								    </div>
								</div>
								
								<div class="item" style="width: 100%;">
								    <div class="con">
								        <div class="label">网址</div>
										
								        <el-form-item class="libipt black" prop="companyUrl">
											<el-input placeholder="请输入公司网址" v-model="form.companyUrl"></el-input>
								        </el-form-item>
								    </div>
								</div>
							</div>
							
							<div class="form-info" v-if="step == 3">
								<div class="item">
								    <div class="con right">
								        <div class="label">办公地址</div>
										
								        <el-form-item class="libipt" prop="workAddress">
											<el-input placeholder="请输入办公地址" v-model="form.workAddress"></el-input>
								        </el-form-item>
								    </div>
								</div>
								<div class="item">
								    <div class="con left">
								        <div class="label">总人数</div>
										
								        <el-form-item class="libipt" prop="allPeopleNumber">
											<el-input placeholder="请输入总人数" v-model="form.allPeopleNumber"></el-input>
								        </el-form-item>
								    </div>
								</div>
								<div class="item">
								    <div class="con right">
								        <div class="label">联系人姓名</div>
										
								        <el-form-item class="libipt" prop="linkName">
											<el-input placeholder="请输入联系人姓名" v-model="form.linkName"></el-input>
								        </el-form-item>
								    </div>
								</div>
								<div class="item">
								    <div class="con left">
								        <div class="label">联系人职务</div>
										
								        <el-form-item class="libipt" prop="linkJob">
											<el-input placeholder="请输入联系人职务" v-model="form.linkJob"></el-input>
								        </el-form-item>
								    </div>
								</div>
								<div class="item">
								    <div class="con right">
								        <div class="label">联系人电话</div>
								        <el-form-item class="libipt" prop="linkPhone">
								            <el-input
								                v-model="form.linkPhone"
								                type="number"
								                placeholder="请输入联系人电话"
								            ></el-input>
								        </el-form-item>
								        <!-- </div> -->
								    </div>
								</div>
								<div class="item ico">
								    <div class="con left">
								        <div class="label">短信验证码</div>
								        <!-- <div class="libipt btn b2"> -->
								        <el-form-item class="libipt btn b2" prop="phoneCode">
								            <!-- <input type="text" v-model="form.smsCaptcha" name="" placeholder="请输入短信验证码" /> -->
								            <el-input
								                v-model="form.phoneCode"
								                type="text"
								                placeholder="请输入短信验证码"
								            ></el-input>
								            <div class="icon" v-if="second == 0" @click="sendSms">发送验证码</div>
								            <div class="icon-dis" v-else>{{ second }}秒后重新发送</div>
								        </el-form-item>
								        <!-- </div> -->
								    </div>
								</div>
								<div class="item">
								    <div class="con right">
								        <div class="label">联系人邮箱</div>
								        <el-form-item class="libipt" prop="linkEmail">
								            <el-input
								                v-model="form.linkEmail"
								                type="text"
								                placeholder="请输入联系人邮箱"
								            ></el-input>
								        </el-form-item>
								        <!-- </div> -->
								    </div>
								</div>
								<div class="item ico">
								    <div class="con left">
								        <div class="label">邮箱验证码</div>
								        <!-- <div class="libipt btn b2"> -->
								        <el-form-item class="libipt btn b2" prop="emailCode">
								            <el-input
								                v-model="form.emailCode"
								                type="text"
								                placeholder="请输入邮箱验证码"
								            ></el-input>
								            <div class="icon" v-if="secondemail == 0" @click="sendemailSms">发送验证码</div>
								            <div class="icon-dis" v-else>{{ secondemail }}秒后重新发送</div>
								        </el-form-item>
								        <!-- </div> -->
								    </div>
								</div>
								<div class="item">
									<el-upload
										action=""
										:show-file-list="false"
                                        :on-success="handleSuccess"
                                		:http-request="uploadPic"
                                        :before-upload="beforeUpload"
									>
										<div class="upload-item">
											
											<template v-if="form.licenseFile">
												<img class="no-upload" v-if="type=='application/pdf'" src="@/assets/images/file_pdf.png">
												<img class="no-upload" v-else :src="form.licenseFile">
											</template>
											
											<div v-else class="no-upload">
												<i class="el-icon-plus"></i>
											</div>
											<div class="upload-desc">
												<div>上传营业执照*</div>
												<div>支持jpg png pdf格式</div>
											</div>
										</div>
									</el-upload>
								</div>
							</div>
							
							<div class="check" v-if="step == 3">
							    <input type="checkbox" id="ckbox_1" v-model="agree" name />
							    <label for="ckbox_1" class="ckb">
							        我已知晓并同意
							        <router-link to="/protocol?type=4">商汤合作伙伴网站用户协议</router-link>和
							        <router-link to="/protocol?type=2">隐私条款</router-link>
							    </label>
							</div>
							<div class="sbmbtns" v-if="step == 3">
							    <button class="btn b1" @click.prevent="submit">注册</button>
							    <div class="btn b2">
							        <router-link to="/login">已有账号，去登录</router-link>
							    </div>
							</div>
							
							<!-- <div class="box-form-apply">校验</div> -->
                        </el-form>
                    </div>
                    <div class="bottom">
                        <a href>沪 ICP 备 19044592 号-3</a> |
                        <a href>沪公网安备 31010402009327号</a> |
                        <a href>© 2014-2021 SenseTime. All Rights Reserved. 上海商汤智能科技有限公司</a>
                    </div>
                </div>
            </div>
        </div>
		<div class="m-popup-m1-register" v-show="showlogin">
			<div class="popup" style="width: 480px;height:300px;">
				<div class="tit">
					注册成功！
				</div>
				<div class="company-content">
					账号信息已发送至联系人邮箱，请注意查收
				</div>
				<router-link to="/login?type=1" class="company-button">登录</router-link>
			</div>
		</div>
    </div>
</template>

<script>

import Header from '@/components/Header.vue';
import Api from '@/request/api.js'
import moment from "moment"
export default {
    components: { Header },
    data() {
        // 手机号验证
        const validatePhone = (rule, value, callback) => {
            if (!/^1[3456789]\d{9}$/.test(value)) {
                callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        }
        const validateEmail = (rule, value, callback) => {
            if (!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/.test(value)) {
                callback(new Error("请输入正确的邮箱"));
            } else {
                callback();
            }
        }
        const validatePassword = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请填写密码'))
            }
            if (!/(?![\d]+$)(?![a-zA-Z]+$)/.test(value)) {
                callback(new Error("至少一个字母和一个数字"));
            } else {
                if (!/^(?![\d]+$)(?![a-zA-Z]+$)[\da-zA-Z-=+_.,]{8,}$/.test(value)) {
                    callback(new Error("至少八个字符，必须包含字母和数字，且不能有特殊字符"));
                } else {
                    callback();
                }
            }
        }
        return {
			//第一步的表单信息
			formone:{
				companyName:"",
				captcha:"",
				checkKey:"",
				captcha_result:false
			},
            form: {
				companyName:"",//公司名称
                legalRepresentative: "",//法定代表人
                registeredCapital: "",//注册资本
                establishmentDate: "",//成立日期
                businessStartDate: "",//营业期限 -开始时间
                businessEndDate: "",//营业期限 -结束时间
                registeredAddress: "",//注册地址
                businessScope: "",//经营范围
                companyUrl: "",//公司网址
                workAddress: "",//办公地址
                allPeopleNumber: "",//总人数
                linkName: "",//联系人姓名
                linkJob: "",//联系人职务
                linkPhone: "",//联系人电话
                phoneCode: "",//短信验证码
                linkEmail: "",//联系人邮箱
                emailCode: "",//邮箱验证码
                licenseFile: "",//营业执照（图片或pdf文件）
                companyCode: "",//公司编码
                password: ""//密码
            },
            agree: false,
            captchaImg: null,
            second: 0,
            timer: null,
			emailtimer:null,
			secondemail:0,
			secondcheck:0,
			checktimer:null,
            rules: {
                companyName: [{ required: true, message: '请填写公司名称', trigger: 'blur' }],
                legalRepresentative: [{ required: true, message: '请填写法定代表人', trigger: 'blur' }],
                registeredCapital: [{ required: true, message: '请填写注册资本', trigger: 'blur' }],
                establishmentDate: [{ required: true, message: '请选择成立日期', trigger: 'blur' }],
                businessStartDate: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
                businessEndDate: [{ required: true, message: '请选择结束时间', trigger: 'blur' }],
                registeredAddress: [{ required: true, message: '请填写注册地址', trigger: 'blur' }],
                businessScope: [{ required: true, message: '请填写经营范围', trigger: 'blur' },
				 { min: 1, max: 800, message: "经营范围最多输入300个字", trigger: "blur" }
				],
                companyUrl: [{ required: true, message: '请填写公司网址', trigger: 'blur' }],
                workAddress: [{ required: true, message: '请填写办公地址', trigger: 'blur' }],
                allPeopleNumber: [{ required: true, message: '请填写总人数', trigger: 'blur' }],
                linkName: [{ required: true, message: '请填写联系人姓名', trigger: 'blur' }],
                linkJob: [{ required: true, message: '请填写联系人职务', trigger: 'blur' }],
                licenseFile: [{ required: true, message: '请上传营业执照', trigger: 'blur' }],
                companyCode: [{ required: true, message: '请填写公司编码', trigger: 'blur' }],
                linkEmail: [
                    { required: true, message: '请填写联系人邮箱', trigger: 'blur', },
                    { validator: validateEmail, trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请填写密码', trigger: 'change' },
                    { validator: validatePassword, trigger: 'blur' }
                ],
                linkPhone: [
                    { required: true, message: '请填写联系人电话', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' }
                ],
                phoneCode: [
                    { required: true, message: '请填写短信验证码', trigger: 'blur' }
                ],
                emailCode: [
                    { required: true, message: '请填写邮箱验证码', trigger: 'blur' }
                ],
                captcha: [
                    { required: true, message: '请填写验证码', trigger: 'blur' }
                ],
            },
			step:1,
			type:"",
			msgshow:false,
			showlogin:false
        }
    },
    mounted() {
		this.step =1;
        // 获取验证码
        this.getCaptcha()
    },
    methods: {
		//验证验证码是否正确
		checkcaptcha(){
			if(this.formone.captcha == ""){
				return false;
			}
			Api.checkImageCode(this.formone).then(res => {
			    this.formone.captcha_result = true;
			})
		},
		//验证公司名称
		checkcompany(){
			this.step = 2;
			this.$refs['companyForm'].validate(async (valid) => {
			    if (valid) {
					Api.getCompanyInfo(this.formone).then(res => {
						this.secondcheck = 30
						if(this.checktimer!= null){
							clearInterval(this.checktimer);
						}
						this.checktimer = setInterval(() => {
						    this.secondcheck--;
						    if (this.secondcheck == 0) {
						        clearInterval(this.checktimer)
						    }
						}, 1000)
						
						this.getCaptcha();
						if(res != null){
							this.msgshow = false;
							this.step = 3;
							this.form.companyName = res.name;
							this.form.legalRepresentative = res.legalPersonName;
							this.form.registeredCapital = res.regCapital;
							this.form.establishmentDate = moment(res.estiblishTime).format('YYYY-MM-DD');
							this.form.businessStartDate = moment(res.fromTime).format('YYYY-MM-DD');
							if(res.toTime == "无固定期限"){
								this.form.businessEndDate = "无固定期限";
							}else{
								this.form.businessEndDate = res.toTime?moment(res.toTime).format('YYYY-MM-DD'):"";
							}
							
							this.form.registeredAddress = res.regLocation;
							this.form.businessScope = res.businessScope;
							this.form.companyUrl = "";
							this.form.manageStatus = "";
							this.form.risk = "";
							this.form.workAddress = "";
							
							this.$nextTick(() => {
							     this.$refs.compnayinfo.scrollTo(0,350);
							})
							
						}else{
							this.formone.companyName = "";
							this.msgshow = true;
						}
					})
			    }
			})
		},
        // 随机生成字符串
        randomString(e) {
            e = e || 32;
            var t = "1234567890",
                a = t.length,
                n = "";
            for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
            return n
        },
        // 获取验证码
        getCaptcha() {
            const key = this.randomString(6)
            // console.log(key)
            this.formone.checkKey = key
            Api.getCaptcha({ key }).then(res => {
                this.captchaImg = res;
				this.formone.captcha_result = false;
            })
        },
        // 发送短信验证码
        sendSms() {
            if (!this.form.linkPhone) {
                return this.$message.error('请先输入手机号')
            }
            if (!/^1[3456789]\d{9}$/.test(this.form.linkPhone)) {
                return this.$message.error('请输入正确的手机号')
            }
            Api.sendSms({ mobile: this.form.linkPhone, smsmode: 1, userType:2 }).then(res => {

                this.second = 60
				if(this.timer!= null){
					clearInterval(this.timer);
				}
                this.timer = setInterval(() => {
                    this.second--
                    if (this.second == 0) {
                        clearInterval(this.timer)
                    }
                }, 1000)
                this.$message.success('短信发送成功');
            })
        },
		//发送邮箱验证码
		sendemailSms() {
            if (!this.form.linkEmail) {
                return this.$message.error('请先输入联系人邮箱')
            }
            if (!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/.test(this.form.linkEmail)) {
                return this.$message.error('请输入正确的邮箱')
            }
			if(this.secondemail != 0){
				return false;
			}
            Api.sendEmail({ email: this.form.linkEmail}).then(res => {
                this.secondemail = 60
				if(this.emailtimer!= null){
					clearInterval(this.emailtimer);
				}
                this.emailtimer = setInterval(() => {
                    this.secondemail--
                    if (this.secondemail == 0) {
                        clearInterval(this.emailtimer);
                    }
                }, 1000)
                this.$message.success('邮件发送成功');
            })
        },
        submit() {
            this.$refs['registerForm'].validate(async (valid) => {
                if (valid) {
					if(!this.form.licenseFile){
						return this.$message.error('请上传营业执照')
					}
                    if (!this.agree) {
                        return this.$message.info('请勾选协议')
                    }
                    await Api.addCompanyUser(this.form)
					this.showlogin = true;
                    // this.$message.success('注册成功')
                    // this.$router.replace({ path: '/login' })
                }else{
					this.$message.error('信息填写不完整');
				}
            })
        },
		uploadPic({ file }) {
			return Api.uploadnologin(file)
		},
		handleSuccess(res) {
			if (res.data.code == 0) {
				this.form.licenseFile = res.data.message
			}
		},
		beforeUpload(file) {
			const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf');
			this.type = file.type;
			if (!isJPG) {
				this.$message.error('上传文件只能是 JPG、png、dpf 格式!');
			}
			return isJPG;
		},
    }
}
</script>


<style src="@/style/company-register.css"  scoped></style>