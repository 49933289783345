<template>
    <div class="mypost">
        <Header />
        <div class="main" id="app" style="background-color: #f5f5f7;">
            <MyBanners :navIndex="1"></MyBanners>
            <div class="wp w1200">
                <ul v-if="user.userType == 1" class="ul-mypost">
                    <li v-for="(item, index) in list" :key="index">
                        <div class="con">
                            <div class="tits">
                                <div class="tit">{{ item.title }}</div>
                               <router-link
                                    :to="'/forumdetail?id=' + item.id+'&pid='+item.pid"
                                    class="g-dingyuebtn btn"
                                >查看详情</router-link>
                            </div>
                            <div class="tabs" v-if="item.typeName!=null">
                                <router-link
                                    :to="'/forumlist?pid=' + item.pid"
                                    class="tab"
                                >{{ item.typeName }}</router-link>
                            </div>
                            <div class="desc" v-html="item.postsContent"></div>
                            <div class="dtinfo">
                                <div class="date">发帖时间：{{ item.createTime }}</div>
                                <div class="btns">
                                    <div class="num">
                                        <router-link :to="'/mypostpage?pid='+item.posType+'&id='+item.id">编辑</router-link>
                                    </div>
                                    <div class="num num1">
                                        <i class="n n1"></i>
                                        {{ item.readNum }}
                                    </div>
                                    <div class="num num2">
                                        <i class="n n2"></i>
                                        {{ item.likeNum }}
                                    </div>
                                    <div class="num num3">
                                        <i class="n n3"></i>
                                        {{ item.revertNum }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
				<ul v-if="user.userType == 2" class="renzheng">
					<li class="renzheng-item" v-for="item in list" :key="item.id">
						<div class="renzheng-item-title">
							<div>{{item.planName}}</div>
							<div class="action">
								<div v-if="item.status == 2 && item.auditNum == 0" @click="zhaohui(item)">召回</div>
								<div v-if="item.status == -3 || item.status  == -1" @click="quxiao(item)">取消签约</div>
								<div v-if="item.status == -3 || item.status  == -1 || item.status  == 1" @click="update(item)">去修改</div>
								<div v-else @click="toinfo(item)">查看详情</div>
							</div>
						</div>
						<div class="image-list">
							<div class="img">
								<img v-if="item.status >= 2" src="@/assets/images/renzheng/s.png"/>
								<img v-else src="@/assets/images/renzheng/1.png"/>
								<div>合作协议签约</div>
							</div>
							<img class="icon" src="@/assets/images/renzheng/icon.png"/>
							<div class="img">
								<img v-if="item.auditNum > 0" src="@/assets/images/renzheng/s.png"/>
								<!-- <img v-else-if="item.status == -1" src="@/assets/images/renzheng/e.png"/> -->
								<img v-else src="@/assets/images/renzheng/2.png"/>
								<div>审批中</div>
							</div>
							<img class="icon" src="@/assets/images/renzheng/icon.png"/>
							<div class="img">
								<img v-if="item.status >= 3" src="@/assets/images/renzheng/s.png"/>
								<img v-else src="@/assets/images/renzheng/3.png"/>
								<div>签约完成</div>
							</div>
							<img class="icon" src="@/assets/images/renzheng/icon.png"/>
							<div class="img">
								<img v-if="item.status >= 4" src="@/assets/images/renzheng/s.png"/>
								<img v-else src="@/assets/images/renzheng/4.png"/>
								<div>制作证书</div>
							</div>
							<img class="icon" src="@/assets/images/renzheng/icon.png"/>
							<div class="img">
								<img v-if="item.status == 6" src="@/assets/images/renzheng/s.png"/>
								<img v-else src="@/assets/images/renzheng/5.png"/>
								<div>结束</div>
							</div>
						</div>
					</li>
				</ul>
                <!-- 内容为空 -->
                <ContentNull style="display: none;" />
				<div class="pagination-box style2" v-if="list.length > 0" v-loading.fullscreen.lock="false">
                    <el-pagination
                        :total="total"
                        layout="prev, pager, next"
                        :page-size="params.pageSize"
                        @current-change="handleCurrentChange"
                    ></el-pagination>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import MyBanners from '@/components/MyBanners.vue';
import Footer from '@/components/Footer.vue';
import ContentNull from '@/components/ContentNull.vue';
import Api from '@/request/api';
import { mapState } from "vuex";
export default {
    components: {
        Header,
        MyBanners,
        Footer,
		ContentNull
    },
    data() {
        return {
            params: {
                belong: 3,
                format: '',
                order: '',
                // order: '阅读量',
                sort: 0,
                title: '',
                pageNo: 1,
                pageSize: 10,
                pid: 0,
                type: 0
            },
            list: [],
            total: 0
        }
    },
    mounted() {
        this.getList()
    },
	computed: {
		...mapState([
			'user'
		])
	},
    watch: {
        $route(v) {
            if (v.path == '/mypost') {
                this.getUserInfo()
                this.getList()
            }
        }
    },
    methods: {
        getList() {
			if(this.user.userType == 1){
				Api.getMyPostsList().then(res => {
				    const imgReg = new RegExp('<img.*?(?:>|\/>)', 'gi')
					const videoReg = new RegExp('<iframe.*?(?:>|\/>)', 'gi')
					const reg = new RegExp('<p>' + '(.*?)' + '</p>')
				    for (let i = 0; i < res.records.length; i++) {
				        const str = res.records[i].postsContent
				        if (str.match(reg)) {
				            res.records[i].postsContent = str.match(reg)[1]
				        }
				        if (imgReg.test(str)) {
				            res.records[i].postsContent = str.replace(imgReg, '')
				        }
				        if (videoReg.test(str)) {
				            res.records[i].postsContent = str.replace(videoReg, '')
				        }
				    }
				    this.list = res.records
				    this.total = res.total
				})
			}else{
				Api.getCompanyAuthRateList().then(res => {
				    this.list = res.records
				    this.total = res.total
				})
			}
            
        },
        handleCurrentChange(v) {
            this.params.pageNo = v
            this.getList()
        },
        // 获取个人信息
        getUserInfo() {
            Api.getUserInfo().then(res => {
				console.log(res);
                this.$store.dispatch('setUserInfo', res)
            })
        },
        // 加载分页评论
        loadMoreComment(id, current, index) {
            const params = {
                id: id,
                order: '',
                pageNo: current + 1,
                pageSize: 10,
                sort: 0
            }
            Api.moreComment(params).then(res => {
                this.list[index].revertRecordVoList.current += 1
                this.list[index].revertRecordVoList.records = this.list[index].revertRecordVoList.records.concat(res.records)
            })
        },
		//召回
		zhaohui(item){
			Api.cancelAuth({status:-3,companyAuthId:item.id}).then(res=>{
				this.getList();
				this.$message.success("召回成功");
			})
		},
		//取消授权
		quxiao(item){
			Api.cancelAuth({status:-2,companyAuthId:item.id}).then(res=>{
				this.getList();
				this.$message.success("取消授权成功");
			})
		},
		update(item){
			this.$router.push("/partnersign?id="+item.planId);
		},
		toinfo(item){
			this.$router.push("/signinfo?id="+item.id);
		}
        // 点赞
		// like(id, like, index) {
  //           console.log(1)
		// 	if (like) {
		// 		Api.forumLike({ postsId: this.info.id,type:type }).then(res => {
		// 			this.getDetail()
		// 		})
		// 		Api.forumLikeCancel({ id: id }).then(res => {
		// 			console.log(res)
		// 			this.list[index].forumPosts.like = !like
		// 			this.list[index].forumPosts.likeNum -= 1
		// 		})
		// 	} else {
		// 		Api.forumLike({ id: id }).then(res => {
		// 			console.log(res)
		// 			this.list[index].forumPosts.like = !like
		// 			this.list[index].forumPosts.likeNum += 1
		// 		})
		// 	}
		// },
    }

}
</script>
<style scoped lang="scss">
a:hover {
    color: #e73522;
}
body {
    font-family: PingFang SC;
}
.main {
    padding: 80px 0 20px;
}
.g-ban {
    margin-bottom: 30px;
}

.ul-mypost {
    padding-top: 20px;
    margin-bottom: 50px;
}
.ul-mypost li {
    margin-bottom: 10px;
}

.ul-mypost .con {
    padding: 20px 30px 10px;
    background-color: #fff;
}
.ul-mypost .tits {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.ul-mypost .tits .tit {
    font-size: 16px;
}
.ul-mypost .tits .btn {
    width: 100px;
    height: 34px;
    line-height: 34px;
    font-size: 14px;
    border-radius: 0;
}
.ul-mypost .tits .btn:hover {
    color: #fff;
}
.ul-mypost .tits .btn::before {
    display: none;
}

.ul-mypost .tabs {
    background-color: rgba(231, 53, 34, 0.2);
    border: 1px solid #e73522;
    border-radius: 13px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    color: #e83421;
    padding: 0 15px;
    display: inline-block;
    margin-bottom: 5px;
}
.ul-mypost .tabs .tab {
    display: inline;
    margin-right: 24px;
    position: relative;
    cursor: pointer;
    color: #e83421;
}
.ul-mypost .tabs .tab::after {
    content: "";
    background: url(../assets/images/mypost/icon1.png) no-repeat center;
    width: 12px;
    height: 12px;
    background-size: 100%;
    position: absolute;
    right: -5px;
    top: 4px;
    transform: translateX(100%);
}
.ul-mypost .tabs .tab:last-of-type {
    margin-right: 0;
}
.ul-mypost .tabs .tab:last-of-type::after {
    display: none;
}

.ul-mypost .dtinfo,
.ul-mypost .dtinfo a,
.ul-mypost .desc {
    font-size: 14px;
    color: #707070;
}
.ul-mypost .desc {
    margin-bottom: 20px;
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}
.ul-mypost .dtinfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
	align-items: center;
}
.ul-mypost .dtinfo .date {
}
.ul-mypost .dtinfo .btns .num {
    padding: 0 20px;
    height: 45px;
    display: inline-block;
    color: #2e2e2e;
    line-height: 45px;
    cursor: pointer;
}
.ul-mypost .dtinfo .btns .num.num2{
	min-width: 90px;
}

.ul-mypost .dtinfo .btns .n.n2 {
    background-image: url(../assets/images/detail/icon9.png);
}
.ul-mypost .dtinfo .btns .n.n3 {
    background-image: url(../assets/images/detail/icon10.png);
	transform: translateY(4px);
}
.ul-mypost .dtinfo .btns .n {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 45px;
    background: url(../assets/images/detail/icon8.png) no-repeat center;
    background-size: 16px;
    position: relative;
    transform: translateY(3px);
    margin-right: 5px;
}
.ul-mypost .dtinfo .btns .n.on::after {
    content: "";
    width: 8px;
    height: 8px;
    background: linear-gradient(-70deg, #e83421, #f67062);
    border-radius: 50%;
    position: absolute;
    top: -2px;
    right: -2px;
}

.ul-mypost .dtinfo .btns .num3.on {
    background-color: #f5f5f7;
    border-radius: 5px 5px 0 0;
}

.ul-mypost .rspdetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ul-rsplist li {
    margin-bottom: 10px;
}
.ul-rsplist .li:last-of-type {
    margin-bottom: 0;
}
.ul-rsplist .con {
    padding: 0;
}
.ul-rsplist .resp {
    padding: 25px 25px 21px 20px;
    background-color: #f5f5f7;
    font-size: 14px;
    line-height: 2;
}
.ul-rsplist .resp .rsptxt {
    line-height: 2;
    margin-bottom: 10px;
}
.ul-rsplist .resp .rsppic {
    width: 24px;
    height: 24px;
    float: left;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 17px;
}
.ul-rsplist .resp .rspt {
    overflow: hidden;
    cursor: pointer;
}
.ul-rsplist .resp .rspname {
    margin-right: 37px;
    float: left;
}
.ul-rsplist .resp .rspdate {
    float: left;
}
.ul-rsplist .resp .rspdate span {
    margin-right: 10px;
    color: #009990;
}

.ul-rsplist .icon:hover .back {
    color: #e73522;
}
.ul-rsplist .back {
    display: inline-block;
    padding-left: 25px;
    background: url(../assets/images/detail/icon5.png) no-repeat left 7px;
    background-size: 16px;
    cursor: pointer;
    transition: 0.5s;
}

.pagination-box {
    text-align: center;
    margin-bottom: 37px;
    margin-top: 13px;
}
@media only screen and (max-width: 750px) {
    .main{
        padding: 1.4rem 0 .4rem;
    }
    .ul-mypost {
        padding-top: .2rem;
        margin-bottom: .5rem;
    }
    .ul-mypost li {
        margin-bottom: .1rem;
    }
    .ul-mypost .con {
        padding: .3rem;
    }
    .ul-mypost .tits {
        margin-bottom: .1rem;
    }
    .ul-mypost .tits .tit {
        font-size: .32rem;
    }
    .ul-mypost .tits .btn {        
        width: 1.2rem;
        height: .42rem;
        font-size: .24rem;
        line-height: .42rem;
    }
    .ul-mypost .tabs {
        border-radius: .4rem;
        height: .4rem;
        line-height: .4rem;
        font-size: .2rem;
        padding: 0 .2rem;
        margin-bottom: .1rem;
    }
    .ul-mypost .tabs .tab {
        margin-right: .2rem;
    }
    .ul-mypost .tabs .tab::after {
        width: .16rem;
        height: .16rem;
        top: .06rem;
    }

    .ul-mypost .dtinfo,
    .ul-mypost .dtinfo a,
    .ul-mypost .desc {
        font-size: .28rem;
        color: #707070;
    }
    .ul-mypost .desc {
        margin-bottom: .2rem;
    }
    .ul-mypost .dtinfo {
        display: block;
        font-size: .24rem;
    }
    .ul-mypost .dtinfo .btns{
        margin: 0 -.2rem;
        margin-top: .1rem;
    }
    .ul-mypost .dtinfo .btns .num {
        padding: 0 .2rem;
        height: auto;
        line-height: 1.8;
    }
    .ul-mypost .dtinfo .btns .num a{
        font-size: .24rem;
    }
    .ul-mypost .dtinfo .btns .num.num1,
    .ul-mypost .dtinfo .btns .num.num2,
    .ul-mypost .dtinfo .btns .num.num3{
        min-width: 1.4rem;
    }
    .ul-mypost .dtinfo .btns .n {
        width: .3rem;
        height: .3rem;
        line-height: normal;
        background-size: 100%;
        transform: translateY(.06rem);
        margin-right: .1rem;
    }
    .ul-mypost .dtinfo .btns .n.n3{
        transform: translateY(.08rem);
    }  
}
  
.renzheng-item{
	width: 100%;
	background: #FFFFFF;
	margin-top: 20px;
	.renzheng-item-title{
		border-bottom: 1px solid #DDDDDD;
		font-size: 18px;
		font-weight: 600;
		color: #111111;
		padding-left: 30px;
		box-sizing: border-box;
		display: flex;
		height: 60px;
		justify-content: space-between;
		align-items: center;
		.action{
			display: flex;
			justify-content: flex-end;
			align-items: center;
			div{
				width: 120px;
				height: 36px;
				border: 1px solid #DDDDDD;
				font-size: 14px;
				font-weight: 400;
				color: #E83421;
				line-height: 36px;
				text-align: center;
				margin: 0px 10px;
				cursor: pointer;
			}
		}
	}
	.image-list{
		display: flex;
		justify-content: space-around;
		padding: 37px 0px 37px;
	}
	.img{
		text-align: center;
		div{
			margin-top: 32px;
			font-size: 16px;
			font-weight: 400;
			color: #111111;
			line-height: 24px;
		}
		img{
			width: 70px;
			height: 70px;
		}
	}
	.icon{
		width: 72px;
		height: 30px;
		margin-top: 20px;
	}
}

</style>