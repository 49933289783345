<template>
    <div class="channel-information" style="background-color: #F5F5F7;">
        <Header />
		<div class="ban">
			<el-carousel>
			  <el-carousel-item v-for="item in banner" :key="item.id">
				<img @click="tourl(item)" :src="item.image" />
			  </el-carousel-item>
			</el-carousel>
			
		</div>
        <div class="work-container wp w1200">
            <div class="slide-menu-area coustom-scroll channel-menu">
				<h1 class="main-tit">产品资料</h1>
                <el-tree
                    ref="proTree"
                    :data="productTree"
                    :default-expand-all="false"
                    :props="treeProps"
                    node-key="id"
                    :current-node-key="params.pid"
                    :highlight-current="true"
                    :expand-on-click-node="false"
                    :check-on-click-node="true"
                    @node-click="nodeClick"
                ></el-tree>
            </div>
            <div class="work-content-box">
                <div class="top">
                    <h1 class="main-tit">
						全部产品资料
						<div v-if="status == 1 && params.pid > 0" @click="dingyue(1)">取消订阅</div>
						<div v-else-if="params.pid > 0" @click="dingyue(0)">+ 订阅</div>
					</h1>
                    <div class="filter">
                        <span class="filter-label">类型：</span>
                        <ul>
                            <li
                                :class="params.infotypeName == '' ? 'active' : ''"
                                @click="params.infotypeName = ''; params.pageNo = 1; list = [];params.typeIdList = [];; getList();getdingyuestatus();"
                            >全部</li>
                            <li
                                :class="params.infotypeName == item.name ? 'active' : ''"
                                v-for="(item, index) in showTypeList"
                                :key="index"
                                @click="changetype(item)"
                            >{{ item.name }}</li>
                        </ul>
                        <span
                            style="color: #e83421;cursor: pointer;white-space: nowrap;margin-left: auto;height: 30px;"
                            v-show="!showMore && lengthMore"
                            @click="showMore = !showMore"
                        >查看更多</span>
                        <span
                            style="color: #e83421;cursor: pointer;white-space: nowrap;margin-left: auto;height: 30px;"
                            v-show="showMore && lengthMore"
                            @click="showMore = !showMore"
                        >收起</span>
                    </div>
                    <div class="filter">
                        <span class="filter-label">格式：</span>
                        <ul>
                            <li
                                :class="params.format == '' ? 'active' : ''"
                                @click="params.format = ''; params.pageNo = 1; list = []; getList();"
                            >全部</li>
                            <li
                                v-for="(item, index) in formats"
                                :key="index"
                                :class="params.format == item ? 'active' : ''"
                                @click="params.format = item; params.pageNo = 1; list = []; getList();"
                            >{{ item }}</li>
                        </ul>
                    </div>
                </div>
                <div class="bot">
                    <div class="filter-sort">
                        <div class="types">
							
							<span>发布时间</span>
							<img v-if="timeorder == 2" @click="timeorder = 1;params.order='发布时间';params.sort = 1; params.pageNo = 1; list = []; getList();" style="width:20px;height:20px;" src="@/assets/images/x2.png"/>
							<img v-if="timeorder == 1" @click="timeorder = 2;params.order='发布时间';params.sort = 0; params.pageNo = 1; list = []; getList();" style="width:20px;height:20px;" src="@/assets/images/s2.png"/>
							<!-- <img style="width:20px;height:20px;" src="@/assets/images/s1.png"/> -->
                            <!-- <el-dropdown placement="bottom-start">
                                <span class="el-dropdown-link sort-label">
                                    {{ timeorder == 1 ? '由早到晚' : '由晚到早' }}
                                    <i
                                        class="el-icon-arrow-down el-icon--right"
                                    ></i>
                                </span>
								
                                <el-dropdown-menu slot="dropdown" class="sort-menu">
                                    <el-dropdown-item
                                        @click.native="timeorder = 1;params.order='发布时间';params.sort = 1; params.pageNo = 1; list = []; getList();"
                                    >由早到晚</el-dropdown-item>
                                    <el-dropdown-item
                                        @click.native="timeorder = 2;params.order='发布时间';params.sort = 0; params.pageNo = 1; list = []; getList();"
                                    >由晚到早</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown> -->
                            <span>下载量</span>
							<img v-if="downorder == 2" @click="downorder = 1;params.order='下载量';params.sort = 1; params.pageNo = 1; list = []; getList();" style="width:20px;height:20px;" src="@/assets/images/x2.png"/>
							<img v-if="downorder == 1" @click="downorder = 2;params.order='下载量';params.sort = 0; params.pageNo = 1; list = []; getList();" style="width:20px;height:20px;" src="@/assets/images/s2.png"/>
							
							
							<!-- <el-dropdown placement="bottom-start">
							    <span class="el-dropdown-link sort-label">
							        {{ downorder == 1 ? '由低到高' : '由高到低' }}
							        <i
							            class="el-icon-arrow-down el-icon--right"
							        ></i>
							    </span>
							    <el-dropdown-menu slot="dropdown" class="sort-menu">
							        <el-dropdown-item
							            @click.native="downorder = 1;params.order='下载量';params.sort = 1; params.pageNo = 1; list = []; getList();"
							        >由低到高</el-dropdown-item>
							        <el-dropdown-item
							            @click.native="downorder = 2;params.order='下载量';params.sort = 0; params.pageNo = 1; list = []; getList();"
							        >由高到低</el-dropdown-item>
							    </el-dropdown-menu>
							</el-dropdown> -->
                            <span>阅读量</span>
							<img v-if="readorder == 2" @click="readorder = 1;params.order='阅读量';params.sort = 1; params.pageNo = 1; list = []; getList();" style="width:20px;height:20px;" src="@/assets/images/x2.png"/>
							<img v-if="readorder == 1" @click="readorder = 2;params.order='阅读量';params.sort = 0; params.pageNo = 1; list = []; getList();" style="width:20px;height:20px;" src="@/assets/images/s2.png"/>
							
							
							<!-- <el-dropdown placement="bottom-start">
							    <span class="el-dropdown-link sort-label">
							        {{ readorder == 1 ? '由低到高' : '由高到低' }}
							        <i
							            class="el-icon-arrow-down el-icon--right"
							        ></i>
							    </span>
							    <el-dropdown-menu slot="dropdown" class="sort-menu">
							        <el-dropdown-item
							            @click.native="readorder = 1;params.order='阅读量';params.sort = 1; params.pageNo = 1; list = []; getList();"
							        >由低到高</el-dropdown-item>
							        <el-dropdown-item
							            @click.native="readorder = 2;params.order='阅读量';params.sort = 0; params.pageNo = 1; list = []; getList();"
							        >由高到低</el-dropdown-item>
							    </el-dropdown-menu>
							</el-dropdown> -->
                            <div class="search-box">
                                <input
                                    type="text"
                                    v-model="params.title"
                                    placeholder="请输入搜索关键词"
                                    @keyup.enter="params.pageNo = 1; list = [];getList"
                                />
                                <i
                                    class="search-btn"
                                    @click="params.pageNo = 1; list = []; getList()"
                                ></i>
                            </div>
                        </div>
                        <div class="count">{{ total }}个</div>
                    </div>

                    <ul class="list-box" v-loading="loading">
                        <li
                            :class="getFormatType(item.infoFormat)"
                            v-for="(item, index) in list"
                            :key="index"
                        >
                            <div class="tit">
                                <div class="val" v-if="item.infoFormat == 'pdf'" @click="priview(item)">{{ item.title }}</div>
                                <div class="val" v-else-if="(item.infoFormat != 'pdf' && item.infoContent != '') || item.infoFormat == '视频'"
                                         @click="toDetail(item)">{{ item.title }}</div>
                                <div class="val" v-else >{{ item.title }}</div>
                            </div>
                            <div class="tim-size">
                                <span class="val">{{ item.createTime }} I {{ item.infoSize }}</span>
                                <div class="op">
									<span
									    class="icon-preview"
									    v-if="item.infoFormat == 'pdf'"
									    @click="priview(item)"
										style="margin-right: 10px;"
									>预览
										<span v-if="item.readNum > 1000">{{(item.readNum/1000).toFixed(1)}}K</span>
										<span v-else>{{item.readNum}}</span></span>
									<span
									    class="icon-preview"
									    v-if="(item.infoFormat != 'pdf' && item.infoContent != '') || item.infoFormat == '视频'"
									    @click="toDetail(item)"
										style="margin-right: 10px;"
									>查看
										<span v-if="item.readNum > 1000">{{(item.readNum/1000).toFixed(1)}}K</span>
										<span v-else>{{item.readNum}}</span>
									</span>
									<span
									    class="icon-download"
									    @click="showDownload(item)"
									    v-if="(item.infoFormat != '视频' && item.infoFormat != '链接')"
										style="margin-right: 10px;"
									>下载
									
										<span v-if="item.downloadNum > 1000">{{(item.downloadNum/1000).toFixed(1)}}K</span>
										<span v-else>{{item.downloadNum}}</span>
									</span>
									
                                    <span class="icon-share" @click="share(item)">分享
																			<span v-if="item.shareNum > 1000">{{(item.shareNum/1000).toFixed(1)}}K</span>
																			<span v-else>{{item.shareNum}}</span>
																		</span>
									<!-- 									
                                    <span v-if="(item.infoFormat != 'pdf' && item.infoContent != '') || item.infoFormat == '视频'" class="icon-share" @click="share(item)">分享
																			<span v-if="item.shareNum > 1000">{{(item.shareNum/1000).toFixed(1)}}K</span>
																			<span v-else>{{item.shareNum}}</span>
																		</span> -->
                                    <span @click="collection(item.id, index, item.collect)" :class="item.collect ? 'icon-faved' : 'icon-fav'">收藏
										<span v-if="item.collectNum > 1000">{{(item.collectNum/1000).toFixed(1)}}K</span>
										<span v-else>{{item.collectNum}}</span>
									</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- 内容为空 -->
                    <ContentNull v-if="total <= 0" />
                </div>
                <div class="pagination-box" v-if="list.length > 0">
                    <el-pagination
                        :total="total"
                        :page-size="params.pageSize"
                        layout="prev, pager, next"
                        @current-change="handleCurrentChange"
                    ></el-pagination>
                </div>
            </div>
        </div>
        <Download :info="downInfo" ref="downloadRef" @confirm="download" />
        <Footer />
    </div>
</template>

<script>

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Download from '@/components/Download.vue';
import ContentNull from '@/components/ContentNull.vue';
import { mapState } from 'vuex';
import Api from '@/request/api';
import download from "downloadjs";
export default {
    name: 'GeneralProduct',
    components: { Header, Download, ContentNull, Footer },
    data() {
        return {
            showMore: false,
            sortData: {
                1: '正序',
                0: '倒序'
            },
            // treedata: [],
            loading: false,
            params: {
                belong: 2,
                format: '',
                order: '',
                sort: 0,
                type: '',
                pageNo: 1,
                pageSize: 10,
                pid: 0,
                title: '',
				infoTypeId:"",
				infotypeName:"",
				typeIdList:[]
            },
			timeorder:2,
			downorder:2,
			readorder:2,
            treeProps: {
                children: 'children',
                label: 'title'
            },
            total: 0,
            list: [],
            showMore: false,
            typeList: [],
            downInfo: {},
			status:0,
			banner:[],
			productTree:[]
        }
    },
    computed: {
        ...mapState([
            // 'productTree',
            'formats',
            'otherCates'
        ]),
        lengthMore() {
            if (this.typeList.length > 8) {
                return true
            } else {
                return false
            }
        },
        showTypeList() {
            if (this.showMore) {
                return this.typeList
            } else {
                return this.typeList.splice(0, 8)
            }
        }
    },
    created() {
			Api.productTreelogin().then(res => {
				this.productTree = res;
			})
			//获取广告列表
			Api.banner({position:5}).then(res => {
				this.banner = res;
			})
			
			if (this.$store.state.tempProductId) {
				this.params.pid = Number(this.$store.state.tempProductId);
				 this.getTypeList();
				 this.getList();
			}
        // console.log(this.$store.state.tempProductId)
			
        //
        // // 获取数据
        // 
    },
		mounted() {
			if(this.$store.state.user.userType != 1){
				this.$router.replace("/");
			}
		},
    activated() {
		
        if (this.$store.state.tempProductId) {
            this.params.pid = Number(this.$store.state.tempProductId)
        }
        this.$nextTick(() => {
            this.$refs.proTree.setCurrentKey(this.params.pid)
        })
        this.getTypeList()
        // 获取数据
        this.getList()
		this.getdingyuestatus();
        console.log(this.$store.state)
    },
    methods: {
        getList() {
            this.loading = true
            console.log(this.params)
            Api.getList(this.params).then(res => {
                console.log(res)
                if (this.total == 0) {
                    this.list = res.records
                    this.total = res.total
                    setTimeout(() => {
                        this.loading = false
                    }, 300)
                } else {
                    setTimeout(() => {
                        this.list = res.records
                        this.total = res.total
                        this.loading = false
                    }, 300)
                }
            }).catch(err => {
                this.loading = false
            })
        },
        // 分页查询
        handleCurrentChange(v) {
            this.params.pageNo = v
            this.getList()
        },
        changeOrder(value) {
            if (value == this.params.order) {
                return this.params.order = ''
            } else {
                this.params.order = value
            }
            this.list = [];
            this.params.pageNo = 1;
            this.getList()
        },
		changetype(e){
			// if((e.applyType == 0 && e.applyStatus == 1) || (e.applyType == 0 && e.applyStatus==undefined)){
			// 		this.$confirm("您尚未开通该目录权限，是否申请开通？","提示").then(()=>{
			// 			Api.doApply({inforClassId:e.id}).then(res=>{
			// 				this.$message.success("申请已提交，请耐心等待");
			// 			})
			// 		})
			// }else{
				this.params.infotypeName = e.name;
				this.params.typeIdList = e.id;
				this.params.pageNo = 1;
				this.list = [];
				this.getList();
				this.getdingyuestatus();
			// }
			
		},
        // 点击树列表
        nodeClick(e, n) {
					// if(e.applyType == 0 && e.applyStatus == 1){
					// 		this.$confirm("您尚未开通该目录权限，是否申请开通？","提示").then(()=>{
					// 			Api.doApply({inforClassId:e.id}).then(res=>{
					// 				this.$message.success("申请已提交，请耐心等待");
					// 			})
					// 		})
					// }else{
						this.tempCates = []
						this.$store.dispatch('setOtherCates', [])
						this.allParent(n)
						this.tempCates.unshift('产品资料')
						this.$store.dispatch('setOtherCates', this.tempCates)
						this.params.pid = e.id
						this.params.pageNo = 1
						this.params.typeIdList = [];
						this.params.infotypeName = "";
						this.params.format = "";
						
						this.list = []
						this.getTypeList()
						this.getList()
						this.getdingyuestatus();
					// }
            
        },
        // 循环获取父级分类
        allParent(datas) {
            for (var i in datas) {
                if (i == 'data') {
                    this.tempCates.unshift(datas[i].title)
                }
                if (i == 'parent' && datas[i].level >= 1) {
                    if (datas[i]) {  //存在子节点就递归
                        this.allParent(datas[i]);
                    }
                }
            }
        },
        // 加载类型
        getTypeList() {
            Api.typeList({ belong: this.params.belong, pid: this.params.pid }).then(res => {
				let data = [];
				Object.keys(res).map(item=>{
					let ids = [];
					res[item].map(value=>{
						ids.push(value.id);
					})
					data.push({name:item,id:ids});
				})
                this.typeList = data
            })
        },
        // 显示下载弹窗
        share(item) {
			if((item.applyType == 0 && item.applyStatus == 1) || (item.applyType == 0 && item.applyStatus==undefined)){
				this.$confirm("您尚未开通该目录权限，是否申请开通？","提示").then(()=>{
					
					Api.doApply({inforClassId:item.infoTypeId,typeIdList:this.params.typeIdList}).then(res=>{
						this.$message.success("申请已提交，请耐心等待");
					})
				})
			}else{
				const data = {
				    belong: 2,
				    id: item.id,
				    type: 5
				}
							Api.inforPosts(data).then(res => {
									// if (item.infoFormat == '链接' || item.infoFormat == 'pdf') {
									// 		if (res.document) {
									// 			// 创建输入框元素
									// 			let oInput = document.createElement('input');
									// 			// 将想要复制的值
									// 			oInput.value = res.document.replace('http', 'https');
									// 			// 页面底部追加输入框
									// 			document.body.appendChild(oInput);
									// 			// 选中输入框
									// 			oInput.select();
									// 			// 执行浏览器复制命令
									// 			document.execCommand('Copy');
									// 			// 弹出复制成功信息
									// 			this.$message.success('链接已复制，快去分享吧~');
									// 			// 复制后移除输入框
									// 			oInput.remove();
									// 		}
									// } else {
										// 创建输入框元素
										let oInput = document.createElement('input');
										// 将想要复制的值
										oInput.value = `${location.origin}/detail?id=${item.id}&type=2`;
										// 页面底部追加输入框
										document.body.appendChild(oInput);
										// 选中输入框
										oInput.select();
										// 执行浏览器复制命令
										document.execCommand('Copy');
										// 弹出复制成功信息
										this.$message.success('链接已复制，快去分享吧~');
										// 复制后移除输入框
										oInput.remove();
									// }
									
							})
			}
            
			
        },
        // 显示下载弹窗
        showDownload(item) {
			if((item.applyType == 0 && item.applyStatus == 1) || (item.applyType == 0 && item.applyStatus==undefined)){
				this.$confirm("您尚未开通该目录权限，是否申请开通？","提示").then(()=>{
					
					Api.doApply({inforClassId:item.infoTypeId,typeIdList:this.params.typeIdList}).then(res=>{
						this.$message.success("申请已提交，请耐心等待");
					})
				})
			}else{
				this.downInfo = {
				    id: item.id,
				    title: item.title,
				    url: item.document,
				    icon: item.infoFormat,
					fileName:item.fileName?item.fileName:''
				}
				this.$refs.downloadRef.open()
			}
           
        },
        // 下载
        download() {
            const data = {
                belong: 2,
                id: this.downInfo.id,
                type: 4
            }
            Api.inforPosts(data).then(res => {
				window.open(res.document.replace('http', 'https'))
				// let name = res.document.split("?")[0];
				//  const a = document.createElement('a');
				//   a.style.display = 'none';
				//   a.setAttribute('target', '_blank');
				//   name && a.setAttribute('download', name);
				//   a.href = res.document;
				//   document.body.appendChild(a);
				//   a.click();
				//   document.body.removeChild(a);
            })
            this.$refs.downloadRef.close()

        },
        // 收藏
        collection(id, index, vlaue) {
            const data = {
                belong: 2,
                id: id,
                type: 3
            }
            this.list[index].collect = !vlaue
            if (vlaue) {
                Api.cancelInforPosts(data)
				this.list[index].collectNum--;
            } else {
                Api.inforPosts(data)
				this.list[index].collectNum++;
            }
        },
        // 跳转到详情页面
        toDetail(item) {
			if((item.applyType == 0 && item.applyStatus == 1) || (item.applyType == 0 && item.applyStatus==undefined)){
				this.$confirm("您尚未开通该目录权限，是否申请开通？","提示").then(()=>{
					
					Api.doApply({inforClassId:item.infoTypeId,typeIdList:this.params.typeIdList}).then(res=>{
						this.$message.success("申请已提交，请耐心等待");
					})
				})
			}else{
				if (item.infoFormat == '链接') {
				    window.location = item.document
				} else {
				    this.$router.push({ path: '/detail', query: { id: item.id, type: 2 } })
				}
			}
            
        },
		
		tourl(item){
			Api.updateClickNum({id:item.id});
			if(item.link){
				window.open(item.link);
			}
		},
        // 根据类型返回类
        getFormatType(type) {
            let str = ''
            this.$store.state.infoFormatType.forEach(item => {
                if (item.type == type) {
                    str = item.class
                    return;
                }
            })
            return str
        },
        // 预览
        priview(e) {
			if((e.applyType == 0 && e.applyStatus == 1) || (e.applyType == 0 && e.applyStatus==undefined)){
				this.$confirm("您尚未开通该目录权限，是否申请开通？","提示").then(()=>{
					
					Api.doApply({inforClassId:e.infoTypeId,typeIdList:this.params.typeIdList}).then(res=>{
						this.$message.success("申请已提交，请耐心等待");
					})
				})
			}else{
				const data = {
				    belong: 2,
				    id: e.id,
				    type: 1
				}
				Api.inforPosts(data).then(res => {
				    console.log(res)
				    if (res.document) {
				        // window.location = res.document
				        window.open(res.document.replace('http', 'https'))
				    }
				})
			}
            
            // window.location = url
        },
        cancel(index) {
            this.inforTree[index].leaf = !this.inforTree[index].leaf
        },
        cancel1(index1, index) {
            this.inforTree[index].children[index1].leaf = !this.inforTree[index].children[index1].leaf
        },
		dingyue(status){
			if(this.params.pid == 0){
				return;
			}
			Api.ziliaodoSubscribe({inforClassId:this.params.pid,typeIdList:this.params.typeIdList,status:status}).then(res=>{
				this.$message.success("操作成功");
				this.getdingyuestatus();
			})
		},
		getdingyuestatus(){
			Api.ziliaoSubscribe({productClassId:this.params.pid,typeIds:this.params.typeIdList.join(",")}).then(res=>{
				this.status  = res;
			})
			
		}
    }
}
</script>

<style scoped lang="scss">
.channel-information {
    padding-top: 80px;
    .work-container {
        padding-top: 30px;
        display: flex;
        background: #f5f5f7;
        padding-bottom: 50px;
        align-items: flex-start;
        .slide-menu-area {
            border-radius: 5px;
            padding: 40px 30px;
            width: 300px;
            min-width: 300px;
            padding-right: 15px;
            margin-right: 20px;
            background: #fff;
            min-height: 800px;
            max-height: 900px;
            overflow-y: auto;
            position: sticky;
            top: 80px;
            .main-tit {
                font-size: 18px;
                font-weight: 500;
                color: #111111;
                margin-bottom: 26px;
            }
            .menus {
                // border-left:1px solid #E8E9E9;
                // padding-left:10px;
                .parent {
                    display: flex;
                    align-items: center;
                    &:before {
                        content: "";
                        min-width: 16px;
                        height: 16px;
                        background: url("../assets/images/meun_collapse.png")
                            no-repeat;
                        display: inline-block;
                        background-size: cover;
                        margin-right: 5px;
                    }
                }
                .iscollapse {
                    &:before {
                        content: "";
                        min-width: 16px;
                        height: 16px;
                        background: url("../assets/images/menu_collapsed.png")
                            no-repeat;
                        display: inline-block;
                        background-size: cover;
                        margin-right: 5px;
                    }
                }
                li {
                    line-height: 25px;
                    a {
                        line-height: 25px;
                        display: inline-block;
                        margin-bottom: 5px;
                        width: 100%;
                        padding-left: 10px;
                        border-radius: 5px;
                        user-select: none;
                    }
                    a.active {
                        background: #f4f9fa;
                        color: #e23523;
                        font-weight: 500;
                    }
                    ul {
                        //    transition:.2s all ease-in-out;
                        //    height:0px;
                        //    overflow: hidden;
                    }
                }
                > li {
                    margin-bottom: 20px;
                    > ul {
                        margin-left: 5px;
                        border-left: 1px solid #e8e9e9;
                        padding-left: 10px;
                        > li {
                            > ul {
                                margin-left: 15px;
                                border-left: 1px solid #e8e9e9;
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }
        }
        .work-content-box {
            border-radius: 5px;
            background: #fff;
            width: 100%;
            .top {
                padding: 40px 30px;
            }
            .main-tit {
                font-size: 18px;
                font-weight: 500;
                color: #333333;
                line-height: 18px;
                margin-bottom: 34px;
				
				display: flex;
				justify-content: space-between;
				align-items: center;
				div{
					width: 70px;
					height: 28px;
					background: #E3E4E5;
					border-radius: 3px;
					font-size: 16px;
					color: #2E2E2E;
					text-align: center;
					line-height: 28px;
					cursor: pointer;
				}
            }
            .filter {
                display: flex;
                margin-bottom: 26px;
                .filter-label {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    white-space: nowrap;
                }
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        text-align: center;
                        min-width: 56px;
                        line-height: 22px;
                        border-radius: 22px;
                        padding: 0px 10px;
                        margin-right: 20px;
                        color: #707070;
                        cursor: pointer;
                        transition: 0.1s all linear;
                        user-select: none;
                        margin-bottom: 10px;
                        &:hover {
                            background: #e73522;
                            color: #fff;
                        }
                    }
                    .active {
                        background: #e73522;
                        color: #fff;
                    }
                }
                .filter-view-more {
                    color: #e73522;
                    cursor: pointer;
                }
            }
            .filter:last-child {
                margin-bottom: 0px;
            }
            .bot {
                border-top: 1px solid #dddddd;
                padding: 20px 30px 0px 30px;
                .filter-sort {
                    margin-bottom: 38px;
                    color: #707070;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .types {
                        display: flex;
                        align-items: center;
                        .sort-label {
                            width: 100px;
                            text-align: center;
                            border: 1px solid #cccccc;
                            border-radius: 3px;
                        }
                        span {
                            margin-right: 14px;
                            display: inline-block;
                            cursor: pointer;
                            line-height: 32px;
                        }
                        .search-box {
                            position: relative;
                            input {
                                border: 1px solid #ccc;
                                line-height: 32px;
                                outline: none;
                                padding: 0px 10px;
                                height: 32px;
                                min-width: 200px;
                                padding-right: 30px;
                            }
                            .search-btn {
                                width: 32px;
                                height: 32px;
                                background: url("../assets/images/search.png")
                                    no-repeat;
                                display: inline-block;
                                background-size: 16px 16px;
                                background-position: center;
                                cursor: pointer;
                                position: absolute;
                                right: 0px;
                                border: 0px;
                                top: 0px;
                                transition: 0.2s all linear;
                                &:active {
                                    opacity: 0.8;
                                    background-color: #eee;
                                }
                            }
                        }
                    }
                }
                .list-box {
                    li {
                        padding-bottom: 22px;
                        border-bottom: 1px solid #dddddd;
                        margin-bottom: 24px;
                        padding-left: 50px;
                        .tit {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .val {
                                font-size: 16px;
                                font-weight: 500;
								cursor: pointer;
                            }
                        }

                        .op {
                            display: flex;
                            justify-content: flex-end;
							flex-wrap: wrap;
                            &>span {
                                padding-left: 20px;
                                cursor: pointer;
								color: #999999;
                                &:hover {
                                    color: #e23523;
                                }
								span{
									color: #111111;
									font-size: 500;
									margin-left: 11px;
								}
                            }
                            &>span:last-child {
                                margin-left: 10px;
                            }
                            .icon-preview {
                                background: url("../assets/images/icon_preview.png")
                                    no-repeat;
                                background-size: 16px 16px;
                                background-position: left;
                            }
                            .icon-download {
                                background: url("../assets/images/icon_download.png")
                                    no-repeat;
                                background-size: 16px 16px;
                                background-position: left;
                            }
                            .icon-share {
                                background: url("../assets/images/share.png")
                                    no-repeat;
                                background-size: 16px 16px;
                                background-position: left;
                            }
                            .icon-fav {
                                background: url("../assets/images/icon_fav.png")
                                    no-repeat;
                                background-size: 16px 16px;
                                background-position: left;
                            }
                            .icon-faved {
                                background: url("../assets/images/icon_faved.png")
                                    no-repeat;
                                background-size: 16px 16px;
                                background-position: left;
                            }
                        }
                        .tim-size {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 13px;
                            .val {
                                color: #989898;
                                font-size: 14px;
                            }
                        }
                    }
                    li:last-child {
                        margin-bottom: 0px;
                    }

                    .file-pdf {
                        background: url("../assets/images/file_pdf.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-word {
                        background: url("../assets/images/file_word.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-xls {
                        background: url("../assets/images/file_xls.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-albums {
                        background: url("../assets/images/file_albums.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-apk {
                        background: url("../assets/images/file_apk.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-exe {
                        background: url("../assets/images/file_exe.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-link {
                        background: url("../assets/images/file_link.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-picture {
                        background: url("../assets/images/file_picture.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-powerpoint {
                        background: url("../assets/images/file_powerpoint.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-video {
                        background: url("../assets/images/file_video.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-other {
                        background: url("../assets/images/file_other.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-zip {
                        background: url("../assets/images/file_zip.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                }
            }
            .pagination-box {
                text-align: center;
                margin-bottom: 37px;
                margin-top: 13px;
            }
        }
    }
    .active {
        color: #e73522;
    }
}
::v-deep .el-carousel__container{
	height: 350px;
}
::v-deep .el-carousel__container img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
@media only screen and (max-width: 750px) {
    .ban{
        height: 2rem;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .channel-information {
        padding-top: 1.4rem;
        .work-container {
            display: block;
            padding: .3rem .3rem .6rem;
            .slide-menu-area {
                padding: .3rem;
                width: 100%;
                min-width:0;
                margin: 0 0 .2rem;
                min-height: 0;
                max-height: 3rem;
                position: static;
                .main-tit {
                    font-size: .32rem;
                    margin-bottom: .2rem;
                }
                .menus {
                    .parent {
                        display: flex;
                        align-items: center;
                        &:before {
                            content: "";
                            min-width: .32rem;
                            height: .32rem;
                            background: url("../assets/images/meun_collapse.png")
                                no-repeat;
                            display: inline-block;
                            background-size: cover;
                            margin-right: .1rem;
                        }
                    }
                    .iscollapse {
                        &:before {
                            content: "";
                            min-width: .32rem;
                            height: .32rem;
                            background: url("../assets/images/menu_collapsed.png")
                                no-repeat;
                            display: inline-block;
                            background-size: cover;
                            margin-right: .1rem;
                        }
                    }
                    li {
                        line-height: .5rem;
                        a {
                            line-height: .5rem;
                            display: inline-block;
                            margin-bottom: .1rem;
                            width: 100%;
                            padding-left: .1rem;
                        }
                    }
                    > li {
                        margin-bottom: .2rem;
                        > ul {
                            margin-left: .1rem;
                            padding-left: .1rem;
                            > li {
                                > ul {
                                    margin-left: .3rem;
                                    padding-left: .2rem;
                                }
                            }
                        }
                    }
                }
            }
            ::v-deep .channel-menu .el-tree > .el-tree-node .el-tree-node__content{
                min-height: .5rem;
                padding: .1rem 0;
            }
            ::v-deep .channel-menu .el-tree-node__expand-icon,
            ::v-deep .channel-menu .el-tree-node__expand-icon.expanded{
                width: .28rem;
                height: .28rem;
                margin-right: .1rem;
            }
            ::v-deep .el-tree-node__label{
                font-size: .24rem;
            }
            .work-content-box {
                overflow: hidden;
                .top {
                    padding: .3rem;
                }
                .main-tit {
                    font-size: .32rem;
                    line-height: 1.2;
                    margin-bottom: .2rem;
                    div{
                        width: auto;
                        min-width: 1rem;
                        padding: 0 .1rem;
                        height: .4rem;
                        line-height: .4rem;
                        font-size: .24rem;
                    }
                }
                .filter {
                    display: block;
                    margin-bottom: .2rem;
                    .filter-label {
                        display: block;
                        font-size: .28rem;
                        line-height: 1.6;
                    }
                    ul {
                        li {
                            font-size: .24rem;
                            min-width:.6rem;
                            line-height: .5rem;
                            border-radius: .25rem;
                            padding: 0px .2rem;
                            margin-right:.1rem;
                            margin-bottom:.1rem;
                        }
                    }
                }
                .bot {
                    padding: .3rem;
                    .filter-sort {
                        margin-bottom: .3rem;
                        display: block;
                        font-size: .28rem;
                        .types {
                            display: block;
                            font-size: .28rem;
                            margin-bottom: .15rem;
                            .sort-label {
                                width: 100%;
                            }
                            .el-dropdown{
                                font-size: .28rem;
                                width: 100%;
                                margin: 0;
                            }
                            
                            span {
                                // display: block;
                                margin-right: .2rem;
                                cursor: pointer;
                                line-height: .6rem;
                                margin: 0;
                            }
                            .search-box {
                                position: relative;
                                margin-top: .2rem;
                                input {
                                    line-height: .6rem;
                                    padding: 0px .2rem;
                                    height: .6rem;
                                    min-width: 260px;
                                    width: calc(100% - .7rem);
                                    padding-right: .3rem;
                                }
                                .search-btn {
                                    width: .6rem;
                                    height: .6rem;
                                    background-position: center center;
                                    background-size: .3rem auto;
                                }
                            }
                        }
                    }
                    .list-box {
                        li {
                            padding-bottom: .2rem;
                            margin-bottom: .2rem;
                            padding-left: .8rem;
                            .tit {
                                display: block;
                                .val {
                                    font-size: .28rem;
                                }
                            }

                            .op {
                                display: flex;
                                justify-content: flex-start;
                                margin-top: .1rem;
                                span {
                                    font-size: .24rem;
                                    padding-left: .4rem;
                                    margin-right: .3rem;
                                }
                                span:last-child {
                                    margin-left: 0;
                                }
                                .icon-preview {
                                    background-size: .3rem auto;
                                }
                                .icon-download {
                                    background-size: .3rem auto;
                                }
                                .icon-fav {
                                    background-size: .3rem auto;
                                }
                                .icon-faved {
                                    background-size: .3rem auto;
                                }
                            }
                            .tim-size {
                                display: block;
                                margin-top: .2rem;
                                .val {
                                    font-size: .24rem;
                                }
                            }
                        }
                        .file-pdf {
                            background-size: .6rem auto;
                        }
                        .file-word {
                            background-size: .6rem auto;
                        }
                        .file-xls {
                            background-size: .6rem auto;
                        }
                        .file-albums {
                            background-size: .6rem auto;
                        }
                        .file-apk {
                            background-size: .6rem auto;
                        }
                        .file-exe {
                            background-size: .6rem auto;
                        }
                        .file-link {
                            background-size: .6rem auto;
                        }
                        .file-picture {
                            background-size: .6rem auto;
                        }
                        .file-powerpoint {
                            background-size: .6rem auto;
                        }
                        .file-video {
                            background-size: .6rem auto;
                        }
                        .file-other {
                            background-size: .6rem auto;
                        }
                        .file-zip {
                            background-size: .6rem auto;
                        }
                    }
                }
                .pagination-box {
                    margin: .3rem 0 .6rem;
                }
            }
        }
    }
    ::v-deep .el-dropdown-menu__item{
        font-size: .28rem;
        line-height: 2;
    }

    ::v-deep .el-carousel__container{
		height: 2rem;
	}
}
</style>