<template>
    <div class="forumdetail">
        <Header />
        <div class="main pb80" id="app" style="background-color: #f5f5f7;">
            <div class="wp w1200">
                <div class="m-subNav">
                    <a @click="goback" class="t">论坛</a>
                    <a
                        class="t"
                        v-for="(item, index) in forumCates"
                        :key="index"
                        @click="goback"
                    >{{ item }}</a>
                    <a class="t">正文</a>
                </div>
                <div class="w300 fr" id="posfx">
                    <div class="g-rightbox adminfo">
                        <div class="m-subadminfo">
                            <div class="pic">
                                <img class="pic" :src="forumInfo.plateImg" />
                            </div>
                            <div class="tit">{{ forumInfo.name }}</div>
                            <div  v-if="forumInfo.subscribeStatus" class="btn" @click="subscription">订阅</div>
                            <div v-else  class="btn" @click="subscription">取消订阅</div>
                        </div>
                    </div>
                    <div class="g-rightbox plr20">
                        <div class="m-tuijiantxt">
                            <div class="tit">热门文章</div>
                            <ul>
                                <li v-for="(item, index) in hotList" :key="index">
                                    <div class="con">
                                        <router-link :to="'/forumdetail?id='+item.id+'&pid='+pid">{{ item.title }}</router-link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="g-leftbox mb20" style="position: relative;">
                    <div class="m-deailInfo">
                        <div class="m-dtTit">
                            <div class="txts">
                                <h3 class="tit">{{ info.title }}</h3>
                                <div class="info">
                                    <span class="date">发帖时间：{{ info.createTimeStr }}</span>
                                    <span class="watch">{{ info.readNum }}</span>
                                </div>
                            </div>
							
							<div class="action">
								<!-- <div @click="collectation(1)" :class="info.collectStatus==0?'action-item action-active':'action-item'">
									<img v-if="info.collectStatus== 1" src="../assets/images/info/no-like.png" />
									<img v-else src="../assets/images/info/like.png" />
									<div class="action-num">{{info.collectNum}}</div>
								</div> -->
								<div @click="collectation(2)" :class="info.likeStatus==0?'action-item action-active':'action-item'">
									<img v-if="info.likeStatus== 1" src="../assets/images/info/no-zan.png" />
									<img v-else src="../assets/images/info/zan.png" />
									<div class="action-num">{{info.likeNum}}</div>
								</div>
								<div class="action-item" style="margin-left: 20px;">
									<img src="../assets/images/info/liulan.png" />
									<div class="action-num">{{total}}</div>
								</div>
							</div>
                        </div>
                    </div>
                    <div class="m-libDetailText">
                        <div class="txt" v-html="info.postsContent"></div>
						<a  class="file-list" v-for="(item,index) in info.fileList" @click="downloadimage(item.id)" :key="index">
							查看附件：{{item.fileName}}
						</a>
                        <div class="info">
                            <div class="tit">版权声明：</div>
                            <div class="desc">本文内容由本网站实名注册用户自发贡献，版权归原作者所有，本论坛不拥有其著作权，亦不承担相应法律责任。</div>
                        </div>
                    </div>
                </div>
				
				<div class="g-leftbox" v-if="list.length > 0">
				    <div class="m-pingluncon">
				        <div class="tit">全部回复</div>
				        <ul class="ul-pldetaillist">
				            <li v-for="(item, index) in list" :key="index" :name="item.id">
				                <div class="con">
				                    <div class="pic">
				                        <img class="pic" :src="item.avatar" />
				                    </div>
				                    <div class="dtinfo">
				                        <div class="picinfo">
				                            <div class="name">{{ item.nickname }}
											<div class="name-liuyan" @click.stop.prevent="liuyan(item)"><img src="@/assets/images/fengxiang.png"/>私信</div>
											<span v-if="item.topStatus==0" class="tit-icon-one">置顶</span></div>
				                            <div class="date">
				                                <div class="year">{{ item.createTime }}</div>
												<div>#{{item.floor}}</div>
				                            </div>
				                        </div>
				                        <div class="txt">
											<div class="text-replay" v-if="item.pcontent">
												<img class="shang" src="../assets/images/detail/douhao.png" />
												<div>{{item.pnickname}}<span style="margin: 0px 4px;">发表于</span>{{item.pcreateTime}}</div>
												<div v-html="item.pcontent"></div>
												<img class="xia" src="../assets/images/detail/douhao.png" />
											</div>
											
				                            <div class="t image-setting" v-html="item.content" style="margin-bottom: 5px;"></div>
											<a class="file-list" v-for="value in item.fileList" @click="downloadimage(value.id)" :key="value.filePath">
												查看附件：{{value.fileName}}
											</a>
				                            <div class="icon" v-if="item.status != 1">
				                                <div style="display: inline-block;cursor: pointer;margin-right: 10px;" v-if="item.isPostsReplyMaster == 0 || info.isModeratorMaster == 0" @click="handleDel(item.id, index)">删除</div>
				                                <div v-if="info.readOnlyStatus == 1" class="back" @click="showRevert(index)">回复</div>
												<div class="top" v-if="info.isModeratorMaster == 0" @click="handlereplayTop(index)">
													{{item.topStatus==0?'取消置顶':'置顶'}}
												</div>
				                            </div>
											<div class="backinput" v-if="item.id==parentId">
												<div class="m-pingluncon" style="text-align: left;margin-top: 20px;">
												    <div class="g-pltxtar">
														<div class="m-quillEditor-m1" v-loading="loading">
															<!-- <quill-editor ref="QuillEditor" @change="onEditorChange($event)" :options="editorOption" v-model="commentContent"></quill-editor> -->
															
															<Editor style="width: 100%;" class="tinymce1" :init="init1" v-model="commentContent"></Editor>
														</div>
												        <div class="sbm" style="margin-top: 20px;">
															<div>
																<div v-for="(item,index) in file" :key="index" class="upload-text">
																	<div>{{item.fileName}}</div><img @click="deleteimage(index)" src="../assets/images/detail/close.png"/>
																</div>
															</div>
															<el-upload v-if="file.length<5" action="" :accept="accept" :show-file-list="false" :http-request="uploadPic" :on-success="handleSuccess">
																<div class="upload">上传附件</div>
															</el-upload>
												            <input type="submit" value="回复" @click="comment()" />
												        </div>
													</div>
												</div>
											</div>
				                        </div>
				                        <!-- <div
				                            class="more"
				                            v-if="item.revertRecords.current < item.revertRecords.pages"
				                            @click="loadMoreRevert(item, index)"
				                        >
				                            <span>查看更多回复</span>
				                        </div> -->
				                    </div>
				                </div>
				            </li>
				        </ul>
				        <div class="more" v-if="total > list.length" @click="loadMoreComment">
				            <span>查看更多评论</span>
				        </div>
				    </div>
				</div>
				
                <div class="g-leftbox" style="margin-top: 20px;" v-if="parentId==0 && info.readOnlyStatus == 1">
                    <div class="m-pingluncon">
                        <div class="g-pltxtar">
                            <div class="tit">回复</div>
                           <!-- <textarea
                                rows
                                cols
                                maxlength="500"
                                v-model="commentContent"
                                placeholder="请写下你的评论，字数限制为500字…"
                            ></textarea> -->
							<div class="m-quillEditor-m1" v-loading="loading">
								<Editor style="width: 100%;" class="tinymce1" :init="init1" v-model="commentContent"></Editor>
							</div>
                            <div class="sbm">
								<div>
									<div v-for="(item,index) in file" :key="index" class="upload-text">
										<div>{{item.fileName}}</div><img @click="deleteimage(index)" src="../assets/images/detail/close.png"/>
									</div>
								</div>
								<el-upload v-if="file.length<5" action="" :accept="accept" :show-file-list="false" :http-request="uploadPic" :on-success="handleSuccess">
									<div class="upload">上传附件</div>
								</el-upload>
                                <input type="submit" value="评论" @click="comment" />
                            </div>
							<div class="tishi">内容安全提示：尊敬的用户您好，为了保障您、论坛及第三方的合法权益，请勿发布可能给各方带来法律风险的内容，包括但不限于政治敏感内容，涉黄赌毒内容，泄露、侵犯他人商业秘密的内容，侵犯他人商标、版本、专利等知识产权的内容，侵犯个人隐私的内容等。也请勿向他人共享您的账号及密码，通过您的账号执行的所有操作，将视同您本人的行为，由您本人承担操作后果。详情请参看“论坛用户协议”</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		<div class="action-list">
			<div class="wp w1200 action-list-info">
				<div v-if="info.isPostsMaster == 0" @click="editinfo">
					<img src="../assets/images/detail/edit.png" />编辑
				</div>
				<div v-if="info.isModeratorMaster == 0" @click="handleTop">
					<img v-if="info.topStatus==0" src="../assets/images/detail/recommend.png" />
					<img v-else src="../assets/images/detail/no-recommend.png" />
					{{info.topStatus==0?'取消置顶':'置顶'}}
				</div>
				<div v-if="info.isPostsMaster == 0 || info.isModeratorMaster == 0" @click="handleDelPosts">
					<img src="../assets/images/detail/delete.png" />删除
				</div>
				<div v-if="info.isPostsMaster == 0 || info.isModeratorMaster == 0" @click="handelreadOnly">
					{{info.readOnlyStatus==0?'取消只读':'只读'}}
				</div>
				<div @click="handleAdminVisible" v-if="info.isModeratorMaster == 0">
					{{info.replyAdminVisible==1?'设置回复仅管理员可见':'取消回复仅管理员可见'}}
				</div>
			</div>
			
		</div>
        <Footer></Footer>
		<Liuyan ref="liuyan"></Liuyan>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Subnav from '@/components/Subnav.vue';
import Footer from '@/components/Footer.vue';
import Api from '@/request/api.js';
import { mapState } from 'vuex';
import tinymce from 'tinymce/tinymce' //tinymce默认hidden，不引入不显示
import Editor from '@tinymce/tinymce-vue' //编辑器引入
import 'tinymce/themes/silver/theme'
import 'tinymce/icons/default' //引入编辑器图标icon，不引入则不显示对应图标
// 引入编辑器插件（基本免费插件都在这儿了）
import 'tinymce/plugins/advlist' //高级列表
import 'tinymce/plugins/autolink' //自动链接
import 'tinymce/plugins/lists' //列表插件
import 'tinymce/plugins/charmap' //特殊字符
import 'tinymce/plugins/wordcount' // 字数统计
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'

import Liuyan from '@/components/liuyan.vue';
const fonts = [
	"宋体=宋体",
	"微软雅黑=微软雅黑",
	"新宋体=新宋体",
	"黑体=黑体",
	"楷体=楷体",
	"隶书=隶书",
	"Courier New=courier new,courier",
	"AkrutiKndPadmini=Akpdmi-n",
	"Andale Mono=andale mono,times",
	"Arial=arial,helvetica,sans-serif",
	"Arial Black=arial black,avant garde",
	"Book Antiqua=book antiqua,palatino",
	"Comic Sans MS=comic sans ms,sans-serif",
	"Courier New=courier new,courier",
	"Georgia=georgia,palatino",
	"Helvetica=helvetica",
	"Impact=impact,chicago",
	"Symbol=symbol",
	"Tahoma=tahoma,arial,helvetica,sans-serif",
	"Terminal=terminal,monaco",
	"Times New Roman=times new roman,times",
	"Trebuchet MS=trebuchet ms,geneva",
	"Verdana=verdana,geneva",
	"Webdings=webdings",
	"Wingdings=wingdings,zapf dingbats"
];

export default {
    name: 'ForumDetail',
    components: {
        Header,
        Footer,
        Subnav,
		Editor,
		Liuyan
    },
    data() {
        return {
            id: null,
            pid: null,
            forumInfo: {},
            info: [],
            list: [],
            total: 0,
			accept: '',
			init1: {
				selector: '.tinymce1',
				language_url: '/tinymce/langs/zh_CN.js', //汉化路径是自定义的，一般放在public或static里面
				language: 'zh_CN',
				skin_url: '/tinymce/skins/ui/oxide', //皮肤
				//工具栏
				plugins: 'link lists image code table colorpicker textcolor contextmenu',
				toolbar: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat',
				fontsize_formats: '12px 14px 16px 18px 20px 22px 24px 28px 32px 36px 48px 56px 72px', //字体大小
				font_formats: fonts.join(";"),
				height: 200, //高度
				branding: false,
				// 图片本地上传方法  点击上传后执行的事件
				images_upload_handler: (blobInfo, success, failure) => {
					this.handleImgUpload(blobInfo, success, failure)
				}
			},
            commentParams: {
                postsId: 0,
                order: '',
                pageNo: 1,
                pageSize: 10,
                sort: 0
            },
            commentContent: '',
            revertContent: '',
            hotList: [],
			file:[],
			loading:false,
			parentId:0
        }
    },
    async created() {
        // 如果论坛关闭 跳转到首页
        if (this.forumStatus == null) {
            await this.getForumConfig()
        }
        if (this.forumStatus != 1) {
            this.$message.error('论坛已关闭')
            return this.$router.replace({ path: '/' })
        }
        if (this.$route.query.id) {
            this.pid = this.$route.query.pid
            this.getForumInfo()
            this.id = this.$route.query.id
            this.commentParams.postsId = this.$route.query.id
            this.getDetail()
            this.getHotList()
			this.commentParams.pageNo = 1
			this.loadMoreComment()
        }
    },
    computed: {
        ...mapState([
            'forumCates',
            'forumStatus',
            'user'
        ])
    },
    mounted() {
		// 固定右侧模块
		var top1 = document.getElementById('posfx').offsetTop;
		let _this = this;
		window.addEventListener("scroll",function(e){
			_this.handleScroll(e,top1)
		},false);
    },
    methods: {
		
		handleImgUpload(blobInfo, success, failure) {
			
			Api.upload(blobInfo.blob()).then((res)=>{
				if(res.data.code == 0){
					success(res.data.message);
				}else{
					failure('HTTP Error: ' + res.data.message);
				}
			});
		},
		editinfo:function(){
			this.$router.push(`/mypostpage?id=${this.id}&pid=${this.pid}`);
		},
		onEditorChange(event) {
		      event.quill.deleteText(2000,1);
		},
		deleteimage(index){
			this.file.splice(index,1);
		},
		uploadFile({ file }) {
			this.loading = true
			return Api.uploadtwo(file)
		},
		uploadPic({ file }) {
			return Api.uploadtwo(file)
		},
		downloadimage(id){
			Api.getFileTempUrl(id).then(res => {
				console.log(res);
				window.open(res);
			})
		},
		handleSuccess(res,file) {
			this.loading = false
			// 如果上传成功
			if (res.data.message) {
				this.file.push({fileName:file.name,filePath:res.data.message});
			} else {
				// 提示信息，需引入Message
				this.$message.error('上传失败，请重试')
			}
		},
		handleUploadSuccess(res) {
			this.loading = false
			// 获取富文本组件实例
			let quill = this.$refs.QuillEditor.quill
			// 如果上传成功
			if (res.data.message) {
				// 获取光标所在位置
				let length = quill.getSelection().index;
				// 插入图片，res为服务器返回的图片链接地址
				quill.insertEmbed(
					length,
					this.accept.includes('image') ? 'image' : 'video',
					res.data.message
				)
				// 调整光标到最后
				quill.setSelection(length + 1)
			} else {
				// 提示信息，需引入Message
				this.$message.error('图片插入失败')
			}
		},
        // 获取详情
        getDetail() {
            Api.forumDetail({ postsId: this.id }).then(res => {
				console.log(res)
                this.info = res
            })
        },
        // 获取板块信息
		getForumInfo() {
			Api.forumInfo(this.pid).then(res => {
				this.forumInfo = res
			})
		},
        // 加载热门推荐文章
        getHotList() {
			Api.getHotPostsList(this.pid).then(res => {
				this.hotList =  res
			})
		},
        // 加载分页评论
        loadMoreComment() {
            Api.moreComment(this.commentParams).then(res => {
                console.log(res.records)
				if(this.commentParams.pageNo == 1){
					this.list = [];
				}
				this.list = this.list.concat(res.records)
                this.commentParams.pageNo += 1
				this.total =res.total;
                
            })
        },
        // 评论
        comment() {
            if (!this.commentContent) {
                return this.$message.info('请填写评论内容')
            }
            const data = {
                postsId: this.id,
				pid:this.parentId,
                content: this.commentContent,
                fileList: this.file
            }
			console.log(data);
            Api.forumRevert(data).then(res => {
                this.$message.success('评论成功')
                this.commentContent = ''
				this.file=[]
				this.commentParams.pageNo = 1
                this.loadMoreComment()
            })
        },
        // 展示输入框
        showRevert(index) {
			this.commentContent = ''
			this.file=[]
			if(this.parentId == this.list[index].id){
				this.parentId = 0;
			}else{
				this.parentId = this.list[index].id
			}
			
			this.$forceUpdate()

        },
        // 回复
        revert(pid, content) {
            if (!content) {
                return this.$message.info('请输入回复内容')
            }
            const data = {
                pid: pid,
                revertContent: content,
                revertType: 2 // 1评论 2回复
            }
            Api.forumRevert(data).then(res => {
                console.log(res)
                this.$message.success('回复成功')
                this.getDetail()
            })
        },
        // 订阅
		subscription() {
			if (this.forumInfo.subscription) {
				Api.subscriptionDel({ classId: this.forumInfo.id }).then(res => {
					this.forumInfo.subscribeStatus = !this.forumInfo.subscribeStatus
				})
			} else {
				Api.subscriptionAdd({ classId: this.forumInfo.id }).then(res => {
					this.forumInfo.subscribeStatus = !this.forumInfo.subscribeStatus
				})
			}
		},
        // 删除帖子
        handleDelPosts() {
            this.$confirm('是否确认删除该帖子?').then(async () => {
				await Api.deletePosts({id: this.info.id})
				this.$message.success('删除成功')
				setTimeout(() => {
					this.$router.replace({ path: '/forumList', query: { pid: this.pid } })
				}, 2000)
			})
        },
		//回复置顶
		handlereplayTop:function(index){
            this.$confirm('确定设置?').then(async () => {
				await Api.updateReplyTopStatus({id: this.list[index].id,topStatus:(this.list[index].topStatus==0?1:0)})
				this.$message.success('操作成功')
				this.commentParams.pageNo = 1
				this.loadMoreComment()
			})
		},
		//帖子置顶
		handleTop:function(){
            this.$confirm('确定设置?').then(async () => {
				await Api.updatePostsTopStatus({id: this.info.id})
				this.$message.success('操作成功')
				this.getDetail()
			})
		},
		//帖子置顶
		handleAdminVisible:function(){
            this.$confirm('确定设置?').then(async () => {
				await Api.updateReplyAdminVisible({id: this.info.id,replyAdminVisible:(this.info.replyAdminVisible==1?0:1)})
				this.$message.success('操作成功')
				this.getDetail()
			})
		},
		//只读
		handelreadOnly:function(){
            this.$confirm('确定设置?').then(async () => {
				await Api.updatePostsReadOnlyStatus({id: this.info.id,readOnlyStatus:(this.info.readOnlyStatus==1?0:1)})
				this.$message.success('操作成功')
				this.getDetail()
			})
		},
        // 删除评论
        handleDel(id, index) {
            this.$confirm('是否确认删除该回复?').then(async () => {
				await Api.revertDelete({id: id})
                this.list.splice(index, 1)
				this.$message.success('删除成功')
				this.getDetail()
			})
        },
        goback() {
            // this.$router.go(-1)
            this.$router.replace({ path: '/forumlist', query: { pid: this.pid } })
        },
		// 详情页右侧栏目
		handleScroll(e,gettop) {
			let widTop =window.pageYOffset;
			let ele = document.getElementById('posfx');
			let top = ele.offsetTop-80;
			let left = ele.offsetLeft;
			
			
			// 获取底部 + 高度
			let ftrtop = document.getElementsByClassName('footer')[0].offsetTop;
			let ht = ele.clientHeight ;
			
			// console.log(widTop)
			if (widTop > top) {
				ele.style.left  = left+'px'
				ele.classList.add('isfixed')
			}
			if(widTop < gettop-80 || widTop> ftrtop-ht-100){
				ele.classList.remove('isfixed')
			}
			
			
		},
        // 获取论坛是否开启
        async getForumConfig() {
            await Api.getConfigId({ id: 17 }).then(async res => {
                console.log(res)
				await this.$store.dispatch('setForumStatus', res.config)
			})
        },
		//收藏数据
		collectation:function(type){
			Api.forumLike({ postsId: this.info.id,type:type }).then(res => {
				this.getDetail()
			})
		},
		liuyan(row){
			this.$refs.liuyan.init(row.userId);
		}
    }

}
</script>

<style>
.m-libDetailText img {
    width: unset;
}
</style>

<style lang="scss" scoped>
body {
    font-family: PingFang SC !important;
}
a:hover {
    color: #e73522;
}

.g-leftbox {
    float: none;
}
.w300 {
    width: 300px;
}
.fr {
    float: right;
}
.g-rightbox {
    float: none;
}

.main.pb80 {
	margin-top: 80px;
    padding-bottom: 80px;
}
.ofh {
    overflow: hidden;
}
.g-leftbox.mb20 {
    margin-bottom: 20px;
}
.m-dtTit {
    padding: 0 0 36px;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
	display: flex;
	justify-content: space-between;
}
.m-dtTit .txts .tit {
    line-height: 1.5;
}
.m-dtTit .txts .watch {
    background-image: url(../assets/images/detail/icon1.png);
}
.m-libDetailText {
}
.m-libDetailText .txt {
    margin-bottom: 34px;
}
.m-libDetailText .info {
    color: #2e2e2e;
    font-size: 16px;
    line-height: 2;
    padding: 26px 38px 22px 20px;
    background-color: #f5f5f7;
}
.m-libDetailText .info .tit {
    font-weight: bold;
}
.m-libDetailText .info .desc {
}

.g-pltxtar {
    padding-bottom: 33px;
}
.g-pltxtar .tit {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 19px;
}
.g-pltxtar textarea {
    width: 841px;
    height: 121px;
    background: #fafafc;
    border: 1px solid #e1e1e3;
    border-radius: 3px;
    outline: none;
    padding: 19px;
    box-sizing: border-box;
    resize: none;
    margin-bottom: 16px;
}
.g-pltxtar .sbm {
    text-align: right;
	display: flex;
	justify-content: flex-end;
}
.g-pltxtar .sbm input {
    width: 84px;
    height: 36px;
    background-color: #e73522;
    text-align: center;
    color: #fff;
    border-radius: 3px;
    font-size: 14px;
    line-height: 36px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: 0.5s;
	margin-left: 20px;
}
.g-pltxtar .sbm input:hover {
    box-shadow: 0 0 10px #e66059;
}
.g-pltxtar .tishi{
	width: 841px;
	background: rgba(231, 53, 34, 0.1);
	border: 1px solid #E73522;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 400;
	color: #E73522;
	line-height: 20px;
	box-sizing: border-box;
	padding: 18px;
	margin-top: 20px;
}

.ul-pldetaillist {
    margin-bottom: 40px;
}
.ul-pldetaillist li {
    margin-bottom: 20px;
}
.ul-pldetaillist li:last-of-type {
    margin-bottom: 0;
}
.ul-pldetaillist .con {
    overflow: hidden;
    padding-top: 30px;
    border-top: 1px solid #ddd;
}
.ul-pldetaillist .pic {
    width: 40px;
    height: 40px;
    float: left;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer;
}
.ul-pldetaillist .dtinfo {
    overflow: hidden;
}
.ul-pldetaillist .picinfo {
    padding-top: 2px;
    margin-bottom: 25px;
    cursor: pointer;
}
.ul-pldetaillist .picinfo .name {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 9px;
    font-weight: bold;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.ul-pldetaillist .picinfo .date {
    font-size: 14px;
    color: #989898;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.ul-pldetaillist .picinfo .year {
    margin-right: 10px;
}
.ul-pldetaillist .picinfo .txt {
    font-size: 14px;
    color: #2e2e2e;
    line-height: 2;
}
.ul-pldetaillist .dtinfo .picinfo .txt .t {
    margin-bottom: 15px;
}
.ul-pldetaillist .dtinfo > .txt {
    margin-bottom: 15px;
}
.ul-pldetaillist .dtinfo > .txt .icon {
    text-align: right;
}
.ul-pldetaillist .dtinfo > .txt .icon .back:hover {
    color: #e73522;
}
.ul-pldetaillist .dtinfo > .txt .back {
    display: inline-block;
    padding-left: 25px;
    background: url(../assets/images/detail/icon12.png) no-repeat left 3px;
    background-size: 16px;
    cursor: pointer;
    transition: 0.5s;
}
.top{
	display: inline-block;
	padding-left: 25px;
	cursor: pointer;
	transition: 0.5s;
}
/* .ul-pldetaillist .dtinfo > .txt .backinput{
	display: flex;
	justify-content: space-between;
} */
/* .ul-pldetaillist .dtinfo > .txt .backinput input{
	width: 681px;
	height: 41px;
	border: 1px solid #CCCCCC; 
	border-radius: 3px;
	background: #F5F5F7;
	outline: none;
	padding: 0 20px;
} */
.ul-pldetaillist .dtinfo > .txt .backbtn{
	width: 101px;
	height: 41px;
	background: #E73522;
	border-radius: 3px;
	color: #fff;
	text-align: center;
	line-height: 41px;
	cursor: pointer;
	transition: .5s;
}
.ul-pldetaillist .dtinfo > .txt .backbtn:hover{
	box-shadow:  0 0 10px #E73522;
}
.ul-pldetaillist .resp {
    padding: 25px 25px 21px 20px;
    background-color: #f5f5f7;
    font-size: 14px;
    line-height: 2;
}
.ul-pldetaillist .resp .rsptxt {
    line-height: 2;
    margin-bottom: 10px;
}
.ul-pldetaillist .resp .rsppic {
    width: 24px;
    height: 24px;
    float: left;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 17px;
}
.ul-pldetaillist .resp .rspt {
    overflow: hidden;
    cursor: pointer;
}
.ul-pldetaillist .resp .rspname {
    margin-right: 37px;
    float: left;
}
.ul-pldetaillist .resp .rspdate {
    float: left;
}
.ul-pldetaillist .resp .rspdate span {
    margin-right: 10px;
    color: #009990;
}

.m-pingluncon .more {
    text-align: center;
    font-size: 14px;
    color: #e83421;
}
.m-pingluncon .more span {
    cursor: pointer;
}

.m-tuijiantxt .tit {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
}
.m-tuijiantxt li {
    margin-bottom: 20px;
}
.m-tuijiantxt .con a {
    font-size: 14px;
}

.g-rightbox.plr20 {
    padding: 25px 20px;
}
.g-rightbox.adminfo {
    padding: 0 20px;
    position: relative;
    margin-bottom: 20px;
}
.g-rightbox.adminfo::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #e83421;
}
.m-subadminfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    box-sizing: border-box;
}
.m-subadminfo .pic {
    width: 30px;
    height: 30px;
    overflow: hidden;
}
.m-subadminfo .tit {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}
.m-subadminfo .btn {
    font-size: 2px;
    color: #e73522;
    /* width: 55px; */
    padding: 0 10px;
    height: 25px;
    box-sizing: border-box;
    border: 1px solid #e83421;
    border-radius: 3px;
    text-align: center;
    line-height: 25px;
    cursor: pointer;
    transition: 0.5s;
    white-space: nowrap;
	display: flex;
	align-items: center;
}
.m-subadminfo .btn::before {
    content: "+";
    margin-right: 2px;
}
.m-subadminfo .btn:hover {
    background-color: #e83421;
    color: #fff;
}
/* subnav */
.m-subNav {
    padding-top: 18px;
    margin-bottom: 18px;
}

.m-subNav a {
    font-size: 14px;
    color: #e83421;
    margin-right: 34px;
    position: relative;
}
.m-subNav .t:hover {
    color: #e83421;
}

.m-subNav a::after {
    content: "";
    position: absolute;
    right: -14px;
    top: 50%;
    transform: translate(100%, -50%);
    background: url(../assets/images/detail/icon3.png) no-repeat;
    background-size: 100%;
    width: 14px;
    height: 14px;
}
.m-subNav a:first-of-type {
    color: #999;
}
.m-subNav a:last-of-type::after {
    display: none;
}

.m-subNav a:last-of-type {
    color: #999;
}
.action{
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.action-item{
	width: 40px;
	height: 40px;
	background: #FFFFFF;
	border-radius: 50%;
	position: relative;
	text-align: center;
	margin-bottom: 20px;
	line-height: 40px;
	cursor: pointer;
}
.action-active{
	width: 40px;
	height: 40px;
	background: #E73522;
}
.action-item img{
	width: 24px;
	height: 24px;
}
.action-item div{
	width: 40px;
	height: 15px;
	background: #E8E8EB;
	border-radius: 8px;
	font-size: 12px;
	font-weight: 400;
	color: #999999;
	line-height: 15px;
	text-align: center;
	position: absolute;
	bottom: -8px;
}
.manage{
	height: 36px;
	margin-top: 20px;
	text-align: right;
}
.manage div{
	padding: 0px 20px;
	margin: 0px 10px;
	display: inline-block;
	background: rgba(231, 53, 34, 0.2);
	border: 1px solid #E73522;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 400;
	color: #E73522;
	line-height: 36px;
	cursor: pointer;
}
.m-quillEditor-m1 {
	padding: 0px;
	box-sizing: border-box;
	width: 100%;
	height: 220px;
	border-radius: 3px;
	margin-bottom: 0px;
}
.m-quillEditor-m1 .tits {
	width: 100%;
	height: 60px;
	border: none;
	outline: none;
	font-size: 24px;
	font-family: PingFang SC;
	background-color: #fff;
	font-weight: 500;
	/* color: #989898; */
	color: #2e2e2e;
	margin-bottom: 15px;
}
.m-quillEditor-m1 .tits::-webkit-input-placeholder {
	color: #989898;
}
.m-quillEditor-m1 .tits:-moz-placeholder {
	color: #989898;
}
.m-quillEditor-m1 .tits::-moz-placeholder {
	color: #989898;
}
.m-quillEditor-m1 .tits::-ms-input-placeholder {
	color: #989898;
}
.m-quillEditor-m1 .quill-editor {
	height: 100px;
	padding: 0px 10px;
}
.m-quillEditor-m1 .ql-editor p {
	color: #2e2e2e;
}
.m-quillEditor-m1 .ql-toolbar.ql-snow {
	border-left: none;
	border-right: none;
}

.m-quillEditor-m1 .ql-container.ql-snow {
	border: none;
}

 .m-quillEditor-m1 ::v-deep .ql-editor {
	font-size: 14px !important;
	font-family: PingFang SC;
	font-weight: 400;
	color: #989898;
	line-height: 20px;
	padding: 10px 0px !important;
}
.m-quillEditor-m1 .ql-editor::before {
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #989898;
	line-height: 24px;
	left: 0;
	font-style: unset;
}
.upload-text{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-right: 10px;
}
.upload-text div{
	height: 36px;
	background: rgba(231, 53, 34, 0.2);
	border: 1px solid #E73522;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 400;
	color: #333333;
	line-height: 36px;
	padding: 0px 10px;
	width: 400px;
	overflow: hidden;
	margin: 5px 0px;
}
.upload-text img{
	width: 15px;
	height: 15px;
}
.tit{
	font-size: 18px;
	font-weight: 500;
	color: #111111;
	margin-bottom: 19px;
}
.action-list{
	height: 60px;
	border-bottom: 1px solid #DDDDDD;
	line-height: 60px;
}
.action-list-info{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.action-list-info>div{
	margin-right: 70px;
	font-size: 16px;
	font-weight: 400;
	color: #000000;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.action-list-info>div img{
	margin-right: 10px;
	width: 20px;
	height: 20px;
}
.file-list{
	width: 100%;
	height: 36px;
	background: rgba(231, 53, 34, 0.1);
	border: 1px solid #E73522;
	border-radius: 3px;
	padding: 0px 18px;
	font-size: 14px;
	font-weight: 400;
	color: #333333;
	line-height: 36px;
	display: block;
	margin-bottom: 20px;
}
.text-replay{
	width: 100%;
	background: #F5F5F7;
	margin-bottom: 10px;
	padding: 15px 41px;
	box-sizing: border-box;
	position: relative;
}
.text-replay div:first-of-type{
	font-size: 14px;
	font-weight: 400;
	color: #666666;
}
.text-replay div:last-of-type{
	font-size: 14px;
	font-weight: 400;
	color: #2E2E2E;
	line-height: 24px;
}
.text-replay .shang{
	position: absolute;
	top: 10px;
	left: 15px;
	width: 22px;
	height: 22px;
}
.text-replay .xia{
	position: absolute;
	bottom: 10px;
	right: 15px;
	width: 22px;
	height: 22px;
	-ms-transform:rotate(180deg); /* IE 9 */
	-webkit-transform:rotate(180deg); /* Safari and Chrome */
	transform:rotate(180deg);
}
.tit-icon-one{
	width: 40px;
	height: 22px;
	background: #E73522;
	border-radius: 3px;
	text-align: center;
	line-height: 22px;
	font-size: 14px;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 22px;
	display: inline-block;
	margin-left: 10px;
}

@media only screen and (max-width: 750px) {
	.main.pb80{
		margin-top: 1.4rem;
		padding-bottom: .8rem;
	}
	.m-subNav{
		padding-top: .2rem;
		margin-bottom: .2rem;
		a{
			display: inline-block;
			vertical-align: middle;
			max-width: 2rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-right: 0;
			padding-right: 16px;
			font-size: .24rem;
			&::after{
				right: 1px;
				transform: translate(0,-35%);
			}
			&:last-child{
				padding-right: 0;
			}
		}
	}
	#posfx{
		&.isfixed{
			position: static;
		}
		float: none;
		width: 100%;
		.g-rightbox{
			width: 100%;
			&.adminfo{
				padding: 0 .3rem;
				margin-bottom: .2rem;
			}
			&.plr20{
				display: none;
				padding: .3rem;
			}
		}
		.m-subadminfo{
			padding: .3rem 0;
			height: auto;
			min-height: .8rem;
			.pic{
				flex-shrink: 0;
				width: .6rem;
				height: .6rem;
			}
			.tit{
				flex: auto;
				font-size: .32rem;
				margin: 0 .2rem;
				margin-bottom: 0;
				overflow: hidden;
			}
			.btn{
				flex-shrink: 0;
				height: .48rem;
				font-size: .24rem;
				line-height: .48rem;
				padding: 0 .2rem;
			}
		}
	}
	.g-leftbox{
		width: 100%;
		padding: .3rem;
		&.mb20{
			margin-bottom: .2rem;
		}
		.m-dtTit{
			display: block;
			padding: 0 0 .3rem;
			margin-bottom: .2rem;
			.txts{
				.tit{
					font-size: .4rem;
					line-height: 1.4;
					margin-bottom: .2rem;
				}
				.info{
					font-size: .28rem;
					line-height: 1.2;
				}
				.date{
					margin-right: .3rem;
				}
			}
			.action{
				justify-content: flex-start;
				margin: .1rem 0;
				.action-item{
					width: .7rem;
					height: .7rem;
					line-height: .5rem;
					margin-bottom: 0;
					img{
						display: block;
						width: .4rem;
						height: .4rem;
						margin: .1rem auto;
					}
					div{
						width: 100%;
						height: auto;
						font-size: .24rem;
						line-height: 1.2;
						padding: 0 .2rem;
						border-radius: .2rem;
						bottom: -.16rem;
					}
				}
			}
		}
		.m-libDetailText{
			.txt{
				font-size: .28rem;
				margin-bottom: .3rem;
			}
			.file-list{
				height: auto;
				font-size: .28rem;
				line-height: 2;
				padding: 0 .3rem;
				margin-bottom: .3rem;
			}
			.info{
				font-size: .28rem;
				padding: .3rem;
				.tit{
					font-size: .32rem;
					margin-bottom: .1rem;
				}
			}
		}
		.m-pingluncon{
			.tit{
				font-size: .28rem;
					margin-bottom: .2rem;
			}
			.ul-pldetaillist{
				margin-bottom: .4rem;
				.con{
					padding-top: .3rem;
				}
				.pic{
					width: .8rem;
					height: .8rem;
					margin-right: .2rem;
				}
				.picinfo{
					margin-bottom: .2rem;
					.name{
						font-size: .28rem;
						margin-bottom: .1rem;
					}
					.date{
						font-size: .24rem;
					}
				}				
				.txt{
					font-size: .24rem;
					line-height: 1.8;
					margin-bottom: .2rem;
					.back{
						padding-left: .4rem;
						background-size: .3rem auto;
						background-position: left center;
					}
				}
			}
			.g-pltxtar{
				padding-bottom: .3rem;
				.tit{
					font-size: .28rem;
					margin-bottom: .2rem;
				}
				.sbm{
					input,
					.upload{
						width: 1.6rem;
						height: .6rem;
						font-size: .24rem;
						line-height: .6rem;
					}
					input{
						margin-left: .2rem;
					}
				}
				.tishi{
					width: 100%;
					font-size: .24rem;
					line-height: 1.8;
					margin-top: .2rem;
				}
				
			}
		}
	}
}
.name-liuyan{
	width: 80px;
	height: 22px;
	background: #E8E8EB;
	border-radius: 11px;
	font-size: 14px;
	font-weight: 400;
	color: #E73522;
	line-height: 22px;
	text-align: center;
	margin-left: 17px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.name-liuyan img{
	width: 14px;
	height: 14px;
	margin-right: 6px;
}

</style>