<template>
    <div class="partner">
        <Header />
            <MyBanners :navIndex="step+2"></MyBanners>
		<div style="height: 30px;"></div>
        <div class="parSortBg">
            <div class="wp w1200" style="background-color: #fff;">
				<div class="comany-title">公司信息维护</div>
                <ul class="parSort">
                    <li @click="step =1" :class="step==1?'active':''"><span>公司信息录入</span></li>
                    <li @click="step =2" :class="step==2?'active':''"><span>公司签约授权</span></li>
                </ul>
            </div>
			<div class="wp w1200" v-if="step == 1" style="background-color: #fff;">
				<div class="m-regform">
				<el-form ref="registerForm" :model="form" class="boxform">
					
					
					<div class="company_info">
						<div class="item" style="width: 100%;">
						    <div class="con">
						        <div class="label">公司名称</div>
						        <el-form-item class="libipt noborder" prop="companyName">
						            <!-- <input type="text" v-model="form.companyname" name="" placeholder="请输入公司名称" /> -->
						            <el-input :readonly="true"
						                v-model="form.companyName"
						                type="text"
						                autocomplete="off"
						                placeholder="请输入公司名称"
						            ></el-input>
						        </el-form-item>
								
								<div class="edit" @click="getcompanytext"><i class="el-icon-edit"></i>编辑</div>
						    </div>
						</div>
						<div class="item">
						    <div class="con right">
						        <div class="label">法定代表人</div>
						        <el-form-item class="libipt black" prop="legalRepresentative">
									<el-input  :readonly="editype=='legalRepresentative'?false:true" placeholder="请输入法定代表人" v-model="form.legalRepresentative"></el-input>
						        </el-form-item>
								
								<div v-if="editype=='legalRepresentative'" class="edit" style="display: block;" @click="saveinfo"><i class="el-icon-edit"></i>保存</div>
								<div v-else class="edit" @click="editype='legalRepresentative'"><i class="el-icon-edit"></i>编辑</div>
						    </div>
						</div>
						<div class="item">
						    <div class="con left">
						        <div class="label">注册资本</div>
						        <el-form-item class="libipt black" prop="registeredCapital">
									<el-input :readonly="editype=='registeredCapital'?false:true" placeholder="请输入注册资本" v-model="form.registeredCapital"></el-input>
						        </el-form-item>
								<div v-if="editype=='registeredCapital'" class="edit" style="display: block;" @click="saveinfo"><i class="el-icon-edit"></i>保存</div>
								<div v-else class="edit" @click="editype='registeredCapital'"><i class="el-icon-edit"></i>编辑</div>
						    </div>
						</div>
						<div class="item">
						    <div class="con right">
						        <div class="label">成立日期</div>
								
								<el-form-item class="libipt black">
									<el-date-picker
									      type="date" v-model="form.establishmentDate"
										  value-format="yyyy-MM-dd"
									      placeholder="选择成立日期" prefix-icon="" :readonly="editype=='establishmentDate'?false:true">
									    </el-date-picker>
								</el-form-item>
								<div v-if="editype=='establishmentDate'" class="edit" style="display: block;" @click="saveinfo"><i class="el-icon-edit"></i>保存</div>
								<div v-else class="edit" @click="editype='establishmentDate'"><i class="el-icon-edit"></i>编辑</div>
						    </div>
						</div>
						<div class="item">
						    <div class="con left">
						        <div class="label">营业期限</div>
								
								
								<el-form-item class="libipt black morediv">
									<div class="between">
										<el-input :readonly="editype=='businessStartDate'?false:true" placeholder="请输入开始日期" v-model="form.businessStartDate"></el-input>
									</div>
									<div>至</div>
									<div class="between">
										<el-input :readonly="editype=='businessStartDate'?false:true" placeholder="请输入结束日期" v-model="form.businessEndDate"></el-input>
									</div>
								</el-form-item>
								<div v-if="editype=='businessStartDate'" class="edit" style="display: block;" @click="saveinfo"><i class="el-icon-edit"></i>保存</div>
								<div v-else class="edit" @click="editype='businessStartDate'"><i class="el-icon-edit"></i>编辑</div>
						    </div>
						</div>
						<div class="item" style="width: 100%;">
						    <div class="con">
						        <div class="label">注册地址</div>
								
						        <el-form-item class="libipt black" prop="registeredAddress">
									<el-input  :readonly="!editstatus" placeholder="请输入注册地址" v-model="form.registeredAddress"></el-input>
						        </el-form-item>
								
								<div v-if="editstatus" class="edit" style="display: block;" @click="saveaddress"><i class="el-icon-edit"></i>保存</div>
								<div v-else class="edit" @click="editstatus=true"><i class="el-icon-edit"></i>编辑</div>
								
							</div>
						</div>
						
						<div class="item" style="width: 100%;">
						    <div class="con">
						        <div class="label">经营范围</div>
								
						        <el-form-item class="libipt black noborder" prop="businessScope">
									<el-input  :readonly="editype=='businessScope'?false:true" type="textarea" resize="none" :maxlength="300" :show-word-limit="true" :rows="6" placeholder="请输入经营范围" v-model="form.businessScope"></el-input>
						        </el-form-item>
								<div v-if="editype=='businessScope'" class="edit" style="display: block;" @click="saveinfo"><i class="el-icon-edit"></i>保存</div>
								<div v-else class="edit" @click="editype='businessScope'"><i class="el-icon-edit"></i>编辑</div>
						    </div>
						</div>
						
						<div class="item" style="width: 100%;">
						    <div class="con">
						        <div class="label">网址</div>
								
						        <el-form-item class="libipt black" prop="companyUrl">
									<el-input  :readonly="editype=='companyUrl'?false:true" placeholder="请输入注册地址" v-model="form.companyUrl"></el-input>
						        </el-form-item>
								<div v-if="editype=='companyUrl'" class="edit" style="display: block;" @click="saveinfo"><i class="el-icon-edit"></i>保存</div>
								<div v-else class="edit" @click="editype='companyUrl'"><i class="el-icon-edit"></i>编辑</div>
						    </div>
						</div>
					</div>
					
					<div class="form-info">
						<div class="item">
						    <div class="con right">
						        <div class="label">办公地址</div>
								
						        <el-form-item class="libipt" prop="workAddress">
									<el-input :readonly="editype=='workAddress'?false:true" placeholder="请输入办公地址" v-model="form.workAddress"></el-input>
						        </el-form-item>
								<div v-if="editype=='workAddress'" class="edit" style="display: block;" @click="saveinfo"><i class="el-icon-edit"></i>保存</div>
								<div v-else class="edit" @click="editype='workAddress'"><i class="el-icon-edit"></i>编辑</div>
						    </div>
						</div>
						<div class="item">
						    <div class="con left">
						        <div class="label">总人数</div>
								
						        <el-form-item class="libipt" prop="allPeopleNumber">
									<el-input :readonly="editype=='allPeopleNumber'?false:true" placeholder="请输入总人数" v-model="form.allPeopleNumber"></el-input>
						        </el-form-item>
								<div v-if="editype=='allPeopleNumber'" class="edit" style="display: block;" @click="saveinfo"><i class="el-icon-edit"></i>保存</div>
								<div v-else class="edit" @click="editype='allPeopleNumber'"><i class="el-icon-edit"></i>编辑</div>
						    </div>
						</div>
						<div class="item">
						    <div class="con right">
						        <div class="label">联系人姓名</div>
								
						        <el-form-item class="libipt" prop="linkName">
									<el-input :readonly="editype=='linkName'?false:true" placeholder="请输入联系人姓名" v-model="form.linkName"></el-input>
						        </el-form-item>
								<div v-if="editype=='linkName'" class="edit" style="display: block;" @click="saveinfo"><i class="el-icon-edit"></i>保存</div>
								<div v-else class="edit" @click="editype='linkName'"><i class="el-icon-edit"></i>编辑</div>
						    </div>
						</div>
						<div class="item">
						    <div class="con left">
						        <div class="label">联系人职务</div>
								
						        <el-form-item class="libipt" prop="linkJob">
									<el-input :readonly="editype=='linkJob'?false:true" placeholder="请输入联系人职务" v-model="form.linkJob"></el-input>
						        </el-form-item>
								<div v-if="editype=='linkJob'" class="edit" style="display: block;" @click="saveinfo"><i class="el-icon-edit"></i>保存</div>
								<div v-else class="edit" @click="editype='linkJob'"><i class="el-icon-edit"></i>编辑</div>
						    </div>
						</div>
						<div class="item" style="width: 100%;">
						    <div class="con">
						        <div class="label">联系人电话</div>
						        <el-form-item class="libipt" prop="linkPhone">
						            <el-input
						                v-model="form.linkPhone" :readonly="true"
						                type="number"
						                placeholder="请输入联系人电话"
						            ></el-input>
						        </el-form-item>
								<div class="update" @click="open7">修改</div>
						        <!-- </div> -->
						    </div>
						</div>
						<div class="item" style="width: 100%;">
						    <div class="con">
						        <div class="label">联系人邮箱</div>
						        <el-form-item class="libipt" prop="linkEmail">
						            <el-input
						                v-model="form.linkEmail" :readonly="true"
						                type="text"
						                placeholder="请输入联系人邮箱"
						            ></el-input>
						        </el-form-item>
								<div class="update" @click="open5">修改</div>
						        <!-- </div> -->
						    </div>
						</div>
						<div class="item" style="width: 100%;">
						    <div class="con">
						        <div class="label">帐号密码</div>
						        <el-form-item class="libipt" prop="linkEmail">
						            <el-input
						                value="******" :readonly="true"
						                type="text"
						                placeholder="请输入帐号密码"
						            ></el-input>
						        </el-form-item>
								<div class="update" @click="open1">修改</div>
						        <!-- </div> -->
						    </div>
						</div>
						<div class="item">
							<div class="upload-item" @click="getcompanytext">
								<template v-if="form.licenseFile">
									<img class="no-upload" v-if="form.licenseFile.indexOf('.pdf') > -1" src="@/assets/images/file_pdf.png">
									<img class="no-upload" v-else :src="form.licenseFile">
								</template>
								
								<div class="upload-desc">
									<div>上传营业执照*</div>
									<div>支持jpg png pdf格式</div>
								</div>
							</div>
						</div>
					</div>
				</el-form>
				</div>
			</div>
			<div class="book wp w1200" v-if="step == 2">
				<template v-if="cer!=null && cer.status != -1">
					<div v-if="cer!=null" class="book_title">预览</div>
					<img v-if="cer!=null && cer.certificateFile.indexOf('.pdf') > -1" src="@/assets/images/file_pdf.png">
					<img v-else-if="cer!=null" :src="cer.certificateFile">
					<div v-if="cer!=null && cer.status == 0" class="book-status">审核通过</div>
					<div v-if="cer!=null && cer.status == 1" class="book-status">已申请</div>
				</template>
				
				<div v-else class="wp w1200">
				    <div class="empower">
				        <div class="empowerCon">
				            <div class="empowerForm">
				                <div @click="download" class="empowerItem">
				                    <!-- <div class="empowerImg">
				                        <i class="empowerIcon empowerDown"></i>
				                    </div> -->
				                    <div class="empowerTips" style="font-size:16px;font-weight: bold;text-decoration: underline;">
				                        <p style="color:rgb(0,177,255);">点击此处下载授权书模板（第2页盖章，并加骑缝章）</p>
				                        <!-- <p>加盖公章后上传</p> -->
				                    </div>
				                </div>
				                <div class="empowerItem">
				                    <div class="empowerImg">
				                        <!-- <img src="../assets/images/partner/sample.png" alt="样例"> -->
				                        <i class="empowerIcon empowerUpload"></i>
				                        <el-upload
				                            class="upload"
				                            action=""
				                            list-type="picture"
				                            :show-file-list="false"
				                            :on-success="handleSuccess"
											:http-request="uploadPic"
				                            :before-upload="beforeUpload">
											<template v-if="certificateFile">
												<img v-if="type=='application/pdf'" src="@/assets/images/file_pdf.png" class="book">
												<img v-else :src="certificateFile" class="book">
											</template>
				                            
				                            <i v-else class="empowerIcon empowerUpload"></i>
				                        </el-upload>
				                    </div>
				                    <div class="empowerTips">
				                        <p>上传授权书</p>
				                        <p>支持jpg png pdf格式</p>
				                    </div>
				                </div>
				            </div>
				            <div class="sample">
				                <h4 class="sampleTit">样例</h4>
				                <div class="sampleImg">
				                    <img src="../assets/images/partner/sample.png" alt="样例">
				                </div>
				            </div>
				        </div>
				        <div class="empowerBtn">
				            <span class="submit" @click="submitinfo">提交审核</span>
				        </div>
				    </div>
				    
				</div>
			</div>
        </div>
        
		
		<div style="height: 100px;"></div>
		
		<!-- 弹窗-更换邮箱-验证 -->
		<div class="m-popup-m1" v-show="showNum == 5">
		    <div class="popup">
		        <div class="tit">
		            <span class="tits">更换邮箱地址</span>
		            <span class="exit" @click="close5"></span>
		        </div>
		        <div class="desc">
		            <div class="desc1">您正在修改账户关联邮箱地址，为了您的账户安全请输入现使用邮箱地址进行验证</div>
		        </div>
		        <div class="content">
		            <div class="m-update-form-m1">
		                <div class="item">
		                    <span class="label">原邮箱</span>
		                    <el-input
		                        readonly
		                        v-model="changeEmailForm.old_email"
		                        type="email"
		                        placeholder="请输入邮箱地址"
		                    />
		                    <!-- <input type="email" v-model="changeEmailForm.old_email" placeholder="请输入邮箱地址" /> -->
		                </div>
		                <div class="item item-qrcode">
		                    <span class="label">验证码</span>
		                    <el-input
		                        v-model="changeEmailForm.old_emailcode"
		                        type="text"
		                        placeholder="请输入验证码"
		                    />
		                    <!-- <input type="text" v-model="changeEmailForm.old_emailcode" placeholder="请输入验证码" /> -->
		                    <span
		                        class="btn-qrcode"
		                        v-if="second == 0"
		                        @click="sendEmail('changeEmailForm', 'old_email')"
		                    >发送验证码</span>
		                    <span class="btn-qrcode-dis" v-else>{{ second }}秒后重新发送</span>
		                </div>
		            </div>
		        </div>
		        <div class="btns">
		            <div class="g-btns-m2">
		                <button type="button" class="btn" @click="close5">取消</button>
		                <button type="button" class="btn" @click="validateOldEmail">确定</button>
		            </div>
		        </div>
		    </div>
		</div>
		<!-- 弹窗-更换邮箱-更换地址 -->
		<div class="m-popup-m1" v-show="showNum == 6">
		    <div class="popup">
		        <div class="tit">
		            <span class="tits">更换邮箱地址</span>
		            <span class="exit" @click="showNum = 0"></span>
		        </div>
		        <div class="desc">
		            <div class="desc1">您正在修改账户关联邮箱地址，为了您的账户安全请输入新邮箱地址完成更换</div>
		        </div>
		        <div class="content">
		            <div class="m-update-form-m1">
		                <div class="item">
		                    <span class="label">新邮箱</span>
		                    <el-input
		                        v-model="changeEmailForm.new_email"
		                        type="email"
		                        placeholder="请输入邮箱地址"
		                    />
		                    <!-- <input type="email" v-model="changeEmailForm.new_email" placeholder="请输入邮箱地址" /> -->
		                </div>
		                <div class="item item-qrcode">
		                    <span class="label">验证码</span>
		                    <!-- <input type="text" v-model="changeEmailForm.new_emailcode" placeholder="请输入验证码" /> -->
							<el-input
								v-model="changeEmailForm.new_emailcode"
								type="text"
								placeholder="请输入验证码"
							/>
		                    <span
		                        class="btn-qrcode"
		                        v-if="second == 0"
		                        @click="sendEmail('changeEmailForm', 'new_email')"
		                    >发送验证码</span>
		                    <span class="btn-qrcode-dis" v-else>{{ second }}秒后重新发送</span>
		                </div>
		            </div>
		        </div>
		        <div class="btns">
		            <div class="g-btns-m2">
		                <button type="button" class="btn">取消</button>
		                <button type="button" class="btn on" @click="submitEmail">确定</button>
		            </div>
		        </div>
		    </div>
		</div>
		<!-- 弹窗-更换手机号-验证 -->
		<div class="m-popup-m1" v-show="showNum == 7">
		    <div class="popup">
		        <div class="tit">
		            <span class="tits">更换手机号码</span>
		            <span class="exit" @click="close7"></span>
		        </div>
		        <div class="desc">
		            <div class="desc1">您正在修改账户关联手机号码，为了您的账户安全请输入现使用手机号码进行验证</div>
		        </div>
		        <div class="content">
		            <div class="m-update-form-m1">
		                <div class="item">
		                    <span class="label">原手机号</span>
		                    <el-input
		                        v-model="changeMobileForm.old_mobile"
		                        type="text"
		                        placeholder="请输入手机号码"
		                    />
		                    <!-- <input
		                        type="text"
		                        placeholder="请输入手机号码"
		                        v-model="changeMobileForm.old_mobile"
		                        name
		                    />-->
		                </div>
		                <div class="item item-qrcode">
		                    <span class="label">验证码</span>
		                    <el-input
		                        v-model="changeMobileForm.old_smscode"
		                        type="text"
		                        placeholder="请输入验证码"
		                    />
		                    <!-- <input
		                        type="text"
		                        placeholder="请输入验证码"
		                        name
		                        v-model="changeMobileForm.old_smscode"
		                    />-->
		                    <span
		                        class="btn-qrcode"
		                        v-if="second == 0"
		                        @click="sendSms('changeMobileForm', 'old_mobile')"
		                    >发送验证码</span>
		                    <span class="btn-qrcode-dis" v-else>{{ second }}秒后重新发送</span>
		                </div>
		            </div>
		        </div>
		        <div class="btns">
		            <div class="g-btns-m2">
		                <button type="button" class="btn" @click="close7">取消</button>
		                <button type="button" class="btn on" @click="validateOldMobile">确定</button>
		            </div>
		        </div>
		    </div>
		</div>
		<!-- 弹窗-更换手机号-更换地址 -->
		<div class="m-popup-m1" v-show="showNum == 8">
		    <div class="popup">
		        <div class="tit">
		            <span class="tits">更换手机号码</span>
		            <span class="exit" @click="showNum = 0"></span>
		        </div>
		        <div class="desc">
		            <div class="desc1">您正在修改账户关联手机号码，为了您的账户安全请输入新用手机号码完成更换</div>
		        </div>
		        <div class="content">
		            <div class="m-update-form-m1">
		                <div class="item">
		                    <span class="label">新手机号</span>
		                    <el-input
		                        v-model="changeMobileForm.new_mobile"
		                        type="text"
		                        placeholder="请输入手机号码"
		                    />
		                    <!-- <input type="text" v-model="changeMobileForm.new_mobile" placeholder="请输入手机号码" name /> -->
		                </div>
		                <div class="item item-qrcode">
		                    <span class="label">验证码</span>
		                    <el-input
		                        v-model="changeMobileForm.new_smscode"
		                        type="text"
		                        placeholder="请输入验证码"
		                    />
		                    <!-- <input type="text" v-model="changeMobileForm.new_smscode" placeholder="请输入验证码" name /> -->
		                    <span
		                        class="btn-qrcode"
		                        v-if="second == 0"
		                        @click="sendSms('changeMobileForm', 'new_mobile')"
		                    >发送验证码</span>
		                    <span class="btn-qrcode-dis" v-else>{{ second }}秒后重新发送</span>
		                </div>
		            </div>
		        </div>
		        <div class="btns">
		            <div class="g-btns-m2">
		                <button type="button" class="btn on" @click="submitMobile">确定</button>
		            </div>
		        </div>
		    </div>
		</div>
		<!-- 弹窗-企业的账号找回 -->
		<div class="m-popup-m1" v-show="showNum == 9">
			<div class="popup" style="width: 350px;height: auto;">
				<div class="tit">
					<span class="tits">联系管理员</span>
					<span class="exit" @click="showNum = 0"></span>
				</div>
				<div class="company-content" v-html="companytext">
				</div>
				<div class="company-button" @click="showNum = 0">知道了</div>
			</div>
		</div>
		
		<!-- 弹窗-选择账户验证方式 -->
		<div class="m-popup-m1" v-show="showNum == 1">
		    <div class="popup">
		        <div class="tit">
		            <span class="tits">修改密码</span>
		            <span class="exit" @click="showNum = 0"></span>
		        </div>
		        <div class="desc">
		            <div class="desc1">您正在修改账户密码，请选择账户验证方式</div>
		        </div>
		        <div class="content">
		            <div class="g-btns-m1">
		                <button type="button" class="btn" @click="showNum = 2;changeType = 1;">手机验证</button>
		                <button type="button" class="btn" @click="showNum = 3;changeType = 2;">邮箱验证</button>
		            </div>
		        </div>
		        <div class="btns">
		            <div class="g-btns-m2">
		                <button type="button" class="btn" @click="showNum = 0">取消</button>
		            </div>
		        </div>
		    </div>
		</div>
		<!-- 弹窗-手机验证 -->
		<div class="m-popup-m1" v-show="showNum == 2">
		    <div class="popup">
		        <div class="tit">
		            <span class="tits">修改密码</span>
		            <span class="exit" @click="close1"></span>
		        </div>
		        <div class="desc">
		            <div class="desc1">您正在修改账户密码，请先验证手机号码</div>
		        </div>
		        <div class="content">
		            <div class="m-update-form-m1">
		                <div class="item">
		                    <span class="label">手机号码</span>
		                    <!-- <input type="text" v-model="changePassForm.mobile" placeholder="请输入手机号码" /> -->
		                    <el-input
		                        v-model="changePassForm.mobile"
		                        type="text"
		                        placeholder="请输入手机号码"
		                    />
		                </div>
		                <div class="item item-qrcode">
		                    <span class="label">验证码</span>
		                    <el-input
		                        v-model="changePassForm.smscode"
		                        type="text"
		                        placeholder="请输入验证码"
		                    />
		                    <!-- <input type="text" v-model="changePassForm.smscode" placeholder="请输入验证码" /> -->
		                    <span
		                        class="btn-qrcode"
		                        v-if="second == 0"
		                        @click="sendSms('changePassForm', 'mobile')"
		                    >发送验证码</span>
		                    <span class="btn-qrcode-dis" v-else>{{ second }}秒后重新发送</span>
		                </div>
		            </div>
		        </div>
		        <div class="btns">
		            <div class="g-btns-m2">
		                <button type="button" class="btn on" @click="validatePass('mobile')">确定</button>
		            </div>
		        </div>
		    </div>
		</div>
		<!-- 弹窗-邮箱验证 -->
		<div class="m-popup-m1" v-show="showNum == 3">
		    <div class="popup">
		        <div class="tit">
		            <span class="tits">修改密码</span>
		            <span class="exit" @click="close1"></span>
		        </div>
		        <div class="desc">
		            <div class="desc1">您正在修改账户密码，请先验证邮箱</div>
		        </div>
		        <div class="content">
		            <div class="m-update-form-m1">
		                <div class="item">
		                    <span class="label">邮箱地址</span>
		                    <el-input
		                        v-model="changePassForm.email"
		                        type="email"
		                        placeholder="请输入邮箱地址"
		                    />
		                    <!-- <input type="email" v-model="changePassForm.email" placeholder="请输入邮箱地址" name value /> -->
		                </div>
		                <div class="item item-qrcode">
		                    <span class="label">验证码</span>
		                    <el-input
		                        v-model="changePassForm.emailcode"
		                        type="text"
		                        placeholder="请输入验证码"
		                    />
		                    <!-- <input type="text" v-model="changePassForm.emailcode" placeholder="请输入验证码" name value /> -->
		                    <span
		                        class="btn-qrcode"
		                        v-if="second == 0"
		                        @click="sendEmail('changePassForm', 'email')"
		                    >发送验证码</span>
		                    <span class="btn-qrcode-dis" v-else>{{ second }}秒后重新发送</span>
		                </div>
		            </div>
		        </div>
		        <div class="btns">
		            <div class="g-btns-m2">
		                <button type="button" class="btn on" @click="validatePass('email')">确定</button>
		            </div>
		        </div>
		    </div>
		</div>
		<!-- 弹窗-请输入新的密码 -->
		<div class="m-popup-m1" v-show="showNum == 4">
		    <div class="popup">
		        <div class="tit">
		            <span class="tits">请输入新的密码</span>
		            <span class="exit" @click="showNum = 0"></span>
		        </div>
		        <div class="desc">
		            <div class="desc1">请输入新密码</div>
		        </div>
		        <div class="content">
		            <div class="m-update-form-m1">
		                <div class="item">
		                    <span class="label">新密码</span>
		                    <el-input
		                        v-model="changePassForm.password"
		                        type="password"
		                        placeholder="请输入新密码"
		                    />
		                    <!-- <input type="password" v-model="changePassForm.password" placeholder="请输入新密码" /> -->
		                </div>
		                <div class="item">
		                    <span class="label">重复密码</span>
		                    <el-input
		                        v-model="changePassForm.repass"
		                        type="password"
		                        placeholder="请重复输入密码"
		                    />
		                    <!-- <input type="password" v-model="changePassForm.repass" placeholder="请重复输入密码" /> -->
		                </div>
		            </div>
		        </div>
		        <div class="btns">
		            <div class="g-btns-m2">
		                <button type="button" class="btn on" @click="submitPass">确定</button>
		            </div>
		        </div>
		    </div>
		</div>
        <Footer />
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import MyBanners from '@/components/MyBanners.vue';
import Api from '@/request/api.js';
export default {
    name: 'PartnerEmpower',
    components: { 
        Header,
        Footer,
		MyBanners
    },
    data() {
        return {
            
            form: {
            	companyName:"",//公司名称
                legalRepresentative: "",//法定代表人
                registeredCapital: "",//注册资本
                establishmentDate: "",//成立日期
                businessStartDate: "",//营业期限 -开始时间
                businessEndDate: "",//营业期限 -结束时间
                registeredAddress: "",//注册地址
                businessScope: "",//经营范围
                companyUrl: "",//公司网址
                workAddress: "",//办公地址
                allPeopleNumber: "",//总人数
                linkName: "",//联系人姓名
                linkJob: "",//联系人职务
                linkPhone: "",//联系人电话
                phoneCode: "",//短信验证码
                linkEmail: "",//联系人邮箱
                emailCode: "",//邮箱验证码
                licenseFile: "",//营业执照（图片或pdf文件）
                companyCode: "",//公司编码
                password: ""//密码
            },
			step:1,
			cer:null,
			showNum: 0,
			second: 0,
			timer: null,
			changeMobileForm: {
			    old_mobile: this.$store.state.user.telephone,
			    old_smscode: '',
			    new_mobile: '',
			    new_smscode: '',
			    randomNumbers: ''
			},
			changeEmailForm: {
			    old_email: this.$store.state.user.email,
			    old_emailcode: '',
			    new_email: '',
			    new_emailcode: '',
			    randomNumbers: ''
			},
			changePassForm: {
			    mobile: this.$store.state.user.telephone,
			    smscode: '',
			    email: this.$store.state.user.email,
			    emailcode: '',
			    password: '',
			    repass: '',
			    randomNumbers: ''
			},
			companytext:"",
			editstatus:false,
			changeType:1,
			
			imageUrl: '',
			// imageUrl: require('@/assets/images/partner/sample.png'),
			certificateFile:"",
			type:"",
			editype:"",
			id:0
	}
    },
	mounted(){
		this.step = this.$route.query.type || 1;
			//获取企业信息
			Api.getuserCompanyInfo().then(res=>{
				this.form = res;
			})
			Api.getUserCertificate().then(res=>{
				this.cer = res;
				if(res != null){
					this.id =res.id;
				}
			})
			this.editype = "";
	},
    methods: {
		// 打开修改邮箱
		open5() {
		    this.changeEmailForm = {
		        old_email: this.$store.state.user.linkEmail,
		        old_emailcode: '',
		        new_email: '',
		        new_emailcode: ''
		    },
		        this.showNum = 5
		},
		close5() {
		    if (this.timer) { clearInterval(this.timer);this.second = 0 }
		    this.showNum = 0
		},
		saveaddress(){
				if(this.form.registeredAddress == ""){
					return this.$message.error("请输入地址");
				}
				Api.updateRegisteredAddress({address:this.form.registeredAddress}).then(()=>{
					this.editstatus = false;
				})
		},
		// 打开修改手机号
		open7() {
		    this.changeMobileForm = {
		        old_mobile: this.$store.state.user.linkPhone,
		        old_smscode: '',
		        new_mobile: '',
		        new_smscode: ''
		    }
		    console.log(this.changeMobileForm)
		    this.showNum = 7
		},
		close7() {
		    if (this.timer) { clearInterval(this.timer);this.second = 0 }
		    this.showNum = 0
		},
		// 前端验证
		validMobileForm(mobile, code) {
		    if (!this.changeMobileForm[mobile]) {
		        this.$message.error('请输入手机号')
		        throw new Error('请输入手机号')
		    }
		    if (!/^1[3456789]\d{9}$/.test(this.changeMobileForm[mobile])) {
		        this.$message.error('请输入正确得手机号')
		        throw new Error('请输入正确得手机号')
		    }
		    if (!this.changeMobileForm[code]) {
		        this.$message.error('请输入验证码')
		        throw new Error('请输入验证码')
		    }
		},
		validEmailForm(email, code) {
		    if (!this.changeEmailForm[email]) {
		        this.$message.error('请输入邮箱')
		        throw new Error('请输入邮箱')
		    }
		    if (!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/.test(this.changeEmailForm[email])) {
		        this.$message.error('请输入正确得邮箱')
		        throw new Error('请输入正确得邮箱')
		    }
		    if (!this.changeEmailForm[code]) {
		        this.$message.error('请输入验证码')
		        throw new Error('请输入验证码')
		    }
		},
		// 验证旧手机号和验证码
		validateOldMobile() {
		    this.validMobileForm('old_mobile', 'old_smscode')
		    Api.validateCode({ username: this.changeMobileForm.old_mobile, code: this.changeMobileForm.old_smscode }).then(res => {
		        this.changeMobileForm.randomNumbers = res
		        clearInterval(this.timer)
		        this.second = 0
		        this.showNum = 8
		    })
		},
		// 验证旧邮箱
		validateOldEmail() {
		    Api.checkcompanyCode({ code: this.changeEmailForm.old_emailcode,type:2 }).then(res => {
		        console.log(res)
		        this.changeEmailForm.randomNumbers = res
		        clearInterval(this.timer)
		        this.second = 0
		        this.showNum = 6
		    })
		},
		// 提交修改手机号
		submitMobile() {
		    this.validMobileForm('new_mobile', 'new_smscode')
		    const data = {
		        code: this.changeMobileForm.old_smscode,
		        newCode: this.changeMobileForm.new_smscode,
		        newLinkPhone: this.changeMobileForm.new_mobile
		    }
		    console.log(data)
		    Api.updateLinkPhone(data).then(res => {
		        clearInterval(this.timer)
		        this.second = 0
		        this.showNum = 0
		        this.$message.success('修改成功')
		        // this.$store.dispatch('logout')
		        this.$router.go(0)
		    })
		},
		getcompanytext(){
			Api.getsiteConfig(1).then(res=>{
				this.companytext = res.content;
				this.showNum = 9;
			})
			// Api.getConfigId({ id: 20 }).then(res => {
			// 	this.companytext = res.memo2;
			// 	this.showNum = 9;
			// })
		},
		validatePass(type) {
		    let field = ''
		    let code = ''
		    if (type == 'mobile') {
		        if (!this.changePassForm.smscode) { return this.$message.error('请输入验证码') }
		        field = this.changePassForm.mobile
		        code = this.changePassForm.smscode
		    } else {
		        if (!this.changePassForm.emailcode) { return this.$message.error('请输入验证码') }
		        field = this.changePassForm.email
		        code = this.changePassForm.emailcode
		    }
		    Api.validateCode({ username: field, code: code }).then(res => {
		        this.changePassForm.randomNumbers = res
		        clearInterval(this.timer)
		        this.second = 0
		        this.showNum = 4
		    })
		},
		
		// 打开修改密码弹窗
		open1() {
			this.changePassForm = {
				mobile: this.$store.state.user.telephone,
				smscode: '',
				email: this.$store.state.user.email,
				emailcode: '',
				password: '',
				repass: ''
			}
			this.showNum = 1
		},
		
		close1() {
		    if (this.timer) { clearInterval(this.timer);this.second = 0 }
		    this.showNum = 0
		},
		// 提交修改密码
		submitPass() {
		    if (this.changePassForm.password != this.changePassForm.repass) {
		        return this.$message.error('密码不一致')
		    }
		    let data = {
		        randomNumbers: this.changePassForm.randomNumbers,
				password: this.changePassForm.password
			}
		    if (this.changeType == 1) {
		        data.telephone2 = this.changePassForm.mobile
		    }
		    if (this.changeType == 2) {
		        data.email2 = this.changePassForm.email
		    }
		    console.log(data)
		    Api.updateUser(data).then(res => {
		        console.log(res)
		        clearInterval(this.timer)
		        this.second = 0
		        this.showNum = 0
		        this.$message.success('修改成功')
		        this.$store.dispatch('logout')
		        this.$router.replace('/')
		    })
		},
		
		// 提交修改邮箱
		async submitEmail() {
		    // 前端验证邮箱格式
		    this.validEmailForm('new_email', 'new_emailcode')
		    const data = {
		        code: this.changeEmailForm.old_emailcode,
		        newCode: this.changeEmailForm.new_emailcode,
		        newLinkEmail: this.changeEmailForm.new_email
		    }
		    Api.updateLinkEmail(data).then(res => {
		        console.log(res)
		        clearInterval(this.timer)
		        this.second = 0
		        this.showNum = 0
		        this.$message.success('修改成功')
		        // this.$store.dispatch('logout')
		        this.$router.go(0)
		    })
		},
		sendSms(form, type) {
		    if (!this[form][type]) {
		        return this.$message.error('请先输入手机号')
		    }
		    if (!/^1[3456789]\d{9}$/.test(this[form][type])) {
		        return this.$message.error('请输入正确的手机号')
		    }
		    let codeType = 3
		    if (type == 'old_mobile') {
		        codeType = 2
		    } else {
		        codeType = 3
		    }
		    Api.sendSms({ mobile: this[form][type], smsmode: codeType,userType:1 }).then(res => {
		        this.second = 10
		        this.timer = setInterval(() => {
		            this.second--
		            if (this.second == 0) {
		                clearInterval(this.timer)
		            }
		        }, 1000)
		        this.$message.success('短信发送成功');
		    })
		},
		sendEmail(form, type) {
		    if (!this[form][type]) {
		        return this.$message.error('请输入邮箱')
		    }
		    if (!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/.test(this[form][type])) {
		        return this.$message.error('请输入正确的邮箱')
		    }
			if(this.second != 0){
				return false;
			}
		    Api.sendEmailCode({ email: this[form][type] }).then(res => {
		        this.second = 10
				if(this.timer){
					clearInterval(this.timer)
				}
		        this.timer = setInterval(() => {
		            this.second--
		            if (this.second == 0) {
		                clearInterval(this.timer)
		            }
		        }, 1000)
		        this.$message.success('发送成功');
		    })
		},
		uploadPic({ file }) {
			return Api.upload(file)
		},
		handleSuccess(res) {
			if (res.data.code == 0) {
				this.certificateFile = res.data.message
			}
		},
		beforeUpload(file) {
		    const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf');
			this.type = file.type;
		    if (!isJPG) {
		        this.$message.error('上传文件只能是 JPG、png、dpf 格式!');
		    }
		    return isJPG;
		},
		submitinfo(){
			if(this.certificateFile == ""){
				return this.$message.error("请上传授权书");
			}
			Api.saveUserCertificate({certificateFile:this.certificateFile,id:this.id}).then(res=>{
				
				Api.getUserCertificate().then(res=>{
					this.cer = res;
				})
				
				this.$alert("已提交，商汤将于1个工作日内完成审核，请留意邮箱通知", "提示");
				
			})
		},
		download(){
			Api.downloadAuthFile().then(res=>{
				const blob = new Blob([res]);
				const fileName = '样例.pdf';
				if ('download' in document.createElement('a')) { // 非IE下载
								const elink = document.createElement('a');
								elink.download = fileName;
								elink.style.display = 'none';
								elink.href = URL.createObjectURL(blob);
								document.body.appendChild(elink);
								elink.click();
								URL.revokeObjectURL(elink.href);// 释放URL 对象
								document.body.removeChild(elink);
								this.loading = false;
				} else { // IE10+下载
								navigator.msSaveBlob(blob, fileName);
								this.loading = false;
				}
				
			})
			
		},
		saveinfo(){
			
			if(this.editype == ""){
				return this.$message.error("暂无修改项");
			}
			if(this.form[this.editype] == ""){
				return this.$message.error("请输入修改内容");
			}
			if(this.editype == "businessStartDate"){
				if(this.form['businessEndDate'] == ""){
					return this.$message.error("请输入结束日期");
				}
			}
			var obj = {};  
			
			obj[this.editype] = this.form[this.editype];
			if(this.editype == "businessStartDate"){
				obj["businessEndDate"] = this.form['businessEndDate'];
			}
			Api.updateBaseInfo(obj).then(()=>{
				this.editype = "";
			})
		}
    }
}
</script>

<style src="@/style/company-register.css"  scoped></style>
<style scoped lang="scss">
	.book{
		background-color: #fff;
		padding: 38px 0px 48px;
		img{
			width: 324px;
			height: auto;
			margin: 16px auto;
			display: block;
		}
	}
	.book_title{
		font-size: 16px;
		font-weight: 400;
		color: #111111;
		line-height: 20px;
		text-align: center;
	}
	.ban{
		position: relative;
		div{
			position: absolute;
			width: 900px;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			font-size: 40px;
			color: #fff;
			font-weight: bold;
		}
	}
    .partner{
        padding: 80px 0 0;
        overflow: hidden;
		background-color: #F5F5F7;
    }
    .parSortBg{
        .parSort{
            display: flex;
			text-align: center;
			line-height: 80px;
			background-color: #FFFFFF;
            li{
                flex: 1;
                font-size: 20px;
                color: #111111;
                opacity: .5;
				cursor: pointer;
                &.active{
                    color: #FFFFFF;
                    background: #E73522;
                    opacity: 1;
                }
            }
        }
    }
	.m-regform{
		padding: 0px 30px;
		background:none;
		height: 100%;
		padding-top: 80px;
		margin-top: 0px;
		.item{
			position: relative;
		}
		.update{
			position: absolute;
			bottom: 10px;
			right: 0px;
			width: 101px;
			height: 37px;
			border: 1px solid #E73522;
			border-radius: 3px;
			font-size: 14px;
			font-weight: 400;
			color: #E73522;
			text-align: center;
			line-height: 37px;
			display: none;
			cursor: pointer;
		}
		.edit{
			position: absolute;
			bottom: 5px;
			right: 0px;
			width: 101px;
			height: 37px;
			background-color: #F5F5F8;
			border-radius: 3px;
			font-size: 14px;
			font-weight: 400;
			color: #333;
			text-align: center;
			line-height: 37px;
			display: none;
			cursor: pointer;
			margin-right: 5px;
		}
		
	}
	.item:hover .edit{
		display: block;
	}
	.item:hover .update{
		display: block;
	}
	.comany-title{
		line-height: 80px;
		font-size: 24px;
		font-weight: 600;
		color: #111111;
		line-height: 80px;
		text-align: center;
		border-bottom: 1px solid #E73522;
	}
	.book-status{
		width: 280px;
		height: 50px;
		background: #E3E4E5;
		border-radius: 3px;
		font-size: 16px;
		line-height: 50px;
		font-weight: 400;
		text-align: center;
		color: #2E2E2E;
		margin: 0 auto;
	}
	
	.m-popup-m1 {
	    width: 100%;
	    height: 100vh;
	    position: fixed;
	    left: 0;
	    top: 0;
	    z-index: 1000;
	    background-color: rgba(0, 0, 0, 0.6);
	}
	.m-popup-m1 .popup {
	    width: 480px;
	    height: 380px;
	    background-color: #fff;
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    padding: 23px 25px 0;
	    box-sizing: border-box;
	}
	.m-popup-m1 .tit {
	    overflow: hidden;
	    position: relative;
	    margin-bottom: 17px;
	}
	.m-popup-m1 .tits {
	    font-size: 16px;
	    line-height: 30px;
	    font-family: PingFang SC;
	    font-weight: 500;
	    color: #333333;
	    float: left;
	}
	.m-popup-m1 .exit {
	    width: 20px;
	    height: 20px;
	    background-image: url(../assets/images/myinfo/icon-w1.png);
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	    position: absolute;
	    top: 50%;
	    right: 0;
	    transform: translateY(-50%);
	    cursor: pointer;
	    transition: 0.5s;
	}
	.m-popup-m1 .exit:hover {
	    transform: translateY(-50%) rotateZ(360deg);
	}
	.m-popup-m1 .desc {
	    width: 100%;
	    /* height: 50px; */
	    background-color: #f5f5f7;
	    padding: 15px 20px 15px 60px;
	    background-image: url(../assets/images/myinfo/icon-w2.png);
	    background-repeat: no-repeat;
	    background-size: 20px 20px;
	    background-position: 20px center;
	    margin-bottom: 30px;
	}
	.m-popup-m1 .desc1 {
	    position: relative;
	    padding-left: 15px;
	    font-size: 14px;
	    font-family: PingFang SC;
	    font-weight: 400;
	    color: #666666;
	    line-height: 20px;
	}
	.m-popup-m1 .desc1::after {
	    content: "";
	    display: block;
	    width: 4px;
	    height: 4px;
	    background-color: #333333;
	    border-radius: 50%;
	    position: absolute;
	    top: 50%;
	    left: 0;
	    transform: translateY(-50%);
	}
	.m-popup-m1 .content {
	}
	.m-popup-m1 .btns {
	    width: 100%;
	    position: absolute;
	    left: 0;
	    bottom: 40px;
	    display: flex;
	    justify-content: center;
	}
	
	.g-btns-m1 {
	    width: 100%;
	    display: flex;
	    justify-content: space-between;
	}
	.g-btns-m1 .btn {
	    width: 201px;
	    height: 51px;
	    border: 1px solid #999;
	    box-sizing: border-box;
	    font-size: 14px;
	    line-height: 49px;
	    text-align: center;
	    font-family: PingFang SC;
	    font-weight: 400;
	    color: #666;
	    outline: none;
	    cursor: pointer;
	    background-color: #fff;
	    transition: 0.5s;
	}
	.g-btns-m1 .btn:hover {
	    color: #e83421;
	    border-color: #e83421;
	}
	
	.g-btns-m2 {
	}
	.g-btns-m2 .btn {
	    width: 71px;
	    height: 31px;
	    border: 1px solid #e83421;
	    box-sizing: border-box;
	    font-size: 14px;
	    line-height: 29px;
	    text-align: center;
	    font-family: PingFang SC;
	    font-weight: 400;
	    color: #e83421;
	    background-color: #fff;
	    margin: 0 10px;
	    cursor: pointer;
	    transition: 0.5s;
	}
	.g-btns-m2 .btn.on {
	    color: #fff;
	    background-color: #e83421;
	}
	.g-btns-m2 .btn:hover{
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
	}
	.m-update-form-m1 {
	}
	.m-update-form-m1 .item {
	    display: flex;
	    justify-content: space-between;
	    align-items: center;
	    margin-bottom: 25px;
	}
	.m-update-form-m1 .label {
	    font-size: 14px;
	    font-family: PingFang SC;
	    font-weight: 400;
	    color: #666666;
	    line-height: 20px;
	}
	.m-update-form-m1 .el-input {
	    width: 346px;
	    height: 35px;
	    font-size: 14px;
	    font-family: PingFang SC;
	    font-weight: 400;
	}
	.m-update-form-m1 .item-qrcode {
	    position: relative;
	}
	.m-update-form-m1 .item-qrcode .btn-qrcode {
	    position: absolute;
	    top: 50%;
	    right: 10px;
	    transform: translateY(-50%);
	    cursor: pointer;
	    font-size: 14px;
	    font-family: PingFang SC;
	    font-weight: 400;
	    color: #e83421;
	    line-height: 20px;
	}
	.m-update-form-m1 .item-qrcode .btn-qrcode-dis {
	    position: absolute;
	    top: 50%;
	    right: 10px;
	    transform: translateY(-50%);
	    cursor: pointer;
	    font-size: 14px;
	    font-family: PingFang SC;
	    font-weight: 400;
	    color: #999;
	    line-height: 20px;
	}
	::v-deep .noborder .el-input__inner{
		height: 50px;
		line-height: 50px;
	}
	
	.company-content{
		width: 300px;
		height: 140px;
		background: #F5F5F7;
		padding: 20px;
		box-sizing: border-box;
		font-size: 14px;
		font-weight: 400;
		color: #333333;
		line-height: 26px;
	}
	.company-button{
		width: 100%;
		height: 31px;
		border: 1px solid #E83421;
		font-size: 14px;
		font-weight: 400;
		color: #E83421;
		line-height: 31px;
		text-align: center;
		cursor: pointer;
		margin: 20px auto 39px;
	}

@media only screen and (max-width: 750px) {
	.partner{
		padding: 1.4rem 0 0;
	}
	.parSortBg{
		padding: 0 .3rem .3rem;
		.comany-title{
			font-size: .32rem;
			line-height: .8rem;
			padding: .1rem 0;
		}
		.parSort{
			line-height: .8rem;
			li{
				font-size: .28rem;
			}
		}
		.m-regform{
			padding: .3rem 0;
			.edit{
				position: absolute;
				bottom: .05rem;
				right: .05rem;
				width: 1.2rem;
				height: .6rem;
				font-size: .24rem;
				line-height: .6rem;
				margin-right: .1rem;
			}
			::v-deep .noborder .el-input__inner{
				font-size: .24rem;
				height: .7rem;
				line-height: .7rem;
			}
		}
	}

    .m-popup-m1{
        .popup{
            width: 92%;
            height: auto;
            padding: .3rem;
            .tit{
                margin-bottom: .2rem;
                .tits{
                    font-size: .32rem;
                    line-height: .6rem;
                }
                .exit{
                    width: .4rem;
                    height: .4rem;
                }
            }
            .desc{
                padding: .3rem;
                padding-left: .6rem;
                margin-bottom: .3rem;
                background-size: .4rem auto;
                .desc1{
                    font-size: .28rem;
                    line-height: .44rem;
                    &::after{
                        display: none;
                    }
                }
            }
            .content{
                margin: .3rem 0;
                .g-btns-m1{
                    .btn{
                        width: 48%;
                        height: .64rem;
                        font-size: .28rem;
                        line-height: .62rem;
                    }
                }
            }
            .m-update-form-m1{
                .item{
                    margin-bottom: .2rem;
                    .label{
                        min-width: 1.32rem;
                        font-size: .28rem;
                        line-height: .7rem;
                    }
                    .el-input{
                        flex: auto;
                        height: auto;
                        overflow: hidden;
                        ::v-deep input{
                            height: .7rem;
                            font-size: .28rem;
                            line-height: .7rem;
                            padding: 0 .2rem;
                            border-radius: .04rem;
                        }
                    }
                    .btn-qrcode{
                        right: .2rem;
                        font-size: .28rem;
                        line-height: .7rem;
                    }
                }
            }
            .btns{
                position: static;
                .btn{
                    width: 1.6rem;
                    height: .6rem;
                    font-size: .28rem;
                    line-height: .58rem;
                    margin: 0 .1rem;
                }
            }
        }
    }
	.company-content{
		width: 100%;
		height: 2rem;
		padding: .2rem;
		font-size: .24rem;
		line-height: 1.8;
	}
	.company-button{
		height: .7rem;
		font-size: .24rem;
		line-height: .7rem;
		margin: .3rem auto;
	}
}
.empower{
    width: 100%;
    max-width: 960px;
    height: 680px;
    padding: 50px;
    margin: 20px auto 40px;
    background-color: #FFFFFF;
    .empowerTit{
        font-size: 24px;
        color: #111111;
        line-height: 1.8;
    }
    .empowerDes{
        font-size: 18px;
        color: #666666;
        line-height: 1.8;
    }
}
.empowerCon{
    display: flex;
    padding: 50px;
    .empowerForm{
        flex: auto;
        overflow: hidden;
        .empowerItem{
            display: flex;
            align-items: flex-end;
            margin-bottom: 20px;
            .empowerImg{
                position: relative;
                flex-shrink: 0;
                width: 121px;
                height: 121px;
                background: #F5F5F8;
                border: 1px solid #DDDDDD;
                border-radius: 3px;
                margin-right: 30px;
                cursor: pointer;
                img{
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: cover;
                }
                .empowerIcon{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    max-width: 100%;
                    max-height: 100%;
                }
                .upload{
                    width: 100%;
                    height: 100%;
                    ::v-deep .el-upload{
                        display: block;
                        height: 100%;
                    }
                }
            }
            .empowerTips{
                flex: auto;
                font-size: 14px;
                color: #999999;
                line-height: 1.8;
                overflow: hidden;
            }
        }
    }
    .sample{
        flex-shrink: 0;
        width: 192px;
        margin-left: auto;
        .sampleTit{
            font-size: 14px;
            color: #666666;
            line-height: 2;
            margin-bottom: 10px;
            font-weight: normal;
        }
        .sampleImg{
            width: 100%;
            img{
                width: 100%;
                height: auto;
            }
        }
    }
}
.empowerBtn{
    display: flex;
    justify-content: center;
    padding: 50px 0;
    span{
        display: block;
        width: 280px;
        font-size: 16px;
        line-height: 50px;
        margin: 0 10px;
        border-radius: 3px;
        text-align: center;
        cursor: pointer;
    }
    .wait{
        color: #2E2E2E;
        background: #E3E4E5;;
    }
    .submit{
        color: #FFFFFF;
        background: #E73522;
    }
}

.empowerDown{
    display: block;
    width: 24px;
    height: 24px;
    background: url("../assets/images/partner/icon-down@2x.png") center center no-repeat;
    background-size: cover;
}
.empowerUpload{
    display: block;
    width: 24px;
    height: 24px;
    background: url("../assets/images/partner/icon-upload@2x.png") center center no-repeat;
    background-size: cover;
}

    
</style>