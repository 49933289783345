const utils = {
    
    //获取随机数
    randomString: function(len) {
        len = len || 32;
        var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
        var maxPos = $chars.length;
        var pwd = '';
        for (var i = 0; i < len; i++) {
            pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    },

    // 获取当前url后参数
    getHashParams: function() {
        let hashStr = window.location.hash;
        let params = {};
        if (hashStr.split('?')[1]) {
            let arr = hashStr.split('?')[1].split('&');

            for (let i = 0, len = arr.length; i < len; i++) {
                let data = arr[i].split('=');
                if (data.length === 2) {
                    params[data[0]] = data[1];
                }
            }
        }
        return params;
    },

    getHashParam: function(key) {
        let p = utils.getHashParams();
        return p[key];
    },



    // 校验手机号，支持新号段
    phonePattern: function(tel) {
        return /1[3456789]\d{9}/g.test(tel);
    },

    /**
     * 时间格式处理
     * @param：时间对象
     * @param：分隔符
     * @param: 保留位数 1:年，2:年月，3:年月日，4:年月日时，5:年月日时分，6:年月日时分秒
     */
    formatTime(date, separate, num) {
        let year = date.getFullYear();
        let month = this.formatNumber(date.getMonth() + 1);
        let day = this.formatNumber(date.getDate());
        let hour = this.formatNumber(date.getHours());
        let minute = this.formatNumber(date.getMinutes());
        let second = this.formatNumber(date.getSeconds());
        if (num == 6) {
            return [year, month, day].join(separate) + [hour, minute, second].join(separate);
        } else if (num == 5) {
            return [year, month, day].join(separate) + ' ' + [hour, minute].join(':');
        } else if (num == 4) {
            return [year, month, day].join(separate) + ' ' + [hour].join(':');
        } else if (num == 3) {
            return [year, month, day].join(separate);
        } else if (num == 2) {
            return [year, month].join(separate);
        } else if (num == 1) {
            return [year].join(separate);
        }
    },
    // 时间格式处理
    formatNumber(n) {
        n = n.toString();
        return n[1] ? n : '0' + n;
    },

    /**
     * 获取用户id
     */
    getUserId(){
        if( localStorage.getItem('userId') ){
            return localStorage.getItem('userId');
        }else{
            return ''
        }
    },

    /**
     * 设置标题
     */
    setTitle(title){
        document.title = title;
    }
};

export default utils;
