<template>
	<div class="mycomments">
		<Header />
		<div class="main">
			<MyBanners :navIndex="3" />
			<div class="wp1200">
				<ul class="ul-alltiezi">
					<li v-for="(item, index) in list" :key="index">
						<div class="con">
							<div class="tit">{{item.postsTitle}}</div>
							<router-link to class="txts">
								<div class="desc image-setting" v-html="item.content"></div>
							</router-link>
							<div class="comments-date" style="display: flex;justify-content: space-between;">
								<span>评论时间：{{ item.createTime }}</span>
								<span style="cursor:pointer" @click="handleDel(item.id)">删除</span>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<!-- 内容为空 -->
			<ContentNull style="display: none;" />
			<div class="pagination-box style2" v-loading.fullscreen.lock="false">
				<el-pagination
					:total="total"
					layout="prev, pager, next"
					:page-size="params.pageSize"
					@current-change="handleCurrentChange"
				></el-pagination>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import ContentNull from '@/components/ContentNull.vue';
import MyBanners from '@/components/MyBanners.vue';
import Api from '../request/api';
export default {
	name: 'Mycomments',
	components: {
		Header,
		Footer,
		MyBanners,
		ContentNull
	},
	data() {
		return {
			params: {
				order: '',
				pageNo: 1,
				pageSize: 10,
				sort: 0
			},
			list: [],
			total: 0
		}
	},
	created() {
		this.getList()
	},
	watch: {
        $route(v) {
            if (v.path == '/mycomments') {
                this.getList()
            }
        }
    },
	methods: {
		getList() {
			Api.myCommentList(this.params).then(res => {
				this.list = res.records
				this.total = res.total
			})
		},
		handleCurrentChange(v) {
            this.params.pageNo = v
            this.getList()
        },
		// 点赞
		like(id, like, index) {
			if (like) {
				Api.forumLikeCancel({ id: id }).then(res => {
					console.log(res)
					this.list[index].forumPosts.like = !like
					this.list[index].forumPosts.likeNum -= 1
				})
			} else {
				Api.forumLike({ id: id }).then(res => {
					console.log(res)
					this.list[index].forumPosts.like = !like
					this.list[index].forumPosts.likeNum += 1
				})
			}
		},
		// 删除
		async handleDel(id) {
			this.$confirm('是否确认删除该回复?').then(async () => {
				await Api.revertDelete({id: id})
				this.$message.success('删除成功')
				this.getList()
			}).catch(() => {
				console.log(432)
			})
		},
		toDetail(id, pid) {
			this.$router.push({ path: '/forumdetail', query: { id:id, pid:pid } })
		}
	}
}
</script>

<style scoped>
.main {
	margin-top: 80px;
	background-color: #f5f5f7;
	padding-bottom: 35px;
}

.ul-alltiezi {
	/* padding: 0 20px; */
	margin-bottom: 34px;
	padding-top: 20px;
}
.ul-alltiezi li {
	padding: 0 20px;
	background-color: #fff;
	margin-bottom: 10px;
}
.ul-alltiezi .con {
	padding: 20px 0;
}
.ul-alltiezi .txts {
	margin-bottom: 13px;
	display: block;
	padding-top: 5px;
}
.ul-alltiezi .txts .desc {
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #2e2e2e;
	line-height: 24px;
	width: 100%;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}
.ul-alltiezi .comments-date {
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 20px;
	margin-bottom: 15px;
}
.ul-alltiezi .comments-con {
	background-color: #f5f5f7;
	padding: 15px 20px 20px;
}
.ul-alltiezi .comments-tit {
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #111111;
	line-height: 25px;
	margin-bottom: 15px;
	cursor: pointer;
}
.ul-alltiezi .info {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}
.ul-alltiezi .hdinfo {
	overflow: hidden;
	color: #333;
}
.ul-alltiezi .hdinfo .pic {
	width: 32px;
	height: 32px;
	float: left;
	overflow: hidden;
	border-radius: 50%;
	margin-right: 18px;
}
.ul-alltiezi .hdinfo .txt {
	overflow: hidden;
}
.ul-alltiezi .hdinfo .name {
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #111111;
	line-height: 20px;
	margin-bottom: 2px;
}
.ul-alltiezi .hdinfo .date {
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #707070;
	line-height: 15px;
}
.ul-alltiezi .hdinfo .date span {
	display: inline-block;
	margin-right: 30px;
}

.ul-alltiezi .icons {
	padding-bottom: 0px;
}
.ul-alltiezi .icons .icon {
	margin-right: 40px;
	float: left;
	padding-left: 20px;
	background: url(../assets/images/detail/icon8.png) no-repeat left center;
	background-size: 16px;
	cursor: pointer;
}
.ul-alltiezi .icons .icon:hover {
	color: #e73522;
}
.ul-alltiezi .icons .icon.i2 {
	background-image: url(../assets/images/detail/icon9.png);
}
.ul-alltiezi .icons .icon.i3 {
	background-image: url(../assets/images/detail/icon10.png);
	background-position: left 2px;
}
.ul-alltiezi .icons .icon:last-of-type {
	margin-right: 0;
}

.pagination-box.style2 {
	text-align: center;
}
.tit{
	font-size: 16px;
	font-weight: bold;
}
@media only screen and (max-width: 750px) {
	.main {
		padding: 1.4rem 0 .4rem;
		margin: 0;
	}
	.ul-alltiezi {
		margin-bottom: .3rem;
		padding-top: .2rem;
	}
	.ul-alltiezi li {
		padding: 0 .3rem;
		margin-bottom: .1rem;
	}
	.ul-alltiezi .con {
		padding: .3rem 0;
	}
	.ul-alltiezi .txts {
		margin-bottom: .1rem;
		padding-top: .1rem;
	}
	.ul-alltiezi .txts .desc {
		font-size: .28rem;
		line-height: 1.8;
	}
	.ul-alltiezi .comments-date {
		font-size: .24rem;
		line-height: 1.8;
		margin-bottom: 0;
	}
	.ul-alltiezi .comments-con {
		padding: .2rem;
	}
	.ul-alltiezi .comments-tit {
		font-size: .32rem;
		line-height: 1.8;
		margin-bottom: .1rem;
	}
	.tit{
		font-size: .32rem;
	}
}
</style>
