 <template>
	<div class="protocol">
		<Header />
		<div class="main">
			<div class="mainAgreement">
				
				<div class="wp1200">
					<el-breadcrumb separator-class="el-icon-arrow-right">
					  <el-breadcrumb-item>新闻公告</el-breadcrumb-item>
					  <el-breadcrumb-item>正文</el-breadcrumb-item>
					</el-breadcrumb>
					<div class="m-agreement">
						<div class="tit">{{title}}</div>
						<div class="txt" v-html="content"></div>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Api from '../request/api';

export default {
	name: 'Protocol',
	components: {
		Header,
		Footer
	},
	data() {
		return {
			id: 0,
			content: '',
			title:""
		}
	},
	created() {
		this.id = (this.$route.query.id ? this.$route.query.id : 0)
		Api.newsdetail({ id:this.id}).then(res => {
			this.content = res.content;
			this.title = res.title;
		})

	},
	methods: {
		goback() {
			this.$router.go(-1)
		}
	}
}
</script>

<style lang="scss" scoped>
.main {
	margin-top: 80px;
}
.mainAgreement {
	background-color: #f5f5f7;
	padding: 18px 0 30px 0;
}
.wp1200 {
	max-width: 1200px;
	margin: 0 auto;
}
.m-agreement {
	padding: 60px 31px 43px 31px;
	background-color: #ffffff;
}
.m-agreement .tit {
	font-size: 24px;
	font-family: PingFang SC;
	font-weight: 500;
	line-height: 1.5;
	color: #111111;
	margin-bottom: 40px;
	text-align: center;
}
.m-agreement .txt {
	height: 1457px;
	overflow-y: auto;
	margin-bottom: 70px;
}
.m-agreement .txt::-webkit-scrollbar {
	display: none;
}

.m-agreement p {
	font-size: 16px;
	line-height: 1.2;
	color: #2e2e2e;
	margin-bottom: 30px;
}
.m-agreement p:last-of-type {
	margin-bottom: 0;
}
.m-agreement .btn {
	cursor: pointer;
	font-size: 16px;
	margin: 0 auto;
	text-align: center;
	width: 280px;
	height: 50px;
	line-height: 50px;
	border-radius: 4px;
	color: #ffffff;
	background-color: #e73522;
	transition: 0.5s;
}
/* .m-agreement .btn:hover {
		box-shadow: 0 0 14px #f73523;
		transition: .5s;
	} */

@media only screen and (max-width: 750px) {
	.main {
		margin-top: 1.4rem;
	}
	.mainAgreement{
		padding: .6rem 0 .3rem;
	}
	.wp1200{
		max-width: 100%;
		padding: 0 .3rem;
	}
	.m-agreement{
		padding: .6rem .3rem .4rem;
		.tit{
			font-size: .48rem;
			margin-bottom: .4rem;
		}
		.txt{
			height: 80vh;
			margin-bottom: .6rem;
		}
		.btn{
			width: 3rem;
			height: .68rem;
			font-size: .28rem;
			line-height: .68rem;
		}
	}
}
::v-deep .el-breadcrumb{
	font-size: .24rem;
	margin-bottom: 24px;
}
</style>
