<template>
		<div class="m-subNav">
			<a class="t" v-for="(item, index) in sublist"  :key="index" @click="goback">{{item}}</a>
			<a class="t">正文</a>
		</div>
</template>

<script>
	export default {
		props:{
			sublist:Array
		},
		data() {
			return {
			};
		},
		methods: {
			goback() {
				this.$router.go(-1)
			}
		}
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.m-subNav {
		padding-top: 18px;
		margin-bottom: 18px;
	}
	
	.m-subNav a {
		font-size: 14px;
		color: #e83421;
		margin-right: 34px;
		position: relative;
	}
	.m-subNav .t:hover{
		color: #e83421;
	}
	
	.m-subNav a::after {
		content: '';
		position: absolute;
		right: -14px;
		top: 50%;
		transform: translate(100%,-50%);
		background: url(../assets/images/detail/icon3.png) no-repeat;
		background-size: 100%;
		width: 14px;
		height: 14px;
	}
	.m-subNav a:first-of-type {
		color: #999;
	}
	.m-subNav a:last-of-type::after{
		display: none;
	}
	
	.m-subNav a:last-of-type {
		color: #999;
	}
	@media only screen and (max-width: 750px) {
		
	.m-subNav{
		padding-top: .2rem;
		margin-bottom: .2rem;
			a{
				display: inline-block;
				vertical-align: middle;
				max-width: 2rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-right: 0;
				padding-right: 16px;
				font-size: .24rem;
				&::after{
					right: 1px;
					transform: translate(0,-35%);
				}
				&:last-child{
					padding-right: 0;
				}
			}
		}
	}
</style>
