<template>
    <div class="partner">
        <Header />
		<div style="height: 30px;"></div>
        <div class="parSortBg">
            <div class="wp w1200">
                <ul class="parSort">
                    <li @click="step =1" :class="step==1?'active':''"><span>认证信息</span></li>
                    <li @click="step =2" :class="step==2?'active':''"><span>合作协议</span></li>
                </ul>
            </div>
        </div>
        <div class="parSignBg">
            <div class="wp w1200">
				
				<div class="parSigntwo" v-if="step == 1">
				    <div class="parSignItem">
				        <div class="parSignCon">
				            <label class="parSignLabel">代理主要品牌I</label>
				            <div class="parSignText">{{ parSignForm.firstBrandName }}</div>
				        </div>
				        <div class="parSignCon">
				            <label class="parSignLabel">大类</label>
				            <div class="parSignText">{{ parSignForm.firstClassName }}</div>
				        </div>
				        <div class="parSignCon">
				            <label class="parSignLabel">年销售额</label>
				            <div class="parSignText">{{ parSignForm.firstSalesVolumeName }}</div>
				        </div>
				    </div>
				    <div class="parSignItem">
				        <div class="parSignCon">
				            <label class="parSignLabel">代理主要品牌II</label>
				            <div class="parSignText">{{ parSignForm.secondBrandName }}</div>
				        </div>
				        <div class="parSignCon" v-if="parSignForm.secondBrandId > 0">
				            <label class="parSignLabel">大类</label>
				            <div class="parSignText">{{ parSignForm.secondClassName }}</div>
				        </div>
				        <div class="parSignCon" v-if="parSignForm.secondBrandId > 0">
				            <label class="parSignLabel">年销售额</label>
				            <div class="parSignText">{{ parSignForm.secondSalesVolumeName }}</div>
				        </div>
				    </div>
				    <div class="parSignItem">
				        <div class="parSignCon">
				            <label class="parSignLabel">代理主要品牌III</label>
				            <div class="parSignText">{{ parSignForm.thirdBrandName }}</div>
				        </div>
				        <div class="parSignCon" v-if="parSignForm.thirdBrandId > 0">
				            <label class="parSignLabel">大类</label>
				            <div class="parSignText">{{ parSignForm.thirdClassName }}</div>
				        </div>
				        <div class="parSignCon" v-if="parSignForm.thirdBrandId > 0">
				            <label class="parSignLabel">年销售额</label>
				            <div class="parSignText">{{ parSignForm.thirdSalesVolumeName }}</div>
				        </div>
				    </div>
				    <div class="parSignItem noBg">
				        <div class="parSignCon">
				            <label class="parSignLabel">推荐人</label>
				            <div class="parSignText">{{ parSignForm.referrer == ''?'无':parSignForm.referrer }}</div>
				        </div>
				        <div class="parSignCon">
				            <label class="parSignLabel">公司</label>
				            <div class="parSignText">{{ parSignForm.companyName == ''?'无':parSignForm.companyName }}</div>
				        </div>
				        <div class="parSignCon">
				            <label class="parSignLabel">手机号</label>
				            <div class="parSignText">{{ parSignForm.phone == ''?'无':parSignForm.phone }}</div>
				        </div>
				    </div>
				    <div class="parSignItem noBg">
				        <div class="parSignCon">
				            <label class="parSignLabel">授权区域</label>
				            <div class="parSignText">{{ parSignForm.province }}</div>
				        </div>
				        <div class="parSignCon">
				            <label class="parSignLabel">对接商汤渠道经理</label>
				            <div class="parSignText">{{ parSignForm.channelManager }}</div>
				        </div>
				    </div>
				    <div class="parSignItem noBg">
				        <div class="parSignCon parSignfujian">
				            <label class="parSignLabel">附件</label>
				            <div class="empowerImg">
				                <template v-if="parSignForm.attachment">
				                	<img v-if="parSignForm.attachment.indexOf('.pdf') > -1" src="@/assets/images/file_pdf.png" class="book">
				                	<img v-else :src="parSignForm.attachment" class="book">
				                </template>
				            </div>
				        </div>
				    </div>
				</div>
				
				<div v-if="step == 2" class="parSignthree">
				    <div class="signText" v-html="info.agreementContent"></div>
				    <div class="signBot">
				        <div class="signTel">协议hash值：{{info.fileHash}}</div>
				    </div>
				</div>
            </div>
        </div>
		
		<div style="height: 100px;"></div>
        <Footer />
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Api from '@/request/api.js';
export default {
    name: 'PartnerEmpower',
    components: { 
        Header,
        Footer
    },
    data() {
        return {
            parSignForm:{
                firstBrandId:'',                
                firstBrandName:'',
                firstClassId:'',
                firstClassName:'',
                firstSalesVolumeId:'',
                firstSalesVolumeName:'',
                secondBrandId:'',
                secondBrandName:'',
                secondClassId:'',
                secondClassName:'',
                secondSalesVolumeId:'',
                secondSalesVolumeName:'',
                thirdBrandId:'',
                thirdBrandName:'',
                thirdClassId:'',
                thirdClassName:'',
                thirdSalesVolumeId:'',
                thirdSalesVolumeName:'',
                referrer:'',
                companyName:'',
                phone:'',
                province:'',
                channelManager:'',
                attachment:'',
                appointUserId:''
            },
			info:{},
			step:1
        }
    },
	created(){
		this.id = this.$route.query.id;
		// //获取计划详情内容
		// Api.getPlanDetail({planId:this.id}).then(res => {
		// 	this.info = res;
		// })
		// if(this.$route.query.autoId){
		// 	this.companyId = this.$route.query.autoId;
			this.getinfo();
		// }
		
	},
    methods: {
		getinfo(){
			Api.companyAuthInfo({companyAuthId:this.id}).then(res=>{
				this.parSignForm = res;
				Api.getPlanDetail({planId:res.planId}).then(res => {
					this.info = res;
					console.log(res);
				})
			})
			
		}
    }
}
</script>
<style scoped lang="scss">

.ban{
	position: relative;
	div{
		position: absolute;
		width: 900px;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		font-size: 40px;
		color: #fff;
		font-weight: bold;
	}
}
.partner{
	padding: 80px 0 0;
	overflow: hidden;
	background-color: #F5F5F7;
}
.parSortBg{
	.parSort{
	line-height: 80px;
	background-color: #FFFFFF;
	text-align: center;
		display: flex;
		li{
			flex: 1;
			font-size: 20px;
			color: #111111;
			opacity: .5;
			cursor: pointer;
			&.active{
				color: #FFFFFF;
				background: #E73522;
				opacity: 1;
			}
		}
	}
}
.parSign{
	padding: 30px 50px 60px;
	background: #FFFFFF;



	.parSignTips{
		font-size: 16px;
		color: #E73522;
		line-height: 1.6;
		margin: 20px auto;
		text-align: center;
	}
	.parSignBtn{
		width: 280px;
		line-height: 50px;
		font-size: 16px;
		color: #FFFFFF;
		background: #E73522;
		border-radius: 3px;
		margin: 10px auto;
		text-align: center;
		cursor: pointer;
	}
}
.parSignForm{
	margin-bottom: 40px;
}
.parSignItem{
	display: flex;
	flex-wrap: wrap;
	margin: 0 -20px;
	.parSignCon{
		width: 33.33%;
		padding: 5px 20px;
		.parSignLabel{
			display: block;
			font-size: 14px;
			color: #2E2E2E;
			line-height: 2;
			padding: 5px 0;
			overflow: hidden;
			span{
				color: #E83421;
				margin-left: 10px;
			}
			i{
				float: right;
				color: #999999;
				font-style: normal;
			}
		}
		.el-select{
			display: block;
			::v-deep .el-input__inner{
				height: 50px;
				line-height: 50px;
				font-size: 16px;
				color: #2E2E2E;
				border: none;
				border-radius: 3px;
				background: #F5F5F8;
			}
		}
		.el-input{
			display: block;
			::v-deep .el-input__inner{
				height: 50px;
				line-height: 50px;
				font-size: 16px;
				color: #2E2E2E;
				padding: 0;
				border: none;
				border-radius: 0;
				border-bottom: 2px solid #DDDDDD;
			}
		}
	}
	.parSignfujian{
		display: flex;
		align-items: flex-end;
		padding: 5px 20px;
		.empowerImg{
			position: relative;
			flex-shrink: 0;
			width: 84px;
			height: 84px;
			background: #F5F5F8;
			border-radius: 3px;
			margin-right: 16px;
			cursor: pointer;
			img{
				display: block;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
				max-width: 100%;
				max-height: 100%;
				object-fit: cover;
			}
			.empowerIcon{
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
				max-width: 100%;
				max-height: 100%;
			}
			.upload{
				width: 100%;
				height: 100%;
				::v-deep .el-upload{
					display: block;
					height: 100%;
				}
			}
		}
		.empowerTips{
			flex: auto;
			font-size: 14px;
			color: #999999;
			line-height: 1.8;
			overflow: hidden;
		}
	}
}
.el-select-dropdown__item.selected{
	color: #E73522;
}
.empowerUpload{
	display: block;
	width: 20px;
	height: 20px;
	background: url("../assets/images/partner/icon-upload@2x.png") center center no-repeat;
	background-size: cover;
}
.parSigntwo{
	padding: 30px 50px 60px;
	background: #FFFFFF;
	.parSignItem{
		display: flex;
		flex-wrap: wrap;
		margin: 10px 0;
		background: #F5F5F8;
		.parSignCon{
			width: 25%;
			padding: 10px;
			&:first-child{
				width: 50%;
			}
		}
		.parSignfujian{
			width: 100%;
			padding: 5px 10px;
			.empowerImg{
				position: relative;
				flex-shrink: 0;
				width: 120px;
				height: 120px;
				img{
					display: block;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
					max-width: 100%;
					max-height: 100%;
					object-fit: cover;
				}
			}
		}
		&.noBg{
			background: transparent;
			margin: 10px -10px;
			.parSignCon{
				width: 33.33% !important;
			}
		}
	}
	.parSignTop{
		margin-bottom: 30px;
	}
	.parSignTopCon{
		font-size: 24px;
		color: #111111;
		font-weight: bold;
	}
	.parSignLabel{
		display: block;
		font-size: 14px;
		color: #666666;
		line-height: 2;
		padding: 5px 0;
		overflow: hidden;
		span{
			color: #E83421;
			margin-left: 10px;
		}
		i{
			float: right;
			color: #999999;
			font-style: normal;
		}
	}
	.parSignText{
		font-size: 18px;
		color: #111111;
		line-height: 2;
		font-weight: bold;
	}
	.empowerBtn{
		display: flex;
		justify-content: center;
		margin-top: .5rem;
		span{
			display: block;
			width: 280px;
			font-size: 16px;
			line-height: 50px;
			margin: 0 10px;
			border-radius: 3px;
			text-align: center;
			cursor: pointer;
		}
		.wait{
			color: #2E2E2E;
			background: #E3E4E5;;
		}
		.submit{
			color: #FFFFFF;
			background: #E73522;
		}
	}
}
.parSignthree{
	padding: 30px 50px 60px;
		background: #FFFFFF;
	.signTit{
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 16px;
		color: #111111;
		line-height: 32px;
		margin: 20px 0 40px;
		h2{
			font-size: 24px;
		}
		span{
			flex-shrink: 0;
			display: block;
			cursor: pointer;
			transition: all .3s;
			img{
				width: 17px;
				height: auto;
				margin-right: 5px;
			}
			&:hover{
				color: #E73522;
			}
		}
	}
	
	.signText{
		font-size: 18px;
		color: #111111;
		line-height: 2;
		height: 560px;
		padding-right: 30px;
		margin-right: -30px;
		margin: 30px 0 60px;
		overflow-y: auto;
		&::-webkit-scrollbar{
			width: 8px;
			border-radius: 4px;
			background: #EEEEEE;
		}
		&::-webkit-scrollbar-thumb{
			border-radius: 4px;
			background: #666666;
		}
	}
	.isAgree{
		font-size: 16px;
		color: #2E2E2E;
		line-height: 30px;
		margin: 30px 0;
		::v-deep .el-checkbox__inner{
			width: 16px;
			height: 16px;
			border-radius: 50%;
			&::after{
				left: 5px;
				top: 2px;
			}
			&:hover{
				border-color: #E73522;
			}
		}
		::v-deep .el-checkbox__label{
			font-size: 16px;
			color: #2E2E2E;
		}
		::v-deep &.is-checked{
			.el-checkbox__inner{
				border-color: #E73522;
				background-color: #E73522;
			}            
		}
		::v-deep .el-checkbox__input.is-focus{
			.el-checkbox__inner{
				border-color: #E73522;
			}
		}
		::v-deep .is-checked+.el-checkbox__label{
			color: #2E2E2E;
		}
	}
	.signBot{
		display: flex;
		align-items: center;
		.signTel{
			min-width: 300px;
			font-size: 16px;
			color: #2E2E2E;
			line-height: 2;
			margin-right: 15px;
		}
		.signYzm{
			margin-bottom: 0;
			border-radius: 3px;
			overflow: hidden;
			::v-deep  .el-form-item__content{
				display: flex;
				.el-input{
					display: block;
				}
			}
			::v-deep .el-input__inner{
				width: 240px;
				height: 50px;
				font-size: 16px;
				color: #666666;
				line-height: 50px;
				border: none;
				border-radius: 0;
				background: #F5F5F8;
			}
			.yzmBtn{
				flex-shrink: 0;
				width: 120px;
				height: 50px;
				line-height: 50px;
				background: #E3E4E5;
				text-align: center;
				cursor: pointer;
			}
			.yzmBtn2{
				cursor: no-drop;
			}
		}
	}
	.empowerBtn{
		display: flex;
		justify-content: center;
		margin-top: .5rem;
		span{
			display: block;
			width: 280px;
			font-size: 16px;
			line-height: 50px;
			margin: 0 10px;
			border-radius: 3px;
			text-align: center;
			cursor: pointer;
		}
		.wait{
			color: #2E2E2E;
			background: #E3E4E5;;
		}
		.submit{
			color: #FFFFFF;
			background: #E73522;
		}
	}
}
@media only screen and (max-width: 750px) {
	.ban{
		height: 2rem;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.partner{
		padding: 1.4rem 0 0;
	}
	.parSortBg{
		line-height: .8rem;
		.parSort{
			display: flex;
			line-height: .8rem;
			li{
				font-size: .28rem;
			}
		}
	}

	.parSignBg{
		padding: .3rem 0 .6rem;
	}
	.parSign{
		padding: .3rem .3rem .6rem;
		.parSignTips{
			font-size: .24rem;
			margin: .2rem auto;
		}
		.parSignBtn{
			width: 100%;
			max-width: 280px;
			line-height: .7rem;
			font-size: .28rem;
			margin: .1rem auto;
		}
	}
	.parSignForm{
		margin-bottom: .3rem;
	}
	.parSignItem{
		margin: 0 -.1rem;
		.parSignCon{
			width: 100%;
			padding: 5px .1rem;
			.parSignLabel{
				font-size: .24rem;
				padding: .1rem 0;
				span{
					margin-left: .1rem;
				}
			}
			.el-select{
				display: block;
				::v-deep .el-input__inner{
					height: .7rem;
					line-height: .7rem;
					font-size: .24rem;
				}
			}
			.el-input{
				display: block;
				::v-deep .el-input__inner{
					height: .7rem;
					line-height: .7rem;
					font-size: .24rem;
				}
			}
		}
		.parSignfujian{
			padding: .1rem .2rem;
			.empowerImg{
				width: 2rem;
				height: 2rem;
				margin-right: .2rem;
				.empowerIcon{
					width: .4rem;
					height: .4rem;
				}
			}
			.empowerTips{
				font-size: .24rem;
			}
		}
	}
	.el-select-dropdown__item{
		height: auto;
		font-size: .28rem;
		line-height: 2;
	}


	.parSigntwo{
		padding: .3rem .3rem .6rem;
		.parSignItem{
			margin: .1rem 0;
			.parSignCon{
				width: 100%;
				padding: .1rem;
				&:first-child{
					width: 100%;
				}
			}
			.parSignfujian{
				width: 100%;
				padding: .1rem .2rem;
				.empowerImg{
					width: 2rem;
					height: 2rem;
				}
			}
			&.noBg{
				background: transparent;
				margin: .1rem -.1rem;
				.parSignCon{
					width: 100% !important;
				}
			}
		}
		.parSignTop{
			margin-bottom: .3rem;
		}
		.parSignTopCon{
			font-size: .32rem;
		}
		.parSignLabel{
				font-size: .24rem;
				padding: .1rem 0;
			span{
				margin-left: .1rem;
			}
		}
		.parSignText{
			font-size: .28rem;
		}
		.empowerBtn{
			span{
				width: 48%;
				font-size: .28rem;
				line-height: .7rem;
				margin: 0 1%;
			}
		}
	}
	.parSignthree{
		padding: .3rem .3rem .6rem;
		.signTit{
			font-size: .24rem;
			line-height: 2;
			margin: .3rem 0 .6rem;
			h2{
				font-size: .32rem;
			}
			span{
				img{
					width: .3rem;
					margin-right: .1rem;
				}
			}
		}
		
		.signText{
			font-size: .24rem;
			height: 6rem;
			padding-right: .3rem;
			margin-right: -.3rem;
			margin: .3rem 0 .6rem;
			&::-webkit-scrollbar{
				width: .08rem;
			}
		}
		.isAgree{
			display: flex;
			font-size: .24rem;
			line-height: .5rem;
			margin: .3rem 0;
			::v-deep .el-checkbox__input{
				flex-shrink: 0;
				display: block;
				width: .3rem;
			}
			::v-deep .el-checkbox__inner{
				width: .3rem;
				height: .3rem;
				&::after{
					left: .1rem;
					top: .02rem;
					width: .08rem;
					height: .18rem;
					border-width: .02rem;
				}
			}
			::v-deep .el-checkbox__label{
				display: block;
				flex: auto;
				font-size: .24rem;
				line-height: .3rem;
				white-space: normal;
				overflow: hidden;
			}
		}
		.signBot{
			display: block;
			.signTel{
				min-width: 0;
				font-size: .24rem;
				margin-right: 0;
			}
			.signYzm{
				::v-deep .el-input__inner{
					width: 100%;
					height: .7rem;
					font-size: .24rem;
					line-height: .7rem;
				}
				.yzmBtn{
					flex-shrink: 0;
					width: 1.4rem;
					height: .7rem;
					font-size: .24rem;
					line-height: .7rem;
				}
			}
		}
		.empowerBtn{
			margin-top: .5rem;
			span{
				width: 48%;
				font-size: .24rem;
				line-height: .7rem;
				margin: 0 1%;
			}
		}
	}
}
</style>