import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

// Sentry.init({
//     Vue,
//     dsn: "https://c88b31a0c3df4320b7c6e7cb32232de4@o1109818.ingest.sentry.io/6272321",
//     integrations: [
//         new Integrations.BrowserTracing(),
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 0.1,
// });

import './style/reset.scss'
import './style/common.scss'
import './style/custom.scss'
import './style/libmd.css'
import './utils/rem'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
