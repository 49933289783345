<template>
    <div class="g-downpopup" v-show="showpopup">
        <div class="down">
            <div class="tit">确认下载</div>
            <div class="desc">文档仅供内部交流，严禁外传</div>
            <div class="info">
                <div class="pic">
                    <img :src="src" />
                </div>
                <div class="txt">{{ fileName }}</div>
            </div>
            <div class="btns">
                <div class="btn b1" @click="close">取消</div>
                <div class="btn b2" @click="download">确定下载</div>
            </div>
            <div class="close" @click="close"><i class="el-icon-close"></i></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            showpopup: false
        }
    },
    computed: {
        src() {
            if (!this.info.icon) { return '' }
            try {
                return require('@/assets/images/file_' + this.getFormatType(this.info.icon) + '.png')
            } catch (error) {

            }
            
        },
        fileName() {
			if(this.info.fileName){ return this.info.fileName}
            if (!this.info.url) { return '' }
            let url = this.info.url
            let index = url.lastIndexOf('/')
            let filename = url.substr(index + 1)
            // let filename = url.split('?Expires')[0]
            return filename
        }
    },
    methods: {
        open() {
            this.showpopup = true
        },
        close() {
            this.showpopup = false
        },
        download() {
			let ua = navigator.userAgent.toLowerCase();
			//android终端
			let isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1; //ios终端
			let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			if ((/micromessenger/.test(ua))) {
				return this.$message.info("移动端暂不支持，请登录PC端进行下载");
			} else {
				if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
					return this.$message.info("移动端暂不支持，请登录PC端进行下载");
				}else{
					this.$emit('confirm')
				}
			}
            
        },
        // 根据类型返回类
        getFormatType(type) {
            let str = ''
            this.$store.state.infoFormatType.forEach(item => {
                if (item.type == type) {
                    str = item.src
                    return;
                }
            })
            return str
        },
    }
}
</script>

<style scoped lang="scss">
.g-downpopup {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.g-downpopup .down {
    width: 480px;
    min-height: 300px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: 30px 25px;
}

.g-downpopup .tit {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 24px;
}
.g-downpopup .close {
    position: absolute;
    top: 29px;
    right: 21px;
    // background: url(../assets/images/detail/close.png) no-repeat;
    background-size: 100%;
    width: 20px;
    height: 20px;
    font-size: 24px;
    color: #666666;
    cursor: pointer;
}
.g-downpopup .desc {
    height: 70px;
    line-height: 70px;
    background-color: #f5f5f7;
    text-align: center;
    font-size: 14px;
    color: #e83421;
    margin-bottom: 10px;
}
.g-downpopup .desc .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 10px;
}
.g-downpopup .info {
    overflow: hidden;
    padding: 20px 17px;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    margin-bottom: 30px;
}
.g-downpopup .info .pic {
    float: left;
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin-right: 19px;
}
.g-downpopup .info .txt {
    font-size: 14px;
    color: #333;
    padding-top: 5px;
}
.g-downpopup .btns {
    text-align: center;
}
.g-downpopup .btn {
    display: inline-block;
    height: 30px;
    text-align: center;
    padding: 0 22px;
    border: 1px solid #e83421;
    font-size: 14px;
    box-sizing: border-box;
    line-height: 30px;
    cursor: pointer;
    transition: 0.5s;
}
.g-downpopup .btn:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}
.g-downpopup .btn.b1 {
    background-color: #fff;
    color: #e83421;
    margin-right: 16px;
}
.g-downpopup .btn.b2 {
    background-color: #e83421;
    color: #fff;
}
@media only screen and (max-width: 750px) {
    .g-downpopup .down{ 
        width: 96%;
        min-height: 0;
        padding: .3rem;
    }
    .g-downpopup .tit{
        font-size: .36rem;
        line-height: .5rem;
    }
    .g-downpopup .close{
        width: .5rem;
        height: .5rem;
        right: .3rem;
        top: .3rem;
        font-size: .4rem;
        line-height: .5rem;
        text-align: right;
        background-size: 80% auto;
    }
    .g-downpopup .desc{
        height: .8rem;
        line-height: .8rem;
        font-size: .24rem;
    }
    .g-downpopup .info{
        padding: .3rem;
        margin-bottom: .5rem;
        .pic{
            width: .6rem;
            height: .6rem;
            margin-right: .2rem;
        }
        .txt{
            font-size: .24rem;
            line-height: .4rem;
            padding: .1rem 0;
            overflow: hidden;
        }
    }
    .g-downpopup .btn{
        padding: 0 .3rem;
        font-size: .28rem;
        height: .7rem;
        line-height: .7rem;
    }
}
</style>