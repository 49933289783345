<template>
	<div class="mypostpage">
		<Header />
		<div class="main">
			<div class="wp1200">
				<div class="m-quillEditor-m1" v-loading="loading">
					<input type="text" placeholder="请输入标题" v-model="form.title" class="tits" />
					<Editor style="width: 100%;height: 700px;" class="tinymce1" :init="init1" v-model="form.postsContent"></Editor>
					<div>
						<div v-for="(item,index) in file" :key="index" class="upload-text">
							<div>{{item.fileName}}</div><img @click="deleteimage(index)" src="../assets/images/detail/close.png"/>
						</div>

					</div>
				</div>
			</div>
			<div class="huati">
				<div class="huati-mishu">
					<div class="wp1200">
						内容安全提示：尊敬的用户您好，为了保障您、社区及第三方的合法权益，请勿发布可能给各方带来法律风险的内容，包括但不限于政治敏感内容，涉黄赌毒内容，泄露、侵犯他人商业秘密的内容，侵犯他人商标、版本、专利等知识产权的内容，侵犯个人隐私的内容等。也请勿向他人共享您的账号及密码，通过您的账号执行的所有操作，将视同您本人的行为，由您本人承担操作后果。详情请参看 “用户协议”
					</div>
				</div>
				<div class="wp1200">
					<div class="m-huati-m1">
						<div style="display: flex;" class="m-huati-m1-1">
							<div class="select" v-if="form.id ? false : true">
								<span class="select-label">选择分类</span>
								<el-select
									v-model="form.posType"
									:disabled="form.id ? true : false"
									class="m-2"
									placeholder="请选择分类"
								>
									<el-option v-for="item in forumCates" :key="item.value" :label="item.name" :value="item.id"></el-option>
								</el-select>
							</div>
							<div class="image">
								<div class="image-title">验证码</div>
								<div class="image-input">
									<input type="text" v-model="form.captcha" placeholder="请输入验证码" />
									<img :src="code.base64" @click="getcode" style="cursor: pointer;"/>
								</div>
							</div>
						</div>
						

						<div style="display: flex;align-items: center;" class="m-huati-m1-2">
							<el-upload v-if="file.length < 5" action="" :accept="accept" :show-file-list="false" :http-request="uploadPic" :on-success="handleSuccess">
								<div class="upload">上传附件</div>
							</el-upload>
							<div class="btn" @click="handleSubmit">发布</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from '@/components/Header.vue';
import Api from '@/request/api.js';

import tinymce from 'tinymce/tinymce' //tinymce默认hidden，不引入不显示
import Editor from '@tinymce/tinymce-vue' //编辑器引入
import 'tinymce/themes/silver/theme'
import 'tinymce/icons/default' //引入编辑器图标icon，不引入则不显示对应图标
// 引入编辑器插件（基本免费插件都在这儿了）
import 'tinymce/plugins/advlist' //高级列表
import 'tinymce/plugins/autolink' //自动链接
import 'tinymce/plugins/lists' //列表插件
import 'tinymce/plugins/charmap' //特殊字符
import 'tinymce/plugins/wordcount' // 字数统计
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
const fonts = [
	"宋体=宋体",
	"微软雅黑=微软雅黑",
	"新宋体=新宋体",
	"黑体=黑体",
	"楷体=楷体",
	"隶书=隶书",
	"Courier New=courier new,courier",
	"AkrutiKndPadmini=Akpdmi-n",
	"Andale Mono=andale mono,times",
	"Arial=arial,helvetica,sans-serif",
	"Arial Black=arial black,avant garde",
	"Book Antiqua=book antiqua,palatino",
	"Comic Sans MS=comic sans ms,sans-serif",
	"Courier New=courier new,courier",
	"Georgia=georgia,palatino",
	"Helvetica=helvetica",
	"Impact=impact,chicago",
	"Symbol=symbol",
	"Tahoma=tahoma,arial,helvetica,sans-serif",
	"Terminal=terminal,monaco",
	"Times New Roman=times new roman,times",
	"Trebuchet MS=trebuchet ms,geneva",
	"Verdana=verdana,geneva",
	"Webdings=webdings",
	"Wingdings=wingdings,zapf dingbats"
];

export default {
	name: 'Mypostpage',
	components: {
		Header,
			Editor
	},
	data() {
		return {
			pid: 0,
			loading: false,
			accept: '',
			init1: {
				selector: '.tinymce1',
				language_url: '/tinymce/langs/zh_CN.js', //汉化路径是自定义的，一般放在public或static里面
				language: 'zh_CN',
				skin_url: '/tinymce/skins/ui/oxide', //皮肤
				//工具栏
				plugins: 'link lists image code table colorpicker textcolor contextmenu',
				toolbar: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat',
				fontsize_formats: '12px 14px 16px 18px 20px 22px 24px 28px 32px 36px 48px 56px 72px', //字体大小
				font_formats: fonts.join(";"),
				height: 700, //高度
				branding: false,
				// 图片本地上传方法  点击上传后执行的事件
				images_upload_handler: (blobInfo, success, failure) => {
					this.handleImgUpload(blobInfo, success, failure)
				}
			},
			form: {
				title: '',
				postsContent: '',
				posType: '',
				captcha:""
			},
			value: '',
			forumCates: [],
			code:{},
			file:[]
		};
	},
	created() {
		if (this.$route.query.pid) {
			this.pid = this.$route.query.pid
			this.getForumCates()
		}
		if (this.$route.query.id) {
			this.id = this.$route.query.id
			this.getDetail()
		}
		this.form.posType = Number(this.$route.query.cateId) || '';
		//获取验证码
		this.getcode();
	},
	methods: {
		// 上传本地 图片执行事件
		handleImgUpload(blobInfo, success, failure) {
			
			Api.upload(blobInfo.blob()).then((res)=>{
				if(res.data.code == 0){
					success(res.data.message);
				}else{
					failure('HTTP Error: ' + res.data.message);
				}
			});
			// let formdata = new FormData()
			// append 方法中的第一个参数就是 我们要上传文件 在后台接收的文件名
			// 这个值要根据后台来定义
			// 第二个参数是我们上传的文件
			// formdata.append('file', blobInfo.blob())
			// this.$api.uploadimg(formdata).then((res) => {
			// 	if (res.code == 1000) {
			// 		success(res.data.url);
			// 	} else {
			// 		failure('HTTP Error: ' + res.message);
			// 	}
			// });
		},
		getcode(){
			Api.randomImage().then(res => {
				console.log(res);
				this.code = res
			})
		},
		deleteimage(index){
			this.file.splice(index,1);
		},
		// 获取分类数组
		getForumCates() {
			Api.forumCates(this.pid).then(res => {
				this.forumCates = res
				console.log(this.forumCates)
			})
		},
		getDetail() {
			Api.forumDetail({ postsId: this.id }).then(res => {
				// this.info = res.forumPosts
				console.log(res);
				this.form.title = res.title
				this.form.postsContent = res.postsContent
				// this.form.posType = Number(res.forumPosts.posType)
				this.form.id = res.id
				if(res.fileList.length > 0){
					this.file = res.fileList;
				}
				
			})
		},
		uploadFile({ file }) {
			this.loading = true
			return Api.upload(file)
		},
		uploadPic({ file }) {
			return Api.uploadtwo(file)
		},
		handleSuccess(res,file) {
			this.loading = false
			// 如果上传成功
			var t = document.body.clientHeight;
			window.scroll({ top: t, left: 0, behavior: 'smooth' });
			if (res.data.message) {
				this.file.push({fileName:file.name,filePath:res.data.message});
			} else {
				// 提示信息，需引入Message
				this.$message.error('上传失败，请重试')
			}
		},
		handleUploadSuccess(res) {
			this.loading = false
			// 获取富文本组件实例
			let quill = this.$refs.QuillEditor.quill
			// 如果上传成功
			if (res.data.message) {
				// 获取光标所在位置
				let length = quill.getSelection().index;
				// 插入图片，res为服务器返回的图片链接地址
				quill.insertEmbed(
					length,
					this.accept.includes('image') ? 'image' : 'video',
					res.data.message
				)
				// 调整光标到最后
				quill.setSelection(length + 1)
			} else {
				// 提示信息，需引入Message
				this.$message.error('图片插入失败')
			}
		},
		// 发布
		handleSubmit() {
			this.form.pid = this.pid
			if (!this.form.title) {
				return this.$message.error('请输入标题')
			}
			if (!this.form.postsContent) {
				return this.$message.error('请输入内容')
			}
			if (!this.form.posType && !this.form.id) {
				return this.$message.error('请选择分类')
			}
			if (!this.form.captcha) {
				return this.$message.error('请输入验证码')
			}
			this.form.checkKey = this.code.checkKey
			this.form.fileList = this.file
			Api.postsAdd(this.form).then(res => {
				this.$message.success('发布成功')
				setTimeout(() => {
					this.$router.replace({ path: '/forumList', query: { pid: this.pid } })
				}, 2000)
			}).finally(() => {
				this.getcode()
            })
		}
	}
}
</script>

<style>
</style>
<style lang="scss" scoped>
.wp1200 {
	max-width: 1200px;
	margin: 0 auto;
}
.main {
	margin-top: 80px;
	padding-top: 20px;
	background-color: #f5f5f7;
	overflow: hidden;
}

.m-quillEditor-m1 {
	background-color: #fff;
	width: 100%;
	/* height: 860px; */
	padding: 15px 30px 30px;
	box-sizing: border-box;
	margin-bottom: 240px;
}
.m-quillEditor-m1 .tits {
	width: 100%;
	height: 60px;
	border: none;
	outline: none;
	font-size: 24px;
	font-family: PingFang SC;
	font-weight: 500;
	/* color: #989898; */
	color: #2e2e2e;
	margin-bottom: 15px;
}
.m-quillEditor-m1 .tits::-webkit-input-placeholder {
	color: #989898;
}
.m-quillEditor-m1 .tits:-moz-placeholder {
	color: #989898;
}
.m-quillEditor-m1 .tits::-moz-placeholder {
	color: #989898;
}
.m-quillEditor-m1 .tits::-ms-input-placeholder {
	color: #989898;
}
.m-quillEditor-m1 .quill-editor {
	height: 700px;
}
.m-quillEditor-m1 .ql-editor p {
	color: #2e2e2e;
}
.m-quillEditor-m1 .ql-toolbar.ql-snow {
	border-left: none;
	border-right: none;
}

.m-quillEditor-m1 .ql-container.ql-snow {
	border: none;
}

.m-quillEditor-m1 .ql-editor {
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #989898;
	line-height: 24px;
	padding: 30px 0;
}
.m-quillEditor-m1 .ql-editor::before {
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #989898;
	line-height: 24px;
	left: 0;
	font-style: unset;
}
.huati {
	width: calc(100% - 2px);
	background-color: #fff;
	position: fixed;
	left: 1px;
	bottom: 1px;
	box-shadow: 0 0 4px rgba(0,0,0,0.5);
}
.m-huati-m1 {
	padding: 30px 0;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.m-huati-m1 .select {
}
.m-huati-m1 .select-label {
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #2e2e2e;
	line-height: 40px;
	padding-right: 15px;
}
.m-huati-m1 .el-select {
	width: 320px;
	height: 40px;
}
.m-huati-m1 .el-input__inner {
	color: #ccc;
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #707070;
}
.m-huati-m1 .el-input__inner::-webkit-input-placeholder {
	color: #707070;
}
.m-huati-m1 .el-input__inner:-moz-placeholder {
	color: #707070;
}
.m-huati-m1 .el-input__inner::-moz-placeholder {
	color: #707070;
}
.m-huati-m1 .el-input__inner::-ms-input-placeholder {
	color: #707070;
}

.m-huati-m1 .el-select .el-input.is-focus .el-input__inner,
.m-huati-m1 .el-select .el-input__inner:focus {
	border-color: #e83421;
}
.el-scrollbar .el-select-dropdown__item.selected {
	color: #e83421;
	font-weight: 700;
}
.m-huati-m1 .btn {
	display: block;
	width: 84px;
	height: 36px;
	background: #e73522;
	border-radius: 3px;
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #ffffff;
	text-align: center;
	line-height: 34px;
	cursor: pointer;
	transition: 0.5s;
	margin-left: 10px;
}
.m-huati-m1 .btn:hover {
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}
.ql-snow .ql-editor img {
	width: unset;
}
.huati-mishu{
	width: 100%;
	height: 90px;
	background: rgba(231, 53, 34, 0.05);
	border-radius: 5px;
	padding: 20px 0px;
	font-size: 14rpx;
	font-weight: 400;
	color: #E73522;
	line-height: 18px;
}
.image{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 54px;
	
}
.image-title{
	font-size: 16px;
	font-weight: 400;
	color: #2E2E2E;
	margin-right: 16px;
}
.image-input{
	width: 261px;
	height: 41px;
	border: 1px solid #CCCCCC;
	border-radius: 3px;
	padding: 6px 6px 6px 15px;
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
}
.image-input input{
	font-size: 16px;
	font-weight: 400;
	color: #707070;
	line-height: 41px;
	border: 0px;
	width: 100%;
	outline: none;
}
.image-input image{
	width: 87px;
	height: 30px;
	flex: 0 0 87px;
}

.upload{
	width: 84px;
	height: 36px;
	background: rgba(231, 53, 34, 0.1);
	border: 1px solid #E73522;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 400;
	color: #E73522;
	line-height: 36px;
	text-align: center;
}
.upload-text{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-right: 10px;
}
.upload-text div{
	height: 36px;
	background: rgba(231, 53, 34, 0.2);
	border: 1px solid #E73522;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 400;
	color: #333333;
	line-height: 36px;
	padding: 0px 10px;
	width: 50%;
	overflow: hidden;
	margin: 5px 0px;
}
.upload-text img{
	width: 15px;
	height: 15px;
}

@media only screen and (max-width: 750px) {
	.main{
		margin-top: 1.4rem;
	}
	.wp1200{
		margin: 0 .3rem;
	}
	.m-quillEditor-m1{
		padding: .3rem;
		margin-bottom: .3rem;
		.tits{
			height: .8rem;
			font-size: .4rem;
			margin-bottom: .2rem;
		}
	}
	.huati{
		position: static;
		width: auto;
		margin: 0 .3rem .6rem;
		.huati-mishu{
			height: auto;
			font-size: .24rem;
			line-height: 1.8;
			padding: .3rem 0;
		}
		.m-huati-m1{
			display: block;
			.m-huati-m1-1{
				display: block !important;
				.select{
					display: flex;
					align-items: center;
					margin-bottom: .2rem;
					.select-label{
						min-width: 1rem;
						font-size: .24rem;
						padding-right: .2rem;
					}
					.el-select{
						flex: auto;
						width: auto;
						height: auto;
						overflow: hidden;
						::v-deep .el-input{
							font-size: .24rem;
						}
						::v-deep .el-input__inner{
							height: .7rem;
							line-height: .7rem;
						}
						::v-deep .el-input__icon{
							line-height: .7rem;
						}
					}
				}
				.image{
					margin-left: 0;
					.image-title{
						min-width: 1rem;
						font-size: .24rem;
						margin-right: .2rem;
					}
					.image-input{
						flex: auto;
						height: .7rem;
						padding: .1rem  .2rem;
						border-color:#DCDFE6;
						border-radius: 4px;
						overflow: hidden;
						input{
							font-size: .24rem;
							line-height: .5rem;
						}
						img{
							width: auto;
							height: .5rem;
						}
					}
				}
			}
			.m-huati-m1-2{
				margin-top: .3rem;
				padding-left: 1.2rem;
			}
			.upload,
			.btn{
				width: 1.6rem;
				height: .6rem;
				font-size: .24rem;
				line-height: .6rem;

			}
		}
	}
}
</style>
