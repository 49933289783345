<template>
    <div class="myinfo">
        <Header />
        <div class="main">
            <div class="wp1200">
                <div class="g-back-btn">
                    <router-link to="/mypost" class="back">返回个人中心</router-link>
                </div>
                <div class="m-username-m1">
                    <div class="username-tit">账号设置</div>
                    <ul class="ul-list-m3">
                        <li>
                            <div class="con">
                                <span class="tit">账号信息</span>
                                <router-link to="/mycenter">
                                    <span class="info">修改信息</span>
                                </router-link>
                            </div>
                        </li>
                        <li>
                            <div class="con" @click="open7">
                                <span class="tit">手机号码</span>
                                <span class="info">{{ $store.state.user.telephone | strReplace }}</span>
                            </div>
                        </li>
                        <li>
                            <div class="con" @click="open5">
                                <span class="tit">邮箱地址</span>
                                <span class="info">{{ $store.state.user.email | strReplace }}</span>
                            </div>
                        </li>
                        <li>
                            <div class="con" @click="open1">
                                <span class="tit">密码管理</span>
                                <span class="info">修改密码</span>
                            </div>
                        </li>
                        <li>
                            <div class="con">
                                <span class="tit">通知管理</span>
								
								<router-link to="/notice">
									<span class="info">设置开关</span>
                                </router-link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 弹窗-选择账户验证方式 -->
        <div class="m-popup-m1" v-show="showNum == 1">
            <div class="popup">
                <div class="tit">
                    <span class="tits">修改密码</span>
                    <span class="exit" @click="showNum = 0"></span>
                </div>
                <div class="desc">
                    <div class="desc1">您正在修改账户密码，请选择账户验证方式</div>
                </div>
                <div class="content">
                    <div class="g-btns-m1">
                        <button type="button" style="width:100%" class="btn" @click="showNum = 2;changeType = 1;">手机验证</button>
                        <!-- <button type="button" class="btn" @click="showNum = 3;changeType = 2;">邮箱验证</button> -->
                    </div>
                </div>
                <div class="btns">
                    <div class="g-btns-m2">
                        <button type="button" class="btn" @click="showNum = 0">取消</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 弹窗-手机验证 -->
        <div class="m-popup-m1" v-show="showNum == 2">
            <div class="popup">
                <div class="tit">
                    <span class="tits">修改密码</span>
                    <span class="exit" @click="close1"></span>
                </div>
                <div class="desc">
                    <div class="desc1">您正在修改账户密码，请先验证手机号码</div>
                </div>
                <div class="content">
                    <div class="m-update-form-m1">
                        <div class="item">
                            <span class="label">手机号码</span>
                            <!-- <input type="text" v-model="changePassForm.mobile" placeholder="请输入手机号码" /> -->
                            <el-input
                                v-model="changePassForm.mobile"
                                type="text"
                                placeholder="请输入手机号码"
                            />
                        </div>
                        <div class="item item-qrcode">
                            <span class="label">验证码</span>
                            <el-input
                                v-model="changePassForm.smscode"
                                type="text"
                                placeholder="请输入验证码"
                            />
                            <!-- <input type="text" v-model="changePassForm.smscode" placeholder="请输入验证码" /> -->
                            <span
                                class="btn-qrcode"
                                v-if="second == 0"
                                @click="sendSms('changePassForm', 'mobile')"
                            >发送验证码</span>
                            <span class="btn-qrcode-dis" v-else>{{ second }}秒后重新发送</span>
                        </div>
                    </div>
                </div>
                <div class="btns">
                    <div class="g-btns-m2">
                        <button type="button" class="btn on" @click="validatePass('mobile')">确定</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 弹窗-邮箱验证 -->
        <div class="m-popup-m1" v-show="showNum == 3">
            <div class="popup">
                <div class="tit">
                    <span class="tits">修改密码</span>
                    <span class="exit" @click="close1"></span>
                </div>
                <div class="desc">
                    <div class="desc1">您正在修改账户密码，请先验证邮箱</div>
                </div>
                <div class="content">
                    <div class="m-update-form-m1">
                        <div class="item">
                            <span class="label">邮箱地址</span>
                            <el-input
                                v-model="changePassForm.email"
                                type="email"
                                placeholder="请输入邮箱地址"
                            />
                            <!-- <input type="email" v-model="changePassForm.email" placeholder="请输入邮箱地址" name value /> -->
                        </div>
                        <div class="item item-qrcode">
                            <span class="label">验证码</span>
                            <el-input
                                v-model="changePassForm.emailcode"
                                type="text"
                                placeholder="请输入验证码"
                            />
                            <!-- <input type="text" v-model="changePassForm.emailcode" placeholder="请输入验证码" name value /> -->
                            <span
                                class="btn-qrcode"
                                v-if="second == 0"
                                @click="sendEmail('changePassForm', 'email')"
                            >发送验证码</span>
                            <span class="btn-qrcode-dis" v-else>{{ second }}秒后重新发送</span>
                        </div>
                    </div>
                </div>
                <div class="btns">
                    <div class="g-btns-m2">
                        <button type="button" class="btn on" @click="validatePass('email')">确定</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 弹窗-请输入新的密码 -->
        <div class="m-popup-m1" v-show="showNum == 4">
            <div class="popup">
                <div class="tit">
                    <span class="tits">请输入新的密码</span>
                    <span class="exit" @click="showNum = 0"></span>
                </div>
                <div class="desc">
                    <div class="desc1">请输入新密码</div>
                </div>
                <div class="content">
                    <div class="m-update-form-m1">
                        <div class="item">
                            <span class="label">新密码</span>
                            <el-input
                                v-model="changePassForm.password"
                                type="password"
                                placeholder="请输入新密码"
                            />
                            <!-- <input type="password" v-model="changePassForm.password" placeholder="请输入新密码" /> -->
                        </div>
                        <div class="item">
                            <span class="label">重复密码</span>
                            <el-input
                                v-model="changePassForm.repass"
                                type="password"
                                placeholder="请重复输入密码"
                            />
                            <!-- <input type="password" v-model="changePassForm.repass" placeholder="请重复输入密码" /> -->
                        </div>
                    </div>
                </div>
                <div class="btns">
                    <div class="g-btns-m2">
                        <button type="button" class="btn on" @click="submitPass">确定</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 弹窗-更换邮箱-验证 -->
        <div class="m-popup-m1" v-show="showNum == 10">
            <div class="popup">
                <div class="tit">
                    <span class="tits">更换邮箱地址</span>
                    <span class="exit" @click="close5"></span>
                </div>
                <div class="desc">
                    <div class="desc1">您正在修改账户关联邮箱地址，为了您的账户安全请输入现使用邮箱地址进行验证</div>
                </div>
                <div class="content">
                    <div class="m-update-form-m1">
                        <div class="item">
                            <span class="label">原邮箱</span>
                            <el-input
                                readonly
                                v-model="changeEmailForm.old_email"
                                type="email"
                                placeholder="请输入邮箱地址"
                            />
                            <!-- <input type="email" v-model="changeEmailForm.old_email" placeholder="请输入邮箱地址" /> -->
                        </div>
                        <div class="item item-qrcode">
                            <span class="label">验证码</span>
                            <el-input
                                v-model="changeEmailForm.old_emailcode"
                                type="text"
                                placeholder="请输入验证码"
                            />
                            <!-- <input type="text" v-model="changeEmailForm.old_emailcode" placeholder="请输入验证码" /> -->
                            <span
                                class="btn-qrcode"
                                v-if="second == 0"
                                @click="sendEmail('changeEmailForm', 'old_email')"
                            >发送验证码</span>
                            <span class="btn-qrcode-dis" v-else>{{ second }}秒后重新发送</span>
                        </div>
                    </div>
                </div>
                <div class="btns">
                    <div class="g-btns-m2">
                        <button type="button" class="btn" @click="close5">取消</button>
                        <button type="button" class="btn" @click="validateOldEmail">确定</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 弹窗-更换邮箱-更换地址 -->
        <div class="m-popup-m1" v-show="showNum == 5">
            <div class="popup">
                <div class="tit">
                    <span class="tits">更换邮箱地址</span>
                    <span class="exit" @click="showNum = 0"></span>
                </div>
                <div class="desc">
                    <div class="desc1">您正在修改账户关联邮箱地址，为了您的账户安全请输入新邮箱地址完成更换</div>
                </div>
                <div class="content">
                    <div class="m-update-form-m1">
                        <div class="item">
                            <span class="label">新邮箱</span>
                            <el-input
                                v-model="changeEmailForm.new_email"
                                type="email"
                                placeholder="请输入邮箱地址"
                            />
                            <!-- <input type="email" v-model="changeEmailForm.new_email" placeholder="请输入邮箱地址" /> -->
                        </div>
                        <div class="item item-qrcode">
                            <span class="label">验证码</span>
                            <!-- <input type="text" v-model="changeEmailForm.new_emailcode" placeholder="请输入验证码" /> -->
							<el-input
								v-model="changeEmailForm.new_emailcode"
								type="text"
								placeholder="请输入验证码"
							/>
                            <span
                                class="btn-qrcode"
                                v-if="second == 0"
                                @click="sendEmail('changeEmailForm', 'new_email')"
                            >发送验证码</span>
                            <span class="btn-qrcode-dis" v-else>{{ second }}秒后重新发送</span>
                        </div>
                    </div>
                </div>
                <div class="btns">
                    <div class="g-btns-m2">
                        <button type="button" class="btn">取消</button>
                        <button type="button" class="btn on" @click="submitEmail">确定</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 弹窗-更换手机号-验证 -->
        <div class="m-popup-m1" v-show="showNum == 7">
            <div class="popup">
                <div class="tit">
                    <span class="tits">更换手机号码</span>
                    <span class="exit" @click="close7"></span>
                </div>
                <div class="desc">
                    <div class="desc1">您正在修改账户关联手机号码，为了您的账户安全请输入现使用手机号码进行验证</div>
                </div>
                <div class="content">
                    <div class="m-update-form-m1">
                        <div class="item">
                            <span class="label">原手机号</span>
                            <el-input
                                v-model="changeMobileForm.old_mobile"
                                type="text"
                                placeholder="请输入手机号码"
                            />
                            <!-- <input
                                type="text"
                                placeholder="请输入手机号码"
                                v-model="changeMobileForm.old_mobile"
                                name
                            />-->
                        </div>
                        <div class="item item-qrcode">
                            <span class="label">验证码</span>
                            <el-input
                                v-model="changeMobileForm.old_smscode"
                                type="text"
                                placeholder="请输入验证码"
                            />
                            <!-- <input
                                type="text"
                                placeholder="请输入验证码"
                                name
                                v-model="changeMobileForm.old_smscode"
                            />-->
                            <span
                                class="btn-qrcode"
                                v-if="second == 0"
                                @click="sendSms('changeMobileForm', 'old_mobile')"
                            >发送验证码</span>
                            <span class="btn-qrcode-dis" v-else>{{ second }}秒后重新发送</span>
                        </div>
                    </div>
                </div>
                <div class="btns">
                    <div class="g-btns-m2">
                        <button type="button" class="btn" @click="close7">取消</button>
                        <button type="button" class="btn on" @click="validateOldMobile">确定</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 弹窗-更换手机号-更换地址 -->
        <div class="m-popup-m1" v-show="showNum == 8">
            <div class="popup">
                <div class="tit">
                    <span class="tits">更换手机号码</span>
                    <span class="exit" @click="showNum = 0"></span>
                </div>
                <div class="desc">
                    <div class="desc1">您正在修改账户关联手机号码，为了您的账户安全请输入新用手机号码完成更换</div>
                </div>
                <div class="content">
                    <div class="m-update-form-m1">
                        <div class="item">
                            <span class="label">新手机号</span>
                            <el-input
                                v-model="changeMobileForm.new_mobile"
                                type="text"
                                placeholder="请输入手机号码"
                            />
                            <!-- <input type="text" v-model="changeMobileForm.new_mobile" placeholder="请输入手机号码" name /> -->
                        </div>
                        <div class="item item-qrcode">
                            <span class="label">验证码</span>
                            <el-input
                                v-model="changeMobileForm.new_smscode"
                                type="text"
                                placeholder="请输入验证码"
                            />
                            <!-- <input type="text" v-model="changeMobileForm.new_smscode" placeholder="请输入验证码" name /> -->
                            <span
                                class="btn-qrcode"
                                v-if="second == 0"
                                @click="sendSms('changeMobileForm', 'new_mobile')"
                            >发送验证码</span>
                            <span class="btn-qrcode-dis" v-else>{{ second }}秒后重新发送</span>
                        </div>
                    </div>
                </div>
                <div class="btns">
                    <div class="g-btns-m2">
                        <button type="button" class="btn on" @click="submitMobile">确定</button>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script lang="ts">
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Api from '@/request/api'
import { mapState } from 'vuex';

export default {
    name: 'Myinfo',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            showNum: 0,
            second: 0,
            timer: null,
            changeMobileForm: {
                old_mobile: this.$store.state.user.telephone,
                old_smscode: '',
                new_mobile: '',
                new_smscode: '',
                randomNumbers: ''
            },
            changeEmailForm: {
                old_email: this.$store.state.user.email,
                old_emailcode: '',
                new_email: '',
                new_emailcode: '',
                randomNumbers: ''
            },
            changePassForm: {
                mobile: this.$store.state.user.telephone,
                smscode: '',
                email: this.$store.state.user.email,
                emailcode: '',
                password: '',
                repass: '',
                randomNumbers: ''
            },
            changeType: null
        };
    },
    filters: {
        strReplace(val) {
            if (val) {
                return val.substr(0, 3) + '****' + val.substr(7)
            } else {
                return ''
            }
        }
    },
    methods: {
        // 打开修改密码弹窗
        open1() {
            this.changePassForm = {
                mobile: this.$store.state.user.telephone,
                smscode: '',
                email: this.$store.state.user.email,
                emailcode: '',
                password: '',
                repass: ''
            }
            this.showNum = 1
        },
        close1() {
            if (this.timer) { clearInterval(this.timer);this.second = 0 }
            this.showNum = 0
        },
        // 打开修改邮箱
        open5() {
            this.changeEmailForm = {
                old_email: this.$store.state.user.email,
                old_emailcode: '',
                new_email: '',
                new_emailcode: ''
            },
                this.showNum = 5
        },
        close5() {
            if (this.timer) { clearInterval(this.timer);this.second = 0 }
            this.showNum = 0
        },
        // 打开修改手机号
        open7() {
            this.changeMobileForm = {
                old_mobile: this.$store.state.user.telephone,
                old_smscode: '',
                new_mobile: '',
                new_smscode: ''
            }
            console.log(this.changeMobileForm)
            this.showNum = 7
        },
        close7() {
            if (this.timer) { clearInterval(this.timer);this.second = 0 }
            this.showNum = 0
        },
        // 前端验证
        validMobileForm(mobile, code) {
            if (!this.changeMobileForm[mobile]) {
                this.$message.error('请输入手机号')
                throw new Error('请输入手机号')
            }
            if (!/^1[3456789]\d{9}$/.test(this.changeMobileForm[mobile])) {
                this.$message.error('请输入正确得手机号')
                throw new Error('请输入正确得手机号')
            }
            if (!this.changeMobileForm[code]) {
                this.$message.error('请输入验证码')
                throw new Error('请输入验证码')
            }
        },
        validEmailForm(email, code) {
            if (!this.changeEmailForm[email]) {
                this.$message.error('请输入邮箱')
                throw new Error('请输入邮箱')
            }
            if (!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/.test(this.changeEmailForm[email])) {
                this.$message.error('请输入正确得邮箱')
                throw new Error('请输入正确得邮箱')
            }
            if (!this.changeEmailForm[code]) {
                this.$message.error('请输入验证码')
                throw new Error('请输入验证码')
            }
        },
        // 验证旧手机号和验证码
        validateOldMobile() {
            this.validMobileForm('old_mobile', 'old_smscode')
            Api.validateCode({ username: this.changeMobileForm.old_mobile, code: this.changeMobileForm.old_smscode }).then(res => {
                this.changeMobileForm.randomNumbers = res
                clearInterval(this.timer)
                this.second = 0
                this.showNum = 8
            })
        },
        // 验证旧邮箱
        validateOldEmail() {
            Api.validateCode({ username: this.changeEmailForm.old_email, code: this.changeEmailForm.old_emailcode }).then(res => {
                console.log(res)
                this.changeEmailForm.randomNumbers = res
                clearInterval(this.timer)
                this.second = 0
                this.showNum = 6
            })
        },
        validatePass(type) {
            let field = ''
            let code = ''
            if (type == 'mobile') {
                if (!this.changePassForm.smscode) { return this.$message.error('请输入验证码') }
                field = this.changePassForm.mobile
                code = this.changePassForm.smscode
            } else {
                if (!this.changePassForm.emailcode) { return this.$message.error('请输入验证码') }
                field = this.changePassForm.email
                code = this.changePassForm.emailcode
            }
            Api.validateCode({ username: field, code: code }).then(res => {
                this.changePassForm.randomNumbers = res
                clearInterval(this.timer)
                this.second = 0
                this.showNum = 4
            })
        },
        // 提交修改手机号
        submitMobile() {
            this.validMobileForm('new_mobile', 'new_smscode')
            const data = {
                randomNumbers: this.changeMobileForm.randomNumbers,
                captcha: this.changeMobileForm.new_smscode,
                telephone: this.changeMobileForm.new_mobile
            }
            console.log(data)
            Api.updateUser(data).then(res => {
                clearInterval(this.timer)
                this.second = 0
                this.showNum = 0
                this.$message.success('修改成功')
                // this.$store.dispatch('logout')
                this.$router.go(0)
            })
        },
        // 提交修改邮箱
        async submitEmail() {
            // 前端验证邮箱格式
            this.validEmailForm('new_email', 'new_emailcode')
            // 后端验证邮箱是否存在
            const result = await Api.verifyUnique({
                colName: 'email',
                tableName: 'user',
                value: this.changeEmailForm.new_email
            })
            console.log(result)
            const data = {
                randomNumbers: this.changeEmailForm.randomNumbers,
                code: this.changeEmailForm.new_emailcode,
                email: this.changeEmailForm.new_email
            }
            console.log(data)
            Api.updateEmail(data).then(res => {
                console.log(res)
                clearInterval(this.timer)
                this.second = 0
                this.showNum = 0
                this.$message.success('修改成功')
                // this.$store.dispatch('logout')
                this.$router.go(0)
            })
        },
        // 提交修改密码
        submitPass() {
            if (this.changePassForm.password != this.changePassForm.repass) {
                return this.$message.error('密码不一致')
            }
            let data = {
                randomNumbers: this.changePassForm.randomNumbers,
				password: this.changePassForm.password
			}
            if (this.changeType == 1) {
                data.telephone2 = this.changePassForm.mobile
            }
            if (this.changeType == 2) {
                data.email2 = this.changePassForm.email
            }
            console.log(data)
            Api.updateUser(data).then(res => {
                console.log(res)
                clearInterval(this.timer)
                this.second = 0
                this.showNum = 0
                this.$message.success('修改成功')
                this.$store.dispatch('logout')
                this.$router.replace('/')
            })
        },
        sendSms(form, type) {
            if (!this[form][type]) {
                return this.$message.error('请先输入手机号')
            }
            if (!/^1[3456789]\d{9}$/.test(this[form][type])) {
                return this.$message.error('请输入正确的手机号')
            }
            let codeType = 1
            if (type == 'old_mobile') {
                codeType = 2
            } else {
                codeType = 1
            }
            Api.sendSms({ mobile: this[form][type], smsmode: codeType }).then(res => {
                this.second = 10
                this.timer = setInterval(() => {
                    this.second--
                    if (this.second == 0) {
                        clearInterval(this.timer)
                    }
                }, 1000)
                this.$message.success('短信发送成功');
            })
        },
        sendEmail(form, type) {
            if (!this[form][type]) {
                return this.$message.error('请输入邮箱')
            }
            if (!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/.test(this[form][type])) {
                return this.$message.error('请输入正确的邮箱')
            }
			if(this.second != 0){
				return false;
			}
            Api.sendEmail({ email: this[form][type] }).then(res => {
                this.second = 10
                this.timer = setInterval(() => {
                    this.second--
                    if (this.second == 0) {
                        clearInterval(this.timer)
                    }
                }, 1000)
                this.$message.success('发送成功');
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.wp1200 {
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
}
.main {
    background-color: #e3e4e5;
    margin-top: 80px;
}
.g-back-btn {
}
.g-back-btn .back {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 60px;
    background-image: url(../assets/images/home/icon-m5.png);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 25px;
}
.m-username-m1 {
    background-color: #fff;
    margin-bottom: 160px;
}
.m-username-m1 .username-tit {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 60px;
    border-bottom: 1px solid #e4e4e4;
    padding-left: 20px;
}
.ul-list-m3 {
    padding: 17px 30px 23px;
}
.ul-list-m3 li {
}
.ul-list-m3 .con {
    display: block;
    overflow: hidden;
    cursor: pointer;
}
.ul-list-m3 .tit {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 50px;
    float: left;
}
.ul-list-m3 .info {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 50px;
    float: right;
    padding-right: 25px;
    background-image: url(../assets/images/home/icon-m4-2.png);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 14px 14px;
    transition: 0.5s;
}
.ul-list-m3 li:hover .con .info {
    color: #e83421;
}
.m-popup-m1 {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6);
}
.m-popup-m1 .popup {
    width: 480px;
    height: 380px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 23px 25px 0;
    box-sizing: border-box;
}
.m-popup-m1 .tit {
    overflow: hidden;
    position: relative;
    margin-bottom: 17px;
}
.m-popup-m1 .tits {
    font-size: 16px;
    line-height: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    float: left;
}
.m-popup-m1 .exit {
    width: 20px;
    height: 20px;
    background-image: url(../assets/images/myinfo/icon-w1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
    transition: 0.5s;
}
.m-popup-m1 .exit:hover {
    transform: translateY(-50%) rotateZ(360deg);
}
.m-popup-m1 .desc {
    width: 100%;
    /* height: 50px; */
    background-color: #f5f5f7;
    padding: 15px 20px 15px 60px;
    background-image: url(../assets/images/myinfo/icon-w2.png);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 20px center;
    margin-bottom: 30px;
}
.m-popup-m1 .desc1 {
    position: relative;
    padding-left: 15px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
}
.m-popup-m1 .desc1::after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    background-color: #333333;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.m-popup-m1 .content {
}
.m-popup-m1 .btns {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 40px;
    display: flex;
    justify-content: center;
}

.g-btns-m1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.g-btns-m1 .btn {
    width: 201px;
    height: 51px;
    border: 1px solid #999;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 49px;
    text-align: center;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666;
    outline: none;
    cursor: pointer;
    background-color: #fff;
    transition: 0.5s;
}
.g-btns-m1 .btn:hover {
    color: #e83421;
    border-color: #e83421;
}

.g-btns-m2 {
}
.g-btns-m2 .btn {
    width: 71px;
    height: 31px;
    border: 1px solid #e83421;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    font-family: PingFang SC;
    font-weight: 400;
    color: #e83421;
    background-color: #fff;
    margin: 0 10px;
    cursor: pointer;
    transition: 0.5s;
}
.g-btns-m2 .btn.on {
    color: #fff;
    background-color: #e83421;
}
.g-btns-m2 .btn:hover{
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}
.m-update-form-m1 {
}
.m-update-form-m1 .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}
.m-update-form-m1 .label {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
}
.m-update-form-m1 .el-input {
    width: 346px;
    height: 35px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
}
/* .m-update-form-m1 input{
    width: 346px;
    height: 35px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    outline: none;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    padding: 0 10px;
}
.m-update-form-m1 input::-webkit-input-placeholder {
    color: #999999;
}
.m-update-form-m1 input:-moz-placeholder {
    color: #999999;
}
.m-update-form-m1 input::-moz-placeholder {
    color: #999999;
}
.m-update-form-m1 input::-ms-input-placeholder {
    color: #999999;
} */
.m-update-form-m1 .item-qrcode {
    position: relative;
}
.m-update-form-m1 .item-qrcode .btn-qrcode {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #e83421;
    line-height: 20px;
}
.m-update-form-m1 .item-qrcode .btn-qrcode-dis {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999;
    line-height: 20px;
}

@media only screen and (max-width: 750px) {
	.main{
		margin-top: 1.4rem;
		overflow: hidden;
	}
	.g-back-btn{
		padding: .2rem .3rem;
	}
	.g-back-btn .back{
		font-size: .24rem;
		line-height: .6rem;
		background-size: .4rem auto;
		padding-left: .4rem;
	}
    .m-username-m1{
		margin: 0 .3rem .6rem;
		.username-tit{
			font-size: .32rem;
			line-height: 2;
			padding-left: .3rem;
		}
        .ul-list-m3{
            padding: .3rem;
            li{
                margin: .1rem 0;
            }
            .tit{
                font-size: .28rem;
                line-height: .7rem;
            }
            .info{
                font-size: .28rem;
                line-height: .7rem;
                padding-right: .4rem;
                background-size: .3rem auto;
            }
        }
	}
    .m-popup-m1{
        .popup{
            width: 92%;
            height: auto;
            padding: .3rem;
            .tit{
                margin-bottom: .2rem;
                .tits{
                    font-size: .32rem;
                    line-height: .6rem;
                }
                .exit{
                    width: .4rem;
                    height: .4rem;
                }
            }
            .desc{
                padding: .3rem;
                padding-left: .6rem;
                margin-bottom: .3rem;
                background-size: .4rem auto;
                .desc1{
                    font-size: .28rem;
                    line-height: .44rem;
                    &::after{
                        display: none;
                    }
                }
            }
            .content{
                margin: .3rem 0;
                .g-btns-m1{
                    .btn{
                        width: 48%;
                        height: .64rem;
                        font-size: .28rem;
                        line-height: .62rem;
                    }
                }
            }
            .m-update-form-m1{
                .item{
                    margin-bottom: .2rem;
                    .label{
                        min-width: 1.32rem;
                        font-size: .28rem;
                        line-height: .7rem;
                    }
                    .el-input{
                        flex: auto;
                        height: auto;
                        overflow: hidden;
                        ::v-deep input{
                            height: .7rem;
                            font-size: .28rem;
                            line-height: .7rem;
                            padding: 0 .2rem;
                            border-radius: .04rem;
                        }
                    }
                    .btn-qrcode{
                        right: .2rem;
                        font-size: .28rem;
                        line-height: .7rem;
                    }
                }
            }
            .btns{
                position: static;
                .btn{
                    width: 1.6rem;
                    height: .6rem;
                    font-size: .28rem;
                    line-height: .58rem;
                    margin: 0 .1rem;
                }
            }
        }
    }
}
</style>

<style type="text/css">
	.m-update-form-m1 .el-input input {
		height: 35px;
		line-height: 35px;
		border: 1px solid #ccc;
		padding: 0 10px;
	}
	.m-update-form-m1 .el-input input::-webkit-input-placeholder {
		color: #999999;
	}
	.m-update-form-m1 .el-input input:-moz-placeholder {
		color: #999999;
	}
	.m-update-form-m1 .el-input input::-moz-placeholder {
		color: #999999;
	}
	.m-update-form-m1 .el-input input::-ms-input-placeholder {
		color: #999999;
	}
</style>