import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ChannelInformation from '@/views/channel_information'
import GeneralProduct from '@/views/general_products'
import Register from '@/views/register'
import CompanyRegister from '@/views/companyRegister'
import Detail from '@/views/detail'
import Mycenter from '@/views/mycenter'
import Notice from '@/views/notice'
import Forumlist from '@/views/forumlist'
import Forumdetail from '@/views/forumdetail'
import Myinfo from '@/views/myinfo'
import Mycomments from '@/views/mycomments'
import Protocol from '@/views/protocol'
import Mysubscribe from '@/views/mysubscribe'
import Mypost from '@/views/mypost'
import Mypostpage from '@/views/mypostpage'
import SearchResults from '@/views/search_results'
import News from '@/views/news'
import Newsinfo from '@/views/newsinfo'
import { Message } from 'element-ui'
import store from '@/store'
import Partner from '@/views/partner'
import PartnerPlan from '@/views/partner_plan'
import PartnerEmpower from '@/views/partner_empower'
import PartnerSignone from '@/views/partner_sign_0'
import PartnerSign from '@/views/partner_sign_01'
import PartnerSign2 from '@/views/partner_sign_02'
import PartnerSign3 from '@/views/partner_sign_03'
import selectpartner from '@/views/selectpartner'
import authIdentity from '@/views/authIdentity'
import companyinfo from '@/views/companyinfo'
import messageinfo from '@/views/messageinfo'

import signinfo from '@/views/signinfo'




Vue.use(VueRouter)

const routes = [
	{
		path: '/login',
		name: 'Home',
		component: Home
	},
	{
		path: '/',
		name: 'News',
		component: News
	},
	{
		path: '/newsinfo',
		name: 'Newsinfo',
		component: Newsinfo
	},
	{
		path: '/user',
		name: 'User',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/user/index.vue')
	},
	//渠道政策
	{
		path: '/ChannelInformation',
		name: 'ChannelInformation',
		component: ChannelInformation
	},
	//通用产品列表
	{
		path: '/GeneralProduct',
		name: 'GeneralProduct',
		component: GeneralProduct
	},
	{
		path: '/register',
		name: 'Register',
		component: Register
	},
	{
		path: '/companyRegister',
		name: 'CompanyRegister',
		component: CompanyRegister
	},
	{
		path: '/detail',
		name: 'Detail',
		meta: {x: 0, y: 0},
		component: Detail
	},
	//账号信息
	{
		path: '/mycenter',
		name: 'Mycenter',
		meta: {x: 0, y: 0},
		component: Mycenter
	},
	{
		path: '/notice',
		name: 'Notice',
		meta: {x: 0, y: 0},
		component: Notice
	},
	{
		path: '/forumlist',
		name: 'Forumlist',
		component: Forumlist
	},
	//账号设置
	{
		path: '/myinfo',
		name: 'Myinfo',
		component: Myinfo
	},
	{
		path: '/forumdetail',
		name: 'Forumdetail',
		meta: {x: 0, y: 0},
		component: Forumdetail
	},
	//我的评论
	{
		path: '/mycomments',
		name: 'Mycomments',
		component: Mycomments
	},
	//协议界面
	{
		path: '/protocol',
		name: 'Protocol',
		component: Protocol
	},
	{
		path: '/messageinfo',
		name: 'messageinfo',
		component: messageinfo
	},
	
	//我的帖子
	{
		path: '/mypost',
		name: 'Mypost',
		component: Mypost
	},
	//我的订阅
	{
		path: '/mysubscribe',
		name: 'Mysubscribe',
		component: Mysubscribe
	},
	//发帖子
	{
		path: '/mypostpage',
		name: 'Mypostpage',
		meta: {x: 0, y: 0},
		component: Mypostpage
	},
	{
		path: '/authIdentity',
		name: 'authIdentity',
		meta: {x: 0, y: 0},
		component: authIdentity
	},
	{
		path: '/signinfo',
		name: 'signinfo',
		meta: {x: 0, y: 0},
		component: signinfo
	},
	
	//搜索结果页
	{
		path: '/searchResults',
		name: 'SearchResults',
		component: SearchResults
	},


	// 我下载的
	{
		path: '/mydown',
		name: 'Mydown',
		component: () => import('../views/my-down.vue')
	},
	// 我收藏的
	{
		path: '/mycollect',
		name: 'Mycollect',
		component: () => import('../views/my-collect.vue')
	},
	// 我点赞的
	{
		path: '/mylike',
		name: 'Mylike',
		component: () => import('../views/my-like.vue')
	},
	// 浏览记录
	{
		path: '/browserecords',
		name: 'Browserecords',
		component: () => import('../views/browse-records.vue')
	},
	{
		path: '/mobile',
		name: 'Mobile',
		component: () => import('../views/mobile.vue')
	},
    // 成为合作伙伴
	{
		path: '/partner',
		name: 'Partner',
        component: Partner
	},    
    // 分销伙伴合作计划
	{
		path: '/partnerplan',
		name: 'PartnerPlan',
		meta: {x: 0, y: 0},
        component: PartnerPlan
	},
    // 公司签约授权
    {
		path: '/partnerempower',
		name: 'PartnerEmpower',
		meta: {x: 0, y: 0},
        component: PartnerEmpower
	},
    {
		path: '/companyinfo',
		name: 'companyinfo',
		meta: {x: 0, y: 0},
        component: companyinfo
	},
	
    // 合作伙伴-填写认证信息
    {
		path: '/partnersign',
		name: 'PartnerSign',
		meta: {x: 0, y: 0},
        component: PartnerSign
	},
    {
		path: '/PartnerSignone',
		name: 'PartnerSignone',
		meta: {x: 0, y: 0},
        component: PartnerSignone
	},
	
    // 合作伙伴-填写认证信息-02
    {
		path: '/partnersign2',
		name: 'PartnerSign2',
        component: PartnerSign2
	},
    // 合作伙伴-填写认证信息-03
    {
		path: '/partnersign3',
		name: 'PartnerSign3',
        component: PartnerSign3
	},
	//查询合作伙伴
	{
		path:"/selectpartner",
		name:"selectpartner",
		component:selectpartner
	}
]

const scrollBehavior = function (to, from, savedPosition) {
	return { x: 0, y: 0 }
}

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior
})

router.beforeEach((to, form, next) => {
	if (localStorage.getItem('token')) {
		next()
	} else {
		
		if (to.path == '/register' || to.path == '/login' || to.path == '/selectpartner' || to.path=="/newsinfo" || to.path=='/partner' || to.path=="/news" || to.path == '/companyRegister' || to.path == '/' || to.path == '/protocol' || to.path == '/mobile' ) {
			next()
		} else {
			Message.error('请登录后进行操作')
            store.dispatch('logout')
			next('/login')
		}
	}
})

export default router
