<template>
    <div class="partner">
        <Header />
        <div v-if="banner.length > 0" class="ban">
			<el-carousel>
			  <el-carousel-item v-for="item in banner" :key="item.id">
				<img @click="tourl(item)" :src="item.image" />
			  </el-carousel-item>
			</el-carousel>
        </div>
        <div class="parBg1">
            <div class="wp w1200">
                <h2 class="parTit">合作申请步骤<a :href="url" target="_blank" download class="parGuide">签约操作指南</a></h2>
                <ul class="stepList">
                    <li>
                        <div class="stepIcon">
                            <img src="../assets/images/partner/stepIcon1@2x.png" alt="公司账号注册">
                            <div class="stepArrow"><img src="../assets/images/partner/stepArrow.png"></div>
                        </div>
                        <p>公司账号注册</p>
                    </li>
                    <li>
                        <div class="stepIcon">
                            <img src="../assets/images/partner/stepIcon2@2x.png" alt="公司签约授权">
                            <div class="stepArrow"><img src="../assets/images/partner/stepArrow.png"></div>
                        </div>
                        <p>公司签约授权</p>
                    </li>
                    <li>
                        <div class="stepIcon">
                            <img src="../assets/images/partner/stepIcon3@2x.png" alt="渠道合作协议签约">
                            <div class="stepArrow"><img src="../assets/images/partner/stepArrow.png"></div>
                        </div>
                        <p>渠道合作协议签约</p>
                    </li>
                    <li>
                        <div class="stepIcon">
                            <img src="../assets/images/partner/stepIcon4@2x.png" alt="颁发证书">
                            <div class="stepArrow"><img src="../assets/images/partner/stepArrow.png"></div>
                        </div>
                        <p>颁发证书</p>
                    </li>
                    <li>
                        <div class="stepIcon">
                            <img src="../assets/images/partner/stepIcon5@2x.png" alt="归档">
                            <div class="stepArrow"><img src="../assets/images/partner/stepArrow.png"></div>
                        </div>
                        <p>结束</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="parBg2">
            <div class="wp w1200">
                <h2 class="parTit">合作计划</h2>
                <ul class="planList">
					<router-link tag="li" :to="'/PartnerPlan?id='+item.id" v-for="item in list" :key="item.id">
							<div class="planLink">
								<div class="planImg">
									<img :src="item.image" alt="">
								</div>
								<div class="planText">
									<h3 class="planTit">{{item.title}}</h3>
									<div class="planCon">
										<div class="planDes">
											<p>{{item.info}}</p>
										</div>
									</div>
								</div>
							</div>
					</router-link>
                </ul>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Api from '@/request/api.js';
export default {
    name: 'Partner',
    components: { 
        Header,
        Footer 
    },
    data() {
        return {
			banner:[],
			list:[],
			url:""
        }
    },
	created() {
		//获取轮播图
		Api.banner({position:2}).then(res => {
			this.banner = res;
		})
	},
	mounted() {
		//获取合作计划
		Api.getPlanList().then(res => {
			this.list = res;
		})
		Api.getsiteConfig(2).then(res=>{
			this.url = res.content;
		})
	},
	methods:{
		
		tourl(item){
			Api.updateClickNum({id:item.id});
			if(item.link){
				window.open(item.link);
			}
		}
	}
}
</script>
<style scoped lang="scss">
.partner{
    padding: 80px 0 0;
    overflow: hidden;
}
.parTit{
    position: relative;
    font-size: 48px;
    color: #111111;
    line-height: 1.2;
    text-align: center;
}
.parGuide{
    position: absolute;
    right: 0;
    top: 0;
    font-size: 16px;
    color: #2E2E2E;
    line-height: 30px;
    padding: 10px 22px;
    border-radius: 3px;
    background-color: #E3E4E5;
    font-weight: normal;
    cursor: pointer;
    transition: all .3s;
    &:hover{
        color: #FFFFFF;
        background-color: #E73522;
    }
}
.parBg1{
    padding: 60px 0;
    background-color: #FBFBFB;
}
.stepList{
    list-style: none;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    color: #111111;
    text-align: center;
    margin: 80px -15px;
    li{
        flex: 1;
        padding: 15px;
        &:last-child{
            .stepArrow{
                display: none;
            }
        }
        p{
            margin-top: 30px;
            font-weight: bold;
        }
    }
    .stepIcon{
        position: relative;
        > img{
            display: block;
            width: 110px;
            height: 110px;
            margin: 10px auto;
        }
    }
    .stepArrow{
        position: absolute;
        left: 100%;
        top: 50%;
        width: 100%;
        transform: translate(-45%,-50%);
        > img{
            display: block;
            width: 71px;
            height: 28px;
            margin: 0 auto;
        }
    }
}
.parBg2{
    padding: 70px 0;
    background-color: #F5F5F7;
}
.planList{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -20px;
    margin-top: 20px;
    li{
        width: 50%;
        padding: 0 20px;
        margin-top: 40px;
        .planLink{
            display: block;
            height: 100%;
            background: #FFFFFF;
            cursor: pointer;
            transition: all .3s;
            .planImg{
                position: relative;
                height: 0;
                padding-bottom: 51.72%;
                overflow: hidden;
                img{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .planText{
                padding: 10px 20px;
                .planTit{
                    font-size: 18px;
                    color: #111111;
                    line-height: 1.6;
                    margin: 10px 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    transition: all .3s;
                }
                .planCon{
                    font-size: 14px;
                    color: #666666;
                    line-height: 1.8;
                    margin: 10px 0;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
            }
            &:hover{
                box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.1);
                .planTit{
                    color: #E73522;
                }
            }
        }
    }
}

::v-deep .el-carousel__container{
	height: 350px;
}
::v-deep .el-carousel__container img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
@media only screen and (max-width: 750px) {
    .ban{
        margin-top: 1.4rem;
    }
    .partner{
        padding: 0;
    }
    .parTit{
        font-size: .36rem;
        line-height: .5rem;
    }
    .parGuide{
        font-size: .24rem;
        padding: 0 .15rem;
        line-height: .5rem;
    }
    .parBg1{
        padding: .6rem 0;
    }
    .stepList{
        font-size: .32rem;
        flex-wrap: wrap;
        justify-content: center;
        margin: .6rem -.2rem;
        li{
            flex: none;
            width: 33.33%;
            padding: .2rem;
            &:nth-child(3){
                .stepArrow{
                    display: none;
                }
            }
            p{
                margin-top: .3rem;
            }
        }
        .stepIcon{
            > img{
                width: 1rem;
                height: 1rem;
                margin: .1rem auto;
            }
        }
        .stepArrow{
            > img{
                width: .4rem;
                height: .2rem;
            }
        }
    }
    .parBg2{
        padding: .6rem 0;
        background-color: #F5F5F7;
    }
    .planList{
        margin: 0 -.1rem;
        margin-top: .2rem;
        li{
            width: 50%;
            padding: 0 .1rem;
            margin-top: .2rem;
            .planLink{
                .planText{
                    padding: .1rem .2rem;
                    .planTit{
                        font-size: .28rem;
                        margin: .1rem 0;
                    }
                    .planCon{
                        font-size: .24rem;
                        margin: .1rem 0;
                    }
                }
                &:hover{
                    box-shadow: 0 0 .15rem rgba($color: #000000, $alpha: 0.1);
                }
            }
        }
    }
    ::v-deep .el-carousel__container{
		height: 2rem;
	}
}
</style>