<template>
	<div class="mycenter">
		<Header />
		<div class="main">
			<div class="wp1200">
				<div class="g-back-btn">
					<router-link to="/myinfo" class="back">返回账号设置</router-link>
				</div>
				<div class="m-username-m1">
					<div class="username-tit">通知管理</div>
					<div class="title nav">
						<div class="one"></div>
						<div class="two"></div>
						<div class="three">站内通知</div>
						<div class="four">邮件通知</div>
					</div>
					<div class="title">
						<div class="one">用户功能提醒</div>
						<div class="two">
							<div>发布主贴收到</div>
							<div>参与帖子收到回复</div>
						</div>
						<div class="three">
							<div><el-switch v-model="info.postsReplyLnside" :active-value="0" :inactive-value="1" active-color="#CD0000" inactive-color="#666"></el-switch></div>
							<div><el-switch v-model="info.partakeReplyLnside" :active-value="0" :inactive-value="1" active-color="#CD0000" inactive-color="#666"></el-switch></div>
						</div>
						<div class="four">
							<div><el-switch v-model="info.postsReplyEmail" :active-value="0" :inactive-value="1" active-color="#CD0000" inactive-color="#666"></el-switch></div>
							<div><el-switch v-model="info.partakeReplyEmail" :active-value="0" :inactive-value="1" active-color="#CD0000" inactive-color="#666"></el-switch></div>
						</div>
					</div>
					<div class="title" v-if="status > 0">
						<div class="one">版主功能提醒</div>
						<div class="two">
							<div>版块有新帖</div>
							<div>版块内有新回复</div>
						</div>
						<div class="three">
							<div><el-switch v-model="info.newPostsLnside" :active-value="0" :inactive-value="1" active-color="#CD0000" inactive-color="#666"></el-switch></div>
							<div><el-switch v-model="info.newReplyLnside" :active-value="0" :inactive-value="1" active-color="#CD0000" inactive-color="#666"></el-switch></div>
						</div>
						<div class="four">
							<div><el-switch v-model="info.newPostsEmail" :active-value="0" :inactive-value="1" active-color="#CD0000" inactive-color="#666"></el-switch></div>
							<div><el-switch v-model="info.newReplyEmail" :active-value="0" :inactive-value="1" active-color="#CD0000" inactive-color="#666"></el-switch></div>
						</div>
					</div>
					<div class="title" v-else>
						<div class="one" style="color: #999;">版主功能提醒</div>
						<div class="two">
							<div style="color: #999;">版块有新帖</div>
							<div style="color: #999;">版块内有新回复</div>
						</div>
						<div class="three">
							<div><el-switch disabled v-model="info.newPostsLnside" :active-value="0" :inactive-value="1" active-color="#CD0000" inactive-color="#666"></el-switch></div>
							<div><el-switch disabled v-model="info.newReplyLnside" :active-value="0" :inactive-value="1" active-color="#CD0000" inactive-color="#666"></el-switch></div>
						</div>
						<div class="four">
							<div><el-switch disabled v-model="info.newPostsEmail" :active-value="0" :inactive-value="1" active-color="#CD0000" inactive-color="#666"></el-switch></div>
							<div><el-switch disabled v-model="info.newReplyEmail" :active-value="0" :inactive-value="1" active-color="#CD0000" inactive-color="#666"></el-switch></div>
						</div>
					</div>
					<div class="title">
						<div class="one">其他提醒</div>
						<div class="two">
							<div>私信</div>
							<div>订阅通知</div>
						</div>
						<div class="three">
							<div>-</div>
							<div>-</div>
						</div>
						<div class="four">
							<div><el-switch v-model="info.wordsEmail" :active-value="0" :inactive-value="1" active-color="#CD0000" inactive-color="#666"></el-switch></div>
							<div><el-switch v-model="info.subscribeEmail" :active-value="0" :inactive-value="1" active-color="#CD0000" inactive-color="#666"></el-switch></div>
						</div>
					</div>
					
					<div class="save" @click="submitinfo">保存</div>
				</div>
				<div class="bottom"></div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Api from '@/request/api';

export default {
	name: 'Mycenter',
	components: {
		Header,
		Footer
	},
	data() {
		return {
			info:{
				postsReplyLnside: 1,//0开 1关 发布主贴收到-站内通知
				partakeReplyLnside:1,//参与帖子收到回复-站内通知
				postsReplyEmail: 1,//发布主贴收到-邮件通知
				partakeReplyEmail: 1,//参与帖子收到回复-邮件通知
				newPostsLnside: 1,//板块新贴通知-站内通知
				newReplyLnside: 1,//板块内有新回复通知-站内通知
				newPostsEmail: 1,//板块新贴通知-邮件通知
				newReplyEmail:1,//板块内有新回复通知-邮件通知
				wordsEmail: 1,//留言-邮件通知
				subscribeEmail: 1//订阅通知-邮件通知
			},
			status:0
		}
	},
	mounted() {
		
		Api.getModerator().then(res=>{
			this.status = res;
			
		})
		Api.userNoticeget().then(res=>{
			if(res != null){
				console.log(res);
				this.info = res;
			}
			
		})
	},
	methods: {
		submitinfo(){
			Api.userNoticeSet(this.info).then(res=>{
				this.$message.success("设置成功");
				this.$router.push("/myinfo");
			})
			
		}
	}
}
</script>

<style scoped lang="scss">
	.m-username-m1{
		padding-bottom: 45px;
		.nav{
			margin-top: 20px;
		}
		.title{
			display: flex;
			justify-content: flex-start;
			margin-top: 20px;
		}
		.one{
			flex: 0 0 109px;
			margin-left: 20px;
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			line-height: 44px;
		}
		.two{
			margin-left: 101px;
			flex:0 0 162px;
			font-size: 16px;
			font-weight: 400;
			color: #111111;
			line-height: 40px;
		}
		.three{
			font-size: 16px;
			font-weight: 600;
			color: #333333;
			line-height: 40px;
			text-align: center;
			margin-left: 228px;
			flex: 0 0 64px;
		}
		.four{
			font-size: 16px;
			font-weight: 600;
			color: #333333;
			line-height: 40px;
			text-align: center;
			margin-left: 237px;
			flex: 0 0 64px;
		}
		.save{
			width: 280px;
			height: 50px;
			background: #E83421;
			border-radius: 3px;
			font-size: 16px;
			font-weight: 400;
			color: #FFFFFF;
			text-align: center;
			line-height: 50px;
			margin: 69px auto 60px;
			cursor: pointer;
		}
	}
.wp1200 {
	max-width: 1200px;
	margin: 0 auto;
}
.bottom{
	background-color: #e3e4e5;
	height: 80px;
}
.main {
	background-color: #e3e4e5;
	margin-top: 80px;
}
.g-back-btn {
}
.g-back-btn .back {
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #333333;
	line-height: 60px;
	background-image: url(../assets/images/home/icon-m5.png);
	background-size: 16px 16px;
	background-repeat: no-repeat;
	background-position: left center;
	padding-left: 25px;
}
.m-username-m1 {
	background-color: #fff;
	margin-bottom: 80px;
}
.m-username-m1 .username-tit {
	font-size: 18px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #333333;
	line-height: 60px;
	border-bottom: 1px solid #e4e4e4;
	padding-left: 20px;
}

@media only screen and (max-width: 750px) {	
	.main{
		margin-top: 1.4rem;
		overflow: hidden;
	}
	.g-back-btn{
		padding: .2rem .3rem;
	}
	.g-back-btn .back{
		font-size: .24rem;
		line-height: .6rem;
		background-size: .4rem auto;
		padding-left: .4rem;
	}
	.m-username-m1{
		margin: 0 .3rem .6rem;
		padding-bottom: .3rem;
		.username-tit{
			font-size: .32rem;
			line-height: 2;
			padding-left: .3rem;
		}
		.title{
			flex-wrap: wrap;
			margin-top: .3rem;
			.one{
				flex: 0 0 100%;
				font-size: .32rem;
				margin: 0;
				padding-left: .3rem;
			}
			.two{
				font-size: .28rem;
				line-height: .6rem;
				margin: 0;
				padding-left: .3rem;
				flex: 0 0 50%;
			}
			.three{
				flex: 0 0 25%;
				line-height: .6rem;
				margin-left: 0;
			}
			.four{
				flex: 0 0 25%;
				line-height: .6rem;
				margin-left: 0;
			}
			&.nav{
				display: none;
			}
		}
		.save{
			width: 3rem;
			height: .6rem;
			font-size: .24rem;
			line-height: .6rem;
			margin: .3rem auto;
			cursor: pointer;
		}
	}
	.bottom{
		display: none;
	}
}

</style>
