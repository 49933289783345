<template>
    <div class="mypost">
        <Header />
        <div class="main" id="app" style="background-color: #f5f5f7;">
            <MyBanners :navIndex="2"></MyBanners>
            <div class="wp w1200">
                <div class="select">
					<el-select v-model="params.planId" @change="handleCurrentChange(1)" placeholder="合作计划">
					    <el-option label="全部" value="">
					    </el-option>
					    <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id">
					    </el-option>
					  </el-select>
					<el-select style="margin-left: 20px;" @change="handleCurrentChange(1)" v-model="params.identity" placeholder="认证身份">
					    <el-option label="全部" value=""></el-option>
					    <el-option label="金牌认证经销商" value="1"></el-option>
					    <el-option label="银牌认证经销商" value="2"></el-option>
					    <el-option label="钻石认证经销商" value="3"></el-option>
					    <el-option label="认证经销商" value="4"></el-option>
					  </el-select>
				</div>
				
				<div class="list">
					<div class="width">
						<div class="one">流程编号</div>
						<div class="two">合作计划名称</div>
						<div class="three">签约生效期</div>
						<div class="four">签约失效期</div>
						<div class="five">协议模板链接</div>
						<div class="six">协议模板hash值</div>
						<div class="seven">认证身份</div>
						<div class="night">
							<div class="hr"></div>操作</div>
					</div>
					<div v-for="item in list" :key="item.id" class="width">
						<div class="one">
							<router-link target="_blank" :to="'/signinfo?id='+item.id" tag="a" style="color: #4568D9;"><span>流程编号：</span>{{item.processNo}}</router-link>
						</div>
						<div class="two"><span>合作计划名称：</span>{{item.planName}}</div>
						<div class="three"><span>签约生效期：</span>{{item.authStartDate}}</div>
						<div class="four"><span>签约失效期：</span>{{item.authEndDate}}</div>
						<div class="five"><span>协议模板链接：</span><a :href="item.agreement" target="_blank">{{item.agreement}}</a></div>
						<div class="six"><span>协议模板hash值：</span>{{item.fileHash}}</div>
						<div class="seven"><span>认证身份：</span>{{item.identityName}}</div>
						<div class="night action">
							<div class="hr"></div>
							<div>
								<template v-for="value in item.certificateList">
									<div v-if="value.status==0" :class="value.status==0?'error':'success'" @click="download(value.certificate)">下载证书</div>
									<div v-else class="success">下载证书</div>
									
								</template>								
							</div>
							<div class="error" @click="xuyue(item)">续约</div>
						</div>
					</div>
				</div>
                <!-- 内容为空 -->
                <ContentNull style="display: none;" />
				<div class="pagination-box style2" v-loading.fullscreen.lock="false">
                    <el-pagination
                        :total="total"
                        layout="prev, pager, next"
                        :page-size="params.pageSize"
                        @current-change="handleCurrentChange"
                    ></el-pagination>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import MyBanners from '@/components/MyBanners.vue';
import Footer from '@/components/Footer.vue';
import ContentNull from '@/components/ContentNull.vue';
import Api from '@/request/api';
import { mapState } from "vuex";
export default {
    components: {
        Header,
        MyBanners,
        Footer,
		ContentNull
    },
    data() {
        return {
            params: {
                planId: "",
                format: '',
                pageNo: 1,
                pageSize: 10
            },
            list: [],
            total: 0,
			options:[]
        }
    },
    created() {
        this.getList();
		Api.getPlanSelectList().then(res=>{
			this.options = res;
		})
    },
	computed: {
		...mapState([
			'user'
		])
	},
    watch: {
        $route(v) {
            if (v.path == '/authIdentity') {
                this.getList()
            }
        }
    },
    methods: {
        getList() {
			if(this.user.usertype == 1){
				Api.getMyPostsList().then(res => {
				    const imgReg = new RegExp('<img.*?(?:>|\/>)', 'gi')
					const videoReg = new RegExp('<iframe.*?(?:>|\/>)', 'gi')
					const reg = new RegExp('<p>' + '(.*?)' + '</p>')
				    for (let i = 0; i < res.records.length; i++) {
				        const str = res.records[i].postsContent
				        if (str.match(reg)) {
				            res.records[i].postsContent = str.match(reg)[1]
				        }
				        if (imgReg.test(str)) {
				            res.records[i].postsContent = str.replace(imgReg, '')
				        }
				        if (videoReg.test(str)) {
				            res.records[i].postsContent = str.replace(videoReg, '')
				        }
				    }
				    this.list = res.records
				    this.total = res.total
				})
			}else{
				Api.getCompanyAuthIdentityList(this.params).then(res => {
				    this.list = res.records
				    this.total = res.total
					console.log(this.list);
				})
			}
            
        },
        handleCurrentChange(v) {
            this.params.pageNo = v
            this.getList()
        },
		xuyue(item){
			Api.renewal({companyAuthId:item.id}).then(res => {
				this.$router.push("/partnersign?id="+item.planId);
			})
			
		},
		download(url){
			window.open(url);
		}
    },
    mounted() {
    }

}
</script>
<style scoped lang="scss">
a:hover {
    color: #e73522;
}
body {
    font-family: PingFang SC;
}
.main {
    padding: 80px 0 20px;
}
.g-ban {
    margin-bottom: 30px;
}
.wp{
	background-color: #fff;
	margin-top: 20px;
	padding: 26px 30px;
}
.select{
	margin-bottom: 18px;
}



.pagination-box {
    text-align: center;
    margin-bottom: 37px;
    margin-top: 13px;
}
.list{
	width: 100%;
	border: 1px solid #DDDDDD;
	box-sizing: border-box;
	border-bottom: 0px;
}
.width{
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #DDDDDD;
	font-size: 14px;
	font-weight: bold;
	color: #111111;
	position: relative;
	&>div{
		padding: 12px 0px 12px 20px;
		word-break: break-all;
		line-height: 20px;
		span{
			display: none;
		}
	}
	.one{
		flex: 0 0 120px;
		width: 120px;
	}
	.two{
		flex: 0 0 112px;
		width: 112px;
	}
	.three,.four{
		flex: 0 0 108px;
		width: 108px;
	}
	.five{
		flex: 0 0 200px;
		width: 200px;
	}
	.six{
		flex: 0 0 123px;
		width: 123px;
	}
	.seven{
		flex: 0 0 76px;
		width: 76px;
	}
	.night{
		width: 240px;
		flex: 0 0 240px;
		margin-left: 10px;
		.hr{
			position: absolute;
			top: 0px;
			right: 240px;
			bottom: 0px;
			height: 100%;
			width: 1px;
			background-color: #DDD;
		}
	}
	.action{
		display: flex;
		justify-content: flex-start;
		padding-left: 10px;
		.success{
			width: 100px;
			height: 32px;
			background: #DDDDDD;
			border: 1px solid #DDDDDD;
			font-size: 14px;
			font-weight: 400;
			color: #666666;
			line-height: 32px;
			text-align: center;
			margin: 0 10px;
			cursor: pointer;
		}
		.error{
			width: 100px;
			height: 32px;
			background: #E83421;
			font-size: 14px;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 32px;
			text-align: center;
			margin: 0 10px;
			cursor: pointer;
		}
	}
}
@media only screen and (max-width: 750px) {
	.main{
		padding: 1.4rem 0 0;
	}
	.wp{
		margin-top: .3rem;
		padding: .3rem;
	}
	.select{
		margin-bottom: .2rem;
		.el-select{
			display: block;
			width: 100%;
			margin: .1rem 0 !important;
		}
	}
	.pagination-box{
		margin: .6rem 0;
	}
	.list .width:first-child{
		display: none;
	}
	.width{
		flex-wrap: wrap;
		div{
			padding: .15rem .3rem;
			word-wrap: break-word;
			flex: 0 0 100% !important;
			font-size: .24rem;
			line-height: 1.6;
			color: #666666;
			span{
				display: inline;
				font-weight: normal;
				color: #333333;
			}
		}
		.night{
			.hr{
				display: none;
			}			
		}
		.action{
			padding: .15rem .3rem;
			width: 100%;
			margin: 0;
			justify-content: space-between;
			.success{
				flex: 0 0 48% !important;
				height: auto;
				font-size: .28rem;
				margin: 0;
				cursor: pointer;
			}
			.error{
				flex: 0 0 48% !important;
				height: auto;
				font-size: .28rem;
				margin: 0;
				cursor: pointer;
			}
		}
	}
}
</style>