<template>
    <div class="partner">
        <Header />
        <div class="empowerBg">
            <div class="wp w1200">
                <div class="empower">
                    <h3 class="empowerTit">公司签约授权</h3>
                    <div class="empowerDes">
                        <p>您需经过公司签约授权，才能进行合作伙伴签约</p>
                    </div>
                    <div class="empowerCon">
                        <div class="empowerForm">
                            <div @click="download" class="empowerItem">
                                <!-- <div class="empowerImg">
                                    <i class="empowerIcon empowerDown"></i>
                                </div> -->
                                <div class="empowerTips" style="font-size:16px;font-weight: bold;text-decoration: underline;">
                                    <p style="color:rgb(0,177,255);">点击此处下载授权书模板（第2页盖章，并加骑缝章）</p>
                                    <!-- <p>加盖公章后上传</p> -->
                                </div>
                            </div>
                            <div class="empowerItem">
                                <div class="empowerImg">
                                    <!-- <img src="../assets/images/partner/sample.png" alt="样例"> -->
                                    <i class="empowerIcon empowerUpload"></i>
                                    <el-upload
                                        class="upload"
                                        action=""
                                        list-type="picture"
                                        :show-file-list="false"
                                        :on-success="handleSuccess"
										:http-request="uploadPic"
                                        :before-upload="beforeUpload">
										<template v-if="certificateFile">
											<img v-if="type=='application/pdf'" src="@/assets/images/file_pdf.png" class="book">
											<img v-else :src="certificateFile" class="book">
										</template>
                                        
                                        <i v-else class="empowerIcon empowerUpload"></i>
                                    </el-upload>
                                </div>
                                <div class="empowerTips">
                                    <p>上传授权书</p>
                                    <p>支持jpg png pdf格式</p>
                                </div>
                            </div>
                        </div>
                        <div class="sample">
                            <h4 class="sampleTit">样例</h4>
                            <div class="sampleImg">
                                <img src="../assets/images/partner/sample.png" alt="样例">
                            </div>
                        </div>
                    </div>
                    <div class="empowerBtn">
                        <span class="wait" @click="showDialog">以后再说</span>
                        <span class="submit" @click="submitinfo">提交审核</span>
                    </div>
                </div>
                
                <el-dialog :visible="showdialog" class="tipsDialog" width="640px" @close="showdialog=false" :append-to-body="true">
                    <div slot="title" class="tips-title">
                        <p>您可在“<span>企业中心-企业信息维护</span>”中，</p>
                        <p>继续上传授权书</p>
                    </div>
                    <div class="tips-content">
                        <div class="tips-bg"></div>
                        <div class="empowerBtn">
                            <span class="submit" @click="closeDialog">知道了</span>
                        </div>
                    </div>
                </el-dialog>
            </div>
        </div>
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Api from '@/request/api.js';
import axios from 'axios';
export default {
    name: 'PartnerEmpower',
    components: { 
        Header 
    },
    data() {
        return {
            imageUrl: '',
            // imageUrl: require('@/assets/images/partner/sample.png'),
            showdialog: false,
			certificateFile:"",
			type:"",
			id:0
        }
    },
	mounted(){
		Api.getUserCertificate().then(res=>{
			console.log(res);			if(res != null){				this.id = res.id;			}		})
	},
    methods: {
		uploadPic({ file }) {
			return Api.upload(file)
		},
		handleSuccess(res) {
			if (res.data.code == 0) {
				this.certificateFile = res.data.message
			}
		},
        beforeUpload(file) {
            const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf');
			this.type = file.type;
            if (!isJPG) {
                this.$message.error('上传文件只能是 JPG、png、dpf 格式!');
            }
            return isJPG;
        },
        showDialog(){
            this.showdialog = true
        },
        closeDialog(){
            this.showdialog = false
			this.$router.push("/");
        },
		submitinfo(){
			if(this.certificateFile == ""){
				return this.$message.error("请上传授权书");
			}
			Api.saveUserCertificate({certificateFile:this.certificateFile,id:this.id}).then(res=>{
				
				this.$alert("已提交，商汤将于1个工作日内完成审核，请留意邮箱通知", "提示").then(() => {
				  this.$router.push("/");
				});
				
			})
		},
		download(){
			Api.downloadAuthFile().then(res=>{
				const blob = new Blob([res]);
				const fileName = '样例.pdf';
				if ('download' in document.createElement('a')) { // 非IE下载
								const elink = document.createElement('a');
								elink.download = fileName;
								elink.style.display = 'none';
								elink.href = URL.createObjectURL(blob);
								document.body.appendChild(elink);
								elink.click();
								URL.revokeObjectURL(elink.href);// 释放URL 对象
								document.body.removeChild(elink);
								this.loading = false;
				} else { // IE10+下载
								navigator.msSaveBlob(blob, fileName);
								this.loading = false;
				}
				
			})
			
		}
    }
}
</script>
<style scoped lang="scss">
.partner{
    padding: 80px 0 0;
    overflow: hidden;
}
.empowerBg{
    display: flex;
    align-items: center;
    padding: 30px 0;
    min-height: calc(100vh - 80px);
    background-color: #F5F5F7;
    overflow: hidden;
    .wp{
        width: 100%;
    }
}
.empower{
    width: 100%;
    max-width: 960px;
    height: 680px;
    padding: 50px;
    margin: 20px auto 40px;
    background-color: #FFFFFF;
    .empowerTit{
        font-size: 24px;
        color: #111111;
        line-height: 1.8;
    }
    .empowerDes{
        font-size: 18px;
        color: #666666;
        line-height: 1.8;
    }
}
.empowerCon{
    display: flex;
    padding: 50px;
    .empowerForm{
        flex: auto;
        overflow: hidden;
        .empowerItem{
            display: flex;
            align-items: flex-end;
            margin-bottom: 20px;
			cursor: pointer;
            .empowerImg{
                position: relative;
                flex-shrink: 0;
                width: 121px;
                height: 121px;
                background: #F5F5F8;
                border: 1px solid #DDDDDD;
                border-radius: 3px;
                margin-right: 30px;
                cursor: pointer;
                img{
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: cover;
                }
                .empowerIcon{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    max-width: 100%;
                    max-height: 100%;
                }
                .upload{
                    width: 100%;
                    height: 100%;
                    ::v-deep .el-upload{
                        display: block;
                        height: 100%;
                    }
                }
            }
            .empowerTips{
                flex: auto;
                font-size: 14px;
                color: #999999;
                line-height: 1.8;
                overflow: hidden;
            }
        }
    }
    .sample{
        flex-shrink: 0;
        width: 192px;
        margin-left: auto;
        .sampleTit{
            font-size: 14px;
            color: #666666;
            line-height: 2;
            margin-bottom: 10px;
            font-weight: normal;
        }
        .sampleImg{
            width: 100%;
            img{
                width: 100%;
                height: auto;
            }
        }
    }
}
.empowerBtn{
    display: flex;
    justify-content: center;
    padding: 50px 0;
    span{
        display: block;
        width: 280px;
        font-size: 16px;
        line-height: 50px;
        margin: 0 10px;
        border-radius: 3px;
        text-align: center;
        cursor: pointer;
    }
    .wait{
        color: #2E2E2E;
        background: #E3E4E5;;
    }
    .submit{
        color: #FFFFFF;
        background: #E73522;
    }
}

.empowerDown{
    display: block;
    width: 24px;
    height: 24px;
    background: url("../assets/images/partner/icon-down@2x.png") center center no-repeat;
    background-size: cover;
}
.empowerUpload{
    display: block;
    width: 24px;
    height: 24px;
    background: url("../assets/images/partner/icon-upload@2x.png") center center no-repeat;
    background-size: cover;
}

.tipsDialog{
    .tips-title{
        font-size: 20px;
        color: #333333;
        line-height: 1.6;
        text-align: center;
        margin: 20px 0 0;
        span{
            color: #E73522;
        }
    }
    .tips-bg{
        width: 580px;
        height: 140px;
        background: #E6E6E6;
        margin: 0 auto;
    }
}
@media only screen and (max-width: 750px) {
    .partner{
        padding: 1.4rem 0 0;
    }
    .empowerBg{
        min-height: calc(100vh - 1.4rem);
        padding: .3rem 0;
    }
    .empower{
        max-width: 100%;
        height: auto;
        padding: .3rem;
        margin: .2rem 0 .4rem;
        .empowerTit{
            font-size: .32rem;
        }
        .empowerDes{
            font-size: .24rem;
        }
        .empowerCon{
            display: block;
            padding: .3rem 0;
            .empowerForm{
                .empowerItem{
                    margin-bottom: .2rem;
                    .empowerImg{
                        width: 2rem;
                        height: 2rem;
                        margin-right: .2rem;
                        .empowerIcon {
                            width: .4rem;
                            height: .4rem;
                        }
                    }
                    .empowerTips{
                        font-size: .24rem;
                    }
                }
            }
            .sample{
                width: 100%;
                .sampleTit{
                    font-size: .24rem;
                }
                .sampleImg{
                    max-width: 200px;
                }
            }
        }
        .empowerBtn{
            padding: .3rem 0;
            span{
                width: 48%;
                font-size: .24rem;
                margin: 0 1%;
                line-height: .7rem;
            }
        }
    }
}
</style>