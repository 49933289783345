<template>
	<div class="detail">
		<Header/>
		<div class="main" id="app" style="background-color: #f5f5f7;">
			<div class="wp w1200">
				<Subnav :sublist="otherCates" />
				<div class="m-deailInfo" v-loading="loading">
					<div class="m-dtTit">
						<div class="pic"><img :src="info.src"></div>
						<div class="txts">
							<h3 class="tit">{{info.title}}</h3>
							<div class="info">
								<span class="date">发布时间：{{info.createTime}} </span>
								<span class="watch">{{info.readNum}}</span>
							</div>
						</div>
						<div class="icons">
							<div class="icon" :class="info.collect ? 'on':''" @click="share(info)">
								<img src="../assets/images/share.png" alt="">
								<img class="dn" src="../assets/images/detail/share2.png" alt="">
								<div class="num">{{info.shareNum}}</div>
							</div>
							<div class="icon" :class="info.collect ? 'on':''" @click="collect">
								<img src="../assets/images/detail/icon2-2.png" alt="">
								<img class="dn" src="../assets/images/detail/icon2.png" alt="">
								<div class="num">{{info.collectNum}}</div>
							</div>
							<div class="icon" v-if="info.infoFormat != '视频'" @click="showDownload(info)">
								<img src="../assets/images/detail/icon4.png" alt="">
								<img class="dn" src="../assets/images/detail/icon4-2.png" alt="">
								<div class="num">{{ info.downloadNum }}</div>
							</div>
						</div>
					</div>
					<div class="box">
						<!-- 图文 -->
						<div class="m-dtcontent" v-if="(info.document != '' && info.infoContent != '' && info.infoFormat != '视频')">
							<div class="txt" v-html="info.infoContent">
							</div>
						</div>
						<!-- 视频 -->
						<div class="m-dtcontent" v-if="info.infoFormat == '视频'">
							<div class="video">
								<video id="detailvd" width="100%" height=""  :src="info.document" controls="controls" controlslist="nodownload" oncontextmenu="return false;">
								</video>
								<!-- <div class="black" v-if="showvd" @click="ckvideo">
									<img class="icon" src="../assets/images/detail/play.png" >
								</div> -->
							</div>
						</div>
						<!-- 文档 -->
						<div class="m-dtcontent txt" v-if="info.document==''">
							<div class="txt" v-html="info.infoContent">
							</div>
						</div>
						<div class="m-dttxts" id="posfx">
							<div class="tit">热门文章</div>
							<ul>
								<li v-for="(item,index) in hotList" :key="index">
									<div class="con" @click="toHotDetail(item.id)">
										<div class="pic"><img :src="item.src" alt=""></div>
										<div class="txt">
											<div class="info">{{item.title}}</div>
											<div class="btns">
												<div class="b b1">{{item.readNum}}</div>
												<div class="b b2"  @click.stop="showDownload(item)">{{item.downloadNum}}</div>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Download :info="downInfo" ref="downloadRef" @confirm="download"/>
		<Footer></Footer>
	</div>
</template>
<script >
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Subnav from '@/components/Subnav.vue';
import Download from '@/components/Download.vue';
import Api from '@/request/api';
import { mapState } from 'vuex';
import download from "downloadjs";
export default {
	name: 'Detail',
    components: {
        Header,
        Subnav,
        Footer,
		Download
    },
    data(){
        return{
            id: null,
            type: null,
            info: {},
			downInfo: {},
			loading: false,
            tittype:{
                // 1=详情图文  2=详情视频   3=详情文档
                type:1,
                src:'images/detail/titimg1.png',
                btns:{
                    bt1:false,
                    bt2:false
                }
            },
            showvd:true,
			hotList: []
        }
    },
    created() {
        this.id = this.$route.query.id
        this.type = this.$route.query.type
        // 获取详情
        this.getDetail()
		// 获取热门资讯
		this.getHotList()
    },
	computed: {
		...mapState([
			'otherCates'
		])
	},
	watch: {
		$route() {
			if (this.$route.path == '/detail' && ( this.$route.query.type != this.type )) {
				this.id = this.$route.query.id
				this.type = this.$route.query.type
				this.getDetail()
			}
		}
	},
    mounted(){
        this.$nextTick(function () {
            // 仅在整个视图都被渲染之后才会运行的代码
            let num = this.tittype.type;
            if(num == 1){
                this.tittype.src = require('../assets/images/detail/titimg1.png')
            }else if(num==2){
                this.tittype.src = require('../assets/images/detail/titimg3.png')
            }else if(num==3){
                this.tittype.src = require('../assets/images/detail/titimg4.png')
            }
        })
		
		
		var top1 = document.getElementById('posfx').offsetTop;
		let _this = this;
		window.addEventListener("scroll",function(e){
			_this.handleScroll(e,top1)
		},false);
    },
    methods: {
        getDetail() {
            const data = {
                belong: this.type,
                id: this.id,
                type: 1  // 1 阅读 获取详情
            }
            Api.inforPosts(data).then(res => {
				try {
					res.src = require('@/assets/images/file_'+this.getFormatType(res.infoFormat)+'.png') 
				} catch (error) {
					
				}
                
                this.info = res
            })
        },
		getHotList() {
			const params = {
				belong: 1,
				format: '',
				order: '阅读量',
				sort: 0,
				pageNo: 1,
				pageSize: 10,
				type: ''
			}
			Api.getList(params).then(res => {
                try {
					for (let i = 0; i < res.records.length; i++) {
                        res.records[i].src = require('@/assets/images/file_'+this.getFormatType(res.records[i].infoFormat)+'.png')
                    }
				} catch (error) {
					
				}
				this.hotList =  res.records
				console.log(this.hotList)
			})
		},
		// 收藏
		collect() {
			const data = {
                belong: 2,
                id: this.info.id,
                type: 3
            }
            if(this.info.collect) {
                Api.cancelInforPosts(data)
            } else {
                Api.inforPosts(data)
            }
			this.info.collect = !this.info.collect
			if (this.info.collect) {
				this.info.collectNum += 1;
			} else {
				this.info.collectNum -= 1;
			}
		},
        //点击视频
        ckvideo(){
            // 隐藏视频播放按钮 播放视频
            this.showvd = false;
            let vid = document.getElementById('detailvd');
            vid.play();
        },
        // 根据类型返回类
        getFormatType(type) {
            let str = ''
            this.$store.state.infoFormatType.forEach(item => {
                if (item.type == type) {
                    str = item.src
                    return;
                }
            })
            return str
        },
		// 显示下载弹窗
        showDownload(item) {
			if((item.applyType == 0 && item.applyStatus == 1) || (item.applyType == 0 && item.applyStatus==undefined)){
				this.$confirm("您尚未开通该目录权限，是否申请开通？","提示").then(()=>{
					
					Api.doApply({inforClassId:item.infoTypeId,typeIdList:[item.infoTypeId]}).then(res=>{
						this.$message.success("申请已提交，请耐心等待");
					})
				})
			}else{
				this.downInfo = {
					id: item.id,
					title: item.title,
					url: item.document,
					icon: item.infoFormat
				}
				// console.log(item)
				this.$refs.downloadRef.open()
			}
        },
        // 下载
        download() {
            const data = {
                belong: 2,
                id: this.downInfo.id,
                type: 4
            }
            Api.inforPosts(data).then(res => {
				let name = res.document.split("?")[0];
				 const a = document.createElement('a');
				  a.style.display = 'none';
				  a.setAttribute('target', '_blank');
				  name && a.setAttribute('download', name);
				  a.href = res.document;
				  document.body.appendChild(a);
				  a.click();
				  document.body.removeChild(a);
            })
            this.$refs.downloadRef.close()
            
        },
		toHotDetail(id) {
			this.loading = true
			setTimeout(() => {
				if (id == this.id) {
					this.loading = false
					return
				}
				this.loading = false
				this.$router.push({ path: '/detail', query: { id: id, type: 1 } })
			}, 300)
		},
		// 详情页右侧栏目
		handleScroll(e,gettop) {
			let widTop =window.pageYOffset;
			let ele = document.getElementById('posfx');
			let top = ele.offsetTop-80;
			let left = ele.offsetLeft;
			
			
			// 获取底部 + 高度
			let ftrtop = document.getElementsByClassName('footer')[0].offsetTop;
			let ht = ele.clientHeight ;
			
			console.log(widTop)
			if (widTop > top) {
				ele.style.left  = left+'px'
				ele.classList.add('isfixed')
			}
			if(widTop < gettop-80 || widTop> ftrtop-ht-100){
				ele.classList.remove('isfixed')
			}
			
			
		},
		// 显示下载弹窗
		share(item) {
			if((item.applyType == 0 && item.applyStatus == 1) || (item.applyType == 0 && item.applyStatus==undefined)){
				this.$confirm("您尚未开通该目录权限，是否申请开通？","提示").then(()=>{
					
					Api.doApply({inforClassId:item.infoTypeId,typeIdList:[item.infoTypeId]}).then(res=>{
						this.$message.success("申请已提交，请耐心等待");
					})
				})
			}else{
				const data = {
					belong: 2,
					id: item.id,
					type: 5
				}
				Api.inforPosts(data).then(res => {
						// if (item.infoFormat == '链接' || item.infoFormat == 'pdf') {
						// 		if (res.document) {
						// 			// 创建输入框元素
						// 			let oInput = document.createElement('input');
						// 			// 将想要复制的值
						// 			oInput.value = res.document.replace('http', 'https');
						// 			// 页面底部追加输入框
						// 			document.body.appendChild(oInput);
						// 			// 选中输入框
						// 			oInput.select();
						// 			// 执行浏览器复制命令
						// 			document.execCommand('Copy');
						// 			// 弹出复制成功信息
						// 			this.$message.success('链接已复制，快去分享吧~');
						// 			// 复制后移除输入框
						// 			oInput.remove();
						// 		}
						// } else {
							// 创建输入框元素
							let oInput = document.createElement('input');
							// 将想要复制的值
							oInput.value = `${location.origin}/detail?id=${item.id}&type=2`;
							// 页面底部追加输入框
							document.body.appendChild(oInput);
							// 选中输入框
							oInput.select();
							// 执行浏览器复制命令
							document.execCommand('Copy');
							// 弹出复制成功信息
							this.$message.success('链接已复制，快去分享吧~');
							// 复制后移除输入框
							oInput.remove();
						// }
						
				})
			}
			
		}
    }
}
</script>

<style lang="scss" scoped>
	body {
		font-family: PingFang SC;
		background-color: #f5f5f7;
	}
	.detail{
		padding-top: 80px;
	}
	.dn{display:none}
	.wp.w1200 {
		max-width: 1200px;
		margin: 0 auto;
	}
	.pic img {
		width: 100%;
	}

	.m-deailInfo {}

	.m-dtTit {
		padding: 36px 40px 35px 30px;
		background-color: #fff;
		border-radius: 5px;
		overflow: hidden;
		position: relative;
		margin-bottom: 20px;
	}

	.m-dtTit .pic {
		float: left;
		width: 70px;
		height: 70px;
		margin-right: 60px;
		border-radius: 5px;
		overflow: hidden;
	}

	.m-dtTit .txts {
		overflow: hidden;
		max-width: 70%;
	}

	.m-dtTit .txts .tit {
		font-size: 24px;
		font-weight: bold;
		color: #111111;
		line-height: 1;
		margin-bottom: 29px;
	}
	.m-dtTit .txts .info{
		font-size: 14px;
		color: #989898;
		line-height: 1;
	}
	.m-dtTit .txts .date{
		margin-right: 50px;
	}
	.m-dtTit .txts .watch{
		display: inline-block;
		background: url(../assets/images/detail/icon1.png) no-repeat left center;
		background-size: 16px;
		padding-left: 20px;
	}
	.m-dtTit .icons{
		position: absolute;
		right: 40px;
		top: 50%;
		transform: translateY(-50%);
	}
	.m-dtTit .icon{
		width: 40px;
		height: 40px;
		border-radius: 50%;
		cursor: pointer;
		float: right;
		position: relative;
		margin: 0 20px;
		background-color: #fff;
		transition: .5s;
	}
	.m-dtTit .icon:hover{
		
	}
	.m-dtTit .icon.on,
	.m-dtTit .icon:hover{
		background-color: #E83421;
	}
	.m-dtTit .icon.on img,
	.m-dtTit .icon:hover img{
		display: none;
	}
	.m-dtTit .icon.on img.dn,
	.m-dtTit .icon:hover img.dn{
		display: block;
	}
	
	.m-dtTit .icon img{
		width: 24px;
		vertical-align: middle;
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		position: absolute;
	}
	.m-dtTit .icon .num{
		height: 15px;
		line-height: 15px;
		padding: 0 13px;
		border-radius: 8px;
		background-color: #E8E8EB;
		font-size: 12px;
		position: absolute;
		bottom: 5px;
		left: 0;
		transform: translateY(100%);
		box-sizing: border-box;
		min-width: 40px;
		text-align: center;
	}
	
	.m-deailInfo .box{
		overflow: hidden;
		padding-bottom: 60px;
	}
	
	.m-dtcontent,
	.m-leftbox{
		padding: 30px 20px 25px;
		background-color: #fff;
		border-radius: 5px;
		float: left;
		width: 880px;
		box-sizing: border-box;
		background-color: #fff;
	}
	.m-dtcontent.txt{
		padding-bottom: 50px;
	}
	.m-dtcontent .txt{
		font-size: 16px;
		color: #2E2E2E;
		line-height: 2;
	}
	.m-dtcontent .txt img{
		max-width: 100%;
	}
	
	.m-dtcontent .video {
		position: relative;
	}
	.m-dtcontent .video .black{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,.4);
		z-index: 99;
		overflow: hidden;
		cursor: pointer;
	}
	.m-dtcontent .video .black .icon{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		width: 80px;
		height: 80px;
	}
	
	
	.m-dttxts{
		width: 300px;
		padding: 10px;
		background-color: #fff;
		border-radius: 5px;
		box-sizing: border-box;
		float: right;
	}
	.m-dttxts>.tit{
		height: 50px;
		background: url(../assets/images/detail/img2.png) no-repeat;
		background-size: auto 100%;
		font-size: 16px;
		color: #E83421;
		line-height: 50px;
		font-weight: bold;
		padding-left: 12px;
		margin-bottom: 15px;
	}
	.m-dttxts .con {
		overflow: hidden;
		padding: 15px 10px;
		border-radius: 5px;
		transition: .5s;
	}

	.m-dttxts .con .pic{
		float: left;
		width: 30px;
		height: 30px;
		border-radius: 5px;
		margin-right: 9px;
	}
	.m-dttxts .con .txt{
		overflow: hidden;
		font-size: 14px;
		color: #333;
	}
	.m-dttxts .con .txt .info::after{
		content: '';
		display: table;
		clear: both;
	}
	.m-dttxts .con .txt .info{
		margin-top: -3px;
	}
	.m-dttxts .con .txt .btns{
		
		/* display: none; */
		height: 0;
		transition: .5s;
		padding-top: 0;
	}
	.m-dttxts .con .txt .b{
		display: inline-block;
		padding-left: 20px;
		background: url(../assets/images/detail/icon1.png) no-repeat left center;
		background-size: 16px;
		margin-right: 35px;
		transition: .5s;
	}
	.m-dttxts .con .txt .b2{
		background-image: url(../assets/images/detail/icon4.png);
		background-size: 14px;
		right: 0;
	}
	.m-dttxts .con:hover .txt .btns{
		/* display: block; */
		height: 20px;
		box-sizing: content-box;
		padding-top: 14px;
	}
	.m-dttxts .con:hover {
		background-color: #F5F5F7;
		cursor: pointer;
	}
	
	
	.g-downpopup{
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,.6);
	}
		
	.g-downpopup .down{
		width: 480px;
		min-height: 300px;
		background-color: #fff;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		box-sizing: border-box;
		padding: 30px 25px;
	}
	
	.g-downpopup .tit{
		font-size: 16px;
		font-weight: bold;
		color: #333;
		margin-bottom: 24px;
	}
	.g-downpopup .close{
		position: absolute;
		top: 29px;
		right: 21px;
		background: url(../assets/images/myinfo/icon-w1.png) no-repeat;
		background-size: 100%;
		width: 20px;
		height: 20px;
		cursor: pointer;
	}
	.g-downpopup .close:hover{
		background-image: url(../assets/images/myinfo/icon-w3.png);
	}
	.g-downpopup .desc{
		height: 70px;
		line-height: 70px;
		background-color: #F5F5F7;
		text-align: center;
		font-size: 14px;
		color: #E83421;
		margin-bottom: 10px;
	}
	.g-downpopup .desc .icon{
		display: inline-block;
		width: 20px;
		height: 20px;
		vertical-align: middle;
		margin-right: 10px;
	}
	.g-downpopup .info{
		overflow: hidden;
		padding: 20px 17px;
		border: 1px solid #E4E4E4;
		border-radius: 5px;
		margin-bottom: 30px;
	}
	.g-downpopup .info .pic{
		float: left;
		width: 30px;
		height: 30px;
		overflow: hidden;
		margin-right: 19px;
	}
	.g-downpopup .info .txt{
		font-size: 14px;
		color: #333;
		padding-top: 5px;
	}
	.g-downpopup .btns{
		text-align: center;
	}
	.g-downpopup  .btn{
		display: inline-block;
		height: 30px;
		text-align: center;
		padding: 0 22px;
		border: 1px solid #E83421;
		font-size: 14px;
		box-sizing: border-box;
		line-height: 30px;
		cursor: pointer;
		transition: .5s;
	}
	.g-downpopup  .btn:hover{
		box-shadow: 0 0 4px rgba(0,0,0,.5);
	}
	.g-downpopup  .btn.b1{
		background-color: #fff;
		color:#E83421 ;
		margin-right: 16px;
	}
	.g-downpopup  .btn.b2{
		background-color: #E83421;
		color:#fff ;
	}
	@media only screen and (max-width: 750px) {
		.detail{
			padding: 1.4rem 0 0;
		}
		.m-dtTit{
			padding: .3rem;
			height: auto;
			min-height: .8rem;
			margin-bottom: .2rem;
			.pic{
				float: none;
				width: .6rem;
				height: .6rem;
				margin-right: .2rem;
			}
			.txts{
				max-width: none;
				margin-top: .3rem;
				.tit{
					font-size: .32rem;
					line-height: 1.4;
					margin-bottom: .1rem;
					overflow: hidden;
				}
				.info{
					font-size: .24rem;
					line-height: 1.2;
				}
				.date{
					margin-right: .3rem;
				}
			}
			.icons{
				right: .3rem;
				top: .3rem;
				transform: translate(0);
			}
			.icon{
				width: .54rem;
				height: .54rem;
				line-height: .5rem;
				margin-bottom: 0;
				padding: 0;
				margin: 0;
				margin-left: .2rem;
				img{
					display: block;
					width: .34rem;
					height: .34rem;
					margin: 0 auto;
				}
				.num{
					left: 50%;
					min-width: 100%;
					height: auto;
					font-size: .22rem;
					line-height: 1.1;
					padding: 0 .2rem;
					white-space: nowrap;
					border-radius: .2rem;
					bottom: .1rem;
					transform: translate(-50%,100%);
				}
				
				&:not(.on):hover{
					background-color: #fff;
					img{
						display: block;
					}
					.dn{
						display: none;
					}
				}
			}
		}
		.m-dtcontent,
		.m-leftbox{
			float: none;
			width: 100%;
			padding: .3rem;
			.video{
				video{
					display: block;
					width: 100%;
					height: auto;
				}
			}
			.txt{
				font-size: .28rem;
			}
		}
		.m-dttxts{
			float: none;
			width: 100%;
			padding: .3rem;
			margin-top: .3rem;
			> .tit{
				height: .8rem;
				line-height: .8rem;
				font-size: .32rem;
				padding-left: .2rem;
				margin-bottom: .2rem;
				background-position: right center;
				background-color: #ffe7d6;
				border-radius: .08rem .08rem 0 0;
			}
			li{				
				&:nth-child(2n){
					background-color: #F5F5F7;
				}
			}
			.con{
				padding: .2rem;
				&:hover{
					background-color: transparent;
				}
				.pic{
					width: .6rem;
					height: .6rem;
					margin-right: .2rem;
				}
				.txt{
					font-size: .24rem;
					.btns{
						height: auto !important;
						padding-top: .1rem !important;
					}
					.b{
						min-width: 1rem;
						margin-right: .3rem;
					}
				}
			}
		}
	}
</style>