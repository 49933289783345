import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router';

Vue.use(Vuex)

let USER = localStorage.getItem('user')

try {
    USER = JSON.parse(USER)
} catch {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    if (router.currentRoute.path != '/') {
        router.replace({
            path: '/'
        });
    }
}

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token') || '',
        user: USER,
        productTree: [],
        inforTree: [],
        forumTree: [],
        globalSearch: '', // 全局搜索内容
        forumCates: localStorage.getItem('forumCates') == 'undefined' ? [] : JSON.parse(localStorage.getItem('forumCates')),
        otherCates: localStorage.getItem('otherCates') == 'undefined' ? [] : JSON.parse(localStorage.getItem('otherCates')),
        formats: [],
        forumStatus: null, // 1开启 0关闭
        infoFormatType: [
            { type: '图片', class: 'file-picture', src: 'picture' },
            { type: '视频', class: 'file-video', src: 'video' },
            { type: '链接', class: 'file-link', src: 'link' },
            { type: 'pdf', class: 'file-pdf', src: 'pdf' },
            { type: 'doc', class: 'file-word', src: 'word' },
            { type: 'excel', class: 'file-xls', src: 'xls' },
            { type: 'ppt', class: 'file-powerpoint', src: 'powerpoint' },
            { type: 'apk', class: 'file-apk', src: 'apk' },
            { type: '其它', class: 'file-other', src: 'other' },
            { type: 'zip', class: 'file-zip', src: 'zip' },
            { type: 'exe', class: 'file-exe', src: 'exe' },
        ],
        randomKeyArr: [],
        tempProductId: 0
    },
    getters: {
        hasLogin: state => {
            return !!state.token
        }
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
            localStorage.setItem('token', token)
        },
        SET_USER: (state, user) => {
            state.user = user
            localStorage.setItem('user', JSON.stringify(user))
        },
        SET_PRODUCT_TREE: (state, data) => {
            state.productTree = data
        },
        SET_INFOR_TREE: (state, data) => {
            state.inforTree = data
        },
        SET_FORUM_TREE: (state, data) => {
            state.forumTree = data
        },
    },
    actions: {
        // 登录
        login({ commit }, data) {
            commit('SET_TOKEN', data.token)
            commit('SET_USER', data.userInfo)
        },
        // 退出
        logout({ commit }) {
            commit('SET_TOKEN', '')
            commit('SET_USER', {})
        },
        setUserInfo({ commit }, data) {
            commit('SET_USER', data)
        },
        // 存储产品树
        setPorductTree({ commit }, data) {
            commit('SET_PRODUCT_TREE', data)
        },
        // 存储咨询树
        setInforTree({ commit }, data) {
            commit('SET_INFOR_TREE', data)
        },
        // 存储咨询树
        setForumTree({ commit }, data) {
            commit('SET_FORUM_TREE', data)
        },
        // 存储全局搜索
        setGlobalSearch({ state }, data) {
            state.globalSearch = data
        },
        // 面包屑
        setForumCates({ state }, data) {
            localStorage.setItem('forumCates', JSON.stringify(data))
            state.forumCates = data
        },
        // 面包屑
        setOtherCates({ state }, data) {
            localStorage.setItem('otherCates', JSON.stringify(data))
            state.otherCates = data
        },
        // 文件格式数组
        setFormats({ state }, data) {
            state.formats = data
        },
        // 论坛是否关闭
        setForumStatus({ state }, data) {
            state.forumStatus = data
        },
        addKey({state}, data) {
            let arr = state.randomKeyArr
            arr.push({ url: data.url, key: data.key })
            state.randomKeyArr = arr
        },
        delKey({state}, key) {
            const index = state.randomKeyArr.findIndex(item => {
                return item.key == key
            })
            let arr = state.randomKeyArr
            arr.splice(index, 1)
            state.randomKeyArr = arr
        },
        setTempProductId({state}, id) {
            state.tempProductId = id
        }
    },
    modules: {
    }
})
