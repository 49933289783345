<template>
	<el-dialog class="ly-dialog" :append-to-body="true" :visible.sync="dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false" @close="clearin"	width="1200px">
		<div slot="title">
			<div class="ly-title">
				<div class="name">私信</div>
				<div class="action" @click="clearmessage"><img  src="@/assets/images/yyidu.png"/>全部标记为已读</div>
			</div>
		</div>
		<div class="ly-content">
			<div class="ly-left">
				<div class="ly-left-user" v-for="item in userlist" :key="item.id+'user'" @click="changemessage(item,item.userType)">
					<div class="ly-left-user-image">
						<img v-if="item.userType == 1" :src="item.receiveUserAvatar"/>
						<img v-else :src="item.senderUserName"/>
						<div v-if="item.notReadNum > 99">...</div>
						<div v-if="item.notReadNum <= 99 && item.notReadNum > 0">{{item.notReadNum}}</div>
					</div>
					<div class="ly-left-user-info">
						<div class="name">
							<div v-if="item.userType == 1">{{item.receiveUserName}}</div>
							<div v-else>{{item.senderUserName}}</div>
							<div>{{item.lastTime}}</div>
						</div>
						<div class="desc">{{item.lastContent}}</div>
					</div>
				</div>
			</div>
			<div class="ly-right">
				<div class="name">{{userinfo.receiveUserName}}</div>
				<div style="height: 80px;"></div>
				<div class="content" id="scrolldIV">
					<template v-for="item in list">
						<div class="ly-time">{{item.createTime}}</div>
						<div v-if="item.senderUserId == id" class="content-left">
							<img :src="item.senderUserAvatar" class="avatar"/>
							<div class="text">
								<img src="@/assets/images/bai.png" class="sanjiao"/>
								{{ item.content }}
								<!-- <el-input type="textarea" :readonly="true" :value="item.content" :autosize="true" resize="none"></el-input> -->
							</div>
						</div>
						
						<div v-if="item.senderUserId != id" class="content-right">
							<div class="text">
								<img src="@/assets/images/lv.png" class="sanjiao"/>
								{{ item.content }}
								<!-- <el-input type="textarea" :readonly="true" :value="item.content" :autosize="true" resize="none"></el-input> -->
							
							</div>
							<img :src="item.senderUserAvatar" class="avatar"/>
						</div>
					</template>
				</div>
				<div class="ly-input">
					<div @click="send" class="button">发送</div>
					<el-input v-model="content" type="textarea" placeholder="请输入..." :autosize="false" resize="none"></el-input>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import Api from '@/request/api.js';
	import moment from 'moment';
	export default{
		data(){
			return {
				dialogVisible:false,
				messageid:0,
				userlist:[],
				list:[],
				userinfo:{},
				content:"",
				id:0,
				timeer:null
			}
		},
		methods:{
			show(){
					this.dialogVisible = true;
					//获取聊天内容
					this.getinfoList();
					//获取聊天记录
					this.getlist();
				this.timeer = setInterval(()=>{
					//获取聊天内容
					this.getinfoList();
					//获取聊天记录
					this.getlist();
				},10000);
			},
			init(id){
				this.id = id;
				//创建聊天列表
				Api.createmessage({receiveUserId:id}).then(res=>{
					this.messageid = res;
					this.dialogVisible = true;
					//获取沟通人的详情
					this.getMessageDetail();
					//获取聊天内容
					this.getinfoList();
					//获取聊天记录
					this.getlist();
					// this.$nextTick(()=>{
					// 	document.getElementById("scrolldIV").addEventListener("scroll", (res)=>{
					// 		var div = document.getElementById('scrolldIV');
					// 		console.log(div.scrollTop);
					// 		console.log(div.offsetHeight);
					// 	});
					// })
				});
				this.timeer = setInterval(()=>{
					//获取聊天内容
					this.getinfoList();
					//获取聊天记录
					this.getlist();
				},10000);
			},
			getlist(){
				Api.getLiuyanList().then(res=>{
					this.userlist = res == null ?[]:res;
					this.userlist.map(item=>{
						if(item.lastTime){
							item.lastTime = this.checktime(item.lastTime);
						}
					})
				});
				
			},
			checktime(date){
				if(moment(date).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
					return moment(date).format('HH:mm');
				}
				if(moment(date).format('YYYY') == moment().format('YYYY')){
					return moment(date).format('MM/DD');
				}
				return moment(date).format('YYYY/MM/DD')
			},
			getinfoList(){
				Api.getinfoList({messageId:this.messageid}).then(res=>{
					res.map(item=>{
						item.createTime = this.checktime(item.createTime);
					})
					this.list = res;
					if(this.id > 0){
						this.$nextTick(()=>{
							var div = document.getElementById('scrolldIV');
							div.scrollTop = div.scrollHeight;
						})
					}
					
				})
			},
			getMessageDetail(){
				Api.getMessageDetail({messageId:this.messageid}).then(res=>{
					this.userinfo = res;
				})
			},
			send(){
				if(this.content == ""){
					return false;
				}
				if(this.id == 0){
					return this.$message.error("请选择好友");
				}
				Api.sendMessage({messageId:this.messageid,content:this.content}).then(res=>{
					this.content = "";
					this.getinfoList();
				})
			},
			clearmessage(){
				Api.allRead().then(res=>{
					this.$message.success("操作成功");
					this.getlist();
				});
			},
			changemessage(item,type){
				clearInterval(this.timeer);
				this.messageid = item.id;
				this.id = type==1?item.receiveUserId:item.senderUserId;
				this.getMessageDetail();
				//获取聊天内容
				this.getinfoList();
				//获取聊天记录
				this.getlist();
				this.timeer = setInterval(()=>{
					//获取聊天内容
					this.getinfoList();
					//获取聊天记录
					this.getlist();
				},10000);
			},
			clearin(){
				clearInterval(this.timeer);
			}
		}
	}
</script>

<style scoped lang="scss">
.ly-input{
	background-color: #fff;
	padding: 45px 30px 0px;
	position: relative;
	.button{
		width: 70px;
		height: 30px;
		background: #E83421;
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 30px;
		text-align: center;
		position: absolute;
		top: 13px;
		right: 30px;
		cursor: pointer;
	}
	
	
}
::v-deep .el-textarea__inner{
	width: 100%;
	height: 126px;
	border: 0px;
}
::v-deep .text .el-textarea__inner{
	border: 0px;
	padding: 0px;
	line-height: 20px;
	background-color: transparent;
	color: #000;
}
.ly-right{
	flex: 0 0 900px;
	background-color: #F5F5F7;
	position: relative;
	.name{
		border-bottom: 1px solid #DDDDDD;
		line-height: 60px;
		font-size: 16px;
		font-weight: 500;
		color: #333333;
		text-align: center;
		position: absolute;
		top:0px;
		left: 0px;
		right: 0px;
	}
	.content{
		height: 418px;
		width: 100%;
		overflow: auto;
		padding: 20px 40px;
		box-sizing: border-box;
		.content-left{
			margin: 17px 0px 13px;
			display: flex;
			.avatar{
				width: 56px;
				height: 56px;
				border-radius: 100%;
				margin-right: 39px;
			}
			.text{
				width: 280px;
				background: #FFFFFF;
				padding: 20px;
				line-height: 20px;
				font-size: 14px;
				position: relative;
				color: #000000;
				.sanjiao{
					position: absolute;
					width: 40px;
					height: 40px;
					top: -8px;
					left: -20px;
					transform: rotate(90deg);
				}
			}
		}
		.content-right{
			margin: 17px 0px 13px;
			display: flex;
			margin-left: 445px;
			.avatar{
				width: 56px;
				height: 56px;
				border-radius: 100%;
				margin-left: 39px;
			}
			.text{
				width: 280px;
				background: #8DFA69;
				padding: 20px;
				line-height: 20px;
				font-size: 14px;
				position: relative;
				color: #000000;
				.sanjiao{
					position: absolute;
					width: 40px;
					height: 40px;
					top: -8px;
					right: -20px;
					transform: rotate(90deg);
				}
			}
		}
	}
	.ly-time{
		text-align: center;
		font-size: 14px;
		font-weight: 500;
		color: #333333;
	}
}
.ly-content{
	display: flex;
	justify-content: flex-start;
}
.ly-left{
	width: 300px;
	height: 650px;
	overflow: auto;
	padding: 20px;
	.ly-left-user{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin: 20px 0px;
		cursor: pointer;
		.ly-left-user-image{
			flex: 0 0 56px;
			height: 56px;
			border-radius: 100%;
			position: relative;
			img{
				width: 56px;
				height: 56px;
				border-radius: 100%;
			}
			div{
				width: 20px;
				height: 20px;
				background: #E73522;
				border-radius: 50%;
				text-align: center;
				line-height: 20px;
				font-size: 14px;
				font-weight: 400;
				color: #FEFEFE;
				position: absolute;
				top: 0px;
				right: 0px;
			}
		}
		.ly-left-user-info{
			margin-left: 19px;
			flex: 0 0 190px;
			.name{
				display: flex;
				justify-content: space-between;
				align-items: center;
				div{
					&:first-of-type{
						font-size: 16px;
						font-weight: 500;
						color: #111111;
						flex: 0 0 100px;
						width: 120px;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space:nowrap;
					}
					&:last-of-type{
						font-size: 14px;
						font-weight: 400;
						color: #999999;
					}
				}
			}
			.desc{
				font-size: 14px;
				font-weight: 400;
				color: #666666;
				margin-top: 14px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space:nowrap;
				width: 190px;
			}
		}
	}
}
.ly-title{
	height: 70px;
	background-color: #fff;
	line-height: 70px;
	padding-left: 42px;
	box-sizing: border-box;
	border-bottom: 1px solid #DDDDDD;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	.action{
		cursor: pointer;
		font-size: 14px;
		font-weight: 400;
		color: #666666;
		margin-left: 52px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		img{
			width: 18px;
			height: 18px;
		}
		
	}
	
}
.ly-title .name{
	font-size: 20px;
	font-weight: 500;
	color: #333333;
}
::v-deep .el-dialog__header{
	padding: 0px;
}
::v-deep .el-dialog__body{
	padding: 0px;
}
@media only screen and (max-width: 750px) {
	.ly-dialog ::v-deep .el-dialog{
		width: 96% !important;
	}
	.ly-title{
		height: .8rem;
		line-height: .8rem;
		padding-left: .3rem;
		.name{
			font-size: .32rem;
		}
		.action{
			font-size: .24rem;
			img{
				width: .3rem;
				height: .3rem;
			}
		}
		
	}
	.ly-content{
		.ly-left{
			flex-shrink: 0;
			width: 35%;
			height: calc(50vh + 2.7rem);
			padding: .2rem;
			.ly-left-user{
				margin: .2rem 0;
				.ly-left-user-image{
					flex: 0 0 .8rem;
					height: .8rem;
					img{
						width: 100%;
						height: 100%;
					}
				}
				.ly-left-user-info{
					flex: auto;
					margin-left: .1rem;
					overflow: hidden;
					.name{
						display: block;
						div{
							&:first-child{
								width: 100%;
								font-size: .24rem;
							}
							&:last-child{
								font-size: .24rem;
							}
						}
					}
					.desc{
						font-size: .24rem;
						width: 100%;
					}
				}
			}
		}
		.ly-right{
			flex: 0 0 65%;
			overflow: hidden;
			.name{
				font-size: .28rem;
				line-height: .8rem;
				height: .8rem;
				+ div{
					height: .8rem !important;
				}
			}
			.content{
				height: 50vh;
				padding: .3rem;
				.ly-time{
					font-size: .24rem;
				}
				.content-left{
					margin: .1rem 0;
					.avatar{
						width: .6rem;
						height: .6rem;
						margin-right: .2rem;
					}
					.text{
						width: calc(100% - .8rem);
						font-size: .24rem;
						padding: .2rem;
						.sanjiao{
							width: .4rem;
							height: .4rem;
							top: -.08rem;
							left: -.2rem;
						}
						.el-textarea{
							font-size: .24rem;
						}
						::v-deep .el-textarea__inner{
							height: auto !important;
							line-height: .4rem !important;
							min-height: .4rem !important;
						}
					}
				}
				.content-right{
					margin-left: 0;
					.avatar{
						width: .6rem;
						height: .6rem;
						margin-left: .2rem;
					}
					.text{
						width: calc(100% - .8rem);
						font-size: .24rem;
						padding: .2rem;
						.sanjiao{
							width: .4rem;
							height: .4rem;
							top: -.08rem;
							right: -.2rem;
						}
						.el-textarea{
							font-size: .24rem;
						}
						::v-deep .el-textarea__inner{
							height: auto !important;
							line-height: .4rem !important;
							min-height: .4rem !important;
						}
					}
				}
			}
			.ly-input{
				padding: .7rem .3rem .2rem;
				.button{
					top: .1rem;
					right: .1rem;
					width: 1rem;
					height: .5rem;
					font-size: .24rem;
					line-height: .5rem;
				}
				::v-deep .el-textarea__inner{
					height: 1rem;
					padding: 0;
				}
			}
		}
	}
	::v-deep .el-dialog__headerbtn{
		top: .2rem;
		right: .2rem;
		font-size: .32rem;
	}
}
</style>