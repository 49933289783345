<template>
	<div class="mycenter">
		<Header />
		<div class="main">
			<div class="wp1200">
				<div class="g-back-btn">
					<router-link to="/myinfo" class="back">返回账号设置</router-link>
				</div>
				<div class="m-username-m1">
					<div class="username-tit">账号信息</div>
					<div class="form">
						<el-form :model="form" :rules="rules" ref="infoForm">
							<div class="frm-m1">
								<div class="frm-m1-l">
									<ul class="ul-input-m1">
										<li>
											<el-form-item prop="realname">
												<div class="label">姓名<span style="color: red;">*</span></div>
												<input type="text" v-model.trim="form.realname" placeholder="请输入姓名" name />
											</el-form-item>
										</li>
										<li>
											<el-form-item prop="nickname">
												<div class="label">昵称<span style="color: red;">*</span></div>
												<input type="text" v-model.trim="form.nickname" placeholder="请输入昵称" name />
											</el-form-item>
										</li>
										<li>
											<el-form-item prop="companyname">
												<div class="label">公司名称<span style="color: red;">*</span></div>
												<input type="text" disabled v-model.trim="form.companyname" placeholder="请输入公司名称" />
											</el-form-item>
										</li>
										<li>
											<el-form-item prop="post">
												<div class="label">职位<span style="color: red;">*</span></div>
												<input type="text" v-model.trim="form.post" placeholder="请输入职位" name />
											</el-form-item>
										</li>
									</ul>
									<!-- <div class="m-radio-m1">
										<div class="radio-label">合作伙伴身份</div>
										<ul>
											<el-form-item prop="iden">
												<li v-for="(item, index) in idenList" :key="index">
													<input type="checkbox" name="iden" :id="'ckbox'+index" :value="item.id" v-model="form.iden" />
													<label :for="'ckbox'+index" class="label">{{ item.idenName }}</label>
												</li>
											</el-form-item>
										</ul>
									</div> -->
								</div>
								<div class="frm-m1-r">
									<div class="m-update-pic">
										<div class="pic">
											<img v-if="!form.avatar" src="../assets/images/home/pic-z1.png" />
											<img v-else :src="form.avatar" />
										</div>
										<div class="btn" @click="upload">点击上传</div>
										<!-- <input
											type="file"
											ref="avatar_input"
											id="avatar_input"
											style="display: none;"
											@change="uploadPic"
										/> -->
										<el-upload
											action=""
											:show-file-list="false"
											:http-request="uploadPic"
											:on-success="handleSuccess"
										></el-upload>
									</div>
								</div>
							</div>
							<div class="frm-m2">
								<button class="g-btn-m1" @click.prevent="submit">保存</button>
							</div>
						</el-form>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Api from '@/request/api';

export default {
	name: 'Mycenter',
	components: {
		Header,
		Footer
	},
	data() {
		const info = this.$store.state.user
		let idenArr = []
		info.idenList.forEach(item => {
			idenArr.push(item.id)
		})
		return {
            idenList: [],
			form: {
				id: info.id,
				realname: info.realname,
				nickname: info.nickname,
				companyname: info.companyname,
				post: info.post,
				avatar: info.avatar,
				iden: idenArr
			},
			rules: {
				realname: [
					{ required: true, message: '请输入姓名', trigger: 'blur' },
					{ max: 10, message: '最多10个字', trigger: 'blur' },
					{ min: 2, message: '最少2个字', trigger: 'blur' },
				],
				nickname: [
					{ required: true, message: '请输入昵称', trigger: 'blur' },
					{ max: 20, message: '最多20个字', trigger: 'blur' },
					{ min: 2, message: '最少2个字', trigger: 'blur' },
				],
				companyname: [
					{ required: true, message: '请输入公司名称', trigger: 'blur' }
				],
				post: [
					{ required: true, message: '请输入职位', trigger: 'blur' }
				],
				iden: [
					{ required: true, message: '请选择身份', trigger: 'blur' }
				]
			}
		}
	},
	created() {
		// this.getInfo()
        // 获取身份列表
        // this.getIdenList()
	},
	methods: {
		getInfo() {
			Api.getUserInfo().then(res => {
				// console.log(res)
			})
		},
        // 获取身份列表
        getIdenList() {
            Api.idenList().then(res => {
                console.log(res)
                this.idenList = res
            })
        },
		upload() {
			document.querySelector('.el-upload input').click()
		},
		uploadPic({ file }) {
			return Api.upload(file)
		},
		handleSuccess(res) {
			if (res.data.code == 0) {
				this.form.avatar = res.data.message
			}
		},
		submit() {
			this.$refs.infoForm.validate(async valid => {
				if (valid) {
					await Api.updateUser(this.form)
					const res = await Api.getUserInfo()
					this.$store.dispatch('setUserInfo', res)
					this.$message.success('修改成功')
				}
			})
		}
	}
}
</script>

<style scoped lang="scss">
.wp1200 {
	max-width: 1200px;
	margin: 0 auto;
}
.main {
	background-color: #e3e4e5;
	margin-top: 80px;
}
.g-back-btn {
}
.g-back-btn .back {
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #333333;
	line-height: 60px;
	background-image: url(../assets/images/home/icon-m5.png);
	background-size: 16px 16px;
	background-repeat: no-repeat;
	background-position: left center;
	padding-left: 25px;
}
.m-username-m1 {
	background-color: #fff;
	margin-bottom: 80px;
}
.m-username-m1 .username-tit {
	font-size: 18px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #333333;
	line-height: 60px;
	border-bottom: 1px solid #e4e4e4;
	padding-left: 20px;
}
.m-username-m1 .form {
	padding: 80px 210px 55px 90px;
}
.m-username-m1 .frm-m1 {
	overflow: hidden;
	margin-bottom: 70px;
}
.m-username-m1 .frm-m1-l {
	float: left;
}
.m-username-m1 .frm-m1-r {
	float: right;
}
.m-username-m1 .frm-m2 {
}

.ul-input-m1 {
	width: 560px;
	margin-bottom: 15px;
	/* overflow: hidden; */
}
.ul-input-m1::after{
	content: "";
	display: block;
	clear: both;
}
.ul-input-m1 li {
	width: 260px;
	float: left;
	margin-right: 20px;
	margin-bottom: 12px;
}
.ul-input-m1 .label {
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #333333;
	line-height: 50px;
}
.ul-input-m1 input {
	width: 100%;
	height: 48px;
	border: 1px solid #ccc;
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #666666;
	box-sizing: border-box;
	outline: none;
	padding: 0 10px;
}
.ul-input-m1 input:-internal-autofill-previewed,
.ul-input-m1 input:-internal-autofill-selected {
	-webkit-text-fill-color: #666 !important;
	transition: background-color 5000s ease-in-out 0s !important;
}
.m-radio-m1 {}
.m-radio-m1 .radio-label {
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	line-height: 30px;
	color: #333333;
	margin-bottom: 10px;
}
.m-radio-m1 ul {
	width: 560px;
	overflow: hidden;
}
.m-radio-m1 li {
	float: left;
	width: 260px;
	position: relative;
	padding-left: 30px;
	box-sizing: border-box;
	margin-right: 20px;
}
.m-radio-m1 input {
	width: 16px;
	height: 16px;
	border: 1px solid #ccc;
	border-radius: 50%;
	outline: none;
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	cursor: pointer;
}
.m-radio-m1 input:checked {
	background-image: url(../assets/images/home/icon-z4.png);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	border: none;
}
.m-radio-m1 .label {
	font-size: 16px;
	line-height: 30px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #333333;
	cursor: pointer;
}
.m-update-pic {
	width: 140px;
}
.m-update-pic .pic {
	width: 140px;
	height: 140px;
	overflow: hidden;
	margin-bottom: 50px;
}
.m-update-pic img {
	width: 100%;
	height: 100%;
	display: block;
}
.m-update-pic .btn {
	width: 100px;
	height: 35px;
	border: 1px solid #dddddd;
	cursor: pointer;
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #e83421;
	line-height: 35px;
	text-align: center;
	margin: 0 auto;
	transition: .5s;
}
.m-update-pic .btn:hover{
	background-color: #e83421;
	color: #fff;
	border-color: #e83421;
}

.g-btn-m1 {
	display: block;
	width: 280px;
	height: 50px;
	background-color: #e83421;
	border-radius: 3px;
	margin: 0 auto;
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #ffffff;
	outline: none;
	cursor: pointer;
}
::v-deep .el-form-item {
	margin-bottom: 0;
}

@media only screen and (max-width: 750px) {
	.main{
		margin-top: 1.4rem;
		overflow: hidden;
	}
	.g-back-btn{
		padding: .2rem .3rem;
	}
	.g-back-btn .back{
		font-size: .24rem;
		line-height: .6rem;
		background-size: .4rem auto;
		padding-left: .4rem;
	}
	.m-username-m1{
		margin: 0 .3rem .6rem;
		.username-tit{
			font-size: .32rem;
			line-height: 2;
			padding-left: .3rem;
		}
		.form{
			padding: .3rem;
		}
		.frm-m1{
			margin-bottom: .6rem;
			.frm-m1-l{
				float: none;
				.ul-input-m1{
					width: 100%;
					margin-bottom: .2rem;
					li{
						float: none;
						width: 100%;
						margin: 0;
						margin-bottom: .2rem;
						.label{
							font-size: .24rem;
						}
						input{
							height: .7rem;
							line-height: .7rem;
							padding: 0 .2rem;
							font-size: .24rem;
						}
						::v-deep .el-form-item__content{
							font-size: .24rem;
						}
					}
				}
			}
			.frm-m1-r{
				float: none;
				.m-update-pic{
					width: 3rem;
					margin: 0 auto;
					.pic{
						width: 3rem;
						height: 3rem;
					}
					.btn{
						width: 3rem;
						height: .6rem;
						font-size: .24rem;
						line-height: .6rem;
					}
				}
			}
		}
		.g-btn-m1{
			width: 6rem;
			height: .7rem;
			line-height: .7rem;
			font-size: .3rem;
		}
	}
}
</style>
