<template>
    <div class="channel-information">
        <Header />
        <div class="ban">
            <img src="../assets/images/ban_qudaozixun.png" alt />
        </div>
        <div class="work-container">
            <div class="slide-menu-area coustom-scroll channel-menu">
                <h1 class="main-tit">渠道发展</h1>
                <el-tree
                    ref="inforTree"
                    :data="inforTree"
                    :default-expand-all="false"
                    :props="treeProps"
                    node-key="id"
                    :current-node-key="params.pid"
                    :highlight-current="true"
                    :expand-on-click-node="false"
                    :check-on-click-node="true"
                    @node-click="nodeClick"
                ></el-tree>
            </div>
            <div class="work-content-box">
                <div class="top">
                    <h1 class="main-tit">全部渠道发展</h1>
                    <div class="filter">
                        <span class="filter-label">类型：</span>
                         <ul>
                            <li :class="params.type == '' ? 'active' : ''" @click="params.type = '';params.pageNo = 1;list = [];getList();">全部</li>
                            <li :class="params.type == item ? 'active' : ''" v-for="(item, index) in showTypeList" :key="index" @click="params.type = item;params.pageNo = 1;list = [];getList();">{{ item }}</li>
                        </ul>
                        <span style="color: #e83421;cursor: pointer;white-space: nowrap;margin-left: auto;height: 30px;" v-show="!showMore&&lengthMore" @click="showMore=!showMore">查看更多</span>
                        <span style="color: #e83421;cursor: pointer;white-space: nowrap;margin-left: auto;height: 30px;" v-show="showMore&&lengthMore" @click="showMore=!showMore">收起</span>
                    </div>
                    <div class="filter">
                        <span class="filter-label">格式：</span>
                        <ul>
                            <li
                                :class="params.format == '' ? 'active' : ''"
                                @click="params.format = '';params.pageNo = 1;list = [];getList();"
                            >全部</li>
                            <li
                                v-for="(item, index) in formats"
                                :key="index"
                                :class="params.format == item ? 'active' : ''"
                                @click="params.format = item;params.pageNo = 1;list = [];getList();"
                            >{{ item }}</li>
                        </ul>
                    </div>
                </div>
                <div class="bot">
                    <div class="filter-sort">
                        <div class="types">
                            <el-dropdown placement="bottom-start">
                                <span class="el-dropdown-link sort-label">
                                    {{ params.sort == 1 ? '从低到高' : '从高到低' }}
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" class="sort-menu">
                                    <el-dropdown-item @click.native="params.sort = 1;params.pageNo = 1;list = [];getList();">从低到高</el-dropdown-item>
                                    <el-dropdown-item @click.native="params.sort = 0;params.pageNo = 1;list = [];getList();">从高到低</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <span
                                :class="params.order == '下载量' ? 'active' : ''"
                                @click="changeOrder('下载量')"
                            >下载量</span>
                            <span
                                :class="params.order == '阅读量' ? 'active' : ''"
                                @click="changeOrder('阅读量')"
                            >阅读量</span>
                            <div class="search-box">
                                <input type="text" v-model="params.title" placeholder="请输入搜索关键词" @keyup.enter="params.pageNo = 1;list = [];getList" />
                                <i class="search-btn" @click="params.pageNo = 1;list = [];getList()"></i>
                            </div>
                        </div>
                        <div class="count">{{ total }}个</div>
                    </div>

                    <ul class="list-box" v-loading="loading">
                        <li
                            :class="getFormatType(item.infoFormat)"
                            v-for="(item, index) in list"
                            :key="index"
                        >
                            <div class="tit">
                                <div class="val">{{ item.title }}</div>
                                <div class="op" style="min-width: 128px;">
                                    <span
                                        class="icon-preview"
                                        v-if="item.infoFormat == 'pdf'"
                                        @click="priview(item.id)"
                                    >预览</span>
                                    <span
                                        class="icon-preview"
                                        v-if="(item.infoFormat != 'pdf' && item.infoContent!='') || item.infoFormat == '视频'"
                                        @click="toDetail(item)"
                                    >查看</span>
                                    <span
                                        class="icon-download"
                                        @click="showDownload(item)"
                                        v-if="(item.infoFormat != '视频' && item.infoFormat != '链接')"
                                    >下载</span>
                                </div>
                            </div>
                            <div class="tim-size">
                                <span class="val">{{ item.createTime }} I {{ item.infoSize }}</span>
                                <div class="op" @click="collection(item.id, index, item.collect)">
                                    <span :class="item.collect ? 'icon-faved' : 'icon-fav'">收藏</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- 内容为空 -->
                    <ContentNull v-if="list.length == 0" />
                </div>
				<div class="pagination-box" v-if="list.length > 0">
                    <el-pagination
                        :total="total"
                        :page-size="params.pageSize"
                        layout="prev, pager, next"
                        @current-change="handleCurrentChange"
                    ></el-pagination>
                </div>
            </div>
        </div>
        <Download :info="downInfo" ref="downloadRef" @confirm="download" />
		<Footer />
    </div>
</template>

<script>

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Download from '@/components/Download.vue';
import ContentNull from '@/components/ContentNull.vue';
import { mapState } from 'vuex';
import Api from '@/request/api';
export default {
    name: 'GeneralProduct',
    components: { Header, Download, ContentNull, Footer },
    data() {
        return {
            showMore:false,
            sortData: {
                1: '正序',
                0: '倒序'
            },
            // treedata: [],
            tempCates: [],
            loading: false,
            params: {
                belong: 1,
                format: '',
                order: '',
                sort: 0,
                type: '',
                pageNo: 1,
                pageSize: 10,
                pid: 0,
                title: ''
            },
            treeProps: {
                children: 'children',
                label: 'title'
            },
            total: 0,
            list: [],
            showMore: false,
            typeList: [],
            downInfo: {}
        }
    },
    computed: {
        ...mapState([
            'inforTree',
            'formats',
            'otherCates'
        ]),
        lengthMore() {
            if (this.typeList.length > 8) {
                return true
            } else {
                return false
            }
        },
        showTypeList() {
            if (this.showMore) {
                return this.typeList
            } else {
                return this.typeList.slice(0, 8)
            }
        }
    },
    watch: {
        $router(v) {
            console.log(v)
        }
    },
    created() {
        this.getTypeList()
        // 获取数据
        this.getList()
    },
    activated() {
        if (this.$store.state.tempProductId) {
            this.params.pid = Number(this.$store.state.tempProductId)
        }
        this.$nextTick(() => {
            this.$refs.inforTree.setCurrentKey(this.params.pid)
        })
        this.getTypeList()
        // 获取数据
        this.getList()
    },
    methods: {
        getList() {
            this.loading = true
            Api.getList(this.params).then(res => {
                if (this.total == 0) {
                    this.list = res.records
                    this.total = res.total
                    setTimeout(() => {
                        this.loading = false
                    }, 300)
                } else {
                    setTimeout(() => {
                        this.list = res.records
                        this.total = res.total
                        this.loading = false
                    }, 300)
                }
            }).catch(err => {
                this.loading = false
            })
        },
        // 分页查询
        handleCurrentChange(v) {
            this.params.pageNo = v
            this.getList()
        },
        // 排序条件
        changeOrder(value) {
            if (value == this.params.order) {
                return this.params.order = ''
            } else {
                this.params.order = value
            }
            this.list = [];
            this.params.pageNo = 1;
            this.getList()
        },
        // 点击树列表
        nodeClick(e, n) {
            this.tempCates = []
            this.$store.dispatch('setOtherCates', [])
            this.allParent(n)
            this.tempCates.unshift('渠道发展')
            this.$store.dispatch('setOtherCates', this.tempCates)
            this.params.pid = e.id
            this.list = [];
            this.params.pageNo = 1
            // 加载类型
            this.getTypeList()
            this.getList()
        },
        // 循环获取父级分类
        allParent(datas) {
            for(var i in datas){
                if (i == 'data') {
                    this.tempCates.unshift(datas[i].title)
                }
                if (i == 'parent' && datas[i].level >= 1) {
                    if(datas[i]){  //存在子节点就递归
                        this.allParent(datas[i]);
                    }
                }
            }
        },
        // 加载类型
        getTypeList() {
            Api.typeList({ belong: this.params.belong, pid: this.params.pid }).then(res => {
                this.typeList = res
            })
        },
        // 显示下载弹窗
        showDownload(item) {
            this.downInfo = {
                id: item.id,
                title: item.title,
                url: item.document,
                icon: item.infoFormat
            }
            this.$refs.downloadRef.open()
        },
        // 下载
        download() {
            const data = {
                belong: 1,
                id: this.downInfo.id,
                type: 4
            }
            Api.inforPosts(data).then(res => {
                // window.location = this.downInfo.url
                // window.location = res.document
                window.open(res.document.replace('http', 'https'))
            })
            this.$refs.downloadRef.close()
        },
        // 收藏
        collection(id, index, vlaue) {
            const data = {
                belong: 1,
                id: id,
                type: 3
            }
            if (vlaue) {
                Api.cancelInforPosts(data)
            } else {
                Api.inforPosts(data)
            }
            this.list[index].collect = !vlaue
        },
        // 跳转到详情页面
        toDetail(item) {
            if (item.infoFormat == '链接') {
                window.location = item.document
            } else {
                this.$router.push({ path: '/detail', query: { id: item.id, type: 1 } })
            }
        },
        // 根据类型返回类
        getFormatType(type) {
            let str = ''
            this.$store.state.infoFormatType.forEach(item => {
                if (item.type == type) {
                    str = item.class
                    return;
                }
            })
            return str
        },
        // 预览
        priview(id) {
            const data = {
                belong: 1,
                id: id,
                type: 4
            }
            Api.inforPosts(data).then(res => {
                console.log(res)
                if (res.document) {
                    // window.location = res.document
                    window.open(res.document.replace('http', 'https'))
                }
            })
            // window.location = url
        },
        cancel(index) {
            this.inforTree[index].leaf = !this.inforTree[index].leaf
        },
        cancel1(index1, index) {
            this.inforTree[index].children[index1].leaf = !this.inforTree[index].children[index1].leaf
        }
    }
}
</script>

<style scoped lang="scss">
.channel-information {
    padding-top: 80px;
    .work-container {
        padding-top: 30px;
        padding-left: 360px;
        padding-right: 360px;
        display: flex;
        background: #f5f5f7;
        padding-bottom: 50px;
        align-items: flex-start;
        .slide-menu-area {
            border-radius: 5px;
            padding: 40px 30px;
            width: 355px;
            min-width:355px;
            padding-right: 15px;
            margin-right: 20px;
            background: #fff;
            min-height: 800px;
            max-height: 900px;
            overflow-y: auto;
            position: sticky;
            top: 80px;
            .main-tit {
                font-size: 18px;
                font-weight: 500;
                color: #111111;
                margin-bottom: 26px;
            }
            .menus {
                // border-left:1px solid #E8E9E9;
                // padding-left:10px;
                .parent {
                    display: flex;
                    align-items: center;
                    &:before {
                        content: "";
                        min-width: 16px;
                        height: 16px;
                        background: url("../assets/images/meun_collapse.png")
                            no-repeat;
                        display: inline-block;
                        background-size: cover;
                        margin-right: 5px;
                    }
                }
                .iscollapse {
                    &:before {
                        content: "";
                        min-width: 16px;
                        height: 16px;
                        background: url("../assets/images/menu_collapsed.png")
                            no-repeat;
                        display: inline-block;
                        background-size: cover;
                        margin-right: 5px;
                    }
                }
                li {
                    line-height: 25px;
                    a {
                        line-height: 25px;
                        display: inline-block;
                        margin-bottom: 5px;
                        width: 100%;
                        padding-left: 10px;
                        border-radius: 5px;
                        user-select: none;
                    }
                    a.active {
                        background: #f4f9fa;
                        color: #e23523;
                        font-weight: 500;
                    }
                    ul {
                        //    transition:.2s all ease-in-out;
                        //    height:0px;
                        //    overflow: hidden;
                    }
                }
                > li {
                    margin-bottom: 20px;
                    > ul {
                        margin-left: 5px;
                        border-left: 1px solid #e8e9e9;
                        padding-left: 10px;
                        > li {
                            > ul {
                                margin-left: 15px;
                                border-left: 1px solid #e8e9e9;
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }
        }
        .work-content-box {
            border-radius: 5px;
            background: #fff;
            width: 100%;
            .top {
                padding: 40px 30px;
            }
            .main-tit {
                font-size: 18px;
                font-weight: 500;
                color: #333333;
                line-height: 18px;
                margin-bottom: 34px;
            }
            .filter {
                display: flex;
                margin-bottom: 26px;
                .filter-label {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    white-space: nowrap;
                }
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        text-align: center;
                        min-width:56px;
                        line-height: 22px;
                        border-radius: 22px;
                        padding: 0px 10px;
                        margin-right:20px;
                        color: #707070;
                        cursor: pointer;
                        transition:.1s all linear;
                        user-select: none;
                        margin-bottom:10px;
                        &:hover{
                            background: #e73522;
                            color: #fff;
                        }
                    }
                    .active {
                        background: #e73522;
                        color: #fff;
                    }
                }
                .filter-view-more {
                    color: #e73522;
                    cursor: pointer;
                }
            }
            .filter:last-child {
                margin-bottom: 0px;
            }
            .bot {
                border-top: 1px solid #dddddd;
                padding: 20px 30px 0px 30px;
                .filter-sort {
                    margin-bottom: 38px;
                    color: #707070;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .types {
                        display: flex;
                        align-items: center;
                        .sort-label {
                            width: 100px;
                            text-align: center;
                            border: 1px solid #cccccc;
                            border-radius: 3px;
                        }
                        span {
                            margin-right: 34px;
                            display: inline-block;
                            cursor: pointer;
                            line-height: 32px;
                        }
                        .search-box {
                            position: relative;
                            input {
                                border: 1px solid #ccc;
                                line-height: 32px;
                                outline: none;
                                padding: 0px 10px;
                                height: 32px;
                                min-width: 260px;
                                padding-right: 30px;
                            }
                            .search-btn {
                                width: 32px;
                                height: 32px;
                                background: url("../assets/images/search.png")
                                    no-repeat;
                                display: inline-block;
                                background-size: 16px 16px;
                                background-position: center;
                                cursor: pointer;
                                position: absolute;
                                right: 0px;
                                border: 0px;
                                top: 0px;
                                transition: 0.2s all linear;
                                &:active {
                                    opacity: 0.8;
                                    background-color: #eee;
                                }
                            }
                        }
                    }
                }
                .list-box {
                    li {
                        padding-bottom: 22px;
                        border-bottom: 1px solid #dddddd;
                        margin-bottom: 24px;
                        padding-left: 50px;
                        .tit {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .val {
                                font-size: 16px;
                                font-weight: 500;
                            }
                        }

                        .op {
                            display: flex;
                            justify-content: flex-end;
                            span {
                                padding-left: 20px;
                                cursor: pointer;
                                &:hover {
                                    color: #e23523;
                                }
                            }
                            span:last-child {
                                margin-left: 32px;
                            }
                            .icon-preview {
                                background: url("../assets/images/icon_preview.png")
                                    no-repeat;
                                background-size: 16px 16px;
                                background-position: left;
                            }
                            .icon-download {
                                background: url("../assets/images/icon_download.png")
                                    no-repeat;
                                background-size: 16px 16px;
                                background-position: left;
                            }
                            .icon-fav {
                                background: url("../assets/images/icon_fav.png")
                                    no-repeat;
                                background-size: 16px 16px;
                                background-position: left;
                            }
                            .icon-faved {
                                background: url("../assets/images/icon_faved.png")
                                    no-repeat;
                                background-size: 16px 16px;
                                background-position: left;
                            }
                        }
                        .tim-size {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 13px;
                            .val {
                                color: #989898;
                                font-size: 14px;
                            }
                        }
                    }
                    li:last-child {
                        margin-bottom: 0px;
                    }

                    .file-pdf {
                        background: url("../assets/images/file_pdf.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-word {
                        background: url("../assets/images/file_word.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-xls {
                        background: url("../assets/images/file_xls.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-albums {
                        background: url("../assets/images/file_albums.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-apk {
                        background: url("../assets/images/file_apk.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-exe {
                        background: url("../assets/images/file_exe.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-link {
                        background: url("../assets/images/file_link.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-picture {
                        background: url("../assets/images/file_picture.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-powerpoint {
                        background: url("../assets/images/file_powerpoint.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-video {
                        background: url("../assets/images/file_video.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-other {
                        background: url("../assets/images/file_other.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                    .file-zip {
                        background: url("../assets/images/file_zip.png")
                            no-repeat;
                        background-size: 40px 40px;
                        background-position: left 0;
                    }
                }
            }
            .pagination-box {
                text-align: center;
                margin-bottom: 37px;
                margin-top: 13px;
            }
        }
    }
    .active {
        color: #e73522;
    }
}
@media only screen and (max-width: 750px) {
    .ban{
        height: 2rem;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .channel-information {
        padding-top: 1.2rem;
        .work-container {
            display: block;
            padding: .3rem .3rem .6rem;
            .slide-menu-area {
                padding: .3rem;
                width: 100%;
                min-width:0;
                margin: 0 0 .2rem;
                min-height: 0;
                max-height: 3rem;
                position: static;
                .main-tit {
                    font-size: .32rem;
                    margin-bottom: .2rem;
                }
                .menus {
                    .parent {
                        display: flex;
                        align-items: center;
                        &:before {
                            content: "";
                            min-width: .32rem;
                            height: .32rem;
                            background: url("../assets/images/meun_collapse.png")
                                no-repeat;
                            display: inline-block;
                            background-size: cover;
                            margin-right: .1rem;
                        }
                    }
                    .iscollapse {
                        &:before {
                            content: "";
                            min-width: .32rem;
                            height: .32rem;
                            background: url("../assets/images/menu_collapsed.png")
                                no-repeat;
                            display: inline-block;
                            background-size: cover;
                            margin-right: .1rem;
                        }
                    }
                    li {
                        line-height: .5rem;
                        a {
                            line-height: .5rem;
                            display: inline-block;
                            margin-bottom: .1rem;
                            width: 100%;
                            padding-left: .1rem;
                        }
                    }
                    > li {
                        margin-bottom: .2rem;
                        > ul {
                            margin-left: .1rem;
                            padding-left: .1rem;
                            > li {
                                > ul {
                                    margin-left: .3rem;
                                    padding-left: .2rem;
                                }
                            }
                        }
                    }
                }
            }
            ::v-deep .channel-menu .el-tree > .el-tree-node .el-tree-node__content{
                min-height: .5rem;
                padding: .1rem 0;
            }
            ::v-deep .channel-menu .el-tree-node__expand-icon,
            ::v-deep .channel-menu .el-tree-node__expand-icon.expanded{
                width: .28rem;
                height: .28rem;
                margin-right: .1rem;
            }
            ::v-deep .el-tree-node__label{
                font-size: .24rem;
            }
            .work-content-box {
                overflow: hidden;
                .top {
                    padding: .3rem;
                }
                .main-tit {
                    font-size: .32rem;
                    line-height: 1.2;
                    margin-bottom: .2rem;
                }
                .filter {
                    display: block;
                    margin-bottom: .2rem;
                    .filter-label {
                        display: block;
                        font-size: .28rem;
                        line-height: 1.6;
                    }
                    ul {
                        li {
                            font-size: .24rem;
                            min-width:.6rem;
                            line-height: .5rem;
                            border-radius: .25rem;
                            padding: 0px .2rem;
                            margin-right:.1rem;
                            margin-bottom:.1rem;
                        }
                    }
                }
                .bot {
                    padding: .3rem;
                    .filter-sort {
                        margin-bottom: .3rem;
                        display: block;
                        font-size: .28rem;
                        .types {
                            display: block;
                            font-size: .28rem;
                            margin-bottom: .15rem;
                            .sort-label {
                                width: 100%;
                            }
                            .el-dropdown{
                                font-size: .28rem;
                                width: calc(100% - 2.2rem);
                                margin-right: .1rem;
                            }
                            
                            span {
                                margin-right: .2rem;
                                display: inline-block;
                                cursor: pointer;
                                line-height: .6rem;
                                margin: 0;
                                margin-right: .1rem;
                            }
                            .search-box {
                                position: relative;
                                margin-top: .2rem;
                                input {
                                    line-height: .6rem;
                                    padding: 0px .2rem;
                                    height: .6rem;
                                    min-width: 260px;
                                    width: calc(100% - .7rem);
                                    padding-right: .3rem;
                                }
                                .search-btn {
                                    width: .6rem;
                                    height: .6rem;
                                    background-position: center center;
                                    background-size: .3rem auto;
                                }
                            }
                        }
                    }
                    .list-box {
                        li {
                            padding-bottom: .2rem;
                            margin-bottom: .2rem;
                            padding-left: .8rem;
                            .tit {
                                display: block;
                                .val {
                                    font-size: .28rem;
                                }
                            }

                            .op {
                                display: flex;
                                justify-content: flex-start;
                                margin-top: .1rem;
                                span {
                                    font-size: .24rem;
                                    padding-left: .4rem;
                                    margin-right: .3rem;
                                }
                                span:last-child {
                                    margin-left: 0;
                                }
                                .icon-preview {
                                    background-size: .3rem auto;
                                }
                                .icon-download {
                                    background-size: .3rem auto;
                                }
                                .icon-fav {
                                    background-size: .3rem auto;
                                }
                                .icon-faved {
                                    background-size: .3rem auto;
                                }
                            }
                            .tim-size {
                                margin-top: .2rem;
                                .val {
                                    font-size: .24rem;
                                }
                                .op{
                                    margin-top: 0;
                                }
                            }
                        }
                        .file-pdf {
                            background-size: .6rem auto;
                        }
                        .file-word {
                            background-size: .6rem auto;
                        }
                        .file-xls {
                            background-size: .6rem auto;
                        }
                        .file-albums {
                            background-size: .6rem auto;
                        }
                        .file-apk {
                            background-size: .6rem auto;
                        }
                        .file-exe {
                            background-size: .6rem auto;
                        }
                        .file-link {
                            background-size: .6rem auto;
                        }
                        .file-picture {
                            background-size: .6rem auto;
                        }
                        .file-powerpoint {
                            background-size: .6rem auto;
                        }
                        .file-video {
                            background-size: .6rem auto;
                        }
                        .file-other {
                            background-size: .6rem auto;
                        }
                        .file-zip {
                            background-size: .6rem auto;
                        }
                    }
                }
                .pagination-box {
                    margin: .3rem 0 .6rem;
                }
            }
        }
    }
    ::v-deep .el-dropdown-menu__item{
        font-size: .28rem;
        line-height: 2;
    }
}
</style>