<template>
	<div class="home">
		<Header />
		<div class="main">
			<div class="banner">
				<div class="item">
					<div class="con">
						<div class="pic">
							<img src="../assets/images/home/pic-m1.jpg" />
						</div>
						<div class="txt">
							<h2 class="tit">
								坚持原创，
								<br />让 AI 引领人类进步
							</h2>
							<div class="info">AI for a Better Tomorrow</div>
							<div
								class="copyright"
							>京 ICP 备 15000892 号 - 8 | 京公网安备 11010802039141号 | © 2014-2022 SenseTime. All Rights Reserved. 北京市商汤科技开发有限公司</div>
						</div>
					</div>
				</div>
			</div>
			<div class="m-logo-m2" v-if="!hasLogin" v-show="form.type == 1">
				<div class="form">
					<div class="form-title">
						<div @click="personType = 1" :class="personType==1?'tit':'tit noselect'">个人登录</div>
						<div @click="personType = 2" :class="personType==2?'tit':'tit noselect'">公司登录</div>
					</div>
					<form action method="post">
						<div class="item">
							<div class="label">账号</div>
							<input v-if="personType == 1"
								type="text"
								v-model="form.username"
								placeholder="请输入手机号、邮箱"
								autocomplete="new-password"
							/>
							<input v-if="personType == 2"
								type="text"
								v-model="form.username"
								placeholder="请输入公司编码"
								autocomplete="new-password"
							/>
							<!-- <el-input v-model="form.username" :readonly="readonly" type="text" autocomplete="off" placeholder="请输入手机号、邮箱" @focus="readonly=false" @select="readonly=true" /> -->
						</div>
						<div class="item">
							<div class="label">密码</div>
							<input type="password" v-model="form.password" autocomplete="new-password" placeholder="请输入密码" />
							<!-- <el-input v-model="form.password" name="password" type="password" placeholder="请输入密码"/> -->
						</div>
						<div class="item2" v-if="personType == 1">
							<div class="forget" @click="open1">忘记密码</div>
							<!-- <router-link to="" class="forget" @click="showNum=1">忘记密码</router-link> -->
							<div class="qrcode" @click="form.type = 2">手机验证码登录</div>
						</div>
						<div class="item2" v-if="personType == 2">
							<div class="forget" @click="open1">忘记密码</div>
							<div class="qrcode" @click="getcompanytext">账号找回</div>
						</div>
						<div class="btns">
							<button class="btn1" @click.prevent="login">登录</button>
							<router-link v-if="personType == 1" to="/register" class="btn2">没有账号，去注册</router-link>
							<router-link v-if="personType == 2" to="/companyRegister" class="btn2">没有账号，去注册</router-link>
						</div>
					</form>
				</div>
			</div>
			<div class="m-logo-m2" v-if="!hasLogin" v-show="form.type == 2">
				<div class="form">
					<div class="tit">个人登录</div>
					<form action method="post">
						<div class="item">
							<div class="label">手机号码</div>
							<input type="text" v-model="form.username" placeholder="请输入手机号" />
							<!-- <el-input v-model="form.username" name="text" placeholder="请输入手机号"/> -->
						</div>
						<div class="item item-qrcode">
							<div class="label">验证码</div>
							<div class="item-qrcode-input">
								<input type="text" v-model="form.captcha" placeholder="请输入验证码" />
								<!-- <el-input v-model="form.captcha" name="text" placeholder="请输入验证码"/> -->
								<div class="btn-qrcode" @click="countDown">{{ count }}</div>
							</div>
						</div>
						<div class="item2">
							<!-- <router-link to="" class="forget">忘记密码</router-link> -->
							<div class="qrcode" @click="form.type = 1">账号密码登录</div>
						</div>
						<div class="btns">
							<button class="btn1" @click.prevent="login">登录</button>
							<router-link to="/register" class="btn2">没有账号，去注册</router-link>
						</div>
					</form>
				</div>
			</div>
		</div>
		<!-- 弹窗-选择账户验证方式 -->
		<div class="m-popup-m1" v-if="!hasLogin" v-show="showNum == 1">
			<div class="popup">
				<div class="tit">
					<span class="tits">修改密码</span>
					<span class="exit" @click="showNum = 0"></span>
				</div>
				<div class="desc">
					<div class="desc1">您正在修改账户密码，请选择账户验证方式</div>
				</div>
				<div class="content" v-if="personType == 1">
					<div class="g-btns-m1">
						<button type="button" style="width: 100%;" class="btn" @click="showNum = 2;changeType = 1">手机验证</button>
						<!-- <button type="button" style="width: 100%;" class="btn" @click="showNum = 3;changeType = 2">邮箱验证</button> -->
					</div>
				</div>
				<div class="content" v-if="personType == 2">
					<div class="g-btns-m1">
						<button type="button" style="width: 100%;border-color: #E83421;color: #E83421;" class="btn" @click="showNum = 2;changeType = 1">手机验证</button>
						<!-- <button type="button" class="btn" @click="showNum = 3;changeType = 2">邮箱验证</button> -->
					</div>
				</div>
				<div class="btns">
					<div class="g-btns-m2">
						<button type="button" class="btn" @click="showNum = 0">取消</button>
						<!-- <button type="button" class="btn on">确定</button> -->
					</div>
				</div>
			</div>
		</div>
		<!-- 弹窗-手机验证 -->
		<div class="m-popup-m1" v-if="!hasLogin" v-show="showNum == 2">
			<div class="popup">
				<div class="tit">
					<span class="tits">修改密码</span>
					<span class="exit" @click="closeShow"></span>
				</div>
				<div class="desc">
					<div class="desc1">您正在修改账户密码，请先验证手机号码</div>
				</div>
				<div class="content">
					<div class="m-update-form-m1">
						<div class="item">
							<span class="label">手机号码</span>
							<el-input v-model="changePassForm.mobile" type="text" placeholder="请输入手机号码" />
						</div>
						<div class="item item-qrcode">
							<span class="label">验证码</span>
							<el-input v-model="changePassForm.smscode" type="text" placeholder="请输入验证码" />
							<span
								class="btn-qrcode"
								v-if="second == 0"
								@click="sendSms('changePassForm', 'mobile')"
							>发送验证码</span>
							<span class="btn-qrcode-dis" v-else>{{ second }}秒后重新发送</span>
						</div>
					</div>
				</div>
				<div class="btns">
					<div class="g-btns-m2">
						<button type="button" class="btn on" @click="validatePass('mobile')">确定</button>
					</div>
				</div>
			</div>
		</div>
		<!-- 弹窗-邮箱验证 -->
		<div class="m-popup-m1" v-if="!hasLogin" v-show="showNum == 3">
			<div class="popup">
				<div class="tit">
					<span class="tits">修改密码</span>
					<span class="exit" @click="showNum = 0"></span>
				</div>
				<div class="desc">
					<div class="desc1">您正在修改账户密码，请先验证邮箱</div>
				</div>
				<div class="content">
					<div class="m-update-form-m1">
						<div class="item">
							<span class="label">邮箱地址</span>
							<el-input v-model="changePassForm.email" type="email" placeholder="请输入邮箱地址" />
							<!-- <input type="email" v-model="changePassForm.email" placeholder="请输入邮箱地址" name value /> -->
						</div>
						<div class="item item-qrcode">
							<span class="label">验证码</span>
							<el-input v-model="changePassForm.emailcode" type="text" placeholder="请输入验证码" />
							<!-- <input type="text" v-model="changePassForm.emailcode" placeholder="请输入验证码" name value /> -->
							<span
								class="btn-qrcode"
								v-if="second == 0"
								@click="sendEmail('changePassForm', 'email')"
							>发送验证码</span>
							<span class="btn-qrcode-dis" v-else>{{ second }}秒后重新发送</span>
						</div>
					</div>
				</div>
				<div class="btns">
					<div class="g-btns-m2">
						<button type="button" class="btn on" @click="validatePass('email')">确定</button>
					</div>
				</div>
			</div>
		</div>
		<!-- 弹窗-请输入新的密码 -->
		<div class="m-popup-m1" v-if="!hasLogin" v-show="showNum == 4">
			<div class="popup">
				<div class="tit">
					<span class="tits">请输入新的密码</span>
					<span class="exit" @click="showNum = 0"></span>
				</div>
				<div class="desc">
					<div class="desc1">请输入新密码</div>
				</div>
				<div class="content">
					<div class="m-update-form-m1">
						<div class="item">
							<span class="label">新密码</span>
							<el-input v-model="changePassForm.password" type="password" placeholder="请输入新密码" />
							<!-- <input type="password" v-model="changePassForm.password" placeholder="请输入新密码" /> -->
						</div>
						<div class="item">
							<span class="label">重复密码</span>
							<el-input v-model="changePassForm.repass" type="password" placeholder="请重复输入密码" />
							<!-- <input type="password" v-model="changePassForm.repass" placeholder="请重复输入密码" /> -->
						</div>
					</div>
				</div>
				<div class="btns">
					<div class="g-btns-m2">
						<button type="button" class="btn on" @click="submitPass">确定</button>
					</div>
				</div>
			</div>
		</div>

		<!-- 通知公告 -->
		<div v-if="showNotice == 0 && hasLogin && mymessage != ''" class="m-notification-m1">
			<div class="con">
				<h2 class="tit">{{ mytitle }}</h2>
				<div class="txt">{{ mymessage }}</div>
				<router-link to="/protocol?type=3" class="links">查看详细 +</router-link>
			</div>
		</div>
		
		<!-- 弹窗-企业的账号找回 -->
		<div class="m-popup-m1" v-if="!hasLogin" v-show="companypwd">
			<div class="popup" style="width: 350px;height: auto;">
				<div class="tit">
					<span class="tits">账号找回</span>
					<span class="exit" @click="companypwd = false"></span>
				</div>
				<div class="company-content" v-html="companytext">
				</div>
				<div class="company-button" @click="companypwd=false">知道了</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import Header from '@/components/Header.vue';
import Api from '@/request/api';
import { mapGetters, mapState } from "vuex";

export default {
	name: 'Home',
	components: {
		// HelloWorld,
		Header
	},
	data() {
		return {
			form: {
				username: '',
				password: '',
				type: 1, // 1 账号密码登录 2 手机号登录
				captcha: '',
				checkKey: ''
			},
			hasLogin: this.$store.getters.hasLogin,
			isqrcode: false,
			count: '发送验证码',
			showNum: 0,
			second: 0,
			timer: null,
			changePassForm: {
				// mobile: this.$store.state.user.telephone,
				mobile: '',
				smscode: '',
				// email: this.$store.state.user.email,
				email: '',
				emailcode: '',
				password: '',
				repass: ''
			},
            changeType: null,
			showNotice: null,
			readonly: true,
			mytitle: '通知公告',
			mymessage: '',
			personType:1,//登录账号类型，1-个人，2-企业
			companypwd:false,
			companytext:""
		}
	},
		computed: {
			...mapGetters([
				'hasLogin'
			])
		},
	mounted() {
		//判断是否已经登录
		if (this.$store.getters.hasLogin) {
			this.$router.push("/");
		}
		this.personType = this.$route.query.type || 1;
		
		// 获取通知公告
		// this.getNotice()
	},
	methods: {
		// 获取通知公告
		getNotice() { 
			Api.getConfigId({ id: 18 }).then(res => {
				this.mymessage = res.config
				this.showNotice = res.datalevel
			})
		},
		async login() {
			if (!this.form.username) {
				return this.$message.info('请输入账号')
			}
			if (!this.form.password && this.form.type == 1) {
				return this.$message.info('请输入密码')
			}
			if (!this.form.captcha && this.form.type == 2) {
				return this.$message.info('请输入验证码')
			}
			//登录的账号身份类型
			this.form.loginType = this.personType;
			const res = await Api.login(this.form)
			this.$store.dispatch('login', res)
			Api.forumTree().then(res => {
			    // console.log(res)
				this.$store.dispatch('setForumTree', res)
			})
			this.$router.go(-1)
		},

		//切换登录方式
		switchLogin(type) {
			this.form.type = type;
			this.isqrcode = true;
		},
		open1() {
			this.showNum = 1
			this.changePassForm = {
				// mobile: this.$store.state.user.telephone,
				mobile: '',
				smscode: '',
				// email: this.$store.state.user.email,
				email: '',
				emailcode: '',
				password: '',
				repass: ''
			}
		},
		//验证码倒计时
		countDown() {
			if (!this.form.username) {
				return this.$message.info('请输入手机号')
			}
			if (!/^1[3456789]\d{9}$/.test(this.form.username)) {
				return this.$message.info('请输入正确的手机号')
			}
			Api.sendSms({ mobile: this.form.username, smsmode: 2 }).then(res => {
				console.log(res)
				let num = 10;
				this.timer = setInterval(() => {
					num--;
					this.count = num + '秒后重新发送';
					if (num <= 0) {
						clearInterval(this.timer);
						this.count = '发送验证码';
					}
				}, 1000)
				this.$message.success('短信发送成功');
			})
		},
		// 关闭时清除定时器
		closeShow() {
			if (this.timer) { clearInterval(this.timer); this.second = 0 }
			this.showNum = 0
		},
		sendSms(form, type) {
			if (!this[form][type]) {
				return this.$message.error('请先输入手机号')
			}
			if (!/^1[3456789]\d{9}$/.test(this[form][type])) {
				return this.$message.error('请输入正确的手机号')
			}
			Api.sendSms({ mobile: this[form][type], smsmode: 2,userType:this.personType }).then(res => {
				this.second = 10
				this.timer = setInterval(() => {
					this.second--
					if (this.second == 0) {
						clearInterval(this.timer)
					}
				}, 1000)
				this.$message.success('短信发送成功');
			})
		},
		// 提交修改密码
		submitPass() {
			if (!this.changePassForm.password || !this.changePassForm.repass) {
				return this.$message.info('请输入密码')
			}
			if (this.changePassForm.password != this.changePassForm.repass) {
				return this.$message.error('密码不一致')
			}
            let data = {
                randomNumbers: this.changePassForm.randomNumbers,
				password: this.changePassForm.password
			}
            if (this.changeType == 1) {
                data.username = this.changePassForm.mobile
            }
            if (this.changeType == 2) {
                data.username = this.changePassForm.email
            }
			data.userType = this.personType;
			Api.changePass(data).then(res => {
				console.log(res)
				clearInterval(this.timer)
				this.second = 0
				this.showNum = 0
				this.$message.success('修改成功')
				this.$store.dispatch('logout')
				this.$router.replace('/login')
			})
		},
		sendEmail(form, type) {
			if (!this[form][type]) {
				return this.$message.error('请输入邮箱')
			}
			if (!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/.test(this[form][type])) {
				return this.$message.error('请输入正确的邮箱')
			}
			Api.sendEmail({ email: this[form][type] }).then(res => {
				this.second = 10
				this.timer = setInterval(() => {
					this.second--
					if (this.second == 0) {
						clearInterval(this.timer)
					}
				}, 1000)
				this.$message.success('发送成功');
			})
		},
		validatePass(type) {
			let field = ''
			let code = ''
			if (type == 'mobile') {
				if (!this.changePassForm.smscode) { return this.$message.error('请输入验证码') }
				field = this.changePassForm.mobile
				code = this.changePassForm.smscode
			} else {
				if (!this.changePassForm.emailcode) { return this.$message.error('请输入验证码') }
				field = this.changePassForm.email
				code = this.changePassForm.emailcode
			}
			Api.validateCode({ username: field, code: code }).then(res => {
				console.log(res)
                this.changePassForm.randomNumbers = res
				clearInterval(this.timer)
				this.second = 0
				this.showNum = 4
			})
		},
		getcompanytext(){
			Api.getsiteConfig(1).then(res=>{
				this.companytext = res.content;
				this.companypwd = true;
			})
			// Api.getConfigId({ id: 20 }).then(res => {
			// 	this.companytext = res.memo2;
			// })
		}
	}
}
</script>

<style scoped lang="scss">
.main {
	overflow: hidden;
	position: relative;
	padding-top: 0;
	margin-top: 0;
}
.banner {
	width: 100%;
	height: 100vh;
}
.banner .item,
.banner .con,
.banner .pic {
	width: 100%;
	height: 100%;
}
.banner .con {
	display: block;
	position: relative;
}
.banner .pic {
}
.banner .pic img {
	width: 100%;
	height: 100%;
	display: block;
}
.banner .txt {
	width: 100%;
	position: absolute;
	left: 100px;
	bottom: 30px;
}
.banner .tit {
	font-size: 44px;
	font-family: PingFang SC;
	font-weight: 600;
	color: #ffffff;
	line-height: 50px;
	margin-bottom: 10px;
}
.banner .info {
	font-size: 24px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #ffffff;
	margin-bottom: 55px;
}
.banner .copyright {
	font-size: 12px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #ffffff;
	/* width: 57%; */
	white-space: nowrap;
}
.m-logo-m2 {
	/* width: 520px; */
	width: 27.08vw;
	height: calc(100vh - 80px);
	background-color: rgba(0, 0, 0, 0.6);
	position: absolute;
	right: 0;
	bottom: 0;
	box-sizing: border-box;
}
.m-logo-m2 .form {
	/* width: 400px; */
	width: 20.83vw;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.m-logo-m2 .tit {
	font-size: 22px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #ffffff;
	/* margin-bottom: 60px; */
	margin-bottom: 3.125vw;
}
.m-logo-m2 .item {
	border-bottom: 2px solid #e4e4e4;
	/* margin-bottom: 45px; */
	margin-bottom: 3.125vw;
}
.m-logo-m2 .label {
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #ffffff;
}
.m-logo-m2 .item input {
	width: 100%;
	height: 30px;
	line-height: 30px;
	border: none;
	outline: none;
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #ffffff;
	background-color: unset;
	padding: 0;
	margin: 10px 0;
}
.m-logo-m2 .item input:-internal-autofill-previewed,
.m-logo-m2 .item input:-internal-autofill-selected {
	-webkit-text-fill-color: #ffffff !important;
	transition: background-color 5000s ease-in-out 0s !important;
}
.m-logo-m2 .item-qrcode {
	position: relative;
}
.m-logo-m2 .item-qrcode-input {
	position: relative;
}
.m-logo-m2 .item-qrcode .btn-qrcode {
	width: 90px;
	height: 30px;
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #ffffff;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	cursor: pointer;
	transition: color 0.5s;
}
.m-logo-m2 .item-qrcode .btn-qrcode:hover {
	color: #e73522;
}
.m-logo-m2 .item2 {
	overflow: hidden;
	/* margin-bottom: 170px; */
	margin-bottom: 8.854vw;
}
.m-logo-m2 .forget,
.m-logo-m2 .qrcode {
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #ffffff;
	display: block;
	transition: 0.5s;
	cursor: pointer;
}
.m-logo-m2 .forget {
	float: left;
}
.m-logo-m2 .qrcode {
	float: right;
}
.m-logo-m2 .forget:hover,
.m-logo-m2 .qrcode:hover {
	color: #e73522;
}
.m-logo-m2 .btns {
}
.m-logo-m2 .btn1,
.m-logo-m2 .btn2 {
	width: 100%;
	height: 50px;
	background: #ffffff;
	border-radius: 3px;
	text-align: center;
	line-height: 50px;
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #989898;
	transition: 0.5s;
}
.m-logo-m2 .btn1 {
	border: none;
	outline: none;
	margin-bottom: 20px;
	cursor: pointer;
}
.m-logo-m2 .btn2 {
	display: block;
}
.m-logo-m2 .btn1:hover,
.m-logo-m2 .btn2:hover {
	color: #e73522;
}

.m-popup-m1 {
	width: 100%;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.6);
}
.m-popup-m1 .popup {
	width: 480px;
	height: 380px;
	background-color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 23px 25px 0;
	box-sizing: border-box;
}
.m-popup-m1 .tit {
	overflow: hidden;
	position: relative;
	margin-bottom: 17px;
}
.m-popup-m1 .tits {
	font-size: 16px;
	line-height: 30px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #333333;
	float: left;
}
.m-popup-m1 .exit {
	width: 20px;
	height: 20px;
	background-image: url(../assets/images/myinfo/icon-w1.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	cursor: pointer;
	transition: 0.5s;
}
.m-popup-m1 .exit:hover {
	transform: translateY(-50%) rotateZ(360deg);
}
.m-popup-m1 .desc {
	width: 100%;
	/* height: 50px; */
	background-color: #f5f5f7;
	padding: 15px 20px 15px 60px;
	background-image: url(../assets/images/myinfo/icon-w2.png);
	background-repeat: no-repeat;
	background-size: 20px 20px;
	background-position: 20px center;
	margin-bottom: 30px;
}
.m-popup-m1 .desc1 {
	position: relative;
	padding-left: 15px;
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 20px;
}
.m-popup-m1 .desc1::after {
	content: "";
	display: block;
	width: 4px;
	height: 4px;
	background-color: #333333;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}
.m-popup-m1 .content {
}
.m-popup-m1 .btns {
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 40px;
	display: flex;
	justify-content: center;
}
.m-update-form-m1 {
}
.m-update-form-m1 .item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px;
}
.m-update-form-m1 .label {
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 20px;
}
.m-update-form-m1 .el-input {
	width: 346px;
	height: 35px;
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
}
/* .m-update-form-m1 input{
		width: 346px;
		height: 35px;
		border: 1px solid #cccccc;
		box-sizing: border-box;
		outline: none;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		padding: 0 10px;
	}
	.m-update-form-m1 input::-webkit-input-placeholder {
		color: #999999;
	}
	.m-update-form-m1 input:-moz-placeholder {
		color: #999999;
	}
	.m-update-form-m1 input::-moz-placeholder {
		color: #999999;
	}
	.m-update-form-m1 input::-ms-input-placeholder {
		color: #999999;
	} */
.m-update-form-m1 .item-qrcode {
	position: relative;
}
.m-update-form-m1 .item-qrcode .btn-qrcode {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	cursor: pointer;
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #e83421;
	line-height: 20px;
}
.m-update-form-m1 .item-qrcode .btn-qrcode-dis {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	cursor: pointer;
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #999;
	line-height: 20px;
}
.g-btns-m1 {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.g-btns-m1 .btn {
	width: 201px;
	height: 51px;
	border: 1px solid #999;
	box-sizing: border-box;
	font-size: 14px;
	line-height: 49px;
	text-align: center;
	font-family: PingFang SC;
	font-weight: 400;
	color: #666;
	outline: none;
	cursor: pointer;
	background-color: #fff;
	transition: 0.5s;
}
.g-btns-m1 .btn:hover {
	color: #e83421;
	border-color: #e83421;
}
.g-btns-m2 {
}
.g-btns-m2 .btn {
	width: 71px;
	height: 31px;
	border: 1px solid #e83421;
	box-sizing: border-box;
	font-size: 14px;
	line-height: 29px;
	text-align: center;
	font-family: PingFang SC;
	font-weight: 400;
	color: #e83421;
	background-color: #fff;
	margin: 0 10px;
	cursor: pointer;
	transition: 0.5s;
}
.g-btns-m2 .btn.on {
	color: #fff;
	background-color: #e83421;
}
.g-btns-m2 .btn:hover {
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.m-notification-m1 {
	width: 500px;
	height: 270px;
	border-radius: 10px;
	background-color: rgba(255, 255, 255, 0.9);
	overflow: hidden;
	position: fixed;
	/* right: 230px;
		bottom: 175px; */
	right: 11.97vw;
	bottom: 16.2vh;
	z-index: 100;
}
.m-notification-m1 .con {
	padding: 22px 25px 25px;
	position: relative;
	height: 100%;
}
.m-notification-m1 .tit {
	font-size: 30px;
	font-family: "PingFangSC";
	color: rgb(22, 22, 22);
	line-height: 1.5;
	font-weight: 500;
	text-align: center;
	margin-bottom: 17px;
}
.m-notification-m1 .txt {
	font-size: 16px;
	font-family: "PingFangSC";
	color: rgb(77, 77, 77);
	line-height: 1.5;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 5;
	overflow: hidden;
}
.m-notification-m1 .links {
	display: block;
	font-size: 16px;
	font-family: "PingFangSC";
	color: rgb(213, 70, 49);
	line-height: 1.5;
	position: absolute;
	right: 25px;
	bottom: 25px;
}
.form-title{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.form-title div{
	margin-right: 60px;
	cursor: pointer;
}
.form-title .noselect{
	color: rgba(255,255,255,0.5);
}
.company-content{
	width: 300px;
	height: 140px;
	background: #F5F5F7;
	padding: 20px;
	box-sizing: border-box;
	font-size: 14px;
	font-weight: 400;
	color: #333333;
	line-height: 26px;
}
.company-button{
	width: 100%;
	height: 31px;
	border: 1px solid #E83421;
	font-size: 14px;
	font-weight: 400;
	color: #E83421;
	line-height: 31px;
	text-align: center;
	cursor: pointer;
	margin: 20px auto 39px;
}

@media only screen and (max-width: 750px) {
	.banner .pic img{
		object-fit: cover;
	}
	.banner .txt{
		width: calc(100% - .6rem);
		right: .3rem;
		left: .3rem;
		bottom: .3rem;
	}
	.banner .tit{
		font-size: .6rem;
		line-height: 1.2;
		margin-bottom: .1rem;
	}
	.banner .info{
		font-size: .48rem;
		margin-bottom: .3rem;
	}
	.banner .copyright{
		font-size: .24rem;
		line-height: 1.6;
		white-space: normal;
		text-align: center;
	}
	.m-logo-m2{
		width: 96%;
		left: 0;
		top: 1.8rem;
		bottom: auto;
		height: auto;
		margin: 0 auto;
	}
	.m-logo-m2 .form{
		position: static;
		width: 100%;
		padding: .3rem;
		transform: translate(0);
	}
	.m-logo-m2 .tit{
		font-size: .4rem;
		margin-bottom: .2rem;
	}
	.m-logo-m2 .item{
		margin-bottom: .3rem;
	}
	.m-logo-m2 .item input{
		height: .6rem;
		font-size: .28rem;
		line-height: .6rem;
		margin: .1rem 0;
	}
	.m-logo-m2 .item2{
		margin-bottom: .4rem;
	}
	.m-logo-m2 .forget,
	.m-logo-m2 .qrcode{
		font-size: .28rem;
	}
	.m-logo-m2 .label{
		font-size: .28rem;
	}
	.m-logo-m2 .btn1,
	.m-logo-m2 .btn2{
		height: .8rem;
		line-height: .8rem;
		font-size: .28rem;
	}
	.m-logo-m2 .btn1{
		margin-bottom: .2rem;
	}
	.form-title div{
		margin-right: .6rem;
	}

	.m-popup-m1{
        .popup{
            width: 92%;
            height: auto;
            padding: .3rem;
            .tit{
                margin-bottom: .2rem;
                .tits{
                    font-size: .32rem;
                    line-height: .6rem;
                }
                .exit{
                    width: .4rem;
                    height: .4rem;
                }
            }
            .desc{
                padding: .3rem;
                padding-left: .6rem;
                margin-bottom: .3rem;
                background-size: .4rem auto;
                .desc1{
                    font-size: .28rem;
                    line-height: .44rem;
                    &::after{
                        display: none;
                    }
                }
            }
            .content{
                margin: .3rem 0;
                .g-btns-m1{
                    .btn{
                        width: 48%;
                        height: .64rem;
                        font-size: .28rem;
                        line-height: .62rem;
                    }
                }
            }
            .m-update-form-m1{
                .item{
                    margin-bottom: .2rem;
                    .label{
                        min-width: 1.32rem;
                        font-size: .28rem;
                        line-height: .7rem;
                    }
                    .el-input{
                        flex: auto;
                        height: auto;
                        overflow: hidden;
                        ::v-deep input{
                            height: .7rem;
                            font-size: .28rem;
                            line-height: .7rem;
                            padding: 0 .2rem;
                            border-radius: .04rem;
                        }
                    }
                    .btn-qrcode{
                        right: .2rem;
                        font-size: .28rem;
                        line-height: .7rem;
                    }
                }
            }
            .btns{
                position: static;
                .btn{
                    width: 1.6rem;
                    height: .6rem;
                    font-size: .28rem;
                    line-height: .58rem;
                    margin: 0 .1rem;
                }
            }
        }
    }

}
</style>
