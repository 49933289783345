<template>
    <div class="partner">
        <Header />
        <div v-if="banner.length > 0" class="ban">
			<el-carousel>
			  <el-carousel-item v-for="item in banner" :key="item.id">
				<img @click="tourl(item)" :src="item.image" />
			  </el-carousel-item>
			</el-carousel>
        </div>
        <div class="parBg1">
            <div class="wp w1200">
                <div class="sp-title">查询合作伙伴</div>
				<div class="sp-desc">您可输入合作伙伴公司全称，以查询并验证合作伙伴身份</div>
				
				<div class="sp-input">
					<el-input v-model="text" prefix-icon="el-icon-search" placeholder="输入合作伙伴公司全称，需与工商注册名称一致"></el-input>
				</div>
				
				<div class="sp-button">
					<div class="sp-button-reset" @click="reset">重置</div>
					<div class="sp-button-search" @click="search">搜索</div>
				</div>
				
				<div class="sp-hr" v-if="step != 1"></div>
				
				<div class="sp-result" v-if="step == 2">
					<div class="sp-result-title">公司名称:{{company}}</div>
					<div class="sp-result-item" v-for="item in list" :key="item.id">
						<div>授权业务：{{item.planName}}</div>

						<div>授权身份：{{item.identityName}}</div>

						<div>授权区域：{{item.province}}</div>

						<div>授权期限：{{item.authStartDate}} 至 {{item.authEndDate}}</div>
					</div>
				</div>
				
				<div class="sp-result" v-if="step == 3">
					<img src="@/assets/images/nodata.png"/>
					<div class="sp-result-no">抱歉，未找到匹配结果，请检查您输入的公司名称，重新搜索</div>
				</div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Api from '@/request/api.js';
export default {
    name: 'Partner',
    components: { 
        Header,
        Footer 
    },
    data() {
        return {
			banner:[],
			list:[],
			step:1,
			text:""
        }
    },
	created() {
		//获取轮播图
		Api.banner({position:3}).then(res => {
			this.banner = res;
		})
	},
	methods:{
		tourl(item){
			Api.updateClickNum({id:item.id});
			if(item.link){
				window.open(item.link);
			}
		},
		reset(){
			this.text = "";
			this.step = 1;
		},
		search(){
			if(this.text == ""){
				return this.$message.error("请输入公司名称");
			}
			Api.getCompanyAuthList({companyName:this.text}).then(res=>{
				this.list = res;
				this.step = res.length == 0 ?3:2;
				this.company = this.text;
			})
		}
	}
}
</script>
<style scoped lang="scss">
.partner{
	padding: 80px 0 0;
	overflow: hidden;
}
.parTit{
	position: relative;
	font-size: 48px;
	color: #111111;
	line-height: 1.2;
	text-align: center;
}
.parGuide{
	position: absolute;
	right: 0;
	top: 0;
	font-size: 16px;
	color: #2E2E2E;
	line-height: 30px;
	padding: 10px 22px;
	border-radius: 3px;
	background-color: #E3E4E5;
	font-weight: normal;
	cursor: pointer;
	transition: all .3s;
	&:hover{
		color: #FFFFFF;
		background-color: #E73522;
	}
}
.parBg1{
	padding: 60px 0;
	background-color: #FBFBFB;
}
.wp{
	padding-top: 61px;
	background-color: #fff;
	padding-bottom: 229px;
}
.sp-title{
	font-size: 48px;
	font-weight: 600;
	color: #111111;
	text-align: center;
}
.sp-desc{
	margin-top: 24px;
	font-size: 24px;
	font-weight: 600;
	color: #111111;
	text-align: center;
}
.sp-input{
	width: 840px;
	margin: 42px auto;
}
::v-deep .el-input__inner{
	width: 100%;
	height: 60px;
	background: #F5F5F8;
	border-radius: 3px;
	border:0px;
	text-align: center;
}
.sp-button{
	display: flex;
	justify-content: center;
	align-items: center;
}
.sp-button-reset{
	width: 280px;
	height: 50px;
	background: #E3E4E5;
	border-radius: 3px;
	font-size: 16px;
	font-weight: 400;
	color: #2E2E2E;
	text-align: center;
	line-height: 50px;
	margin: 0px 10px;
	cursor: pointer;
}
.sp-button-search{
	width: 280px;
	height: 50px;
	background: #E73522;
	border-radius: 3px;
	font-size: 16px;
	font-weight: 400;
	color: #fff;
	text-align: center;
	line-height: 50px;
	margin: 0px 10px;
	cursor: pointer;
}
.sp-hr{
	width: 1100px;
	height: 2px;
	background: #DDDDDD;
	margin:50px auto 0px;
}
.sp-result{
	width: 1100px;
	margin: 44px auto 0px;
}
.sp-result-title{
	font-size: 24px;
	font-weight: 600;
	color: #111111;
}
.sp-result-item{
	width: 100%;
	background: #F5F5F8;
	border-radius: 3px;
	padding: 30px;
	box-sizing: border-box;
	font-size: 16px;
	font-weight: 400;
	color: #111111;
	line-height: 20px;
}
.sp-result img{
	width: 432px;
	height: 268px;
	margin: 0 auto;
	display: block;
}
.sp-result-no{
	margin-top: 15px;
	font-size: 16px;
	font-weight: 400;
	color: #707070;
	line-height: 24px;
	text-align: center;
}
::v-deep .el-carousel__container{
	height: 350px;
}
::v-deep .el-carousel__container img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
@media only screen and (max-width: 750px) {
	.ban{
		margin-top: 1.4rem;
	}
	.partner{
		padding: 0;
	}
	.parBg1{
		padding: .6rem 0;
		.wp{
			padding: .6rem 0;
			margin: 0 .3rem;
		}
	}
	.sp-title{
		font-size: .48rem;
	}
	.sp-desc{
		margin-top: .3rem;
		font-size: .32rem;
	}
	.sp-input{
		width: 100%;
		margin: .5rem 0;
	}
	.sp-button-reset,
	.sp-button-search{
		width: 48%;
		margin: 1%;
		height: .76rem;
		font-size: .32rem;
		line-height: .76rem;
	}
	.sp-hr{
		width: 100%;
		margin: .5rem auto 0;
	}
	.sp-result{
		width: 100%;
		margin: .5rem 0;
	}
	.sp-result img{
		width: 80%;
		height: auto;
	}
	.sp-result-no{
		font-size: .32rem;
		line-height: 2;
	}
	::v-deep .el-input__inner{
		height: .9rem;
		font-size: .28rem;
		line-height: .9rem;
	}

	::v-deep .el-carousel__container{
		height: 2rem;
	}
}
</style>