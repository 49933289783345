/* eslint-disable */
import http from './http.js'; // 导入http中创建的axios实例
import axios from 'axios';
const Api = {
    // 文件上传
    upload(data) {
        const formData = new FormData();
        formData.append("file", data);
        return axios.post(process.env.VUE_APP_BASE_URL + '/teamwork/public/st/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Access-Token': localStorage.getItem('token') || ''
            }
        })
    },
	uploadnologin(data) {
	    const formData = new FormData();
	    formData.append("file", data);
	    return axios.post(process.env.VUE_APP_BASE_URL + '/teamwork/public/st/uploadFile', formData, {
	        headers: {
	            'Content-Type': 'multipart/form-data'
	        }
	    })
	},
    // 文件上传
    uploadtwo(data) {
        const formData = new FormData();
        formData.append("file", data);
        return axios.post(process.env.VUE_APP_BASE_URL + '/teamwork/public/st/privateUpload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Access-Token': localStorage.getItem('token') || ''
            }
        })
    },
    // 发送email验证码
    sendEmail(data) {
        return http.post('/teamwork/public/st/sendEmail', data)
    },
    sendSms(data) {
        return http.post('/teamwork/public/st/sms', data)
    },
    // 获取验证码
    getCaptcha(data) {
        return http.post('/teamwork/public/st/randomImage', data)
    },
    // 获取身份列表
    idenList() {
        return http.get('/teamwork/iden/st/listAll')
    },
    // 注册
    register(data) {
        return http.post('/teamwork/user/st/add', data)
    },
    // 登录
    login(data) {
        return http.post('/teamwork/user/st/login', data)
    },
    // 获取当前用户信息
    getUserInfo() {
        return http.post('/teamwork/user/st/queryCurrentUser')
    },
    // 修改头像
    updateAvatar() {
        return http.post('/teamwork/user/st/updateAvatar')
    },
    // 忘记密码
    changePass(data) {
        return http.post('/teamwork/user/st/changePassword', data)
    },
    // 修改账户信息
    updateUser(data) {
        return http.post('/teamwork/user/st/edit', data)
    },
    updateEmail(data) {
        return http.post('/teamwork/user/st/updateEmail', data)
    },
    // 修改手机号或邮箱
    updateMobileOrEmail(data) {
        return http.post('/teamwork/user/st/checkCode', data)
    },
    // 验证验证码
    validateCode(data) {
        return http.post('/teamwork/public/st/verifyCode', data)
    },
    // 验证邮箱是否可用
    verifyUnique(data) {
        return http.post('/teamwork/public/st/verifyUnique', data)
    },
    // 配置接口
    getConfigKey(data) {
        return http.post('/teamwork/config/st/queryByKey', data)
    },
    getConfigId(data) {
        return http.post('/teamwork/config/st/queryById', data)
    },
    // 产品分类树
    productTree() {
        return http.get('/teamwork/productClass/st/getList')
    },
    // 产品分类树
    productTreelogin() {
        return http.get('/teamwork/productClass/st/list')
    },
		
    // 资讯分类树
    inforTree() {
        return http.post('/teamwork/inforClass/st/list')
    },
    // 论坛分类树
    forumTree() {
        return http.get('/teamwork/st/forum/getClassList')
    },
    
    // 获取列表数据
    getHotPostsList(data) {
        return http.get('/teamwork/st/forum/getHotPostsList?classId='+data)
    },
    // 获取列表数据
    getList(data) {
        return http.post('/teamwork/inforPosts/st/list', data)
    },
    // 资讯点赞下载阅读收藏
    inforPosts(data) {
        return http.post('/teamwork/inforPosts/st/click', data)
    },
    // 取消收藏点赞
    cancelInforPosts(data) {
        return http.post('/teamwork/inforPosts/st/clickCancel', data) 
    },
    // 根据树id 查询资料类型
    typeList(data) {
        return http.post('/teamwork/inforPosts/st/queryTypeList', data)
    },

    // 论坛模块
    // 根据id查询板块信息
    forumInfo(data) {
        return http.get(`/teamwork/st/forum/getClassDetail?classId=${data}`)
    },
    // 发布帖子
    postsAdd(data) {
        return http.post('/teamwork/st/forum/savePosts', data)
    },
    // 帖子列表
    forumList(data) {
        return http.get('/teamwork/st/forum/getPostsList',{params:data})
    },
    // 我发布的帖子
    getMyPostsList(data) {
        return http.get('/teamwork/st/forum/getMyPostsList')
    },
    // 帖子分类
    forumCates(data) {
        return http.get(`/teamwork/st/forum/getClassTypeList?classId=${data}`)
    },
    // 订阅
    subscriptionAdd(data) {
        return http.post('/teamwork/st/forum/subscribe/collect', data)
    },
    // 取消订阅
    subscriptionDel(data) {
        return http.post('/teamwork/st/forum/subscribe/collect', data)
    },
    // 帖子详情
    forumDetail(data) {
        return http.get('/teamwork/st/forum/getForumPostsInfo', {params:data})
    },
    // 帖子评论
    forumRevert(data) {
        return http.post('/teamwork/st/forum/savePostsReply', data)
    },
    // 加载更多评论
    moreComment(data) {
        return http.get('/teamwork/st/forum/getPostsReplyList', {params:data})
    },
    // 加载更多回复
    moreRevert(data) {
        return http.post('/teamwork/revertRecord/st/listByRevertId', data)
    },
    // 帖子点赞收藏
    forumLike(data) {
        return http.post('/teamwork/st/forum/forumPostsCollect', data)
    },
    // // 帖子取消点赞
    // forumLikeCancel(data) {
    //     return http.post('/teamwork/forumPosts/st/likeCancel', data)
    // },
    // 删除帖子
    deletePosts(data) {
        return http.post('/teamwork/st/forum/deletePosts', data)
    },
    // 删除评论或者回复
    revertDelete(data) {
        return http.post('/teamwork/st/forum/deletePostsReply', data)
    },
    // 帖子置顶
    updatePostsTopStatus(data) {
        return http.post('/teamwork/st/forum/updatePostsTopStatus', data)
    },
    // 帖子置顶
    updateReplyTopStatus(data) {
        return http.post('/teamwork/st/forum/updateReplyTopStatus', data)
    },
    updatePostsReadOnlyStatus(data) {
        return http.post('/teamwork/st/forum/updatePostsReadOnlyStatus', data)
    },
    // 帖子设置仅管理员可见
    updateReplyAdminVisible(data) {
        return http.post('/teamwork/st/forum/updateReplyAdminVisible', data)
    },

    // 我的浏览记录
    myList(data) {
        return http.post('/teamwork/user/st/collectList', data)
    },
    // 我的订阅记录
    mySubscription(data) {
        return http.get('/teamwork/st/forum/getMyClassList', {params:data})
    },
    // 我的阅读记录
    myReadList(data) {
        return http.post('/teamwork/user/st/readList', data)
    },
    // 我的评论
    myCommentList(data) {
        return http.get('/teamwork/st/forum/getMyPostsReplyList', {params:data})
    },
    // 我的点赞
    myLike(data) {
        return http.post('/teamwork/st/forum/getHistoryPostsList', data)
    },
    // 获取验证码
    randomImage() {
        return http.get('/teamwork/st/forum/randomImage')
    },
    // 下载图片
    getFileTempUrl(id) {
        return http.get('/teamwork/st/forum/getFileTempUrl?fileId='+id)
    },
    // 获取消息列表
    getMessageList(page) {
        return http.get('/teamwork/st/forum/getMessageList?pageNo='+page)
    },
    // 标记为已读
    updateMessageStatus(data) {
        return http.post('/teamwork/st/forum/updateMessageStatus',data)
    },
    updatenoticeStatus(data) {
        return http.post('/teamwork/st/notice/updateReadStatus',data)
    },
    // 标记为已读
    getUserNum() {
        return http.get('/teamwork/st/forum/getUserNum')
    },
	//验证图形验证码是否正确
	checkImageCode(data){
		return http.post("/teamwork/user/st/checkImageCode",data)
	},
	//验证公司信息是否存在
	getCompanyInfo(data){
		return http.post("/teamwork/user/st/getCompanyInfo",data)
	},
	//公司账号注册
	addCompanyUser(data){
		return http.post("/teamwork/user/st/addCompanyUser",data)
		
	},
	//获取新闻列表
	newsList(data){
		return http.get("/teamwork/st/news/getList",{params:data})
	},
	//获取新闻详情
	newsdetail(data){
		return http.get("/teamwork/st/news/getDetail",{params:data})
	},
	//获取banner轮播图
	banner(data){
		return http.get("/teamwork/st/banner/getList",{params:data})
	},
	//创建聊天内容
	createmessage(data){
		return http.post("/teamwork/st/message/createMessage",data)
		
	},
	//获取聊天人员的列表
	getLiuyanList(){
		return http.get("/teamwork/st/message/getMessageList")
	},
	//获取聊天记录
	getinfoList(data){
		return http.get("/teamwork/st/message/getItemList",{params:data})
	},
	//获取聊天人详情
	getMessageDetail(data){
		return http.get("/teamwork/st/message/getMessageDetail",{params:data})
	},
	//获取聊天人详情
	getMessageDetail(data){
		return http.get("/teamwork/st/message/getMessageDetail",{params:data})
	},
	//发送消息
	sendMessage(data){
		return http.post("/teamwork/st/message/sendMessage",data)
	},
	//清除消息
	allRead(){
		return http.post("/teamwork/st/message/allRead")
	},
	//获取订阅功能
	ziliaodoSubscribe(data){
		console.log(data);
		return http.post("/teamwork/st/userSubscribeProductClass/doSubscribe",data)
	},
	//获取订阅状态
	ziliaoSubscribe(data){
		return http.get("/teamwork/st/userSubscribeProductClass/getSubscribeStatus",{params:data})
	},
	//获取合作计划
	getPlanList(){
		return http.get("/teamwork/st/plan/getPlanList");
	},
	//获取合作的详情
	getItemList(data){
		return http.get("/teamwork/st/plan/getItemList",{params:data});
	},
	//获取合作的详情
	getItemList(data){
		return http.get("/teamwork/st/plan/getItemList",{params:data});
	},
	//获取公司授权书
	getUserCertificate(){
		return http.get("/teamwork/st/userCertificate/getUserCertificate");
		
	},
	//提交公司授权书
	saveUserCertificate(data){
		return http.post("/teamwork/st/userCertificate/saveUserCertificate",data);
		
	},
	//查看点击量
	updateClickNum(data){
		return http.post("/teamwork/st/banner/updateClickNum",data);
		
	},
	//查看协议
	getCompanyAuthList(data){
		return http.get("/teamwork/st/companyAuth/getCompanyAuthList",{params:data});
	},
	//获取签约详情
	getDetailByPlanId(data){
		return http.get("/teamwork/st/companyAuth/getDetailByPlanId",{params:data});
	},
	//获取合作计划详情
	getPlanDetail(data){
		return http.get("/teamwork/st/plan/getPlanDetail",{params:data});
	},
	//获取合作计划详情
	getOptionList(data){
		return http.get("/teamwork/st/planOption/getOptionList",{params:data});
	},
	getUserList(data){
		return http.get("/teamwork/st/companyAuth/getUserList",{params:data});
	},
	checkCompany(){
		return http.get("/teamwork/st/companyAuth/checkCompany");
	},
	saveCompanyAuth(data){
		return http.post("/teamwork/st/companyAuth/saveCompanyAuth",data);
	},
	companyAuthInfo(data){
		return http.get("/teamwork/st/companyAuth/getDetail",{params:data});
	},
	getCompanyAuthRateList(data){
		return http.get("/teamwork/st/companyAuth/getCompanyAuthRateList",{params:data});
	},
	getPlanSelectList(){
		return http.get("/teamwork/st/plan/getPlanSelectList");
	},
	doApply(data){
		return http.post("/teamwork/st/userApplyProductClass/doApply",data);
	},
	companyAuthsendSms(data){
		return http.post("/teamwork/st/companyAuth/sendSms",data);
	},
	confirmSign(data){
		return http.post("/teamwork/st/companyAuth/confirmSign",data);
	},
	cancelAuth(data){
		return http.post("/teamwork/st/companyAuth/cancelAuth",data);
	},
	getNotReadNum(){
		return http.get("/teamwork/st/message/getNotReadNum");
	},
	getnoticeNotReadNum(){
		return http.get("/teamwork/st/notice/getNotReadNum");
	},
	getnoticeDetail(id){
		return http.get("/teamwork/st/notice/getDetail?id="+id);
	},
	
	// 获取消息列表
	getnoticeList(page) {
	    return http.get('/teamwork/st/notice/getList?pageSize=10&pageNo='+page)
	},
	
	
	//获取公司基础消息
	getuserCompanyInfo(){
		return http.get("/teamwork/st/userCompany/getCompanyInfo");
	},
	updateLinkPhone(data){
		return http.post("/teamwork/st/userCompany/updateLinkPhone",data);
	},
	sendEmailCode(){
		return http.post("/teamwork/st/userCompany/sendEmailCode");
	},
	updateLinkEmail(data){
		return http.post("/teamwork/st/userCompany/updateLinkEmail",data);
	},
	checkcompanyCode(data){
		return http.post("/teamwork/st/userCompany/checkCode",data);
	},
	updateRegisteredAddress(data){
		return http.post("/teamwork/st/userCompany/updateRegisteredAddress",data);
	},
	updateBaseInfo(data){
		return http.post("/teamwork/st/userCompany/updateBaseInfo",data);
	},
	userNoticeget(){
		return http.get("/teamwork/st/userNoticeSet/getSet");
	},
	userNoticeSet(data){
		return http.post("/teamwork/st/userNoticeSet/doSet",data);
	},
	getCompanyAuthIdentityList(data){
		return http.get("/teamwork/st/companyAuth/getCompanyAuthIdentityList",{params:data});
	},
	renewal(data){
		return http.post("/teamwork/st/companyAuth/renewal",data);
	},
	// export function downFile(url,parameter){
	//   return axios({
	//     url: url,
	//     params: parameter,
	//     method:'get' ,
	//     responseType: 'blob'
	//   })
	// }
	downloadAuthFile(){
		return http.get("/teamwork/userCertificate/downloadAuthFile",{ responseType: "blob" });
	},
	getModerator(){
		return http.get("/teamwork/forumPosts/getModerator");
	},
	getsiteConfig(id){
		return http.get("/teamwork/st/siteConfig/getDetail?id="+id);
	},
	searchCompanyList(keywords){
		return http.get("/teamwork/user/st/searchCompanyList?keywords="+keywords);
	},
	 
	
	
	
	
	
	
	
	
	
	
	
	
}

export default Api;
