<template>
	<div class="contentnull">
		<div class="m-results-m1">
			<div class="item">
				<div class="pic">
					<img src="../assets/images/home/results_null.png" >
				</div>
				<div class="txt">
					<p>未检索到相关资料内容</p>
					<p>请试试其他关键词</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ContentNull',
		props: {},
		data() {
			return {
				str: ''
			}
		},
	}
</script>

<style lang="scss">
	.m-results-m1{
		padding: 100px 0 125px;
		background-color: #f5f5f7;
	}
	.m-results-m1 .item{
		width: 432px;
		margin: 0 auto;
	}
	.m-results-m1 .pic{
		width: 100%;
		height: 268px;
		overflow: hidden;
		margin-bottom: 15px;
	}
	.m-results-m1 .pic img{
		width: 100%;
		height: 100%;
		display: block;
	}
	.m-results-m1 .txt{
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #707070;
		line-height: 24px;
		text-align: center;
	}
@media only screen and (max-width: 750px) {	
	.m-results-m1{
		padding: 1rem 0;
		.item{
			width: 100%;
			.pic{
				height: auto;
			}
		}
		.txt{
			font-size: .32rem;
			line-height: 1.6;
		}
	}
}
</style>
