<template>
	<!-- <div class="m-subNav">
			<router-link :to="item.to" class="t" v-for="item in sublist"  :key="item.id">{{item.tit}}</router-link>
	</div>-->
	<div class="m-admindesc">
		<router-link to class="pic">
			<img :src="sublist.plateImg" alt />
		</router-link>
		<div class="txts">
			<router-link to class="info">
				<div class="tit">{{ sublist.name }}</div>
				<div class="nums">
					<div class="num">订阅: {{ sublist.postsNum }}</div>
					<div class="num">回复: {{ sublist.replyNum }}</div>
					<div class="num">帖子: {{ sublist.posNum }}</div>
				</div>
				<div class="desc">{{ sublist.memo }}</div>
			</router-link>
			<div class="btns" :class="sublist.subscribeStatus == 0 ? 'on' : ''">
				<div v-if="sublist.subscribeStatus == 0" class="g-dingyuebtn bt1" @click="toList(sublist)">点击查看</div>
				<div v-if="sublist.subscribeStatus == 0" @click="subscription" class="bt2">取消订阅</div>
			</div>
		</div>
	</div>
</template>

<script>
import Api from '@/request/api.js'
export default {
	props: {
		sublist: Object
	},
	data() {
		return {
			dingyue: false
		};
	},
	methods: {
		// 订阅
		subscription() {
			if (this.sublist.subscription) {
				Api.subscriptionDel({ classId: this.sublist.id }).then(res => {
					console.log(res)
					this.sublist.subscription = !this.sublist.subscription
				})
			} else {
				Api.subscriptionAdd({ classId: this.sublist.id }).then(res => {
					console.log(res)
					this.sublist.subscription = !this.sublist.subscription
				})
			}
			this.$router.go(0);
		},
		// 查看板块
		toList(item) {
			this.$store.dispatch('setNowForum', item)
			this.$router.push({ path: '/forumlist', query: { pid: item.id } })
		}
	}
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
a:hover {
	color: #e73522;
}
.m-admindesc {
	// border-top: 2px solid  #E83421;
	padding: 28px 60px 30px 17px;
	background-color: #fff;
	overflow: hidden;
	color: #333;
}
.m-admindesc .pic {
	float: left;
	width: 90px;
	height: 90px;
	overflow: hidden;
	margin-right: 43px;
}
.m-admindesc .txts {
	display: flex;
	overflow: hidden;
	justify-content: space-between;
	align-items: center;
}
.m-admindesc .txts .info {
	width: 752px;
	flex: 0 0 752px;
	position: relative;
}
.m-admindesc .txts .info::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	right: -58px;
	border-right: 1px solid #e4e4e4;
}
.m-admindesc .txts .tit {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 15px;
}
.m-admindesc .txts .nums {
	font-size: 14px;
	overflow: hidden;
	margin-bottom: 12px;
	color: #333;
}
.m-admindesc .txts .num {
	float: left;
	margin-right: 50px;
}
.m-admindesc .txts .num:last-of-type {
	margin-right: 0;
}
.m-admindesc .txts .desc {
	font-size: 14px;
	color: #999;
}
.m-admindesc .txts .g-dingyuebtn {
	width: 120px;
	height: 36px;
	font-size: 14px;
	line-height: 36px;
	border-color: #ddd;
	display: block;
	text-align: center;
}
.m-admindesc .txts .g-dingyuebtn:hover {
	color: #fff;
	border-color: #E83421;
}
.m-admindesc .txts .on .bt2 {
	margin-top: 14px;
	opacity: 1;
	height: 36px;
}
.m-admindesc .txts .on .bt1::before {
	display: none;
}
.m-admindesc .txts .bt2 {
	margin-top: 0;
	opacity: 0;
	height: 0;
	text-align: center;
	line-height: 36px;
	transition: 0.5s;
	color: #666;
	cursor: pointer;
}

@media only screen and (max-width: 750px) {	
	.m-admindesc{
		padding: .3rem;
		.pic{
			width: 1.4rem;
			height: 1.4rem;
			margin-right: .2rem;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.txts{
			display: block;
			.info{
				width: 100%;
			}
			.tit{
				font-size: .32rem;
				margin-bottom: .1rem;
			}
			.nums{
				font-size: .24rem;
				margin-bottom: .1rem;
			}
			.num{
				margin-right: .5rem;
			}
			.desc{
				font-size: .28rem;
				margin-bottom: .1rem;
			}
			.btns{
				display: flex;
			}
			.g-dingyuebtn{
				width: 1.4rem;
				height: .5rem;
				line-height: .5rem;
				font-size: .24rem;
			}
			.on{
				.bt2{
					width: 1.6rem;
					height: .5rem;
					line-height: .5rem;
					margin-top: 0;
					margin-left: .1rem;
				}
			}
		}
	}
	
}
</style>
