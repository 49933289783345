<template>
    <div class="partner">
        <Header />
        <div class="ban">
            <img src="../assets/images/partner/ban-sign.png" alt />
        </div>
        <div class="parSortBg">
            <div class="wp w1200">
                <ul class="parSort">
                    <li class="active"><span>填写认证信息</span></li>
                    <li><span>信息核对</span></li>
                    <li><span>签署合作协议</span></li>
                </ul>
            </div>
        </div>
        <div class="parSignBg">
            <div class="wp w1200">
                <div class="parSign">
                    <el-form ref="parSignForm" :model="parSignForm" :rules="rules" class="parSignForm">
                        <div class="parSignItem">
                            <div class="parSignCon">
                                <label class="parSignLabel">代理主要品牌I<span>*</span></label>
                                <el-form-item prop="pinpai">
                                    <el-select v-model="parSignForm.pinpai" placeholder="选择">
                                        <el-option label="区域一" value="shanghai"></el-option>
                                        <el-option label="区域二" value="beijing"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="parSignCon">
                                <label class="parSignLabel">大类<span>*</span></label>
                                <el-form-item prop="dalei">
                                    <el-select v-model="parSignForm.dalei" placeholder="选择">
                                        <el-option label="区域一" value="shanghai"></el-option>
                                        <el-option label="区域二" value="beijing"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="parSignCon">
                                <label class="parSignLabel">年销售额<span>*</span></label>
                                <el-form-item prop="xiaoshoue">
                                    <el-select v-model="parSignForm.xiaoshoue" placeholder="选择">
                                        <el-option label="区域一" value="shanghai"></el-option>
                                        <el-option label="区域二" value="beijing"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="parSignItem">
                            <div class="parSignCon">
                                <label class="parSignLabel">代理主要品牌II</label>
                                <el-form-item prop="pinpai2">
                                    <el-select v-model="parSignForm.pinpai2" placeholder="选择">
                                        <el-option label="区域一" value="shanghai"></el-option>
                                        <el-option label="区域二" value="beijing"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="parSignItem">
                            <div class="parSignCon">
                                <label class="parSignLabel">代理主要品牌III</label>
                                <el-form-item prop="pinpai3">
                                    <el-select v-model="parSignForm.pinpai3" placeholder="选择">
                                        <el-option label="区域一" value="shanghai"></el-option>
                                        <el-option label="区域二" value="beijing"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="parSignItem">
                            <div class="parSignCon">
                                <label class="parSignLabel">推荐人<span>*</span></label>
                                <el-form-item prop="tuijianren">
                                    <el-input placeholder="请输入推荐人" v-model="parSignForm.tuijianren"></el-input>
                                </el-form-item>
                            </div>
                            <div class="parSignCon">
                                <label class="parSignLabel">公司<span>*</span></label>
                                <el-form-item prop="gongsi">
                                    <el-input placeholder="请输入公司名称" v-model="parSignForm.gongsi"></el-input>
                                </el-form-item>
                            </div>
                            <div class="parSignCon">
                                <label class="parSignLabel">手机号<span>*</span></label>
                                <el-form-item prop="shoujihao">
                                    <el-input placeholder="请输入手机号" v-model="parSignForm.shoujihao"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="parSignItem">
                            <div class="parSignCon">
                                <label class="parSignLabel">授权区域<span>*</span></label>
                                <el-form-item prop="shouquanquyu">
                                    <el-select v-model="parSignForm.shouquanquyu" placeholder="选择省份，多选">
                                        <el-option label="区域一" value="shanghai"></el-option>
                                        <el-option label="区域二" value="beijing"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="parSignCon">
                                <label class="parSignLabel">对接商汤渠道经理<span>*</span><i>若无对接人，可选“无”</i></label>
                                <el-form-item prop="qudaojingli">
                                    <el-input placeholder="请输入渠道经理准确姓名" v-model="parSignForm.qudaojingli"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="parSignItem">
                            <div class="parSignfujian">
                                <div class="empowerImg">
                                    <el-upload
                                        class="upload"
                                        action=""
                                        list-type="picture"
                                        :show-file-list="false">
                                        <img v-if="parSignForm.fujian" :src="parSignForm.fujian" class="book">
                                        <i v-else class="empowerIcon empowerUpload"></i>
                                    </el-upload>
                                </div>
                                <div class="empowerTips">
                                    <p>上传授权书</p>
                                    <p>支持jpg png pdf格式</p>
                                </div>
                            </div>
                        </div>
                    </el-form>
                    <div class="parSignTips">
                        <p>请联系商汤渠道经理以取得总代分配，即可建档确认</p>
                    </div>
                    <div class="parSignBtn" @click="next">下一步</div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
    name: 'PartnerEmpower',
    components: { 
        Header,
        Footer
    },
    data() {
        return {
            parSignForm:{
                pinpai:'',                
                dalei:'',
                xiaoshoue:'',
                pinpai2:'',
                pinpai3:'',
                tuijianren:'刘彼得',
                gongsi:'',
                shoujihao:'',
                shouquanquyu:'',
                qudaojingli:'',
                fujian:''
            },
            rules:{
                
            }
        }
    },
    methods: {
        next(){
            this.$router.push({ path: '/partnersign2'})
        }
    }
}
</script>
<style scoped lang="scss">
.partner{
    padding: 80px 0 0;
    overflow: hidden;
}
.parSortBg{
    line-height: 80px;
    background-color: #FFFFFF;
    text-align: center;
    .parSort{
        display: flex;
        li{
            flex: 1;
            font-size: 20px;
            color: #111111;
            opacity: .5;
            &.active{
                color: #FFFFFF;
                background: #E73522;
                opacity: 1;
            }
        }
    }
}

.parSignBg{
    padding: 30px 0 74px;
    background-color: #F5F5F7;
}
.parSign{
    padding: 30px 50px 60px;
    background: #FFFFFF;



    .parSignTips{
        font-size: 16px;
        color: #E73522;
        line-height: 1.6;
        margin: 20px auto;
        text-align: center;
    }
    .parSignBtn{
        width: 280px;
        line-height: 50px;
        font-size: 16px;
        color: #FFFFFF;
        background: #E73522;
        border-radius: 3px;
        margin: 10px auto;
        text-align: center;
        cursor: pointer;
    }
}
.parSignForm{
    margin-bottom: 40px;
}
.parSignItem{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    .parSignCon{
        width: 33.33%;
        padding: 5px 20px;
        .parSignLabel{
            display: block;
            font-size: 14px;
            color: #2E2E2E;
            line-height: 2;
            padding: 5px 0;
            overflow: hidden;
            span{
                color: #E83421;
                margin-left: 10px;
            }
            i{
                float: right;
                color: #999999;
                font-style: normal;
            }
        }
        .el-select{
            display: block;
            ::v-deep .el-input__inner{
                height: 50px;
                line-height: 50px;
                font-size: 16px;
                color: #2E2E2E;
                border: none;
                border-radius: 3px;
                background: #F5F5F8;
            }
        }
        .el-input{
            display: block;
            ::v-deep .el-input__inner{
                height: 50px;
                line-height: 50px;
                font-size: 16px;
                color: #2E2E2E;
                padding: 0;
                border: none;
                border-radius: 0;
                border-bottom: 2px solid #DDDDDD;
            }
        }
    }
    .parSignfujian{
        display: flex;
        align-items: flex-end;
        padding: 5px 20px;
        .empowerImg{
            position: relative;
            flex-shrink: 0;
            width: 84px;
            height: 84px;
            background: #F5F5F8;
            border-radius: 3px;
            margin-right: 16px;
            cursor: pointer;
            img{
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
            }
            .empowerIcon{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                max-width: 100%;
                max-height: 100%;
            }
            .upload{
                width: 100%;
                height: 100%;
                ::v-deep .el-upload{
                    display: block;
                    height: 100%;
                }
            }
        }
        .empowerTips{
            flex: auto;
            font-size: 14px;
            color: #999999;
            line-height: 1.8;
            overflow: hidden;
        }
    }
}
.el-select-dropdown__item.selected{
    color: #E73522;
}
.empowerUpload{
    display: block;
    width: 20px;
    height: 20px;
    background: url("../assets/images/partner/icon-upload@2x.png") center center no-repeat;
    background-size: cover;
}
@media only screen and (max-width: 750px) {
    .ban{
        height: 2rem;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .partner{
        padding: 1.4rem 0 0;
    }
    .parSortBg{
        line-height: .8rem;
        .parSort{
            display: flex;
            li{
                font-size: .28rem;
            }
        }
    }

    .parSignBg{
        padding: .3rem 0 .6rem;
    }
    .parSign{
        padding: .3rem .3rem .6rem;
        .parSignTips{
            font-size: .24rem;
            margin: .2rem auto;
        }
        .parSignBtn{
            width: 100%;
            max-width: 280px;
            line-height: .7rem;
            font-size: .28rem;
            margin: .1rem auto;
        }
    }
    .parSignForm{
        margin-bottom: .3rem;
    }
    .parSignItem{
        margin: 0 -.1rem;
        .parSignCon{
            width: 100%;
            padding: 5px .1rem;
            .parSignLabel{
                font-size: .24rem;
                padding: .1rem 0;
                span{
                    margin-left: .1rem;
                }
            }
            .el-select{
                display: block;
                ::v-deep .el-input__inner{
                    height: .7rem;
                    line-height: .7rem;
                    font-size: .24rem;
                }
            }
            .el-input{
                display: block;
                ::v-deep .el-input__inner{
                    height: .7rem;
                    line-height: .7rem;
                    font-size: .24rem;
                }
            }
        }
        .parSignfujian{
            padding: .1rem .2rem;
            .empowerImg{
                width: 2rem;
                height: 2rem;
                margin-right: .2rem;
                .empowerIcon{
                    width: .4rem;
                    height: .4rem;
                }
            }
            .empowerTips{
                font-size: .24rem;
            }
        }
    }
    .el-select-dropdown__item{
        height: auto;
        font-size: .28rem;
        line-height: 2;
    }
    
}
</style>