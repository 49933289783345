<template>
    <div class="register">
        <Header />
        <div class="m-regform">
            <div class="wp w1200">
                <div class="regformbox">
                    <div class="regform">
                        <div class="tit">个人账号注册</div>
                        <el-form ref="registerForm" :model="form" :rules="rules" class="boxform">
                            
							<div class="items-cont">
							<div class="item" style="width: 100%;">
                                <div class="con">
                                    <div class="label">真实姓名</div>
                                    <el-form-item class="libipt" prop="realname">
                                        <!-- <input type="email" v-model="form.email" name="" placeholder="请输入电子邮箱地址" /> -->
                                        <el-input
                                            v-model="form.realname"
                                            type="realname"
                                            placeholder="请输入真实姓名"
                                        ></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                            <!-- <div class="item rd">
                                <div class="con">
                                    <div class="label">合作伙伴身份</div>
                                    <el-form-item class="libipt radio" prop="iden">
                                        <el-select
                                            v-model="form.iden"
                                            multiple
                                            collapse-tags
                                            style="width: 100%;"
                                            placeholder="请选择合作伙伴身份"
                                        >
                                            <el-option
                                                v-for="item in idenList"
                                                :key="item.id"
                                                :label="item.idenName"
                                                :value="item.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div> -->
                            <div class="item">
                                <div class="con">
                                    <div class="label">公司名称</div>
                                    <el-form-item class="libipt" prop="companyname">
										
										<el-select style="width:100%;" remote reserve-keyword :remote-method="remoteMethod" v-model="form.companyname" :filterable="true" placeholder="请输入公司名称">
											<!-- <el-option label="无" value="0"></el-option> -->
											<el-option v-for="item in userList" :key="item.id+'user'" :label="item.name" :value="item.name"></el-option>
										</el-select>
                                        <!-- <input type="text" v-model="form.companyname" name="" placeholder="请输入公司名称" /> -->
                                        <!-- <el-input
                                            v-model="form.companyname"
                                            type="text"
                                            autocomplete="off"
                                            placeholder="请输入公司名称"
                                        ></el-input> -->
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="item">
                                <div class="con">
                                    <div class="label">电子邮箱</div>
                                    <el-form-item class="libipt" prop="email">
                                        <!-- <input type="email" v-model="form.email" name="" placeholder="请输入电子邮箱地址" /> -->
                                        <el-input
                                            v-model="form.email"
                                            type="email"
                                            placeholder="请输入电子邮箱地址"
                                        ></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="item">
                                <div class="con">
                                    <div class="label">职位</div>
                                    <el-form-item class="libipt" prop="post">
                                        <!-- <input type="text" v-model="form.post" name="" placeholder="请输入您的职位" /> -->
                                        <el-input
                                            v-model="form.post"
                                            type="text"
                                            placeholder="请输入您的职位"
                                        ></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="item">
                                <div class="con">
                                    <div class="label">密码</div>
                                    <el-form-item class="libipt" prop="password">
                                        <!-- <input type="password" v-model="form.password" name="" placeholder="请输入您的密码" /> -->
                                        <el-input
                                            v-model="form.password"
                                            type="password"
                                            autocomplete="off"
                                            placeholder="请输入您的密码"
                                        ></el-input>
                                    </el-form-item>
                                </div>
                            </div>
							
							<div class="item ico">
							    <div class="con">
							        <div class="label">验证码</div>
							        <!-- <div class="libipt btn"> -->
							        <el-form-item class="libipt btn" prop="captcha">
							            <!-- <input type="text" v-model="form.captcha" name="" placeholder="请输入验证码" /> -->
							            <el-input
							                v-model="form.captcha"
							                type="text"
							                placeholder="请输入验证码"
							            ></el-input>
							            <div class="icon" @click="getCaptcha">
							                <!-- <img src="../assets/images/register/yzm.png" > -->
							                <img :src="captchaImg" />
							            </div>
							        </el-form-item>
							        <!-- </div> -->
							    </div>
							</div>
                            <div class="item">
                                <div class="con">
                                    <div class="label">手机号</div>
                                    <!-- <div class="libipt"> -->
                                    <el-form-item class="libipt" prop="telephone">
                                        <!-- <input type="number" v-model="form.telephone" name="" placeholder="请输入您的手机号码" /> -->
                                        <el-input
                                            v-model="form.telephone"
                                            type="number"
                                            placeholder="请输入您的手机号码"
                                        ></el-input>
                                    </el-form-item>
                                    <!-- </div> -->
                                </div>
                            </div>
                            <div class="item">
                                <div class="con">
                                    <div class="label">昵称</div>
                                    <!-- <div class="libipt"> -->
                                    <el-form-item class="libipt" prop="nickname">
                                        <!-- <input type="text" name="" v-model="form.username" placeholder="请输入您在论坛的昵称" /> -->
                                        <el-input
                                            v-model="form.nickname"
                                            type="text"
                                            placeholder="请输入昵称"
                                        ></el-input>
                                    </el-form-item>
                                    <!-- </div> -->
                                </div>
                            </div>
                            <div class="item ico">
                                <div class="con">
                                    <div class="label">短信验证码</div>
                                    <!-- <div class="libipt btn b2"> -->
                                    <el-form-item class="libipt btn b2" prop="smsCaptcha">
                                        <!-- <input type="text" v-model="form.smsCaptcha" name="" placeholder="请输入短信验证码" /> -->
                                        <el-input
                                            v-model="form.smsCaptcha"
                                            type="text"
                                            placeholder="请输入短信验证码"
                                        ></el-input>
                                        <div class="icon" v-if="second == 0" @click="sendSms">发送验证码</div>
                                        <div class="icon-dis" v-else>{{ second }}秒后重新发送</div>
                                    </el-form-item>
                                    <!-- </div> -->
                                </div>
                            </div>
                            <!-- <div class="item">
                                <div class="con">
                                    <div class="label">邀请码</div>
                                    <el-form-item class="libipt" prop="code">
                                        <el-input
                                            v-model="form.code"
                                            type="text"
                                            placeholder="请输入邀请码"
                                        ></el-input>
                                    </el-form-item>
                                </div>
                            </div> -->
                            <!-- <div class="check">
                                <input type="checkbox" v-model="agree" name="" />
                                我已知晓并同意<a href="">商汤合作伙伴网站用户协议</a>及<a href="">隐私政策</a>
                            </div>-->
							</div>
                            <div class="check">
                                <input type="checkbox" id="ckbox_1" v-model="agree" name />
                                <label for="ckbox_1" class="ckb">
                                    我已知晓并同意
                                    <router-link to="/protocol?type=4">商汤合作伙伴网站用户协议</router-link>和
                                    <router-link to="/protocol?type=2">隐私条款</router-link>
                                </label>
                            </div>
                            <div class="sbmbtns">
                                <button class="btn b1" @click.prevent="submit">注册</button>
                                <div class="btn b2">
                                    <router-link to="/login?type=1">已有账号，去登录</router-link>
                                </div>
                            </div>
                        </el-form>
                    </div>
                    <div class="bottom">
                        <a href>沪 ICP 备 19044592 号-3</a> |
                        <a href>沪公网安备 31010402009327号</a> |
                        <a href>© 2014-2021 SenseTime. All Rights Reserved. 上海商汤智能科技有限公司</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Header from '@/components/Header.vue';
import Api from '@/request/api.js'
export default {
    components: { Header },
    data() {
        // 手机号验证
        const validatePhone = (rule, value, callback) => {
            if (!/^1[3456789]\d{9}$/.test(value)) {
                callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        }
        const validateEmail = (rule, value, callback) => {
            if (!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/.test(value)) {
                callback(new Error("请输入正确的邮箱"));
            } else {
                callback();
            }
        }
        const validatePassword = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请填写密码'))
            }
            if (!/(?![\d]+$)(?![a-zA-Z]+$)/.test(value)) {
                callback(new Error("至少一个字母和一个数字"));
            } else {
                if (!/^(?![\d]+$)(?![a-zA-Z]+$)[\da-zA-Z-=+_.,]{8,}$/.test(value)) {
                    callback(new Error("至少八个字符，必须包含字母和数字，且不能有特殊字符"));
                } else {
                    callback();
                }
            }
        }
        return {
            form: {
                avatar: '',
                realname: '',
                iden: [],
                companyname: '', // 公司名称
                post: '', // 职位
                email: '', // 电子邮箱
                nickname: '', // 昵称
                password: '', // 密码
                telephone: '', // 手机号
                smsCaptcha: '', // 短信验证码
                code: '', // 邀请码
                captcha: '', // 验证码
                checkKey: '', // 验证码key
            },
            idenList: [],
            agree: false,
            captchaImg: null,
            second: 0,
            timer: null,
            rules: {
                realname: [
                    { required: true, message: '请填写真实姓名', trigger: 'blur' }
                ],
                iden: [
                    { required: true, message: '请选择合作伙伴身份', trigger: 'blur' }
                ],
                companyname: [
                    { required: true, message: '请填写公司名称', trigger: 'blur' }
                ],
                post: [
                    { required: true, message: '请填写职位', trigger: 'blur' }
                ],
                email: [
                    { required: true, message: '请填写电子邮箱', trigger: 'blur', },
                    { validator: validateEmail, trigger: 'blur' }
                ],
                nickname: [
                    { required: true, message: '请填写昵称', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请填写密码', trigger: 'change' },
                    { validator: validatePassword, trigger: 'blur' }
                ],
                telephone: [
                    { required: true, message: '请填写手机号', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' }
                ],
                smsCaptcha: [
                    { required: true, message: '请填写短信验证码', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请填写邀请码', trigger: 'blur' }
                ],
                captcha: [
                    { required: true, message: '请填写验证码', trigger: 'blur' }
                ],
            },
			userList:[]
        }
    },
    created() {
        // 获取身份列表
        this.getIdenList()
        // 获取验证码
        this.getCaptcha()
    },
    methods: {
        // 随机生成字符串
        randomString(e) {
            e = e || 32;
            var t = "1234567890",
                a = t.length,
                n = "";
            for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
            return n
        },
        // 获取身份列表
        getIdenList() {
            Api.idenList().then(res => {
                this.idenList = res
            })
        },
        // 获取验证码
        getCaptcha() {
            const key = this.randomString(6)
            // console.log(key)
            this.form.checkKey = key
            Api.getCaptcha({ key }).then(res => {
                this.captchaImg = res
            })
        },
        // 发送短信验证码
        sendSms() {
            if (!this.form.telephone) {
                return this.$message.error('请先输入手机号')
            }
            if (!/^1[3456789]\d{9}$/.test(this.form.telephone)) {
                return this.$message.error('请输入正确的手机号')
            }
            Api.sendSms({ mobile: this.form.telephone, smsmode: 1 }).then(res => {
                console.log(res)
                this.second = 60
                this.timer = setInterval(() => {
                    this.second--
                    if (this.second == 0) {
                        clearInterval(this.timer)
                    }
                }, 1000)
                this.$message.success('短信发送成功');
            })
        },
		
		remoteMethod(query){
			Api.searchCompanyList(query).then(res => {
				if(res != null){
					this.userList = res.items;
				}else{
					this.userList = [];
				}
			    
			})
			
		},
        submit() {
            this.$refs['registerForm'].validate(async (valid) => {
                if (valid) {
                    if (!this.agree) {
                        return this.$message.info('请勾选协议')
                    }
                    if (!Array.isArray(this.form.iden)) {
                        this.form.iden = [this.form.iden]
                    }
                    await Api.register(this.form)
                    this.$message.success('注册成功')
                    this.$router.replace({ path: '/login' })
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
/* .m-regform{
    background: url(../assets/images/register/bg1.png) no-repeat center;
    background-size: cover;
    width: 100%;
    height:calc(100vh - 80px);
    position: relative;
	
}
.m-regform .regformbox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.m-regform .regform{
    width: 960px;
    box-sizing: border-box;
    padding:35px 50px;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
    
}
.m-regform .regform .tit{
    font-size: 24px;
    color: #111;
    line-height: 1;
    margin-bottom: 20px;
}

.m-regform .boxform{
    margin: 0 -30px;
}
.m-regform .item{
    float: left;
    width: 50%;
    margin-bottom: 28px;
}
.m-regform .item .con{
    margin: 0 30px;
}
.m-regform .item .label{
    font-size: 14px;
    color: #2E2E2E;
    position: relative;
    display: inline-block;
}
.m-regform .item .label::after{
    content: '*';
    position: absolute;
    top: 1px;
    right: -10px;
    font-size: 14px;
    color: #E83421;
}
.m-regform .item .libipt{
    padding: 10px 0 0;
    border-bottom: 2px solid #E4E4E4;
}
.m-regform .item .libipt input{
    font-size: 16px;
	border: none;
    width: 100%;
    outline: none;
}



::v-deep .m-regform .el-input__inner{
	border: none ;
	padding-left: 0;
}
::v-deep .m-regform .item.rd .el-form-item__error{
	padding-top: 20px !important;
}
::v-deep .m-regform .item.ico .el-input__inner{
	max-width: 70%;
}

.m-regform input::-webkit-input-placeholder { 
    color:#989898;
}
.m-regform .item .libipt .radio{
    font-size: 16px;
    margin-bottom: 5px;
}
.m-regform .item .libipt .radio.nomb{
    margin-bottom: 0 ;
}
.m-regform .item.rd{
	transform: translateY(6px);
}
.m-regform .item.rd .libipt{
	padding-bottom: 15px;
}
label.ckb{
	cursor: pointer;
	margin-left: 5px;
}
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
    transition: background-color 5000s ease-in-out 0s !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
}
input[type="number"] {
-moz-appearance: textfield;
}
.m-regform input[type="checkbox"],
.m-regform .item.rd .libipt input[type="radio"],
.m-regform .item.rd .libipt input[type="checkbox"]{
    width: 16px;
    height: 16px;
    background: url(../assets/images/register/icon1.png) no-repeat center;
    background-size: 100% 100%;
    outline: none;
    margin-right:5px;
    -webkit-appearance: none;
    transform: translateY(3px);
    cursor: pointer;
    }
.m-regform input[type="checkbox"]:checked,
.m-regform .item.rd .libipt input[type="radio"]:checked,
.m-regform .item.rd .libipt input[type="checkbox"]:checked{
    background-image: url(../assets/images/register/icon1-2.png);
    }
    
    

.m-regform .item .libipt.btn {
    position: relative;
}
.m-regform .item .libipt.btn input{
    width: 50%;
}
    
.m-regform .item .libipt.btn .icon{
    position: absolute;
    bottom: 5px;
    right: 0;
    width: 97px;
    height: 38px;
    cursor: pointer;
}
.m-regform .item .libipt.btn .icon-dis{
    position: absolute;
    bottom: -5px;
    right: 0;
    width: 97px;
    height: 38px;
    cursor: pointer;
}
.m-regform .item .libipt.btn .icon img{
	position: relative;
	bottom: -4px;
}
    
.m-regform .item .libipt.btn.b2 .icon{
    width: 101px;
    height: 37px;
    border: 1px solid #E73522;
    border-radius: 3px;
    text-align: center;
    line-height: 37px;
    color: #E73522;
}
.m-regform .item .libipt.btn.b2 .icon-dis{
    width: auto;
    height: 37px;
    border: 1px solid #999999;
    border-radius: 3px;
    text-align: center;
    line-height: 37px;
    color: #999999;
}
.m-regform .check{
    text-align: center;
    font-size: 14px;
    margin-bottom: 20px;
}
.m-regform .check a{
    color: #E73522;
}
.m-regform .sbmbtns{text-align: center;}
.m-regform .sbmbtns .btn{
    display: inline-block;
    width: 280px;
    height: 50px;
    border: none;
    outline: none;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    color: #fff;
    background-color: #E73522;
    margin: 0 8px;
    cursor: pointer;
    transition: .5s;
}
.m-regform .sbmbtns .btn:hover{
    box-shadow: 0 0 5px rgba(0,0,0,.5);
}
.m-regform .sbmbtns .btn.b2{
    background-color: #E3E4E5;
    color: #2E2E2E;
}
.m-regform .bottom,
.m-regform .bottom a{
    font-size: 14px;
    color: #fff;
    text-align: center;
}
.m-regform .bottom a:hover{
    text-shadow: 2px 1px #E73522;
}

::v-deep .el-form-item__content {
    line-height: normal;
}
::v-deep .el-form-item {
    margin-bottom: 0;
} */

body{
	padding-top: 80px;
}
.m-regform {
    background: url(../assets/images/register/bg1.png) no-repeat center;
    background-size: cover;
    width: 100%;
    height: calc(100vh - 80px);
    position: relative;
}
.m-regform .regformbox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.m-regform .regform {
    width: 88.88vh;
    box-sizing: border-box;
    padding: 2.24vh 3.629vh;
    background-color: #fff;
    border-radius: 0.462vh;
    margin-bottom: 1.851vh;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.m-regform .regform .tit {
    font-size: 2.222vh;
    color: #111;
    line-height: 1;
    margin-bottom: 1.851vh;
}

.m-regform .boxform {
    margin: 0 -2.777vh;
}
.m-regform .item {
    /* float: left; */
    display: inline-block;
    width: 50%;
    margin-bottom: 2vh;
    vertical-align: text-bottom;
}
.m-regform .item .con {
    margin: 0 2.777vh;
}
.m-regform .item .label {
    font-size: 1.296vh;
    color: #2e2e2e;
    position: relative;
    display: inline-block;
}
.m-regform .item .label::after {
    content: "*";
    position: absolute;
    top: 0.092vh;
    right: -0.925vh;
    font-size: 1.296vh;
    color: #e83421;
}
.m-regform .item .libipt {
    /* padding: 0.925vh 0 0; */
    border-bottom: 0.185vh solid #e4e4e4;
}
.m-regform .item .libipt input {
    font-size: 1.481vh;
    border: none;
    width: 100%;
    outline: none;
}

::v-deep .m-regform .el-input__inner {
    border: none;
    padding-left: 0;
}
::v-deep .m-regform .item.rd .el-form-item__error {
    /* padding-top: 1.851vh !important; */
}
::v-deep .m-regform .item.ico .el-input__inner {
    max-width: 70%;
}

.m-regform input::-webkit-input-placeholder {
    color: #989898;
}
.m-regform .item .libipt .radio {
    font-size: 1.481vh;
    margin-bottom: 0.462vh;
}
.m-regform .item .libipt .radio.nomb {
    margin-bottom: 0;
}
.m-regform .item.rd {
    /* transform: translateY(0.555vh); */
}
.m-regform .item.rd .libipt {
    padding-bottom: 1.388vh;
}
label.ckb {
    cursor: pointer;
    margin-left: 0.462vh;
}
::v-deep input:-internal-autofill-previewed,
::v-deep input:-internal-autofill-selected {
    transition: background-color 5000s ease-in-out 0s !important;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}
::v-deep input[type="number"] {
    -moz-appearance: textfield;
}
::v-deep .m-regform .item.rd .libipt {
    padding-bottom: 0;
}
.m-regform input[type="checkbox"],
.m-regform .item.rd .libipt input[type="radio"],
.m-regform .item.rd .libipt input[type="checkbox"] {
    width: 1.481vh;
    height: 1.481vh;
    background: url(../assets/images/register/icon1.png) no-repeat center;
    background-size: 100% 100%;
    outline: none;
    margin-right: 0.462vh;
    -webkit-appearance: none;
    transform: translateY(0.277vh);
    cursor: pointer;
}
.m-regform input[type="checkbox"]:checked,
.m-regform .item.rd .libipt input[type="radio"]:checked,
.m-regform .item.rd .libipt input[type="checkbox"]:checked {
    background-image: url(../assets/images/register/icon1-2.png);
}

.m-regform .item .libipt.btn {
    position: relative;
}
.m-regform .item .libipt.btn input {
    width: 50%;
}

.m-regform .item .libipt.btn .icon {
    position: absolute;
    bottom: 0.462vh;
    right: 0;
    width: 8.981vh;
    height: 3.518vh;
    cursor: pointer;
}
.m-regform .item .libipt.btn .icon-dis {
    position: absolute;
    bottom: -0.462vh;
    right: 0;
    width: 8.981vh;
    height: 3.518vh;
    cursor: pointer;
}
.m-regform .item .libipt.btn .icon img {
    position: relative;
    bottom: -0.37vh;
}

.m-regform .item .libipt.btn.b2 .icon {
    width: auto;
    height: 3.425vh;
	padding: 0 1vw;
    border: 0.092vh solid #e73522;
    border-radius: 0.277vh;
    text-align: center;
    line-height: 3.425vh;
    color: #e73522;
}
.m-regform .item .libipt.btn.b2 .icon-dis {
    /* width: 10.27vh; */
    width: auto;
    padding: 0 1vw;
    height: 3.425vh;
    border: 0.092vh solid #999999;
    border-radius: 0.277vh;
    text-align: center;
    line-height: 3.425vh;
    color: #999999;
    bottom: 0.538vh;
}
.m-regform .check {
    text-align: center;
    font-size: 1.296vh;
    margin-bottom: 1.851vh;
}
.m-regform .check a {
    color: #e73522;
}
.m-regform .sbmbtns {
    text-align: center;
}
.m-regform .sbmbtns .btn {
    display: inline-block;
    width: 25.92vh;
    height: 4.629vh;
    border: none;
    outline: none;
    text-align: center;
    line-height: 4.629vh;
    font-size: 1.481vh;
    color: #fff;
    background-color: #e73522;
    margin: 0 0.74vh;
    cursor: pointer;
    transition: 0.5s;
}
.m-regform .sbmbtns .btn:hover {
    box-shadow: 0 0 0.462vh rgba(0, 0, 0, 0.5);
}
.m-regform .sbmbtns .btn.b2 {
    background-color: #e3e4e5;
    color: #2e2e2e;
}
.m-regform .bottom{
	width: 90vw;
}
.m-regform .bottom,
.m-regform .bottom a {
    font-size: 1.296vh;
    color: #fff;
    text-align: center;
    /* height: 2vh; */
    /* overflow-y: auto; */
}
.m-regform .bottom::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}
.m-regform .bottom {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
}
.m-regform .bottom a:hover {
    text-shadow: 0.185vh 0.092vh #e73522;
}

::v-deep .el-form-item__content {
    line-height: normal;
}
::v-deep .el-form-item {
    margin-bottom: 0;
}

.items-cont{
	max-height: 55vh;
	overflow-y: auto;
}

@media only screen and (max-width: 750px) {
    body{
        padding-top: 0 !important;
    }
    .m-regform{
        // height: 100vh;
        height: auto;
        min-height: calc(100vh - 80px);
    }
    .m-regform .boxform{
        margin: 0;
    }
    .m-regform .regformbox{
        position: static;
        transform: translate(0);
        padding: .3rem 0;
        min-height: calc(100vh - 90px);
    }
    .m-regform .regform{
        width: 100%;
        padding: .3rem;
        margin: 0;
    }
    .m-regform .regform .tit{
        font-size: .28rem;
    }
    .items-cont{
        max-height: none;
    }
    .m-regform .item{
        display: block;
        width: 100%;
        margin-bottom: .4rem;
    }
    .m-regform .item .con{
        margin: 0;
    }
    .m-regform .item .label{
        font-size: .24rem;
        &::after{
            font-size: .24rem;
            right: -.16rem;
            top: 0;
        }
    }
    .m-regform .item .libipt{
        border-bottom-width: 1px;
    }
    .m-regform .item .libipt.btn .icon{
        width: 1.6rem;
        height: .6rem;
        bottom: .1rem;
    }
    .m-regform .item .libipt.btn.b2 .icon-dis,
    .m-regform .item .libipt.btn.b2 .icon{
        height: .6rem;
        font-size: .24rem;
        line-height: .58rem;
        padding: 0 .1rem;
        border-width: 1px;
    }
    ::v-deep .m-regform .el-input__inner {
        display: block;
        height: .7rem;
        font-size: .24rem;
        line-height: .7rem;
        padding: 0;
    }
    ::v-deep .el-form-item__error{
        font-size: .24rem;
        line-height: 1.2;
    }
    


    .m-regform .check{
        font-size: .24rem;
        margin-bottom: .2rem;
    }
    .m-regform input[type="checkbox"],
    .m-regform .item.rd .libipt input[type="radio"],
    .m-regform .item.rd .libipt input[type="checkbox"] {
        width: .3rem;
        height: .3rem;
        background: url(../assets/images/register/icon1.png) no-repeat center;
        background-size: 100% 100%;
        outline: none;
        margin-right: 0;
        transform: translateY(.04rem);
        cursor: pointer;
    }
    .m-regform .sbmbtns{
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;
    }
    .m-regform .sbmbtns .btn{
        width: 48%;
        height: .64rem;
        font-size: .28rem;
        line-height: .64rem;
    }

    .m-regform .bottom{
        font-size: .24rem;
    }
}
</style>